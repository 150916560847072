import * as React from "react";
import { StringMobx } from "../../../mobxDataModels/stringModel";
import { request } from "../../../services/cpln";
import { FormElement } from "../../../components/forms/formElement";
import { AccountSummary } from "../../../mobxStores/userData/userData";
import { observer } from "mobx-react-lite";
import { NGFormElement } from "../../../newcomponents/ngformelement/ngformelement";
import { ListOfItemsMobx } from "../../../mobxDataModels/listOfItemsModel";
import { NGInputListMst } from "../../../newcomponents/inputList/inputListMst";
import { NGCombobox } from "../../../newcomponents/select/ngcombobox";
import NGAlert from "../../../newcomponents/alert";

interface Props {
  selectedAccount: AccountSummary;
  invitees: ListOfItemsMobx;
  name: StringMobx;
  description: StringMobx;
  permissionError: boolean | "loading";
  error: string;
}
const OrgCreateGeneralRaw: React.FC<Props> = ({
  selectedAccount,
  invitees,
  name,
  description,
  permissionError,
  error,
}) => {
  const [userEmails, setUserEmails] = React.useState<string[]>([]);
  const [options, setOptions] = React.useState<{ value: string }[]>([]);

  React.useEffect(() => {
    request({ service: "billing-ng", url: `/account/${selectedAccount.id}/user` }).then((res: any) => {
      setUserEmails(res.data.items.map((item: any) => item.email));
    });
  }, []);

  React.useEffect(() => {
    updateOrgAdminOptions();
  }, [userEmails, invitees.hash]);

  function updateOrgAdminOptions() {
    setOptions(
      userEmails
        .filter((email) => !invitees.items.map((item) => item.firstValue).includes(email))
        .map((email) => ({ value: email, label: email })),
    );
  }

  return (
    <>
      <NGFormElement
        name={"orgname"}
        label={name.label}
        value={name.value}
        onChange={name.setValue}
        required={name.isRequired}
        error={name.error}
      />
      <NGFormElement
        name={"orgdescription"}
        label={description.label}
        value={description.value}
        onChange={description.setValue}
        required={description.isRequired}
        error={description.error}
      />
      {permissionError ? null : (
        <>
          <div className="mb-4">
            <FormElement
              label={"Billing Account"}
              value={selectedAccount.accountName || selectedAccount.fullName || ""}
            />
          </div>
          <NGAlert className="mb-2" style={{ maxWidth: 450 }} message={"You can invite additional org admins."} />
          <NGInputListMst
            error={error}
            data={invitees}
            styles={{ container: { maxWidth: 450 } }}
            firstInput={(item) => (
              <NGCombobox
                options={options}
                name={"orgAdmin"}
                value={item.firstValue}
                onChange={(e) => item.setFirstValue(e)}
              />
            )}
            label="Org Admins"
          />
        </>
      )}
    </>
  );
};

export const OrgCreateGeneral = observer(OrgCreateGeneralRaw);
