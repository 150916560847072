import { observer } from "mobx-react-lite";
import * as React from "react";
import { Mk8sDraftAutoscalerMobx } from "../../../mst/stores/mk8s.draft.providerAutoscaler";
import { NGLabel } from "../../../newcomponents/text/label";
import { NGSelect } from "../../../newcomponents/select/ngselect";
import { NGInputListMst } from "../../../newcomponents/inputList/inputListMst";
import { NGFormElement } from "../../../newcomponents/ngformelement/ngformelement";
import { InfoTooltip } from "../../../components/InfoTooltip";
import { DryRunAlert } from "./../dryRunAlert";
import { mk8sDryRun } from "../../../mobxStores/dryRun/mk8s";
import { getDryRunErrorText } from "./../getDryRunErrorText";

interface Props {
  autoscaler: Mk8sDraftAutoscalerMobx;
  provider: string;
}
const ProviderAutoscalerRaw: React.FC<Props> = ({ autoscaler, provider }) => {
  const allOptions = [
    { label: "random", value: "random" },
    { label: "most-pods", value: "most-pods" },
    { label: "least-waste", value: "least-waste" },
    { label: "price", value: "price" },
  ];

  function getAvailableOptions() {
    const currentItems = autoscaler.expanders.items.map((i) => i.firstValue);
    const availableOptions = allOptions.filter((opt) => !currentItems.includes(opt.value));
    return availableOptions;
  }

  function updateAvailableOptions() {
    setExpanderOptions(getAvailableOptions());
  }

  const [expanderOptions, setExpanderOptions] = React.useState(getAvailableOptions());

  React.useEffect(() => {
    updateAvailableOptions();
  }, [autoscaler.expanders.items.length]);

  const expandersDryRun = getDryRunErrorText({
    dryRunResponse: mk8sDryRun.response,
    path: `spec.provider.${provider}.autoscaler.expanders`,
    paths: autoscaler.expanders.items.map((_, idx) => `spec.provider.${provider}.autoscaler.expanders[${idx}]`),
  });

  return (
    <div className="p-4 mb-4 border rounded">
      <div className="mb-4">
        <NGLabel size={2}>Autoscaler</NGLabel>
      </div>

      <div className="flex items-start">
        <NGInputListMst
          data={autoscaler.expanders}
          className="mb-8"
          styles={{ container: { width: 450, minWidth: 450 } }}
          label="Expander"
          noItemLabel={`Default value is ["most-pods"]`}
          canAdd={() => autoscaler.expanders.items.length < 3}
          draggable
          firstInput={(i, index) => {
            let invalid = false;
            if (!i.firstValue) {
              invalid = true;
            }
            if (expandersDryRun.message.split("[")[1]?.split("]")[0] === String(index)) {
              invalid = true;
            }

            return (
              <NGSelect
                value={i.firstValue}
                options={expanderOptions}
                onChange={(v) => i.setFirstValue(v)}
                invalid={invalid}
                allowEmpty={false}
                placeholder="Select an Option"
              />
            );
          }}
          invalid={expandersDryRun.severity === "error"}
          error={expandersDryRun.severity === "error" ? expandersDryRun.message : ""}
          warning={expandersDryRun.severity === "error" ? "" : expandersDryRun.message}
        />
        <div style={{ transform: "translateY(6px)" }}>
          <InfoTooltip
            title={[
              `Type of node group expander to be used in scale up. Available values: [random,most-pods,least-waste,price].`,
              `Specifying multiple values separated by commas will call the expanders in succession until there is only one option remaining.`,
              `Ties still existing after this process are broken randomly. (default "random")`,
            ]}
          />
        </div>
      </div>

      <div className="flex gap-2 items-start">
        <NGFormElement
          name={"autoscaler.unneededTime"}
          label={autoscaler.unneededTime.label}
          value={autoscaler.unneededTime.value}
          onChange={autoscaler.unneededTime.setValue}
          required={autoscaler.unneededTime.isRequired}
          error={autoscaler.unneededTime.error}
          info={["How long a node should be unneeded before it is eligible for scale down (default 10m0s)"]}
        />
        <div className="mt-6">
          <DryRunAlert
            key={mk8sDryRun.key}
            canShow={true}
            dryRunResponse={mk8sDryRun.response}
            onFix={autoscaler.unneededTime.setValue}
            path={`spec.provider.${provider}.autoscaler.unneededTime`}
          />
        </div>
      </div>

      <div className="flex gap-2 items-start">
        <NGFormElement
          name={"autoscaler.unreadyTime"}
          label={autoscaler.unreadyTime.label}
          value={autoscaler.unreadyTime.value}
          onChange={autoscaler.unreadyTime.setValue}
          required={autoscaler.unreadyTime.isRequired}
          error={autoscaler.unreadyTime.error}
          info={["How long an unready node should be unneeded before it is eligible for scale down (default 20m0s)"]}
        />
        <div className="mt-6">
          <DryRunAlert
            key={mk8sDryRun.key}
            canShow={true}
            dryRunResponse={mk8sDryRun.response}
            onFix={autoscaler.unreadyTime.setValue}
            path={`spec.provider.${provider}.autoscaler.unreadyTime`}
          />
        </div>
      </div>

      <div className="flex gap-2 items-start">
        <NGFormElement
          name={"autoscaler.utilizationThreshold"}
          label={autoscaler.utilizationThreshold.label}
          value={autoscaler.utilizationThreshold.value}
          onChange={autoscaler.utilizationThreshold.setValue}
          required={autoscaler.utilizationThreshold.isRequired}
          error={autoscaler.utilizationThreshold.error}
          info={[
            `Sum of cpu or memory of all pods running on the node divided by node's corresponding allocatable resource, below which a node can be considered for scale down (default 0.7)`,
          ]}
        />
        <div className="mt-6">
          <DryRunAlert
            key={mk8sDryRun.key}
            canShow={true}
            dryRunResponse={mk8sDryRun.response}
            onFix={autoscaler.utilizationThreshold.setValue}
            path={`spec.provider.${provider}.autoscaler.utilizationThreshold`}
          />
        </div>
      </div>
    </div>
  );
};

export const ProviderAutoscaler = observer(ProviderAutoscalerRaw);
