import * as React from "react";
import { Tooltip as AntTooltip, notification } from "antd";
import { CreateLayout } from "../../../components/create/layout";
import { Routes, Route, Navigate, useNavigate, useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Mk8sCreateGeneral } from "./general";
import { homeLink, request } from "../../../services/cpln";
import { TagsNew } from "../../../components/create/tags";
import { Mk8sDraftMobx } from "../../../mst/stores/mk8s.draft";
import { mk8sDryRun } from "../../../mobxStores/dryRun/mk8s";
import { IS_DEPLOYMENT_ENV_TEST } from "../../../envVariables";
import { HiddenSectionsMobx } from "../../../mobxStores/hiddenSections";
import { BasePathContext, useBasePath } from "../../../reactContexts/basePathContext";
import { PromptContext } from "../../../mobxStores/prompt/prompt";
import { InnerNavLink } from "../../../components/detail/ngNav";
import { AlertCircle, Plus } from "react-feather";
import { Mk8sCreateProvider } from "./provider/provider";
import { Mk8sCreateAddonsRoute } from "./addons/route";
import { Mk8sCreateFirewall } from "./firewall";
import { NGFormContext } from "../../../reactContexts/ngFormContext";
import { NGFormData } from "../../../mobxStores/ngFormData";
import { useDebounce } from "../../../components/table/useDebounce";

interface Props {
  mk8sDraft: Mk8sDraftMobx;
}
const Mk8sCreateRaw: React.FC<Props> = ({ mk8sDraft }) => {
  const basePath = useBasePath("/mk8s/-create/*");

  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const [debouncedHash, setDebouncedHash] = useDebounce("", 500);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    setDebouncedHash(mk8sDraft.hash);
  }, [mk8sDraft.hash]);
  React.useEffect(() => {
    mk8sDryRun.run(mk8sDraft.asObject);
  }, [debouncedHash]);

  React.useEffect(() => {
    // TODO make params always an object?
    mk8sDryRun.reset(false);

    navigate(`${basePath}/-general`, { replace: true });
  }, []);

  React.useEffect(() => {
    fetchSchemaData();
  }, []);
  async function fetchSchemaData() {
    // Versions
    try {
      const { data: schemaRes } = await request({ url: "/catalog/schema/mk8s" });
      const versions: string[] = schemaRes.keys.spec.keys.version.allow;
      mk8sDraft.version.setOptions(versions.map((v) => ({ label: v, value: v })).reverse());
      mk8sDraft.setDefaultVersion(versions[versions.length - 1]);
    } catch (e) {
      console.error("Failed to get versions from mk8s catalog schema");
    }
  }

  const [previousPathname, setPreviousPathname] = React.useState("");
  const [visitedPages, setVisitedPages] = React.useState<string[]>([]);
  React.useEffect(() => {
    if (!visitedPages.includes(previousPathname)) {
      setVisitedPages((_visitedPages) => [..._visitedPages, previousPathname]);
    }
    setPreviousPathname(pathname);
  }, [pathname]);
  function visited(pathSection: string) {
    return visitedPages.some((visitedPath) => visitedPath.includes(pathSection));
  }

  async function onConfirm() {
    try {
      setIsLoading(true);
      const url = homeLink("mk8s");
      await request({ method: "post", url, body: mk8sDraft.asObject });
      setIsLoading(false);
      notification.success({
        message: "Success",
        description: "Created Kubernetes Cluster",
      });
      PromptContext.setIsDisabled(true);
      navigate(`/console${url}/${mk8sDraft.name.value}`);
    } catch (e) {
      setIsLoading(false);
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.error({
        message: "Failed",
        description: errorMessage,
      });
    }
  }

  function shouldBlockNavigation(nextLocation: any, currentLocation: any) {
    const { pathname: nextPathname } = nextLocation;
    return !nextPathname.includes("/mk8s/-create");
  }

  const formDataRef = React.useRef(new NGFormData());
  const formData = formDataRef.current;

  const dryrunErrors = (mk8sDryRun.response?.details.issues || []).filter((i) => i.severity === "error");

  // Update when new addon is added, below list is the list of addons which doesn't have their own config page.
  const anyAddonsDryRun = dryrunErrors.some((i) =>
    [
      "spec.addOns", //
      "spec.addOns.awsWorkloadIdentity",
      "spec.addOns.dashboard",
      "spec.addOns.localPathStorage",
      "spec.addOns.sysbox",
    ].includes(i.path),
  );

  const awsDryRun = dryrunErrors.some((i) => {
    for (const pathItem of mk8sDraft.provider_aws.dryRun_awsPathList) {
      if (i.path.includes(pathItem)) {
        return true;
      }
    }
    return false;
  });
  const awsAdvancedDryRun = dryrunErrors.some((i) => {
    for (const pathItem of mk8sDraft.provider_aws.dryRun_advancedPathList) {
      if (i.path.includes(pathItem)) {
        return true;
      }
    }
    return false;
  });

  const digitaloceanDryRun = dryrunErrors.some((i) => {
    for (const pathItem of mk8sDraft.provider_digitalocean.dryRun_digitaloceanPathList) {
      if (i.path.includes(pathItem)) {
        return true;
      }
    }
    return false;
  });
  const digitaloceanAdvancedDryRun = dryrunErrors.some((i) => {
    for (const pathItem of mk8sDraft.provider_digitalocean.dryRun_advancedPathList) {
      if (i.path.includes(pathItem)) {
        return true;
      }
    }
    return false;
  });

  const ephemeralDryRun = dryrunErrors.some((i) => {
    for (const pathItem of mk8sDraft.provider_ephemeral.dryRun_ephemeralPathList) {
      if (i.path.includes(pathItem)) {
        return true;
      }
    }
    return false;
  });

  const genericDryRun = dryrunErrors.some((i) => {
    for (const pathItem of mk8sDraft.provider_generic.dryRun_genericPathList) {
      if (i.path.includes(pathItem)) {
        return true;
      }
    }
    return false;
  });
  const genericAdvancedDryRun = dryrunErrors.some((i) => {
    for (const pathItem of mk8sDraft.provider_generic.dryRun_advancedPathList) {
      if (i.path.includes(pathItem)) {
        return true;
      }
    }
    return false;
  });

  const hetznerDryRun = dryrunErrors.some((i) => {
    for (const pathItem of mk8sDraft.provider_hetzner.dryRun_hetznerPathList) {
      if (i.path.includes(pathItem)) {
        return true;
      }
    }
    return false;
  });
  const hetznerAdvancedDryRun = dryrunErrors.some((i) => {
    for (const pathItem of mk8sDraft.provider_hetzner.dryRun_advancedPathList) {
      if (i.path.includes(pathItem)) {
        return true;
      }
    }
    return false;
  });

  const lambdalabsDryRun = dryrunErrors.some((i) => {
    for (const pathItem of mk8sDraft.provider_lambdalabs.dryRun_lambdalabsPathList) {
      if (i.path.includes(pathItem)) {
        return true;
      }
    }
    return false;
  });
  const lambdalabsAdvancedDryRun = dryrunErrors.some((i) => {
    for (const pathItem of mk8sDraft.provider_lambdalabs.dryRun_advancedPathList) {
      if (i.path.includes(pathItem)) {
        return true;
      }
    }
    return false;
  });

  const linodeDryRun = dryrunErrors.some((i) => {
    for (const pathItem of mk8sDraft.provider_linode.dryRun_linodePathList) {
      if (i.path.includes(pathItem)) {
        return true;
      }
    }
    return false;
  });
  const linodeAdvancedDryRun = dryrunErrors.some((i) => {
    for (const pathItem of mk8sDraft.provider_linode.dryRun_advancedPathList) {
      if (i.path.includes(pathItem)) {
        return true;
      }
    }
    return false;
  });

  const oblivusDryRun = dryrunErrors.some((i) => {
    for (const pathItem of mk8sDraft.provider_oblivus.dryRun_oblivusPathList) {
      if (i.path.includes(pathItem)) {
        return true;
      }
    }
    return false;
  });
  const oblivusAdvancedDryRun = dryrunErrors.some((i) => {
    for (const pathItem of mk8sDraft.provider_oblivus.dryRun_advancedPathList) {
      if (i.path.includes(pathItem)) {
        return true;
      }
    }
    return false;
  });

  const paperspaceDryRun = dryrunErrors.some((i) => {
    for (const pathItem of mk8sDraft.provider_paperspace.dryRun_paperspacePathList) {
      if (i.path.includes(pathItem)) {
        return true;
      }
    }
    return false;
  });
  const paperspaceAdvancedDryRun = dryrunErrors.some((i) => {
    for (const pathItem of mk8sDraft.provider_paperspace.dryRun_advancedPathList) {
      if (i.path.includes(pathItem)) {
        return true;
      }
    }
    return false;
  });

  const tritonDryRun = dryrunErrors.some((i) => {
    for (const pathItem of mk8sDraft.provider_triton.dryRun_tritonPathList) {
      if (i.path.includes(pathItem)) {
        return true;
      }
    }
    return false;
  });
  const tritonAdvancedDryRun = dryrunErrors.some((i) => {
    for (const pathItem of mk8sDraft.provider_triton.dryRun_advancedPathList) {
      if (i.path.includes(pathItem)) {
        return true;
      }
    }
    return false;
  });

  const ngLinks: (InnerNavLink & { isActive: boolean })[] = [
    // TODO change "isActive" property name to "disabled"
    {
      label: "General",
      url: `-general`,
      isActive: true,
      renderIcon:
        !pathname.endsWith("/-general") && visited("/-general") && !mk8sDraft.isGeneralValid
          ? () => (
              <AntTooltip mouseEnterDelay={0} title={"Click to see details"}>
                <AlertCircle className="ml-1 h-4 color-danger" />
              </AntTooltip>
            )
          : undefined,
    },
    {
      label: "Provider",
      url: `-provider`,
      disableFold: true,
      isActive: mk8sDraft.isGeneralValid,
      links: [
        {
          label: mk8sDraft.provider.options.find((opt) => opt.value === mk8sDraft.provider.value)!.label,
          url: mk8sDraft.provider.value,
          visible: !!mk8sDraft.provider.value,
          isActive: mk8sDraft.isGeneralValid,
          renderIcon:
            mk8sDraft.provider.value === "aws"
              ? !pathname.endsWith("/aws") && visited("/aws") && (!mk8sDraft.provider_aws.ui_isAwsValid || awsDryRun)
                ? () => (
                    <AntTooltip title={"AWS is not configured correctly."}>
                      <AlertCircle className="ml-1 h-4 color-danger" />
                    </AntTooltip>
                  )
                : undefined
              : mk8sDraft.provider.value === "digitalocean"
              ? !pathname.endsWith("/digitalocean") &&
                visited("/digitalocean") &&
                (!mk8sDraft.provider_digitalocean.ui_isDigitalOceanValid || digitaloceanDryRun)
                ? () => (
                    <AntTooltip title={"Digital Ocean is not configured correctly."}>
                      <AlertCircle className="ml-1 h-4 color-danger" />
                    </AntTooltip>
                  )
                : undefined
              : mk8sDraft.provider.value === "ephemeral"
              ? !pathname.endsWith("/ephemeral") &&
                visited("/ephemeral") &&
                (!mk8sDraft.provider_ephemeral.ui_isEphemeralValid || ephemeralDryRun)
                ? () => (
                    <AntTooltip title={"Ephemeral is not configured correctly."}>
                      <AlertCircle className="ml-1 h-4 color-danger" />
                    </AntTooltip>
                  )
                : undefined
              : mk8sDraft.provider.value === "generic"
              ? !pathname.endsWith("/generic") &&
                visited("/generic") &&
                (!mk8sDraft.provider_generic.ui_isGenericValid || genericDryRun)
                ? () => (
                    <AntTooltip title={"Generic is not configured correctly."}>
                      <AlertCircle className="ml-1 h-4 color-danger" />
                    </AntTooltip>
                  )
                : undefined
              : mk8sDraft.provider.value === "hetzner"
              ? !pathname.endsWith("/hetzner") &&
                visited("/hetzner") &&
                (!mk8sDraft.provider_hetzner.ui_isHetznerValid || hetznerDryRun)
                ? () => (
                    <AntTooltip title={"Hetzner is not configured correctly."}>
                      <AlertCircle className="ml-1 h-4 color-danger" />
                    </AntTooltip>
                  )
                : undefined
              : mk8sDraft.provider.value === "lambdalabs"
              ? !pathname.endsWith("/lambdalabs") &&
                visited("/lambdalabs") &&
                (!mk8sDraft.provider_lambdalabs.ui_isLambdalabsValid || lambdalabsDryRun)
                ? () => (
                    <AntTooltip title={"Lambda Labs is not configured correctly."}>
                      <AlertCircle className="ml-1 h-4 color-danger" />
                    </AntTooltip>
                  )
                : undefined
              : mk8sDraft.provider.value === "linode"
              ? !pathname.endsWith("/linode") &&
                visited("/linode") &&
                (!mk8sDraft.provider_linode.ui_isLinodeValid || linodeDryRun)
                ? () => (
                    <AntTooltip title={"Linode is not configured correctly."}>
                      <AlertCircle className="ml-1 h-4 color-danger" />
                    </AntTooltip>
                  )
                : undefined
              : mk8sDraft.provider.value === "oblivus"
              ? !pathname.endsWith("/oblivus") &&
                visited("/oblivus") &&
                (!mk8sDraft.provider_oblivus.ui_isOblivusValid || oblivusDryRun)
                ? () => (
                    <AntTooltip title={"Oblivus is not configured correctly."}>
                      <AlertCircle className="ml-1 h-4 color-danger" />
                    </AntTooltip>
                  )
                : undefined
              : mk8sDraft.provider.value === "paperspace"
              ? !pathname.endsWith("/paperspace") &&
                visited("/paperspace") &&
                (!mk8sDraft.provider_paperspace.ui_isPaperspaceValid || paperspaceDryRun)
                ? () => (
                    <AntTooltip title={"Paperspace is not configured correctly."}>
                      <AlertCircle className="ml-1 h-4 color-danger" />
                    </AntTooltip>
                  )
                : undefined
              : mk8sDraft.provider.value === "triton"
              ? !pathname.endsWith("/triton") &&
                visited("/triton") &&
                (!mk8sDraft.provider_triton.ui_isTritonValid || tritonDryRun)
                ? () => (
                    <AntTooltip title={"Triton is not configured correctly."}>
                      <AlertCircle className="ml-1 h-4 color-danger" />
                    </AntTooltip>
                  )
                : undefined
              : undefined,
          links:
            mk8sDraft.provider.value === "aws"
              ? [
                  {
                    label: "Node Pools",
                    url: "-node-pools",
                    disableFold: true,
                    // @ts-ignore
                    isActive: mk8sDraft.isGeneralValid,
                    links: [
                      ...mk8sDraft.provider_aws.nodePools.map((np, npIndex) => ({
                        label: np.name.value || "New Node Pool",
                        url: String(np.index),
                        isDirty: np.isDirty,
                        isActive: mk8sDraft.isGeneralValid,
                        renderIcon:
                          !pathname.endsWith(`/-node-pools/${npIndex}`) &&
                          visited(`/-provider/aws/-node-pools/${npIndex}`) &&
                          (!np.isValid ||
                            !mk8sDraft.provider_aws.isNodePoolNameValid(np.index) ||
                            dryrunErrors.some((i) => i.path.includes(`spec.provider.aws.nodePools[${npIndex}]`)))
                            ? () => (
                                <AntTooltip title={"Node Pool is not valid"}>
                                  <AlertCircle className="ml-1 h-4 color-danger" />
                                </AntTooltip>
                              )
                            : undefined,
                      })),
                      {
                        label: "Add",
                        url: "-add",
                        type: "action",
                        renderIcon: () => <Plus />,
                        // @ts-ignore
                        isActive: mk8sDraft.isGeneralValid,
                      },
                    ],
                  },
                  {
                    label: "Advanced",
                    url: "-advanced",
                    // @ts-ignore
                    isActive: mk8sDraft.isGeneralValid,
                    renderIcon:
                      !pathname.endsWith("/-advanced") &&
                      visited("/-advanced") &&
                      (!mk8sDraft.provider_aws.ui_isAdvancedValid || awsAdvancedDryRun)
                        ? () => (
                            <AntTooltip title={"Advanced options are not valid"}>
                              <AlertCircle className="ml-1 h-4 color-danger" />
                            </AntTooltip>
                          )
                        : undefined,
                  },
                ]
              : mk8sDraft.provider.value === "digitalocean"
              ? [
                  {
                    label: "Node Pools",
                    url: "-node-pools",
                    disableFold: true,
                    // @ts-ignore
                    isActive: mk8sDraft.isGeneralValid,
                    links: [
                      ...mk8sDraft.provider_digitalocean.nodePools.map((np, npIndex) => ({
                        label: np.name.value || "New Node Pool",
                        url: String(np.index),
                        isDirty: np.isDirty,
                        isActive: mk8sDraft.isGeneralValid,
                        renderIcon:
                          !pathname.endsWith(`/-node-pools/${npIndex}`) &&
                          visited(`/-provider/digitalocean/-node-pools/${npIndex}`) &&
                          (!np.isValid ||
                            !mk8sDraft.provider_digitalocean.isNodePoolNameValid(np.index) ||
                            dryrunErrors.some((i) =>
                              i.path.includes(`spec.provider.digitalocean.nodePools[${npIndex}]`),
                            ))
                            ? () => (
                                <AntTooltip title={"Node Pool is not valid"}>
                                  <AlertCircle className="ml-1 h-4 color-danger" />
                                </AntTooltip>
                              )
                            : undefined,
                      })),
                      {
                        label: "Add",
                        url: "-add",
                        type: "action",
                        renderIcon: () => <Plus />,
                        // @ts-ignore
                        isActive: mk8sDraft.isGeneralValid,
                      },
                    ],
                  },
                  {
                    label: "Advanced",
                    url: "-advanced",
                    // @ts-ignore
                    isActive: mk8sDraft.isGeneralValid,
                    renderIcon:
                      !pathname.endsWith("/-advanced") &&
                      visited("/-advanced") &&
                      (!mk8sDraft.provider_digitalocean.ui_isAdvancedValid || digitaloceanAdvancedDryRun)
                        ? () => (
                            <AntTooltip title={"Advanced options are not valid"}>
                              <AlertCircle className="ml-1 h-4 color-danger" />
                            </AntTooltip>
                          )
                        : undefined,
                  },
                ]
              : mk8sDraft.provider.value === "ephemeral"
              ? [
                  {
                    label: "Node Pools",
                    url: "-node-pools",
                    disableFold: true,
                    // @ts-ignore
                    isActive: mk8sDraft.isGeneralValid,
                    links: [
                      ...mk8sDraft.provider_ephemeral.nodePools.map((np, npIndex) => ({
                        label: np.name.value || "New Node Pool",
                        url: String(np.index),
                        isDirty: np.isDirty,
                        isActive: mk8sDraft.isGeneralValid,
                        renderIcon:
                          !pathname.endsWith(`/-node-pools/${npIndex}`) &&
                          visited(`/-provider/ephemeral/-node-pools/${npIndex}`) &&
                          (!np.isValid ||
                            !mk8sDraft.provider_ephemeral.isNodePoolNameValid(np.index) ||
                            dryrunErrors.some((i) => i.path.includes(`spec.provider.ephemeral.nodePools[${npIndex}]`)))
                            ? () => (
                                <AntTooltip title={"Node Pool is not valid"}>
                                  <AlertCircle className="ml-1 h-4 color-danger" />
                                </AntTooltip>
                              )
                            : undefined,
                      })),
                      {
                        label: "Add",
                        url: "-add",
                        type: "action",
                        renderIcon: () => <Plus />,
                        // @ts-ignore
                        isActive: mk8sDraft.isGeneralValid,
                      },
                    ],
                  },
                ]
              : mk8sDraft.provider.value === "generic"
              ? [
                  {
                    label: "Node Pools",
                    url: "-node-pools",
                    disableFold: true,
                    // @ts-ignore
                    isActive: mk8sDraft.isGeneralValid,
                    links: [
                      ...mk8sDraft.provider_generic.nodePools.map((np, npIndex) => ({
                        label: np.name.value || "New Node Pool",
                        url: String(np.index),
                        isDirty: np.isDirty,
                        isActive: mk8sDraft.isGeneralValid,
                        renderIcon:
                          !pathname.endsWith(`/-node-pools/${npIndex}`) &&
                          visited(`/-provider/generic/-node-pools/${npIndex}`) &&
                          (!np.isValid ||
                            !mk8sDraft.provider_generic.isNodePoolNameValid(np.index) ||
                            dryrunErrors.some((i) => i.path.includes(`spec.provider.generic.nodePools[${npIndex}]`)))
                            ? () => (
                                <AntTooltip title={"Node Pool is not valid"}>
                                  <AlertCircle className="ml-1 h-4 color-danger" />
                                </AntTooltip>
                              )
                            : undefined,
                      })),
                      {
                        label: "Add",
                        url: "-add",
                        type: "action",
                        renderIcon: () => <Plus />,
                        // @ts-ignore
                        isActive: mk8sDraft.isGeneralValid,
                      },
                    ],
                  },
                  {
                    label: "Advanced",
                    url: "-advanced",
                    // @ts-ignore
                    isActive: mk8sDraft.isGeneralValid,
                    renderIcon:
                      !pathname.endsWith("/-advanced") &&
                      visited("/-advanced") &&
                      (!mk8sDraft.provider_generic.ui_isAdvancedValid || genericAdvancedDryRun)
                        ? () => (
                            <AntTooltip title={"Advanced options are not valid"}>
                              <AlertCircle className="ml-1 h-4 color-danger" />
                            </AntTooltip>
                          )
                        : undefined,
                  },
                ]
              : mk8sDraft.provider.value === "hetzner"
              ? [
                  {
                    label: "Node Pools",
                    url: "-node-pools",
                    disableFold: true,
                    // @ts-ignore
                    isActive: mk8sDraft.isGeneralValid,
                    links: [
                      ...mk8sDraft.provider_hetzner.nodePools.map((np, npIndex) => ({
                        label: np.name.value || "New Node Pool",
                        url: String(np.index),
                        isDirty: np.isDirty,
                        isActive: mk8sDraft.isGeneralValid,
                        renderIcon:
                          !pathname.endsWith(`/-node-pools/${npIndex}`) &&
                          visited(`/-provider/hetzner/-node-pools/${npIndex}`) &&
                          (!np.isValid ||
                            !mk8sDraft.provider_hetzner.isNodePoolNameValid(np.index) ||
                            dryrunErrors.some((i) => i.path.includes(`spec.provider.hetzner.nodePools[${npIndex}]`)))
                            ? () => (
                                <AntTooltip title={"Node Pool is not valid"}>
                                  <AlertCircle className="ml-1 h-4 color-danger" />
                                </AntTooltip>
                              )
                            : undefined,
                      })),
                      {
                        label: "Add",
                        url: "-add",
                        type: "action",
                        renderIcon: () => <Plus />,
                        // @ts-ignore
                        isActive: mk8sDraft.isGeneralValid,
                      },
                    ],
                  },
                  {
                    label: "Dedicated Server Node Pools",
                    url: "-dedicated-server-node-pools",
                    disableFold: true,
                    // @ts-ignore
                    isActive: mk8sDraft.isGeneralValid,
                    links: [
                      ...mk8sDraft.provider_hetzner.dedicatedServerNodePools.map((np, npIndex) => ({
                        label: np.name.value || "New Node Pool",
                        url: String(np.index),
                        isDirty: np.isDirty,
                        isActive: mk8sDraft.isGeneralValid,
                        renderIcon:
                          !pathname.endsWith(`/-dedicated-server-node-pools/${npIndex}`) &&
                          visited(`/-provider/hetzner/-dedicated-server-node-pools/${npIndex}`) &&
                          (!np.isValid ||
                            !mk8sDraft.provider_hetzner.isDedicatedServerNodePoolNameValid(np.index) ||
                            dryrunErrors.some((i) =>
                              i.path.includes(`spec.provider.hetzner.dedicatedServerNodePools[${npIndex}]`),
                            ))
                            ? () => (
                                <AntTooltip title={"Dedicated Server Node Pool is not valid"}>
                                  <AlertCircle className="ml-1 h-4 color-danger" />
                                </AntTooltip>
                              )
                            : undefined,
                      })),
                      {
                        label: "Add",
                        url: "-add",
                        type: "action",
                        renderIcon: () => <Plus />,
                        // @ts-ignore
                        isActive: mk8sDraft.isGeneralValid,
                      },
                    ],
                  },
                  {
                    label: "Advanced",
                    url: "-advanced",
                    // @ts-ignore
                    isActive: mk8sDraft.isGeneralValid,
                    renderIcon:
                      !pathname.endsWith("/-advanced") &&
                      visited("/-advanced") &&
                      (!mk8sDraft.provider_hetzner.ui_isAdvancedValid || hetznerAdvancedDryRun)
                        ? () => (
                            <AntTooltip title={"Advanced options are not valid"}>
                              <AlertCircle className="ml-1 h-4 color-danger" />
                            </AntTooltip>
                          )
                        : undefined,
                  },
                ]
              : mk8sDraft.provider.value === "lambdalabs"
              ? [
                  {
                    label: "Node Pools",
                    url: "-node-pools",
                    disableFold: true,
                    // @ts-ignore
                    isActive: mk8sDraft.isGeneralValid,
                    links: [
                      ...mk8sDraft.provider_lambdalabs.nodePools.map((np, npIndex) => ({
                        label: np.name.value || "New Node Pool",
                        url: String(np.index),
                        isDirty: np.isDirty,
                        isActive: mk8sDraft.isGeneralValid,
                        renderIcon:
                          !pathname.endsWith(`/-node-pools/${npIndex}`) &&
                          visited(`/-provider/lambdalabs/-node-pools/${npIndex}`) &&
                          (!np.isValid ||
                            !mk8sDraft.provider_lambdalabs.isNodePoolNameValid(np.index) ||
                            dryrunErrors.some((i) => i.path.includes(`spec.provider.lambdalabs.nodePools[${npIndex}]`)))
                            ? () => (
                                <AntTooltip title={"Node Pool is not valid"}>
                                  <AlertCircle className="ml-1 h-4 color-danger" />
                                </AntTooltip>
                              )
                            : undefined,
                      })),
                      {
                        label: "Add",
                        url: "-add",
                        type: "action",
                        renderIcon: () => <Plus />,
                        // @ts-ignore
                        isActive: mk8sDraft.isGeneralValid,
                      },
                    ],
                  },
                  {
                    label: "Unmanaged Node Pools",
                    url: "-unmanaged-node-pools",
                    disableFold: true,
                    // @ts-ignore
                    isActive: mk8sDraft.isGeneralValid,
                    links: [
                      ...mk8sDraft.provider_lambdalabs.unmanagedNodePools.map((np, npIndex) => ({
                        label: np.name.value || "New Node Pool",
                        url: String(np.index),
                        isDirty: np.isDirty,
                        isActive: mk8sDraft.isGeneralValid,
                        renderIcon:
                          !pathname.endsWith(`/-unmanaged-node-pools/${npIndex}`) &&
                          visited(`/-provider/lambdalabs/-unmanaged-node-pools/${npIndex}`) &&
                          (!np.isValid ||
                            !mk8sDraft.provider_lambdalabs.isUnmanagedNodePoolNameValid(np.index) ||
                            dryrunErrors.some((i) => i.path.includes(`spec.provider.lambdalabs.nodePools[${npIndex}]`)))
                            ? () => (
                                <AntTooltip title={"Unmanaged Node Pool is not valid"}>
                                  <AlertCircle className="ml-1 h-4 color-danger" />
                                </AntTooltip>
                              )
                            : undefined,
                      })),
                      {
                        label: "Add",
                        url: "-add",
                        type: "action",
                        renderIcon: () => <Plus />,
                        // @ts-ignore
                        isActive: mk8sDraft.isGeneralValid,
                      },
                    ],
                  },
                  {
                    label: "Advanced",
                    url: "-advanced",
                    // @ts-ignore
                    isActive: mk8sDraft.isGeneralValid,
                    renderIcon:
                      !pathname.endsWith("/-advanced") &&
                      visited("/-advanced") &&
                      (!mk8sDraft.provider_lambdalabs.ui_isAdvancedValid || lambdalabsAdvancedDryRun)
                        ? () => (
                            <AntTooltip title={"Advanced options are not valid"}>
                              <AlertCircle className="ml-1 h-4 color-danger" />
                            </AntTooltip>
                          )
                        : undefined,
                  },
                ]
              : mk8sDraft.provider.value === "linode"
              ? [
                  {
                    label: "Node Pools",
                    url: "-node-pools",
                    disableFold: true,
                    // @ts-ignore
                    isActive: mk8sDraft.isGeneralValid,
                    links: [
                      ...mk8sDraft.provider_linode.nodePools.map((np, npIndex) => ({
                        label: np.name.value || "New Node Pool",
                        url: String(np.index),
                        isDirty: np.isDirty,
                        isActive: mk8sDraft.isGeneralValid,
                        renderIcon:
                          !pathname.endsWith(`/-node-pools/${npIndex}`) &&
                          visited(`/-provider/linode/-node-pools/${npIndex}`) &&
                          (!np.isValid ||
                            !mk8sDraft.provider_linode.isNodePoolNameValid(np.index) ||
                            dryrunErrors.some((i) => i.path.includes(`spec.provider.linode.nodePools[${npIndex}]`)))
                            ? () => (
                                <AntTooltip title={"Node Pool is not valid"}>
                                  <AlertCircle className="ml-1 h-4 color-danger" />
                                </AntTooltip>
                              )
                            : undefined,
                      })),
                      {
                        label: "Add",
                        url: "-add",
                        type: "action",
                        renderIcon: () => <Plus />,
                        // @ts-ignore
                        isActive: mk8sDraft.isGeneralValid,
                      },
                    ],
                  },
                  {
                    label: "Advanced",
                    url: "-advanced",
                    // @ts-ignore
                    isActive: mk8sDraft.isGeneralValid,
                    renderIcon:
                      !pathname.endsWith("/-advanced") &&
                      visited("/-advanced") &&
                      (!mk8sDraft.provider_linode.ui_isAdvancedValid || linodeAdvancedDryRun)
                        ? () => (
                            <AntTooltip title={"Advanced options are not valid"}>
                              <AlertCircle className="ml-1 h-4 color-danger" />
                            </AntTooltip>
                          )
                        : undefined,
                  },
                ]
              : mk8sDraft.provider.value === "oblivus"
              ? [
                  {
                    label: "Node Pools",
                    url: "-node-pools",
                    disableFold: true,
                    // @ts-ignore
                    isActive: mk8sDraft.isGeneralValid,
                    links: [
                      ...mk8sDraft.provider_oblivus.nodePools.map((np, npIndex) => ({
                        label: np.name.value || "New Node Pool",
                        url: String(np.index),
                        isDirty: np.isDirty,
                        isActive: mk8sDraft.isGeneralValid,
                        renderIcon:
                          !pathname.endsWith(`/-node-pools/${npIndex}`) &&
                          visited(`/-provider/oblivus/-node-pools/${npIndex}`) &&
                          (!np.isValid ||
                            !mk8sDraft.provider_oblivus.isNodePoolNameValid(np.index) ||
                            dryrunErrors.some((i) => i.path.includes(`spec.provider.oblivus.nodePools[${npIndex}]`)))
                            ? () => (
                                <AntTooltip title={"Node Pool is not valid"}>
                                  <AlertCircle className="ml-1 h-4 color-danger" />
                                </AntTooltip>
                              )
                            : undefined,
                      })),
                      {
                        label: "Add",
                        url: "-add",
                        type: "action",
                        renderIcon: () => <Plus />,
                        // @ts-ignore
                        isActive: mk8sDraft.isGeneralValid,
                      },
                    ],
                  },
                  {
                    label: "Unmanaged Node Pools",
                    url: "-unmanaged-node-pools",
                    disableFold: true,
                    // @ts-ignore
                    isActive: mk8sDraft.isGeneralValid,
                    links: [
                      ...mk8sDraft.provider_oblivus.unmanagedNodePools.map((np, npIndex) => ({
                        label: np.name.value || "New Node Pool",
                        url: String(np.index),
                        isDirty: np.isDirty,
                        isActive: mk8sDraft.isGeneralValid,
                        renderIcon:
                          !pathname.endsWith(`/-unmanaged-node-pools/${npIndex}`) &&
                          visited(`/-provider/oblivus/-unmanaged-node-pools/${npIndex}`) &&
                          (!np.isValid ||
                            !mk8sDraft.provider_oblivus.isUnmanagedNodePoolNameValid(np.index) ||
                            dryrunErrors.some((i) => i.path.includes(`spec.provider.oblivus.nodePools[${npIndex}]`)))
                            ? () => (
                                <AntTooltip title={"Unmanaged Node Pool is not valid"}>
                                  <AlertCircle className="ml-1 h-4 color-danger" />
                                </AntTooltip>
                              )
                            : undefined,
                      })),
                      {
                        label: "Add",
                        url: "-add",
                        type: "action",
                        renderIcon: () => <Plus />,
                        // @ts-ignore
                        isActive: mk8sDraft.isGeneralValid,
                      },
                    ],
                  },
                  {
                    label: "Advanced",
                    url: "-advanced",
                    // @ts-ignore
                    isActive: mk8sDraft.isGeneralValid,
                    renderIcon:
                      !pathname.endsWith("/-advanced") &&
                      visited("/-advanced") &&
                      (!mk8sDraft.provider_oblivus.ui_isAdvancedValid || oblivusAdvancedDryRun)
                        ? () => (
                            <AntTooltip title={"Advanced options are not valid"}>
                              <AlertCircle className="ml-1 h-4 color-danger" />
                            </AntTooltip>
                          )
                        : undefined,
                  },
                ]
              : mk8sDraft.provider.value === "paperspace"
              ? [
                  {
                    label: "Node Pools",
                    url: "-node-pools",
                    disableFold: true,
                    // @ts-ignore
                    isActive: mk8sDraft.isGeneralValid,
                    links: [
                      ...mk8sDraft.provider_paperspace.nodePools.map((np, npIndex) => ({
                        label: np.name.value || "New Node Pool",
                        url: String(np.index),
                        isDirty: np.isDirty,
                        isActive: mk8sDraft.isGeneralValid,
                        renderIcon:
                          !pathname.endsWith(`/-node-pools/${npIndex}`) &&
                          visited(`/-provider/paperspace/-node-pools/${npIndex}`) &&
                          (!np.isValid ||
                            !mk8sDraft.provider_paperspace.isNodePoolNameValid(np.index) ||
                            dryrunErrors.some((i) => i.path.includes(`spec.provider.paperspace.nodePools[${npIndex}]`)))
                            ? () => (
                                <AntTooltip title={"Node Pool is not valid"}>
                                  <AlertCircle className="ml-1 h-4 color-danger" />
                                </AntTooltip>
                              )
                            : undefined,
                      })),
                      {
                        label: "Add",
                        url: "-add",
                        type: "action",
                        renderIcon: () => <Plus />,
                        // @ts-ignore
                        isActive: mk8sDraft.isGeneralValid,
                      },
                    ],
                  },
                  {
                    label: "Unmanaged Node Pools",
                    url: "-unmanaged-node-pools",
                    disableFold: true,
                    // @ts-ignore
                    isActive: mk8sDraft.isGeneralValid,
                    renderIcon:
                      mk8sDraft.provider_paperspace.unmanagedNodePools.some((np) => !np.isValid) &&
                      visited("/-provider/paperspace/-unmanaged-node-pools")
                        ? () => (
                            <AntTooltip title={"Some unmanaged node pools are not configured."}>
                              <AlertCircle className="ml-1 h-4 color-danger" />
                            </AntTooltip>
                          )
                        : undefined,
                    links: [
                      ...mk8sDraft.provider_paperspace.unmanagedNodePools.map((np, npIndex) => ({
                        label: np.name.value || "New Node Pool",
                        url: String(np.index),
                        isDirty: np.isDirty,
                        isActive: mk8sDraft.isGeneralValid,
                        renderIcon:
                          (!np.isValid || !mk8sDraft.provider_paperspace.isUnmanagedNodePoolNameValid(np.index)) &&
                          visited(`/-provider/paperspace/-unmanaged-node-pools/${npIndex}`)
                            ? () => (
                                <AntTooltip title={"Unmanaged Node Pool is not valid"}>
                                  <AlertCircle className="ml-1 h-4 color-danger" />
                                </AntTooltip>
                              )
                            : undefined,
                      })),
                      {
                        label: "Add",
                        url: "-add",
                        type: "action",
                        renderIcon: () => <Plus />,
                        // @ts-ignore
                        isActive: mk8sDraft.isGeneralValid,
                      },
                    ],
                  },
                  {
                    label: "Advanced",
                    url: "-advanced",
                    // @ts-ignore
                    isActive: mk8sDraft.isGeneralValid,
                    renderIcon:
                      !pathname.endsWith("/-advanced") &&
                      visited("/-advanced") &&
                      (!mk8sDraft.provider_paperspace.ui_isAdvancedValid || paperspaceAdvancedDryRun)
                        ? () => (
                            <AntTooltip title={"Advanced options are not valid"}>
                              <AlertCircle className="ml-1 h-4 color-danger" />
                            </AntTooltip>
                          )
                        : undefined,
                  },
                ]
              : mk8sDraft.provider.value === "triton"
              ? [
                  {
                    label: "Node Pools",
                    url: "-node-pools",
                    disableFold: true,
                    // @ts-ignore
                    isActive: mk8sDraft.isGeneralValid,
                    links: [
                      ...mk8sDraft.provider_triton.nodePools.map((np, npIndex) => ({
                        label: np.name.value || "New Node Pool",
                        url: String(np.index),
                        isDirty: np.isDirty,
                        isActive: mk8sDraft.isGeneralValid,
                        renderIcon:
                          !pathname.endsWith(`/-node-pools/${npIndex}`) &&
                          visited(`/-provider/triton/-node-pools/${npIndex}`) &&
                          (!np.isValid ||
                            !mk8sDraft.provider_triton.isNodePoolNameValid(np.index) ||
                            dryrunErrors.some((i) => i.path.includes(`spec.provider.triton.nodePools[${npIndex}]`)))
                            ? () => (
                                <AntTooltip title={"Node Pool is not valid"}>
                                  <AlertCircle className="ml-1 h-4 color-danger" />
                                </AntTooltip>
                              )
                            : undefined,
                      })),
                      {
                        label: "Add",
                        url: "-add",
                        type: "action",
                        renderIcon: () => <Plus />,
                        // @ts-ignore
                        isActive: mk8sDraft.isGeneralValid,
                      },
                    ],
                  },
                  {
                    label: "Advanced",
                    url: "-advanced",
                    // @ts-ignore
                    isActive: mk8sDraft.isGeneralValid,
                    renderIcon:
                      !pathname.endsWith("/-advanced") &&
                      visited("/-advanced") &&
                      (!mk8sDraft.provider_triton.ui_isAdvancedValid || tritonAdvancedDryRun)
                        ? () => (
                            <AntTooltip title={"Advanced options are not valid"}>
                              <AlertCircle className="ml-1 h-4 color-danger" />
                            </AntTooltip>
                          )
                        : undefined,
                  },
                ]
              : [],
        },
      ],
    },
    {
      label: "Add-ons",
      url: `-addons`,
      isActive: mk8sDraft.isGeneralValid && mk8sDraft.isProviderValid,
      renderIcon:
        !pathname.endsWith("/-addons") && visited("/-addons") && (!mk8sDraft.ui_isAddonsValid || anyAddonsDryRun)
          ? () => (
              <AntTooltip title={"Addons are not valid."}>
                <AlertCircle className="ml-1 h-4 color-danger" />
              </AntTooltip>
            )
          : undefined,
      links: [
        mk8sDraft.addon_awsEcr.isEnabled
          ? {
              label: "AWS ECR",
              url: "-aws-ecr",
              isActive: true,
              renderIcon:
                !pathname.endsWith(`/-aws-ecr`) &&
                (!mk8sDraft.addon_awsEcr.isValid || dryrunErrors.some((i) => i.path.includes(`spec.addOns.awsECR`)))
                  ? () => (
                      <AntTooltip title={"AWS ECR addon is not valid."}>
                        <AlertCircle className="ml-1 h-4 color-danger" />
                      </AntTooltip>
                    )
                  : undefined,
            }
          : { label: "filterOut", url: "filterOut" },
        mk8sDraft.provider.value === "aws" && mk8sDraft.addon_awsEfs.isEnabled
          ? {
              label: "AWS EFS",
              url: "-aws-efs",
              isActive: true,
              renderIcon:
                !pathname.endsWith(`/-aws-efs`) &&
                (!mk8sDraft.addon_awsEfs.isValid || dryrunErrors.some((i) => i.path.includes(`spec.addOns.awsEFS`)))
                  ? () => (
                      <AntTooltip title={"AWS EFS addon is not valid."}>
                        <AlertCircle className="ml-1 h-4 color-danger" />
                      </AntTooltip>
                    )
                  : undefined,
            }
          : { label: "filterOut", url: "filterOut" },
        mk8sDraft.addon_awsElb.isEnabled
          ? {
              label: "AWS ELB",
              url: "-aws-elb",
              isActive: true,
              renderIcon:
                !pathname.endsWith(`/-aws-elb`) &&
                (!mk8sDraft.addon_awsElb.isValid || dryrunErrors.some((i) => i.path.includes(`spec.addOns.awsELB`)))
                  ? () => (
                      <AntTooltip title={"AWS ELB addon is not valid."}>
                        <AlertCircle className="ml-1 h-4 color-danger" />
                      </AntTooltip>
                    )
                  : undefined,
            }
          : { label: "filterOut", url: "filterOut" },
        mk8sDraft.addon_awsWorkloadIdentity.isEnabled
          ? {
              label: "AWS Workload Identity",
              url: "-aws-workload-identity",
              isActive: true,
            }
          : { label: "filterOut", url: "filterOut" },
        mk8sDraft.addon_azureAcr.isEnabled
          ? {
              label: "Azure Container Registry",
              url: "-azure-acr",
              isActive: true,
              renderIcon:
                !pathname.endsWith(`/-azure-acr`) &&
                (!mk8sDraft.addon_azureAcr.isValid || dryrunErrors.some((i) => i.path.includes(`spec.addOns.azureACR`)))
                  ? () => (
                      <AntTooltip title={"Azure ACR addon is not valid."}>
                        <AlertCircle className="ml-1 h-4 color-danger" />
                      </AntTooltip>
                    )
                  : undefined,
            }
          : { label: "filterOut", url: "filterOut" },
        mk8sDraft.addon_azureWorkloadIdentity.isEnabled
          ? {
              label: "Azure Workload Identity",
              url: "-azure-workload-identity",
              isActive: true,
              renderIcon:
                !pathname.endsWith(`/-azure-workload-identity`) &&
                (!mk8sDraft.addon_azureWorkloadIdentity.isValid ||
                  dryrunErrors.some((i) => i.path.includes(`spec.addOns.azureWorkloadIdentity`)))
                  ? () => (
                      <AntTooltip title={"Azure Workload Identity addon is not valid."}>
                        <AlertCircle className="ml-1 h-4 color-danger" />
                      </AntTooltip>
                    )
                  : undefined,
            }
          : { label: "filterOut", url: "filterOut" },
        mk8sDraft.addon_localPathStorage.isEnabled
          ? {
              label: "Local Path Storage",
              url: "-local-path-storage",
              isActive: true,
            }
          : { label: "filterOut", url: "filterOut" },
        mk8sDraft.addon_logs.isEnabled
          ? {
              label: "Logs",
              url: "-logs",
              isActive: true,
              renderIcon:
                !pathname.endsWith(`/-logs`) &&
                (!mk8sDraft.addon_logs.isValid || dryrunErrors.some((i) => i.path.includes(`spec.addOns.logs`)))
                  ? () => (
                      <AntTooltip title={"Logs addon is not valid."}>
                        <AlertCircle className="ml-1 h-4 color-danger" />
                      </AntTooltip>
                    )
                  : undefined,
            }
          : { label: "filterOut", url: "filterOut" },
        mk8sDraft.addon_metrics.isEnabled
          ? {
              label: "Metrics",
              url: "-metrics",
              isActive: true,
              renderIcon:
                !pathname.endsWith(`/-metrics`) &&
                (!mk8sDraft.addon_metrics.isValid || dryrunErrors.some((i) => i.path.includes(`spec.addOns.metrics`)))
                  ? () => (
                      <AntTooltip title={"Metrics addon is not valid."}>
                        <AlertCircle className="ml-1 h-4 color-danger" />
                      </AntTooltip>
                    )
                  : undefined,
            }
          : { label: "filterOut", url: "filterOut" },
        mk8sDraft.addon_nvidia.isEnabled
          ? {
              label: "NVIDIA",
              url: "-nvidia",
              isActive: true,
              renderIcon:
                !pathname.endsWith(`/-nvidia`) &&
                (!mk8sDraft.addon_nvidia.isValid || dryrunErrors.some((i) => i.path.includes(`spec.addOns.nvidia`)))
                  ? () => (
                      <AntTooltip title={"NVIDIA addon is not valid."}>
                        <AlertCircle className="ml-1 h-4 color-danger" />
                      </AntTooltip>
                    )
                  : undefined,
            }
          : { label: "filterOut", url: "filterOut" },
      ].filter((l) => l.label !== "filterOut"),
    },
    {
      label: "Firewall",
      url: `-firewall`,
      isActive: mk8sDraft.isGeneralDirty && mk8sDraft.isProviderValid && mk8sDraft.isAddonsValid,
      renderIcon:
        !pathname.endsWith(`/-firewall`) &&
        visited(`/-firewall`) &&
        (!mk8sDraft.firewall.isValid || dryrunErrors.some((i) => i.path.includes(`spec.firewall`)))
          ? () => (
              <AntTooltip title={"Firewall is not valid."}>
                <AlertCircle className="ml-1 h-4 color-danger" />
              </AntTooltip>
            )
          : undefined,
    },
    {
      label: "Tags",
      url: `-tags`,
      isActive:
        mk8sDraft.isGeneralDirty && mk8sDraft.isProviderValid && mk8sDraft.isAddonsValid && mk8sDraft.firewall.isValid,
      renderIcon:
        !pathname.endsWith(`/-tags`) &&
        visited(`/-tags`) &&
        (!mk8sDraft.tags.isValid || dryrunErrors.some((i) => i.path.includes(`tags`)))
          ? () => (
              <AntTooltip title={"Tags are not valid."}>
                <AlertCircle className="ml-1 h-4 color-danger" />
              </AntTooltip>
            )
          : undefined,
    },
  ];

  const next = {
    isActive: false,
    label: "Next",
    url: "/",
  };

  if (pathname.includes("-general")) {
    if (mk8sDraft.isGeneralValid) {
      next.isActive = true;
    }
    next.label = "Next (Provider)";
    next.url = `-provider`;
  } else if (pathname.endsWith("-provider")) {
    next.isActive = true;
    next.label = `Next (${mk8sDraft.provider.options.find((opt) => opt.value === mk8sDraft.provider.value)!.label})`;
    next.url = `-provider/${mk8sDraft.provider.value}`;
  } else if (pathname.endsWith(`-provider/${mk8sDraft.provider.value}`)) {
    if (mk8sDraft.isProviderValid) {
      next.isActive = true;
    }
    if (mk8sDraft.hasAnyNodePool) {
      next.label = `Node Pool`;
      next.url = `-provider/${mk8sDraft.provider.value}/-node-pools/0`;
    } else {
      next.label = `Add Node Pool`;
      next.url = `-provider/${mk8sDraft.provider.value}/-node-pools/-add`;
    }
  } else if (pathname.includes("-provider")) {
    if (mk8sDraft.isProviderValid) {
      next.isActive = true;
    }
    next.label = "Next (Add-ons)";
    next.url = `-addons`;
  } else if (pathname.includes("-addons")) {
    next.isActive = true;

    if (mk8sDraft.addon_awsEcr.isEnabled && !pathname.includes("-aws-ecr") && !mk8sDraft.addon_awsEcr.isValid) {
      next.label = "Configure AWS ECR";
      next.url = `-addons/-aws-ecr`;
    } else if (
      mk8sDraft.provider.value === "aws" &&
      mk8sDraft.addon_awsEfs.isEnabled &&
      !pathname.includes("-aws-efs") &&
      !mk8sDraft.addon_awsEfs.isValid
    ) {
      next.label = "Configure AWS EFS";
      next.url = `-addons/-aws-efs`;
    } else if (mk8sDraft.addon_awsElb.isEnabled && !pathname.includes("-aws-elb") && !mk8sDraft.addon_awsElb.isValid) {
      next.label = "Configure AWS ELB";
      next.url = `-addons/-aws-elb`;
    } else if (mk8sDraft.addon_awsWorkloadIdentity.isEnabled && !pathname.includes("-aws-workload-identity")) {
      next.label = "Configure AWS";
      next.url = `-addons/-aws-workload-identity`;
    } else if (
      mk8sDraft.addon_azureAcr.isEnabled &&
      !pathname.includes("-azure-acr") &&
      !mk8sDraft.addon_azureAcr.isValid
    ) {
      next.label = "Configure Azure ACR";
      next.url = `-addons/-azure-acr`;
    } else if (
      mk8sDraft.addon_azureWorkloadIdentity.isEnabled &&
      !pathname.includes("-azure-workload-identity") &&
      !mk8sDraft.addon_azureWorkloadIdentity.isValid
    ) {
      next.label = "Configure Azure";
      next.url = `-addons/-azure-workload-identity`;
    } else if (mk8sDraft.addon_localPathStorage.isEnabled && !pathname.includes("-local-path-storage")) {
      next.label = "Configure Local Path Storage";
      next.url = `-addons/-local-path-storage`;
    } else if (mk8sDraft.addon_logs.isEnabled && !pathname.includes("-logs") && !mk8sDraft.addon_logs.isValid) {
      next.label = "Configure Logs";
      next.url = `-addons/-logs`;
    } else if (
      mk8sDraft.addon_metrics.isEnabled &&
      !pathname.includes("-metrics") &&
      !mk8sDraft.addon_metrics.isValid
    ) {
      next.label = "Configure Metrics";
      next.url = `-addons/-metrics`;
    } else if (mk8sDraft.addon_nvidia.isEnabled && !pathname.includes("-nvidia") && !mk8sDraft.addon_nvidia.isValid) {
      next.label = "Configure NVIDIA";
      next.url = `-addons/-nvidia`;
    } else {
      next.label = "Next (Firewall)";
      next.url = `-firewall`;
      next.isActive = mk8sDraft.isAddonsValid;
    }
  } else if (pathname.includes("-firewall")) {
    if (mk8sDraft.firewall.isValid) {
      next.isActive = true;
    }
    next.label = "Next (Tags)";
    next.url = `-tags`;
  }

  return (
    <div style={{ maxWidth: 1600 }}>
      <BasePathContext.Provider value={basePath}>
        <NGFormContext.Provider value={formData}>
          <CreateLayout
            getPreview={() => ({ kind: "mk8s", ...mk8sDraft.asObject })}
            next={next}
            shouldBlockNavigation={shouldBlockNavigation}
            title={"Create Kubernetes Cluster"}
            name={mk8sDraft.name.value}
            canCreate={mk8sDraft.isValid && !mk8sDryRun.hasError}
            onConfirm={onConfirm}
            isLoading={isLoading}
            links={[]}
            useNewNav
            ngLinks={ngLinks as any}
            hideButtons={search.includes("?nodePool=")}
          >
            <Routes>
              <Route index element={<Navigate to={`-general`} />} />
              <Route path={`-general`} element={<Mk8sCreateGeneral mk8sDraft={mk8sDraft} />} />
              <Route path={`-provider/*`} element={<Mk8sCreateProvider mk8sDraft={mk8sDraft} />} />
              <Route path={`-addons/*`} element={<Mk8sCreateAddonsRoute mk8sDraft={mk8sDraft} />} />
              <Route path={`-firewall`} element={<Mk8sCreateFirewall mk8sDraft={mk8sDraft} />} />
              <Route path={`-tags`} element={<TagsNew tags={mk8sDraft.tags} />} />
            </Routes>
          </CreateLayout>
        </NGFormContext.Provider>
      </BasePathContext.Provider>
      {IS_DEPLOYMENT_ENV_TEST && HiddenSectionsMobx.isRevealed("debug") ? (
        <div>
          <div>Invalid Reason</div>
          <div>{mk8sDraft.invalidReason || "none"}</div>
          <div>Dry run error</div>
          <div>Has error?: {String(mk8sDryRun.hasError)}</div>
          {mk8sDryRun.hasError ? <pre className="text-xs">{JSON.stringify(mk8sDryRun.response, null, 2)}</pre> : null}
        </div>
      ) : null}
    </div>
  );
};

export const Mk8sCreate = observer(Mk8sCreateRaw);
