import * as React from "react";
import { observer } from "mobx-react-lite";
import { GVCMobx } from "../../mst/kinds/gvc";
import { notification } from "antd";
import { FormButtons } from "../../components/forms/formButtons";
import { useDetailContext } from "../../components/detail/detailContext";
import { NGButton } from "../../newcomponents/button/Button";
import { PromptContext } from "../../mobxStores/prompt/prompt";
import { useCleanPrompt } from "../../reactHooks/useCleanPrompt";
import { useTableKindId } from "../../newcomponents/table/data/useTableKindId";
import { useTableItemQueryData } from "../../newcomponents/table/data/useTableItemQueryData";
import { Secret } from "../../schema/types/secret";
import { ngParseLink } from "../../utils/linkParser/linkParser";
import { AxiosResponse } from "axios";
import { arraysAreEqual, linksOf, request } from "../../services/cpln";
import { sortBy } from "lodash";
import { Table } from "../../newcomponents/table/table";
import { SecretTypeColumn } from "../../newcomponents/table/columns/secret/typeColumn";
import { TagsColumn } from "../../newcomponents/table/columns/wellKnown/tagsColumn";
import { CreatedColumn } from "../../newcomponents/table/columns/wellKnown/createdColumn";
import { LastModifiedColumn } from "../../newcomponents/table/columns/wellKnown/lastModifiedColumn";
import { NameDescriptionNoLinkColumn } from "../../newcomponents/table/columns/wellKnown/nameDescriptionNoLinkColumn";
import { PlusCircle } from "react-feather";
import { TableModal } from "../../components/antd/TableModal";
import { TableHeaderRefreshButton } from "../../newcomponents/table/components/RefreshButton";

interface SecretTableItem extends Secret {
  selfLink: string;
}

interface Props {
  gvc: GVCMobx;
}
const GVCPullSecretsRaw: React.FC<Props> = ({ gvc }) => {
  const { kind, id } = useTableKindId("secret", "gvc-pullsecret-add");
  const qData = useTableItemQueryData<Secret>(kind, {
    fetchAllPages: true,
    query: {
      kind: "secret",
      spec: {
        match: "any",
        terms: [
          { property: "type", op: "=", value: "docker" },
          { property: "type", op: "=", value: "ecr" },
          { property: "type", op: "=", value: "gcp" },
        ],
      },
    },
  });

  const { fetchItem } = useDetailContext();

  const [isInited, setIsInited] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  function getDefaultLinks(): string[] {
    return [...gvc.spec.pullSecretLinks];
  }
  function getDefaultCurrentItems(): SecretTableItem[] {
    return getDefaultLinks().map((link) => ({
      id: "",
      name: ngParseLink(link, { useInputCtx: true }).name,
      kind: "secret",
      version: 0,
      description: "",
      tags: {},
      created: "",
      lastModified: "",
      links: [{ rel: "self", href: link }],
      type: "" as any,
      selfLink: link,
    }));
  }
  const [currentItems, setCurrentItems] = React.useState<SecretTableItem[]>(getDefaultCurrentItems());
  function getCurrentLinks(): string[] {
    return currentItems.map((i) => i.selfLink);
  }

  React.useEffect(() => {
    fetchGvcPullSecrets();
  }, []);

  async function fetchGvcPullSecrets() {
    try {
      setIsLoading(true);
      const promises: Promise<AxiosResponse<Secret>>[] = [];
      for (const link of getDefaultLinks()) {
        promises.push(request({ url: link }));
      }

      const responses = await Promise.allSettled(promises);
      setCurrentItems((currentItems) => {
        const newItems: SecretTableItem[] = [];

        for (const responseIndexStr in responses) {
          const responseIndex = Number(responseIndexStr);
          const response = responses[responseIndex];

          if (response.status === "rejected") {
            let currentItem = currentItems[responseIndex];
            if (!currentItem) {
              currentItem = getDefaultCurrentItems()[responseIndex];
            }
            newItems.push(currentItem);
            continue;
          }

          const newItem = { ...response.value.data, selfLink: linksOf(response.value.data).self! };
          newItems.push(newItem);
        }

        return newItems;
      });

      setIsLoading(false);
      setIsInited(true);
    } catch (e) {
      setIsLoading(false);
    }
  }

  const [selectionsToRemove, setSelectionsToRemove] = React.useState<string[]>([]);
  const [selectionsToAdd, setSelectionsToAdd] = React.useState<string[]>([]);
  const [selectionItemsToAdd, setSelectionItemsToAdd] = React.useState<SecretTableItem[]>([]);
  const [isAddingItem, setIsAddingItem] = React.useState(false);

  const isDirty = React.useMemo(() => {
    return !arraysAreEqual(getCurrentLinks(), getDefaultLinks());
  }, [currentItems.length, gvc.spec.pullSecretLinks.length]);

  React.useEffect(() => {
    PromptContext.setWhen(isDirty || isLoading);
  }, [isDirty, isLoading]);
  useCleanPrompt();

  async function handleSave() {
    try {
      setIsLoading(true);
      const body: any = {
        spec: {
          pullSecretLinks: getCurrentLinks(),
        },
      };
      await request({ method: "patch", url: gvc.selfLink, body: body });
      if (fetchItem) {
        fetchItem();
      }
      notification.success({
        message: "Success",
        description: "Updated GVC Pull Secrets",
      });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
      if (e.response.status === 409) {
        if (fetchItem) {
          await fetchItem();
          notification.info({
            message: "Updated Item",
            description: "Fetched the latest version of the item and discarded changes.",
          });
        }
      }
    }
  }

  async function handleReset() {
    fetchGvcPullSecrets();
    setSelectionsToRemove([]);
    setSelectionsToAdd([]);
    setSelectionItemsToAdd([]);
  }

  function onRemove() {
    setCurrentItems((items) => items.filter((item) => !selectionsToRemove.includes(item.selfLink)));
    setSelectionsToRemove([]);
  }

  function onOkAddModal() {
    setCurrentItems((items) => {
      const newItems: SecretTableItem[] = [...items];
      const currentLinks = items.map((i) => i.selfLink);

      for (const itemToAdd of selectionItemsToAdd) {
        if (currentLinks.includes(itemToAdd.selfLink)) {
          continue;
        }

        newItems.push(itemToAdd);
      }

      return sortBy(newItems, "name");
    });

    setSelectionsToAdd([]);
    setSelectionItemsToAdd([]);
  }

  const secretTableItems: SecretTableItem[] = qData.visibleItems
    .map((item) => ({
      ...item,
      selfLink: linksOf(item).self!,
    }))
    .filter((item) => !getCurrentLinks().includes(item.selfLink));

  return (
    <>
      <Table<SecretTableItem>
        tableId={"gvc-pullsecrets"}
        title={"GVC Pull Secrets"}
        data={currentItems}
        selectMode={"multi"}
        selectKey="selfLink"
        selections={selectionsToRemove}
        onSelectionsChange={setSelectionsToRemove}
        columns={[
          NameDescriptionNoLinkColumn(), //
          SecretTypeColumn(),
          TagsColumn(),
          CreatedColumn(),
          LastModifiedColumn(),
        ]}
        headerRenderer={() => (
          <>
            <NGButton
              variant={"danger"}
              size={"small"}
              outlined
              disabled={selectionsToRemove.length < 1}
              onClick={onRemove}
            >
              Remove
            </NGButton>
            <NGButton variant={"action"} size={"small"} onClick={() => setIsAddingItem(true)}>
              Add
            </NGButton>
          </>
        )}
        noItemsFoundRenderer={(table) => (
          <NGButton
            variant="primary"
            onClick={() => setIsAddingItem(true)}
            renderIcon={(_, props) => <PlusCircle {...props} />}
          >
            Add GVC Pull Secrets
          </NGButton>
        )}
      />
      <FormButtons
        loading={isLoading}
        resetDisabled={!isInited || isLoading || !isDirty}
        saveDisabled={!isInited || isLoading || !isDirty}
        onReset={handleReset}
        onSave={handleSave}
      />
      <TableModal
        open={isAddingItem}
        onCancel={() => setIsAddingItem(false)}
        onOk={() => {
          onOkAddModal();
          setIsAddingItem(false);
        }}
      >
        <Table<SecretTableItem>
          tableId={id}
          title={"Select Pull Secrets to Add"}
          selectMode="multi"
          selectKey="selfLink"
          selections={selectionsToAdd}
          onSelectionsChange={(keys, items) => {
            setSelectionsToAdd(keys);
            setSelectionItemsToAdd(items!);
          }}
          data={secretTableItems}
          headerRenderer={() => {
            return (
              <>
                <TableHeaderRefreshButton onClick={qData.fetchItems} />
              </>
            );
          }}
          enableFilter
          isLoading={qData.isLoading}
          columns={[
            NameDescriptionNoLinkColumn(),
            SecretTypeColumn(),
            TagsColumn(),
            CreatedColumn(),
            LastModifiedColumn(),
          ]}
        />
      </TableModal>
    </>
  );
};

export const GVCPullSecrets = observer(GVCPullSecretsRaw);
