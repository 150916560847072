import { Agent, AgentInfo } from "../../schema/types/agent";
import moment from "moment-timezone";
import { dateString } from "../../components/dateStringFn";
import { formatStrings } from "../../utils/time";
import jsYaml from "js-yaml";

export const AGENT_CHECKING = "Checking";

export const agentHelpers = {
  getEnvText(agent: Agent, info: AgentInfo) {
    if (info.env) {
      return jsYaml.dump(info.env, { indent: 2, noRefs: true });
    }
    return "Env not found";
  },
  getLastActiveText(agent: Agent, info: AgentInfo) {
    try {
      if (info.lastActive !== undefined && info.lastActive !== AGENT_CHECKING && info.lastActive.length > 0) {
        return dateString(info.lastActive, { format: formatStrings.humanizeWAgo });
      }
      throw new Error("no last active found");
    } catch (e) {
      return "This agent was never active";
    }
  },
  getLastActiveTextTable(agent: Agent, info: AgentInfo) {
    if (info.lastActive === AGENT_CHECKING) return AGENT_CHECKING;
    try {
      if (info.lastActive !== undefined && info.lastActive.length > 0) {
        return dateString(info.lastActive, { format: formatStrings.humanizeWAgo });
      }
      throw new Error("no last active found");
    } catch (e) {
      return "Never";
    }
  },
  getIsActive(agent: Agent, info: AgentInfo) {
    const lastActiveText = this.getLastActiveText(agent, info);
    if (lastActiveText.includes("never")) return false;
    try {
      const lastActiveDate = moment(info.lastActive);
      const nowDate = moment();
      const diff = nowDate.diff(lastActiveDate, "seconds");
      return diff <= 60;
    } catch (e) {
      return false;
    }
  },
};

export interface AgentTableItem extends Agent {
  selfLink: string;
  isActive: boolean;
  lastActiveText: string;
}
