import { TableColumn } from "../../types";
import { Identity, ProviderStatus } from "../../../../schema/types/identity";
import { NGChip } from "../../../chip";

const typeDefault = { id: "providerStatus", label: "Status" };
export function IdentityStatusColumn<TData extends Identity>(column: TableColumn<TData> = typeDefault) {
  const preset: TableColumn<TData> = {
    ...typeDefault,
    size: 130,
    enableHide: true,
    cell: (p) => {
      // Disabling
      return null;

      // const identity = p.row.original;
      // const status = identity.status;
      // if (!status) {
      //   return null;
      // }
      // const providerStatusArr: ProviderStatus[] = [status.aws, status.azure, status.gcp].filter(
      //   Boolean,
      // ) as ProviderStatus[];
      // const hasError = providerStatusArr.some((s) => s.usable === false);

      // if (!hasError) {
      //   return null;
      // }

      // return (
      //   <NGChip //
      //     size="small"
      //     variant={"error"}
      //     label={"Unusable"}
      //   />
      // );
    },
  };
  return Object.assign(preset, column);
}
