import React from "react";
import { Modal } from "antd";
import { useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useMediaQuerycontext } from "./components/detail/mediaQueryContext";

const STORAGE_KEY_OPTIMAL_VIEW = "has-seen-optimal-viewing-notice";

const OptimalViewingModalRaw = () => {
  const { pathname } = useLocation();
  const { max_w_1024 } = useMediaQuerycontext();

  const hasSeenOptimalViewingNotice = localStorage.getItem(STORAGE_KEY_OPTIMAL_VIEW);

  const [hasSeenNotice, setHasSeenNotice] = React.useState(hasSeenOptimalViewingNotice === "true");

  const shouldShowModal = !hasSeenNotice && max_w_1024 && !pathname.startsWith("/billing/account/-create");

  const handleCancel = () => {
    localStorage.setItem(STORAGE_KEY_OPTIMAL_VIEW, "true");
    setHasSeenNotice(true); // Update state to trigger re-render
  };

  React.useEffect(() => {
    setHasSeenNotice(hasSeenOptimalViewingNotice === "true");
  }, [pathname, max_w_1024]);

  return (
    <Modal title="Optimal Viewing Notice" footer={null} open={shouldShowModal} onCancel={handleCancel}>
      For optimal use, please access on a desktop or larger screen. Thank you!
    </Modal>
  );
};

export const OptimalViewingModal = observer(OptimalViewingModalRaw);
