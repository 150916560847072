import { types, Instance } from "mobx-state-tree";
import { SelectModel } from "../../mobxDataModels/selectModel";
import { StringModel } from "../../mobxDataModels/stringModel";
import { NumberModel } from "../../mobxDataModels/numberModel";

export const WorkloadDraftContainerGPUModel = types
  .model({
    _vendor: types.optional(types.string, "none"),
    vendor: types.optional(SelectModel, () =>
      SelectModel.create({
        label: "Vendor",
        options: [
          { label: "None", value: "none" },
          { label: "Custom", value: "custom" },
          { label: "NVidia", value: "nvidia" },
        ],
        initialValue: "none",
      }),
    ),
    _nvidia_model: types.optional(types.string, "t4"),
    _nvidia_quantity: types.optional(types.number, 1),
    nvidia_model: types.optional(SelectModel, () =>
      SelectModel.create({
        label: "Model",
        initialValue: "t4",
        options: [
          { label: "t4", value: "t4" },
          { label: "a10g", value: "a10g" },
        ],
      }),
    ),
    nvidia_quantity: types.optional(StringModel, () =>
      NumberModel.create({
        label: "Quantity",
        initialValue: 1,
        min: 0,
        max: 4,
        isRequired: true,
      }),
    ),
    _custom_resource: types.optional(types.string, ""),
    _custom_runtimeClass: types.optional(types.string, ""),
    _custom_quantity: types.optional(types.number, 1),
    custom_resource: types.optional(StringModel, () =>
      StringModel.create({
        label: "Resource",
        isRequired: true,
      }),
    ),
    custom_runtimeClass: types.optional(StringModel, () =>
      StringModel.create({
        label: "Runtime Class",
        isRequired: false,
      }),
    ),
    custom_quantity: types.optional(StringModel, () =>
      NumberModel.create({
        label: "Quantity",
        initialValue: 1,
        min: 0,
        max: 8,
        isRequired: true,
      }),
    ),
  })
  .actions((self) => ({
    reset() {
      self.vendor.setInitialValue(self._vendor);
      self.nvidia_model.setInitialValue(self._nvidia_model);
      self.nvidia_quantity.setInitialValue(String(self._nvidia_quantity));
      self.custom_resource.setInitialValue(self._custom_resource);
      self.custom_runtimeClass.setInitialValue(self._custom_runtimeClass);
      self.custom_quantity.setInitialValue(String(self._custom_quantity));
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.reset();
    },
    setNvidiaModel(value: "t4" | "a10g") {
      let maxValue = 1;
      if (value === "t4") {
        maxValue = 4;
      }
      self.nvidia_quantity.setMax(maxValue);
      self.nvidia_model.setValue(value);
    },
    confirm() {
      self._vendor = self.vendor.value as any;
      self.vendor.confirm();
      self._nvidia_model = self.nvidia_model.value;
      self.nvidia_model.confirm();
      self._nvidia_quantity = Number(self.nvidia_quantity.value);
      self.nvidia_quantity.confirm();
      self._custom_resource = self.custom_resource.value;
      self.custom_resource.confirm();
      self._custom_runtimeClass = self.custom_runtimeClass.value;
      self.custom_runtimeClass.confirm();
      self._custom_quantity = Number(self.custom_quantity.value);
      self.custom_quantity.confirm();
    },
  }))
  .views((self) => ({
    get isDirty() {
      let res = false;
      if (self.vendor.isDirty) res = true;
      if (self.vendor.value === "nvidia") {
        if (self.nvidia_model.isDirty) res = true;
        if (self.nvidia_quantity.isDirty) res = true;
      }
      if (self.vendor.value === "custom") {
        if (self.custom_resource.isDirty) res = true;
        if (self.custom_runtimeClass.isDirty) res = true;
        if (self.custom_quantity.isDirty) res = true;
      }
      return res;
    },
    get isValid() {
      let res = true;
      if (self.vendor.value === "nvidia") {
        if (!self.nvidia_quantity.isValid) res = false;
      }
      if (self.vendor.value === "custom") {
        if (!self.custom_resource.isValid) res = false;
        if (!self.custom_quantity.isValid) res = false;
      }
      return res;
    },
    get asObject() {
      if (self.vendor.value === "none" && self._vendor !== "none") {
        return undefined; // for replace return undefined, normally should be null
      }
      if (self.vendor.value === "none" && self._vendor === "none") {
        return undefined;
      }
      let res: any = {};
      if (self.vendor.value === "nvidia") {
        res.nvidia = { model: self.nvidia_model.value, quantity: Number(self.nvidia_quantity.value) };
      }
      if (self.vendor.value === "custom") {
        res.custom = {
          resource: self.custom_resource.value,
          quantity: Number(self.custom_quantity.value),
        };
        if (!!self.custom_runtimeClass.value) {
          res.custom.runtimeClass = self.custom_runtimeClass.value;
        }
      }
      return res;
    },
  }));
export interface WorkloadDraftContainerGPUMobx extends Instance<typeof WorkloadDraftContainerGPUModel> {}
