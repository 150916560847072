import * as React from "react";
import { Modal, notification } from "antd";
import { observer } from "mobx-react-lite";
import { OrgMobx } from "../../mst/kinds/org";
import { StringModel } from "../../mobxDataModels/stringModel";
import { request } from "../../services/cpln";
import { FormButtons } from "../../components/forms/formButtons";
import { AccountSummary, UserData } from "../../mobxStores/userData/userData";
import { useDetailContext } from "../../components/detail/detailContext";
import { TagsNewModel } from "../../mobxDataModels/tagsNewModel";
import { kindMobxToTagsModel } from "../../mst/mobxUtilts";
import { NumberModel } from "../../mobxDataModels/numberModel";
import { ConsoleContext } from "../../mobxStores/consoleContext/consoleContext";
import { tagLinkUrlPrefixes } from "../../services/utils";
import { NGButton } from "../../newcomponents/button/Button";
import { NGLabel } from "../../newcomponents/text/label";
import { PromptContext } from "../../mobxStores/prompt/prompt";
import { useCleanPrompt } from "../../reactHooks/useCleanPrompt";
import { NGFormElement } from "../../newcomponents/ngformelement/ngformelement";
import { NGSelect } from "../../newcomponents/select/ngselect";
import { TagLinksTable } from "../../components/detail/tagLinksTable";
import NGAlert from "../../newcomponents/alert";
import { CopyButton } from "../../components/copy";

interface Props {
  org: OrgMobx;
  account: AccountSummary | undefined;
  updateAccount: () => Promise<void>;
}
const InfoRaw: React.FC<Props> = ({ org, account, updateAccount }) => {
  const { fetchItem } = useDetailContext();

  const descriptionRef = React.useRef(StringModel.create({ label: "Description", initialValue: org.description }));
  const tagsRef = React.useRef(TagsNewModel.create({ tags: kindMobxToTagsModel(org) }));
  const sessionTimeoutSecondsRef = React.useRef(
    NumberModel.create({ label: "Session Timeout Seconds", initialValue: org.spec?.sessionTimeoutSeconds || 15 * 60 }),
  );

  const [isDirty, setIsDirty] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isLoadingChangeAccount, setIsLoadingChangeAccount] = React.useState(false);
  const [showChangingAccount, setShowChangingAccount] = React.useState(false);
  const [newAccountId, setNewAccountId] = React.useState("");

  React.useEffect(() => {
    process();
  }, []);

  React.useEffect(() => {
    PromptContext.setWhen(isDirty || isLoading);
  }, [isDirty, isLoading]);

  useCleanPrompt();

  React.useEffect(() => {
    setIsDirty(descriptionRef.current.isDirty || sessionTimeoutSecondsRef.current.isDirty);
  }, [descriptionRef.current.isDirty, sessionTimeoutSecondsRef.current.isDirty]);

  async function process() {
    await UserData.requestAccountSummaries();

    if (!!account) {
      const filteredSummaries = UserData.accountSummaries.filter((acc) => acc.id !== account.id);
      if (filteredSummaries.length > 0) {
        setNewAccountId(filteredSummaries[0].id);
      }
    }
  }

  function reset() {
    descriptionRef.current.reset();
    sessionTimeoutSecondsRef.current.reset();
  }

  async function save() {
    try {
      setIsLoading(true);
      const description = descriptionRef.current.value || null;
      await org.patch({ description, spec: { sessionTimeoutSeconds: Number(sessionTimeoutSecondsRef.current.value) } });
      descriptionRef.current.confirm();
      if (!descriptionRef.current.value) {
        descriptionRef.current.setInitialValue(org.name);
      }
      if (sessionTimeoutSecondsRef.current.isDirty) {
        ConsoleContext.updateOrgTimeoutSeconds();
      }
      sessionTimeoutSecondsRef.current.confirm();
      notification.success({
        message: "Success",
        description: "Updated Org",
      });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.error("Org Info", e);
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
      if (e.response.status === 409) {
        if (fetchItem) {
          await fetchItem();
          notification.info({
            message: "Updated Item",
            description: "Fetched the latest version of the item and discarded changes.",
          });
        }
      }
    }
  }

  async function onAccountChange() {
    if (!account) {
      return;
    }

    setShowChangingAccount(true);
    const options = UserData.accountSummaries.filter((acc) => acc.accountName !== account.accountName);
    if (options.length > 0) {
      setNewAccountId(options[0].id);
    }
  }

  async function onConfirmAccountChange() {
    if (!account) {
      return;
    }
    try {
      setIsLoadingChangeAccount(true);
      await request({
        method: "post",
        service: "billing-ng",
        url: `/account/${account.id}/org/${org.name}`,
        body: {
          toAccountUUID: newAccountId,
        },
      });
      notification.success({
        message: "Success",
        description: "Changed account of the org",
      });
      setNewAccountId("");
      updateAccount();
    } catch (e) {
      let errorMessage = e?.response?.data?.error;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
    } finally {
      setIsLoadingChangeAccount(false);
      setShowChangingAccount(false);
    }
  }

  const showChangeAccountButton =
    account && UserData.accountSummaries.filter((acc) => acc.id !== account.id).length > 0;

  const showAlertEmail =
    !org.spec?.observability?.defaultAlertEmails || org.spec.observability.defaultAlertEmails.length < 1;

  return (
    <>
      <div>
        {showAlertEmail ? (
          <NGAlert
            className="mb-4"
            title="Alert Email is Not Set"
            message="Set default alert emails in Observability page to receive grafana alerts."
          />
        ) : null}
        <div style={{ width: 450 }}>
          {account ? (
            <div className="mb-4">
              <NGLabel>Account Name{account.company ? ` | Company` : ""}</NGLabel>
              <div className={`flex items-center ${showChangeAccountButton ? "mb-2" : ""}`}>
                <div>
                  {account.accountName || account.fullName || ""}
                  {account.company ? ` | ${account.company}` : ""}
                </div>
                {showChangeAccountButton ? (
                  <NGButton className="ml-2" size={"small"} variant={"primary"} onClick={onAccountChange}>
                    Change
                  </NGButton>
                ) : null}
              </div>
            </div>
          ) : null}
        </div>
        {org.status?.endpointPrefix ? (
          <>
            <NGLabel>Endpoint Prefix</NGLabel>
            <div className="flex items-center mb-4">
              <div>{org.status?.endpointPrefix}</div>
              <CopyButton content={org.status?.endpointPrefix} />
            </div>
          </>
        ) : null}
        <NGFormElement
          name="description"
          label={descriptionRef.current.label}
          value={descriptionRef.current.value}
          onChange={descriptionRef.current.setValue}
        />
        <NGFormElement
          name="sessionTimeoutSeconds"
          label={sessionTimeoutSecondsRef.current.label}
          value={sessionTimeoutSecondsRef.current.value}
          onChange={sessionTimeoutSecondsRef.current.setValue}
        />
        <TagLinksTable
          tableId="org-info-tag-links"
          tags={tagsRef.current.editTags.filter((t) => tagLinkUrlPrefixes.some((p) => t.value.startsWith(p)))}
          styles={{ header: { marginBottom: 0 } }}
        />
        <FormButtons
          onReset={reset}
          onSave={save}
          resetDisabled={isLoading || !isDirty}
          saveDisabled={isLoading || !isDirty}
          loading={isLoading}
        />
      </div>
      {!!account && showChangingAccount ? (
        <Modal
          open={showChangingAccount}
          title={"Change Account of the Org"}
          maskClosable={false}
          closable={false}
          onCancel={() => setShowChangingAccount(false)}
          footer={
            <div className="modal-actions">
              <NGButton
                variant="secondary"
                onClick={() => setShowChangingAccount(false)}
                disabled={isLoadingChangeAccount}
              >
                Cancel
              </NGButton>
              <NGButton
                variant="primary"
                onClick={onConfirmAccountChange}
                loading={isLoadingChangeAccount}
                disabled={isLoadingChangeAccount || !newAccountId}
              >
                OK
              </NGButton>
            </div>
          }
        >
          <div>Current Account</div>
          <div>{account.accountName || account.fullName || ""}</div>
          <div className="mt-6">New Account</div>
          <NGSelect
            value={newAccountId}
            onChange={setNewAccountId}
            options={UserData.accountSummaries
              .filter((acc) => acc.accountName !== account.accountName)
              .map((acc) => ({ label: acc.accountName || acc.fullName, value: acc.id }))}
          />
        </Modal>
      ) : null}
    </>
  );
};

export const Info = observer(InfoRaw);
