import * as React from "react";
import { observer } from "mobx-react-lite";
import { IdentityDraftGcpHelper } from "../../../../mst/stores/identity.gcp.helper";
import { Link } from "react-router-dom";
import { CloudaccountMobx, CloudaccountModel } from "../../../../mst/kinds/cloudaccount";
import { request } from "../../../../services/cpln";
import { LoadingOutlined } from "@ant-design/icons";
import { FormLabel } from "../../../../components/forms/formLabel";
import { Theme } from "../../../../mobxStores/uiData/theme";
import { NGButton } from "../../../../newcomponents/button/Button";
import { Tooltip } from "../../../../components/Tooltip";
import { IdentityStatusProviderMobx } from "../../../../mst/kinds/identity";
import { NGChip } from "../../../../newcomponents/chip";

interface Props {
  gcp: IdentityDraftGcpHelper;
  providerStatus?: IdentityStatusProviderMobx;
  infoOnly: boolean;
}
const GCPIdentityInfoRaw: React.FC<Props> = ({ gcp, providerStatus, infoOnly }) => {
  const { theme } = Theme;
  const [gcpLogoPath, setGcpLogoPath] = React.useState(() => getGcpLogoPath());
  const [isPageReady, setIsPageReady] = React.useState(false);
  const [cloudaccount, setCloudaccount] = React.useState<CloudaccountMobx>(null as any);
  const cloudaccountLink = gcp.edited.cloudaccountLink;
  // const hasStatusError = providerStatus?.usable === false;

  React.useEffect(() => {
    if (!gcp.edited.isActive) return;
    request({ url: gcp.edited.cloudaccountLink })
      .then(({ data }) => {
        setCloudaccount(CloudaccountModel.create(data));
        setIsPageReady(true);
      })
      .catch(() => {
        setIsPageReady(true);
      });
  }, [gcp.edited.isActive, gcp.edited.cloudaccountLink]);

  React.useEffect(() => {
    setGcpLogoPath(getGcpLogoPath());
  }, [theme]);

  function getGcpLogoPath() {
    return `/resources/logos/gcpLogo${theme === "dark" ? "Dark" : ""}.svg`;
  }

  if (!gcp.edited.isActive) {
    if (infoOnly) return null;
    return (
      <button
        data-testid={`gcp-none`}
        onClick={() => {
          gcp.setup();
        }}
        className={`mb-8 p-4 w-full hover:shadow-lg border bg-identity-gcp-logo-drop flex flex-col items-center focus-big`}
      >
        <div className="py-2" style={{ height: 88 }}>
          <img style={{ height: "100%" }} src={gcpLogoPath} />
        </div>
      </button>
    );
  }

  if (!isPageReady) {
    return (
      <div
        data-testid={`gcp-loading`}
        className="mb-8 p-4 relative border cursor-wait flex flex-col items-center bg-identity-gcp-logo-drop"
        style={{ height: 122 }}
      >
        <span
          className="absolute flex items-center justify-center"
          style={{ width: 20, height: 20, left: "calc(50% - 10px)", top: "calc(75% - 10px)" }}
        >
          <LoadingOutlined />
        </span>
        <div className="py-2" style={{ height: 44 }}>
          <img style={{ height: "100%" }} src={gcpLogoPath} />
        </div>
      </div>
    );
  }

  const hasCloudaccount = cloudaccount !== null;
  const cloudaccountName = cloudaccount?.name || cloudaccountLink;

  if (gcp.edited.method === "existing") {
    return (
      <div data-testid={`gcp-existing`} className="mb-8 border">
        <div className="py-4 px-4 mb-4 border-b-4 border-identity-gcp-logo-accent">
          <img style={{ height: 30 }} src={gcpLogoPath} />
        </div>
        <div className="mb-2 px-4 flex">
          <div className="w-4/12">
            <FormLabel>Cloud Account</FormLabel>
            <Tooltip title={gcp.edited.cloudaccountLink} open={cloudaccountName.length >= 25}>
              {hasCloudaccount ? (
                <Link
                  className={`w-full inline-block truncate color-link font-medium ngfocus`}
                  data-testid="gcp-ca"
                  to={`/console${gcp.edited.cloudaccountLink}`}
                >
                  {cloudaccountName}
                </Link>
              ) : (
                <span className={`w-full inline-block truncate`}>{cloudaccountName}</span>
              )}
            </Tooltip>
          </div>
          {hasCloudaccount ? (
            <div className="w-8/12">
              <FormLabel>Project Id</FormLabel>
              <div data-testid="gcp-projectid" className="font-mono truncate">
                {cloudaccount.data?.projectId}
              </div>
            </div>
          ) : null}
        </div>
        <div className="mb-2 px-4">
          <FormLabel>Reusing an Existing Service Account</FormLabel>
          <Tooltip title={gcp.edited.serviceaccount!} open={(gcp.edited.serviceaccount || "").length >= 86}>
            <div data-testid={`gcp-serviceaccount`} className="font-mono truncate">
              {gcp.edited.serviceaccount!}
            </div>
          </Tooltip>
        </div>
        {infoOnly ? null : (
          <div className="flex items-center justify-end px-4 pb-4">
            <NGButton
              style={{ width: 77, marginRight: 10 }}
              className="mt-2"
              variant={"primary"}
              onClick={() => {
                gcp.setup();
              }}
            >
              Edit
            </NGButton>
            <NGButton
              variant={"danger"}
              style={{ width: 77, marginLeft: 10 }}
              className="mt-2"
              onClick={() => {
                gcp.remove();
              }}
            >
              Remove
            </NGButton>
          </div>
        )}
      </div>
    );
  }
  return (
    <div data-testid={`gcp-new`} className="mb-8 border">
      <div className="flex gap-4 items-center p-4 pt-5 mb-4 bg-identity-aws-logo-drop border-b-4 border-identity-aws-logo-accent">
        <img style={{ height: 30 }} src={gcpLogoPath} />
        {/* {hasStatusError ? <NGChip size="small" variant={"error"} label={"Unusable"} /> : null} */}
      </div>
      <div className="mb-2 px-4 flex">
        <div className="w-4/12 pr-4">
          <FormLabel>Cloud Account</FormLabel>
          <Tooltip title={gcp.edited.cloudaccountLink} open={cloudaccountName.length >= 25}>
            {hasCloudaccount ? (
              <Link
                className={`w-full inline-block truncate font-medium ngfocus color-link`}
                data-testid="gcp-ca"
                to={`/console${gcp.edited.cloudaccountLink}`}
              >
                {cloudaccountName}
              </Link>
            ) : (
              <span className={`w-full inline-block truncate`}>{cloudaccountName}</span>
            )}
          </Tooltip>
        </div>
        {hasCloudaccount ? (
          <div className="w-8/12">
            <FormLabel>Project Id</FormLabel>
            <div data-testid={`gcp-projectid`} className="font-mono truncate">
              {cloudaccount.data?.projectId}
            </div>
          </div>
        ) : null}
      </div>
      <div className="mb-2 px-4">
        <FormLabel>Service Account Bindings</FormLabel>
        {gcp.edited.bindings.map((binding, index) => (
          <div key={index} className="mb-4 mt-2 p-4 border border-t-4 rounded-b border-identity-gcp-logo-accent">
            <FormLabel>Resource</FormLabel>
            <Tooltip
              title={binding.forEdit.resourceInput.value || "Project Itself (Legacy)"}
              open={binding.forEdit.resourceInput.value.length >= 86}
            >
              <div
                data-testid={`gcp-resource-${binding.forEdit.resourceInput.value || "project-itself"}`}
                className="font-mono truncate mb-2"
              >
                {binding.forEdit.resourceInput.value || "Project Itself (Legacy)"}
              </div>
            </Tooltip>
            <FormLabel>Roles</FormLabel>
            <ul className="pl-6">
              {binding.forEdit.roles.map((role) => (
                <li key={role} role={role} className="list-disc">
                  <div
                    data-testid={`gcp-role-${role}-resource-${binding.forEdit.resourceInput.value || "project-itself"}`}
                    className="font-mono truncate"
                  >
                    {role}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      {infoOnly ? null : (
        <div className="flex items-center justify-end px-4 pb-4">
          <NGButton
            style={{ width: 77, marginRight: 10 }}
            className="mt-2"
            onClick={() => {
              gcp.setup();
            }}
            variant={"primary"}
          >
            Edit
          </NGButton>
          <NGButton
            variant={"danger"}
            outlined
            style={{ width: 77, marginLeft: 10 }}
            className="mt-2"
            onClick={() => {
              gcp.remove();
            }}
          >
            Remove
          </NGButton>
        </div>
      )}
    </div>
  );
};

export const GCPIdentityInfo = observer(GCPIdentityInfoRaw);
