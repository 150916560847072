import * as React from "react";
import { observer } from "mobx-react-lite";
import { request, resourceLink } from "../../services/cpln";

import { WorkloadMobx } from "../../mst/kinds/workload";
import { GVCMobx } from "../../mst/kinds/gvc";
import { RunCronReducerActionType, useRunCronReducer } from "./reducer_runCron";

import { NoTag } from "../../components/tag/noTag";
import { Modal, Tabs, notification } from "antd";
import { CommandTagItem } from "../../components/detail/commandTagItem";
import { NGButton } from "../../newcomponents/button/Button";
import { NGFormElement } from "../../newcomponents/ngformelement/ngformelement";
import { NGTextArea } from "../../newcomponents/textarea";
import { NGLabel } from "../../newcomponents/text/label";
import { NGInputList } from "../../newcomponents/inputList/inputList";
import { NGLabelText } from "../../newcomponents/text/labelText";
import { NGCheckbox } from "../../newcomponents/checkbox";

import { memoryUnitOptions } from "../../mobxDataModels/memoryModel";
import { cpuUnitOptions } from "../../mobxDataModels/cpuModel";

interface Props {
  workloadMobx: WorkloadMobx;
  initialLocation?: string;
  onClose: any;
  onDone: any;
  visible: boolean;
}

const CommandModal_StartCronJobRaw: React.FC<Props> = ({ workloadMobx, initialLocation, onClose, onDone, visible }) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const [gvcLocations, setGVCLocations] = React.useState<string[]>([]);
  const [location, setLocation] = React.useState(initialLocation);
  // TODO Filter by the active workload locations
  const availableLocations = [...gvcLocations];
  if (!!initialLocation && !availableLocations.includes(initialLocation)) {
    availableLocations.push(initialLocation);
  }

  const [state, dispatch] = useRunCronReducer(workloadMobx.spec.containers.map((c) => c.name));

  React.useEffect(() => {
    fetchGVCLocations();
  }, []);

  async function fetchGVCLocations() {
    try {
      const { data } = await request({ url: resourceLink("gvc", workloadMobx.gvc) });
      const gvc: GVCMobx = data;
      const locationNames = gvc.spec.staticPlacement.locationLinks.map((link) => link.split("/").pop()!);
      setGVCLocations(locationNames);
      if (!initialLocation && locationNames.length > 0) {
        setLocation(locationNames[0]);
      }
    } catch (e) {
      setGVCLocations([]);
    }
  }

  async function handleConfirm() {
    try {
      setIsLoading(true);
      await request({
        method: "post",
        url: workloadMobx.selfLink + "/-command",
        body: {
          type: "runCronWorkload",
          spec: {
            location: location,
            containerOverrides: state.overrides
              .map((ov) => {
                if (!ov.isOverridden) {
                  return undefined;
                }
                let res: any = {
                  name: ov.name,
                };
                if (ov.command) {
                  res.command = ov.command;
                }
                if (ov.memory.value) {
                  if (ov.memory.suffix === "Other") {
                    res.memory = ov.memory.value;
                  } else {
                    if (ov.memory.value) {
                      res.memory = String(ov.memory.value) + String(ov.memory.suffix);
                    }
                  }
                }
                if (ov.cpu.value) {
                  if (ov.cpu.suffix === "Cores") {
                    res.cpu = ov.cpu.value;
                  } else {
                    res.cpu = ov.cpu.value + "m";
                  }
                }
                if (ov.image) {
                  res.image = ov.image;
                }
                if (ov.args.length > 0) {
                  res.args = ov.args.map((a) => a.name);
                }
                if (ov.env.length > 0) {
                  res.env = ov.env.map((e) => ({ name: e.name, value: e.value }));
                }
                return res;
              })
              .filter(Boolean),
          },
        },
      });
      onDone();
      notification.success({
        message: "Success",
        description: "Created Command.",
      });
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed to start cron job",
        description: errorMessage,
      });
      setIsLoading(false);
    }
  }

  const selectedOverride = state.overrides.find((o) => o.name === state.selectedName)!;

  const isValid = !!location;

  return (
    <Modal
      width={500}
      open={visible}
      closable={false}
      onCancel={onClose}
      maskClosable={!isLoading}
      destroyOnClose
      footer={
        <div className="modal-actions">
          <NGButton variant="secondary" onClick={() => onClose()} disabled={isLoading}>
            Cancel
          </NGButton>
          <NGButton variant="primary" onClick={handleConfirm} loading={isLoading} disabled={isLoading || !isValid}>
            Start Cron Job
          </NGButton>
        </div>
      }
    >
      <div className="flex flex-col">
        <div className={"text-2xl mb-4"}>Start Cron Job Now</div>
        <NGFormElement
          as={"select"}
          name={"location"}
          style={{ width: "auto" }}
          options={availableLocations.map((aL) => ({ label: aL, value: aL, disabled: false, isLabel: false }))}
          value={location || ""}
          onChange={(value) => setLocation(value)}
          label={"Location"}
        />
        <NGLabel>Containers</NGLabel>
        {state.containerNames.length > 0 ? (
          <Tabs
            onChange={(n) => dispatch({ type: RunCronReducerActionType.SELECT_CONTAINER, payload: n })}
            items={state.containerNames.map((n) => ({
              label: n,
              key: n,
              children: (
                <>
                  <NGCheckbox
                    className="mb-4"
                    checked={selectedOverride.isOverridden}
                    onChange={() =>
                      dispatch({ type: RunCronReducerActionType.OVERRIDE, payload: !selectedOverride.isOverridden })
                    }
                  >
                    <NGLabelText>Override Container Spec</NGLabelText>
                  </NGCheckbox>
                  {selectedOverride.isOverridden ? (
                    <>
                      <NGLabel>Override Command</NGLabel>
                      <NGTextArea
                        className="mb-4"
                        placeholder="Multiline Input"
                        value={selectedOverride.command}
                        onChange={(e) => dispatch({ type: RunCronReducerActionType.COMMAND, payload: e.target.value })}
                      />
                      <NGInputList<{ id: string; chosen: boolean; name: string }>
                        label={"Arg Overrides"}
                        draggable
                        allowListSelection={true}
                        items={selectedOverride.args}
                        add={() => dispatch({ type: RunCronReducerActionType.ADD_ARG, payload: "" })}
                        setItems={(newState) => {
                          dispatch({ type: RunCronReducerActionType.ARGS, payload: newState });
                        }}
                        isAllSelected={selectedOverride.isAllArgsSelected}
                        removeSelections={() =>
                          dispatch({ type: RunCronReducerActionType.REMOVE_SELECTIONS, payload: "" })
                        }
                        selections={selectedOverride.argSelections}
                        toggleAllSelect={() =>
                          dispatch({ type: RunCronReducerActionType.TOGGLE_ALL_SELECT, payload: "" })
                        }
                        toggleSelect={(id) =>
                          dispatch({ type: RunCronReducerActionType.TOGGLE_SELECT, payload: { id: id } })
                        }
                        firstInput={(item) => (
                          <NGTextArea
                            key={item.id}
                            value={item.name}
                            placeholder="Multiline Input"
                            onChange={(e) =>
                              dispatch({
                                type: RunCronReducerActionType.CHANGE_ARG,
                                payload: { id: item.id, name: e.target.value },
                              })
                            }
                          />
                        )}
                      />
                      <div className="flex items-center gap-2 mb-4" style={{ width: 450 }}>
                        <NGFormElement
                          value={selectedOverride.cpu.value}
                          label="CPU"
                          className="w-1/2"
                          name="cpu-value"
                          inputProps={{ type: "number" }}
                          error={!selectedOverride.cpu.isValid ? "CPU format is invalid" : undefined}
                          onChange={(value) =>
                            dispatch({
                              type: RunCronReducerActionType.SET_CPU,
                              payload: { ...selectedOverride.cpu, value },
                            })
                          }
                        />
                        <NGFormElement
                          value={selectedOverride.cpu.suffix}
                          label="Unit"
                          as="select"
                          className="w-1/2"
                          name="cpu-suffix"
                          options={cpuUnitOptions}
                          onChange={(suffix) =>
                            dispatch({
                              type: RunCronReducerActionType.SET_CPU,
                              payload: { ...selectedOverride.cpu, suffix },
                            })
                          }
                        />
                      </div>
                      <div className="flex items-center gap-2 mb-4" style={{ width: 450 }}>
                        <NGFormElement
                          value={selectedOverride.memory.value}
                          label="Memory"
                          className="w-1/2"
                          name="memory-value"
                          inputProps={selectedOverride.memory.suffix !== "Other" ? { type: "number" } : {}}
                          error={!selectedOverride.memory.isValid ? "Memory format is invalid" : undefined}
                          onChange={(value) =>
                            dispatch({
                              type: RunCronReducerActionType.SET_MEMORY,
                              payload: { ...selectedOverride.memory, value },
                            })
                          }
                        />
                        <NGFormElement
                          value={selectedOverride.memory.suffix}
                          label="Memory"
                          as="select"
                          className="w-1/2"
                          name="memory-suffix"
                          options={memoryUnitOptions}
                          onChange={(suffix) =>
                            dispatch({
                              type: RunCronReducerActionType.SET_MEMORY,
                              payload: { ...selectedOverride.memory, suffix },
                            })
                          }
                        />
                      </div>
                      <NGFormElement
                        value={selectedOverride.image}
                        label="Image"
                        name="image"
                        onChange={(value) => dispatch({ type: RunCronReducerActionType.SET_IMAGE, payload: value })}
                      />
                      <div className="flex items-center gap-2 mb-4">
                        <NGLabel>Environment Variables</NGLabel>
                        <NGButton
                          onClick={() => dispatch({ type: RunCronReducerActionType.ADD_ENV, payload: "" })}
                          variant={"action"}
                          size="small"
                        >
                          Add
                        </NGButton>
                      </div>
                      {selectedOverride.env.length > 0 ? null : <NoTag override={"No Env Var Overrides"} />}
                      <div className="flex flex-col gap-2">
                        {selectedOverride.env.map((env) => (
                          <CommandTagItem
                            key={env.index}
                            index={env.index}
                            name={env.name}
                            value={env.value}
                            onChange={(index, name, value) =>
                              dispatch({ type: RunCronReducerActionType.CHANGE_ENV, payload: { index, name, value } })
                            }
                            onRemove={() => dispatch({ type: RunCronReducerActionType.REMOVE_ENV, payload: env.index })}
                          />
                        ))}
                      </div>
                    </>
                  ) : null}
                </>
              ),
              onClick: () => dispatch({ type: RunCronReducerActionType.SELECT_CONTAINER, payload: n }),
            }))}
          />
        ) : null}
      </div>
    </Modal>
  );
};

export const CommandModal_StartCronJob = observer(CommandModal_StartCronJobRaw);
