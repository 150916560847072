import * as React from "react";
import { notification } from "antd";
import { observer } from "mobx-react-lite";
import { OrgMobx } from "../../mst/kinds/org";
import { FormButtons } from "../../components/forms/formButtons";
import { useDetailContext } from "../../components/detail/detailContext";
import { NumberModel } from "../../mobxDataModels/numberModel";
import { NGInputAdapter } from "../../newcomponents/input/inputAdapter";
import { PromptContext } from "../../mobxStores/prompt/prompt";
import { useCleanPrompt } from "../../reactHooks/useCleanPrompt";
import { NGInputList } from "../../newcomponents/inputList/inputList";
import { NGInput } from "../../newcomponents/input/input";
import { ListOfItemsModel } from "../../mobxDataModels/listOfItemsModel";
import { inputValidations } from "../../mobxDataModels/validations";
import { defaultValues } from "../../mst/base";

interface Props {
  org: OrgMobx;
}
const ObservabilityRaw: React.FC<Props> = ({ org }) => {
  const { fetchItem } = useDetailContext();

  const logsRetentionDays = React.useRef(
    NumberModel.create({
      label: "Logs Retention Days",
      initialValue:
        org.spec?.observability?.logsRetentionDays || defaultValues.org.observability.logsRetentionDays.default,
      min: defaultValues.org.observability.logsRetentionDays.min,
      isRequired: true,
    }),
  );
  const metricsRetentionDays = React.useRef(
    NumberModel.create({
      label: "Metrics Retention Days",
      initialValue:
        org.spec?.observability?.metricsRetentionDays || defaultValues.org.observability.metricsRetentionDays.default,
      min: defaultValues.org.observability.metricsRetentionDays.min,
      isRequired: true,
    }),
  );
  const tracesRetentionDays = React.useRef(
    NumberModel.create({
      label: "Traces Retention Days",
      initialValue:
        org.spec?.observability?.tracesRetentionDays || defaultValues.org.observability.tracesRetentionDays.default,
      min: defaultValues.org.observability.tracesRetentionDays.min,
      isRequired: true,
    }),
  );

  const alertEmailsRef = React.useRef(
    ListOfItemsModel.create({
      _items: ((org.spec?.observability?.defaultAlertEmails || []) as string[]).map((v, idx) => ({
        id: idx.toString(),
        firstValue: v,
      })),
    }),
  );

  const [isDirty, setIsDirty] = React.useState(false);
  const [isValid, setIsValid] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    PromptContext.setWhen(isDirty || isLoading);
  }, [isDirty, isLoading]);

  useCleanPrompt();

  React.useEffect(() => {
    setIsDirty(
      logsRetentionDays.current.isDirty ||
        metricsRetentionDays.current.isDirty ||
        tracesRetentionDays.current.isDirty ||
        alertEmailsRef.current.isDirty,
    );
  }, [
    logsRetentionDays.current.isDirty,
    metricsRetentionDays.current.isDirty,
    tracesRetentionDays.current.isDirty,
    alertEmailsRef.current.isDirty,
  ]);

  React.useEffect(() => {
    let alertEmailsValid = true;
    for (const item of alertEmailsRef.current.items) {
      const email = item.firstValue;
      // TODO make a function that uses these
      if (!email) {
        alertEmailsValid = false;
      }
      for (const validationFn of inputValidations.email) {
        if (validationFn("Email", email) !== true) {
          alertEmailsValid = false;
        }
      }
    }

    setIsValid(
      logsRetentionDays.current.isValid &&
        metricsRetentionDays.current.isValid &&
        tracesRetentionDays.current.isValid &&
        alertEmailsValid,
    );
  }, [
    logsRetentionDays.current.isValid,
    metricsRetentionDays.current.isValid,
    tracesRetentionDays.current.isValid,
    alertEmailsRef.current.hash,
  ]);

  function reset() {
    logsRetentionDays.current.reset();
    metricsRetentionDays.current.reset();
    tracesRetentionDays.current.reset();
    alertEmailsRef.current.reset();
  }

  async function save() {
    try {
      setIsLoading(true);
      await org.patch({
        spec: {
          observability: {
            tracesRetentionDays: Number(tracesRetentionDays.current.value),
            logsRetentionDays: Number(logsRetentionDays.current.value),
            metricsRetentionDays: Number(metricsRetentionDays.current.value),
            defaultAlertEmails: alertEmailsRef.current.items.map((i) => i.firstValue),
          },
        },
      });

      tracesRetentionDays.current.confirm();
      logsRetentionDays.current.confirm();
      metricsRetentionDays.current.confirm();
      alertEmailsRef.current.confirm();

      notification.success({
        message: "Success",
        description: "Updated Observability",
      });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.error("Observability", e);
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
      if (e.response.status === 409) {
        if (fetchItem) {
          await fetchItem();
          notification.info({
            message: "Updated Item",
            description: "Fetched the latest version of the item and discarded changes.",
          });
        }
      }
    }
  }

  return (
    <>
      <div style={{ width: 450 }}>
        <NGInputAdapter style={{ width: 450 }} className="mb-4" data={logsRetentionDays.current} />
        <NGInputAdapter style={{ width: 450 }} className="mb-4" data={metricsRetentionDays.current} />
        <NGInputAdapter style={{ width: 450 }} className="mb-4" data={tracesRetentionDays.current} />
        <NGInputList<{
          id: string;
          chosen: boolean;
          firstValue: string;
          secondValue: string;
          setFirstValue: (value: string) => void;
          setSecondValue: (value: string) => void;
        }>
          label="Default Alert Emails"
          styles={{ header: { width: "100%" } }}
          add={() => alertEmailsRef.current.add()}
          remove={alertEmailsRef.current.remove}
          items={alertEmailsRef.current.items}
          setItems={alertEmailsRef.current.setItems}
          info={[
            "These emails are configured as alert recipients in grafana when the grafana-default-email contact delivery type is Email.",
          ]}
          firstInput={(item) => {
            let invalid = false;
            for (const validationFn of inputValidations.email) {
              if (validationFn("Email", item.firstValue) !== true) {
                invalid = true;
              }
            }
            return (
              <div className="flex items-center gap-2">
                <NGInput
                  className="flex-grow"
                  required
                  invalid={invalid}
                  placeholder="name@example.com"
                  value={item.firstValue}
                  onChange={(e) => item.setFirstValue(e.target.value)}
                />
              </div>
            );
          }}
        />
        <FormButtons
          onReset={reset}
          onSave={save}
          resetDisabled={isLoading || !isDirty}
          saveDisabled={isLoading || !isDirty || !isValid}
          loading={isLoading}
        />
      </div>
    </>
  );
};

export const Observability = observer(ObservabilityRaw);
