import * as React from "react";
import { observer } from "mobx-react-lite";
import { Modal, notification } from "antd";
import { k8sKeySort, updateLastDeploymentTimeOnHubspot } from "../../services/utils";
import { WorkloadDraftMobx } from "../../mst/stores/workload.draft";
import { slug } from "github-slugger";
import { v4 as uuidv4 } from "uuid";
import jsYaml from "js-yaml";
import { homeLink, parentLink, request } from "../../services/cpln";
import { ConsoleContext } from "../../mobxStores/consoleContext/consoleContext";
import { StringModel } from "../../mobxDataModels/stringModel";
import { useDetailContext } from "../../components/detail/detailContext";
import { Link } from "react-router-dom";
import { ArrowRight, ExternalLink } from "react-feather";
import { NGButton } from "../../newcomponents/button/Button";
import { NGInputAdapter } from "../../newcomponents/input/inputAdapter";
import { PromptContext } from "../../mobxStores/prompt/prompt";
import { useCleanPrompt } from "../../reactHooks/useCleanPrompt";
import { ngParseLink } from "../../utils/linkParser/linkParser";
import { NGKindSelect } from "../../newcomponents/select/ngkindselect";
import NGAlert from "../../newcomponents/alert";
import { NGFormLabel } from "../../newcomponents/text/formLabel";
import { InfoTooltip } from "../../components/InfoTooltip";
import { IdentityMobx } from "../../mst/kinds/identity";
import { DOCS_URL } from "../../envVariables";

interface Props {
  draft: WorkloadDraftMobx;
  patch: (body: any) => Promise<void>;
  identityMobx: IdentityMobx | null;
}
const IdentityRaw: React.FC<Props> = ({ draft, patch, identityMobx }) => {
  const { fetchItem } = useDetailContext();

  const { org, gvc } = ConsoleContext;
  const [isLoading, setIsLoading] = React.useState(false);

  const [isFixing, setIsFixing] = React.useState(false);
  const [fixLoading, setFixLoading] = React.useState<boolean>(false);
  const [fixPermissionError, setFixPermissionError] = React.useState<boolean>(false);
  const [identityFixCounter, setIdentityFixCounter] = React.useState(0);
  const identityNameManualRef = React.useRef(StringModel.create({ label: "Identity Name", validationKey: "name" }));
  const identityNameRef = React.useRef<string>("");
  const identityBodyRef = React.useRef<any>(null as any);
  const policyNameManualRef = React.useRef(StringModel.create({ label: "Policy Name", validationKey: "name" }));
  const policyNameRef = React.useRef<string>("");
  const policyBodyRef = React.useRef<any>(null as any);
  const workloadBodyRef = React.useRef<any>(null as any);

  React.useEffect(() => {
    draft.reset();
    return () => {
      draft.reset();
    };
  }, []);

  React.useEffect(() => {
    PromptContext.setWhen(draft._identityLink !== draft.identityLink);
  }, [draft._identityLink, draft.identityLink]);

  useCleanPrompt();

  React.useEffect(() => {
    if (draft.processingIdentity) {
      return;
    }
    draft.processIdentity(draft.identityLink);
  }, [draft.identityLink, draft.secretLinks.length, identityFixCounter]);

  async function save(fromFix = false) {
    try {
      setIsLoading(true);
      const body: any = {
        spec: {
          identityLink: draft.identityLink || null,
        },
      };
      // TODO tablefix
      // if (fromFix) {
      //   body.spec.identityLink = identity.identitySelect.initialValue;
      // }
      await patch(body);
      draft.confirm();
      notification.success({
        message: "Success",
        description: "Workload is updated",
      });
      updateLastDeploymentTimeOnHubspot();
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) {
        errorMessage = e.message;
      }
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
      if (e.response.status === 409) {
        if (fetchItem) {
          await fetchItem();
          notification.info({
            message: "Updated Item",
            description: "Fetched the latest version of the item and discarded changes.",
          });
        }
      }
      if (fromFix) {
        throw e;
      }
    } finally {
      setIsLoading(false);
    }
  }

  function getIdentityLink() {
    return ngParseLink(identityNameRef.current, { kind: "identity" }).absolute;
  }

  function getShortIdentityLink() {
    return ngParseLink(identityNameRef.current, { kind: "identity" }).gvcRelative;
  }

  function onFix() {
    var identityValue = !!draft.identityLink
      ? draft.identityLink.split("/").pop()!
      : `${slug(draft.name.value)}-${uuidv4().slice(0, 6)}`;
    setIsFixing(true);
    identityNameManualRef.current.setInitialValue(identityValue);
    policyNameManualRef.current.setInitialValue(`${slug(draft.name.value)}-${uuidv4().slice(0, 6)}`);
  }

  async function onFixConfirm() {
    try {
      setFixLoading(true);
      identityNameRef.current = identityNameManualRef.current.value;
      identityBodyRef.current = {
        name: identityNameRef.current,
        kind: "identity",
        description: `Auto-managed identity for the workload "${draft.name.value}"`,
      };
      policyNameRef.current = policyNameManualRef.current.value;
      policyBodyRef.current = {
        name: policyNameRef.current,
        kind: "policy",
        description: `Auto-created policy for the workload "${draft.name.value}"`,
        targetKind: "secret",
        targetLinks: draft.secretLinks.map((l) => l.replace(`/org/${org}/secret/`, "//secret/")),
        bindings: [{ permissions: ["reveal"], principalLinks: [`//gvc/${gvc}/identity/${identityNameRef.current}`] }],
      };
      workloadBodyRef.current = {
        name: draft.name.value,
        kind: "workload",
        spec: {
          identityLink: getShortIdentityLink(),
        },
      };
      // Create identity
      await request({ url: parentLink("identity"), body: identityBodyRef.current, method: "put" });
      // Create policy
      await request({ url: homeLink("policy"), body: policyBodyRef.current, method: "put" });
      draft.setIdentityLink(getIdentityLink());
      notification.success({ message: "Fixed the policy and identity." });
      await save(true);
      notification.success({ message: "Assigned identity to workload" });
      setIsFixing(false);
      setFixLoading(false);
      setIdentityFixCounter((x) => x + 1);
    } catch (e) {
      setFixLoading(false);
      if (e.message.includes("403")) {
        setIsFixing(false);
        setFixPermissionError(true);
      }
      if (e.response?.status !== 409) {
        let errorMessage = e?.response?.data?.message;
        if (!errorMessage) errorMessage = e.message;
        notification.error({ message: "Failed", description: errorMessage });
      }
    }
  }

  function toYaml(data: any) {
    try {
      return jsYaml.dump(data, { indent: 2, noRefs: true, sortKeys: k8sKeySort });
    } catch (e) {
      console.error("toYaml failed", data, e.message);
      throw e;
    }
  }

  async function onYaml() {
    try {
      const yamls = [identityBodyRef.current, policyBodyRef.current, workloadBodyRef.current].map(toYaml);
      const yaml = yamls.join("\n---\n");
      const a = document.createElement("a");
      a.style.display = "none";
      a.classList.add("cpln-temp-a");
      a.download = draft.name.value + "-fix-identity.yaml";
      const file = new Blob([yaml], { type: "text/yaml" });
      const href = URL.createObjectURL(file);
      a.href = href;
      a.click();
    } catch (e) {
      console.error("export yaml failed", e.message);
      throw e;
    }
  }

  const identitySelectValue = ngParseLink(draft.identityLink, { kind: "identity" }).name;

  function onIdentityChange(value: string) {
    const identityLink = ngParseLink(value, { kind: "identity" }).absolute;
    draft.setIdentityLink(identityLink);
  }

  return (
    <>
      <div>
        {!draft.identityLink && draft.hasAnyCloudVolume ? (
          <NGAlert
            className="mb-4"
            render={() => {
              return (
                <>
                  <p>
                    The workload should be assigned an identity. It uses a volume that points to a cloud account
                    resource. The identity should grant access to the resource.{" "}
                  </p>
                  <p>
                    <a
                      className="color-link flex items-center gap-1 underline"
                      target={"_blank"}
                      href={`${DOCS_URL}/concepts/accessing-cloud-resources`}
                    >
                      <span>Learn more</span>
                      <ExternalLink className="h-4" />
                    </a>
                  </p>
                </>
              );
            }}
          />
        ) : null}
        <NGFormLabel
          required={draft.identityValid === "required"}
          invalid={!["unknown", "unneeded", true].includes(draft.identityValid)}
        >
          Identity
        </NGFormLabel>
        <div className="flex items-center mb-4">
          <NGKindSelect
            value={identitySelectValue}
            onChange={(value) => onIdentityChange(value)}
            showClear
            style={{ width: 450 }}
            kind="identity"
            fetchAll
            placeholder=" "
            invalid={draft.identityValid === false || draft.identityValid === "required"}
            queries={[{ rel: "gvc", value: ConsoleContext.gvc! }]}
          />
          <InfoTooltip
            title={[
              "The identity link is used as the access scope for 3rd party cloud resources.",
              "A single identity can provide access to multiple cloud providers.",
              "An identity encapsulates several things. First, a workload automatically has all the permissions defined across multiple cloud providers, regardless of where the workload executes. Second, an identity can manage native networking capabilities and cloud wormhole policies, allowing workloads to reach private networks, such as VPCs. Third, policies can refer to an identity to grant a workload fine-grained access to Control Plane resources.",
            ]}
          />
        </div>
        {identityMobx !== null && !!draft.identityLink ? (
          <Link className="flex items-center mb-4 ngfocus color-link" to={`/console${draft.identityLink}`}>
            <span>Go to Identity</span>
            <ArrowRight className="mr-1 feather-icon inline-block" />
          </Link>
        ) : null}
        {draft.processingIdentity ? (
          <div className="flex items-center mb-4">
            <NGAlert type={"info"} message={"Checking validity of the identity."} />
          </div>
        ) : draft.identityValid === "required" ? (
          <div className="mb-4">
            <NGAlert type={"warning"} message={"An Identity is required since this workload uses secrets."} />
            <NGButton
              disabled={fixLoading || fixPermissionError}
              loading={fixLoading}
              className="mt-4"
              size={"small"}
              variant={"action"}
              onClick={onFix}
            >
              Fix Identity Requirement Automatically
            </NGButton>
            {fixPermissionError ? (
              <div className="flex items-center gap-2 mt-4">
                <NGAlert type={"warning"} message={"You dont have permission to create required items."} />
                <NGButton onClick={onYaml} variant={"primary"}>
                  Download Generated YAML
                </NGButton>
              </div>
            ) : null}
          </div>
        ) : draft.identityValid === "unknown" ? (
          <div className="mb-4">
            <NGAlert
              type={"info"}
              message={"We could not determine the validity of identity with your account's permissions."}
            />
            <NGButton
              disabled={fixLoading || fixPermissionError}
              loading={fixLoading}
              size={"small"}
              className="mt-4"
              variant={"action"}
              onClick={onFix}
            >
              Fix Identity Requirement Automatically
            </NGButton>
            {fixPermissionError ? (
              <div className="flex items-center gap-2 mt-4">
                <NGAlert type={"warning"} message={"You dont have permission to create required items."} />
                <NGButton onClick={onYaml} variant={"primary"}>
                  Download Generated YAML
                </NGButton>
              </div>
            ) : null}
          </div>
        ) : draft.identityValid === true ? (
          <div className="flex items-center mb-4">
            <NGAlert type={"success"} message={"Identity is valid for this workload."} />
          </div>
        ) : draft.identityValid === false ? (
          <div className="mb-4">
            <NGAlert
              type={"error"}
              message={
                "Either identity is not valid for this workload, or your account's permissions are not enough to determine validity."
              }
            />
            <NGButton
              disabled={fixLoading || fixPermissionError}
              loading={fixLoading}
              className="mt-4"
              size={"small"}
              variant={"action"}
              onClick={onFix}
            >
              Fix Identity Requirement Automatically
            </NGButton>
          </div>
        ) : null}
        {/* {identityMobx?.status?.aws?.usable === false ||
        identityMobx?.status?.azure?.usable === false ||
        identityMobx?.status?.gcp?.usable === false ? (
          <NGAlert
            className="mb-4"
            type={"error"}
            title="Identity is Unusable"
            message={`${
              identityMobx?.status?.aws?.lastError
                ? "AWS"
                : identityMobx?.status?.azure?.lastError
                ? "Azure"
                : identityMobx?.status?.gcp?.lastError
                ? "GCP"
                : ""
            } | ${
              identityMobx?.status?.aws?.lastError ||
              identityMobx?.status?.azure?.lastError ||
              identityMobx?.status?.gcp?.lastError
            }`}
          />
        ) : null} */}
        <div className="mt-8 flex items-center">
          <NGButton
            disabled={!draft.isDirty || isLoading || fixLoading}
            style={{ width: 215, marginRight: 10 }}
            onClick={draft.reset}
            variant={"danger"}
            outlined
          >
            Reset
          </NGButton>
          <NGButton
            disabled={!draft.isDirty || isLoading || fixLoading}
            loading={isLoading || fixLoading}
            style={{ width: 215, marginLeft: 10 }}
            onClick={() => save(false)}
            variant={"primary"}
          >
            Save
          </NGButton>
        </div>
      </div>
      {isFixing ? (
        <Modal
          open={isFixing}
          closable={false}
          maskClosable={false}
          onCancel={() => setIsFixing(false)}
          footer={
            <div className="modal-actions">
              <NGButton variant="secondary" onClick={() => setIsFixing(false)}>
                Cancel
              </NGButton>
              <NGButton
                variant="primary"
                onClick={onFixConfirm}
                loading={fixLoading}
                disabled={!policyNameManualRef.current.isValid || !identityNameManualRef.current.isValid || fixLoading}
              >
                OK
              </NGButton>
            </div>
          }
        >
          <NGInputAdapter style={{ width: "100%" }} data={policyNameManualRef.current} className="mb-4" />
          <NGInputAdapter style={{ width: "100%" }} data={identityNameManualRef.current} />
        </Modal>
      ) : null}
    </>
  );
};

export const Identity = observer(IdentityRaw);
