import { Instance, types } from "mobx-state-tree";
import { FIREWALL_ALLALLOW_REMOVES_PREV_ITEMS } from "../../envVariables";
import { PortModel } from "../../mobxDataModels/portModel";
import { SelectModel } from "../../mobxDataModels/selectModel";
import { StringModel } from "../../mobxDataModels/stringModel";
import { arraysAreEqual } from "../../services/cpln";
import { defaultValues } from "../base";
import { v4 as uuidv4 } from "uuid";

export const OutboundAllowPortReadOnlyModel = types.model({
  protocol: types.enumeration(["http", "https", "tcp"]),
  number: types.number,
});

export const OutboundAllowPortModel = types
  .model({
    id: types.optional(types.string, () => uuidv4()),
    _protocol: types.optional(types.string, "tcp"),
    protocol: types.optional(SelectModel, () =>
      SelectModel.create({
        options: [
          { label: "HTTP", value: "http" },
          { label: "HTTPS", value: "https" },
          { label: "TCP", value: "tcp" },
        ],
        label: "Protocol",
        initialValue: "tcp",
      }),
    ),
    _number: types.optional(types.number, -1),
    number: types.optional(StringModel, () => PortModel.create({ label: "Number", isRequired: true })),
  })
  .actions((self) => ({
    afterCreate() {
      self.protocol.setInitialValue(self._protocol);
      self.number.setInitialValue(self._number === -1 ? "" : String(self._number));
    },
    confirm() {
      self._protocol = self.protocol.value;
      self.protocol.confirm();
      self._number = Number(self.number.value);
      self.number.confirm();
    },
  }))
  .views((self) => ({
    get isValid() {
      let res = true;
      if (!self.number.isValid) res = false;
      return res;
    },
    get isDirty() {
      let res = false;
      if (self.protocol.isDirty) res = true;
      if (self.number.isDirty) res = true;
      return res;
    },
    get isDirtyReason() {
      let reason = "";
      if (self.protocol.isDirty) reason = "protocol";
      if (self.number.isDirty) reason = "number";
      return reason;
    },
    get asObject() {
      return {
        protocol: self.protocol.value as "http" | "https" | "tcp",
        number: Number(self.number.value),
      };
    },
  }));

export const WorkloadDraftFirewallModel = types
  .model({
    _resetTriggerKey: types.optional(types.number, 0),
    _orgName: types.string,
    _gvcName: types.string,
    _workloadName: types.string,
    _internal_inboundAllowType: types.optional(types.string, defaultValues.inboundAllowType as any),
    _internal_inboundAllowWorkload: types.array(types.string),
    _internal_inboundAllowItself: types.optional(types.boolean, false),
    _external_inboundAllowCIDR: types.array(types.string),
    _external_inboundBlockedCIDR: types.array(types.string),
    _external_outboundAllowCIDR: types.array(types.string),
    _external_outboundBlockedCIDR: types.array(types.string),
    _external_outboundAllowHostname: types.array(types.string),
    _external_outboundAllowPort: types.array(OutboundAllowPortReadOnlyModel),

    internal_inboundAllowType: types.optional(SelectModel, () =>
      SelectModel.create({
        label: "Inbound Allow Type",
        initialValue: defaultValues.inboundAllowType,
        options: [
          { label: "None", value: "none" },
          { label: "Same GVC", value: "same-gvc" },
          { label: "Same Org", value: "same-org" },
          { label: "Specific Workloads", value: "workload-list" },
          { label: "Same GVC and Specific Workloads", value: "same-gvc-and-workload-list" },
        ],
      }),
    ),
    internal_inboundAllowWorkload: types.array(types.string),
    internal_inboundAllowItself: types.optional(types.boolean, false),
    external_inboundAllowCIDR: types.array(types.string),
    external_inboundAllowCIDRInput: types.optional(StringModel, () =>
      StringModel.create({
        label: "Inbound Allow CIDR (IPV4, IPV6)",
        validationKey: "cidr",
      }),
    ),
    external_inboundBlockedCIDR: types.array(types.string),
    external_inboundBlockedCIDRInput: types.optional(StringModel, () =>
      StringModel.create({
        label: "Inbound Blocked CIDR (IPV4, IPV6)",
        validationKey: "cidr",
      }),
    ),

    external_outboundAllowCIDR: types.array(types.string),
    external_outboundAllowCIDRInput: types.optional(StringModel, () =>
      StringModel.create({
        label: "Outbound Allow CIDR (IPV4, IPV6)",
        validationKey: "cidr",
      }),
    ),
    external_outboundBlockedCIDR: types.array(types.string),
    external_outboundBlockedCIDRInput: types.optional(StringModel, () =>
      StringModel.create({
        label: "Outbound Blocked CIDR (IPV4, IPV6)",
        validationKey: "cidr",
      }),
    ),
    external_outboundAllowHostname: types.array(types.string),
    external_outboundAllowHostnameInput: types.optional(StringModel, () =>
      StringModel.create({
        label: "Outbound Allow Hostname",
        validationKey: "hostname",
        transformKey: "lowerCase",
      }),
    ),

    external_outboundAllowPort: types.array(OutboundAllowPortModel),
  })
  .views((self) => ({
    get external_allInboundAllowed() {
      return self.external_inboundAllowCIDR.some((i) => defaultValues.allowIP === i);
    },
    get external_allOutboundAllowed() {
      return self.external_outboundAllowCIDR.some((i) => defaultValues.allowIP === i);
    },
    get workloadLink() {
      return `/org/${self._orgName}/gvc/${self._gvcName}/workload/${self._workloadName}`;
    },
  }))
  .actions((self) => ({
    setInfo(orgName: string, gvcName: string) {
      self._orgName = orgName;
      self._gvcName = gvcName;
    },
    setWorkloadName(value: string) {
      self._workloadName = value;
    },
    internal_setInboundAllowItself(value: boolean) {
      self.internal_inboundAllowItself = value;
    },
    internal_setInboundAllowWorkload(values: string[]) {
      self.internal_inboundAllowWorkload.clear();
      for (const value of values) {
        self.internal_inboundAllowWorkload.push(value);
      }
    },
    external_setAllInboundAllowed(value: boolean) {
      const ipItem = self.external_inboundAllowCIDR.find((i) => i === defaultValues.allowIP);
      if (value) {
        if (!ipItem) {
          self.external_inboundAllowCIDR.push(defaultValues.allowIP);
        }
      } else {
        if (ipItem) {
          self.external_inboundAllowCIDR.remove(ipItem);
        }
      }
    },
    external_removeInboundAllowCIDR(ip: string) {
      const ipItem = self.external_inboundAllowCIDR.find((i) => i === ip);
      if (ipItem) {
        self.external_inboundAllowCIDR.remove(ipItem);
      }
    },
    external_addInboundAllowCIDR() {
      if (!self.external_inboundAllowCIDRInput.isValid) return;
      const input = self.external_inboundAllowCIDRInput.value.trim();
      const inputArray = input.split(" ");
      for (let inputItem of inputArray) {
        let ipArr: string[] = [];
        if (inputItem.includes(",")) {
          ipArr = inputItem.split(",");
        } else if (inputItem.includes(" ")) {
          ipArr = inputItem.split(" ");
        } else {
          ipArr = [inputItem];
        }
        for (let ip of ipArr.map((ip) => ip.trim())) {
          if (!ip) {
            continue;
          }
          const ipItem = self.external_inboundAllowCIDR.find((i) => i === ip);
          if (!ipItem) {
            self.external_inboundAllowCIDR.push(ip);
          }
        }
      }
      self.external_inboundAllowCIDRInput.reset();
    },
    external_removeInboundBlockedCIDR(ip: string) {
      const ipItem = self.external_inboundBlockedCIDR.find((i) => i === ip);
      if (ipItem) {
        self.external_inboundBlockedCIDR.remove(ipItem);
      }
    },
    external_addInboundBlockedCIDR() {
      if (!self.external_inboundBlockedCIDRInput.isValid) return;
      const input = self.external_inboundBlockedCIDRInput.value.trim();
      const inputArray = input.split(" ");
      for (let inputItem of inputArray) {
        let ipArr: string[] = [];
        if (inputItem.includes(",")) {
          ipArr = inputItem.split(",");
        } else if (inputItem.includes(" ")) {
          ipArr = inputItem.split(" ");
        } else {
          ipArr = [inputItem];
        }
        for (let ip of ipArr.map((ip) => ip.trim())) {
          if (!ip) {
            continue;
          }
          const ipItem = self.external_inboundBlockedCIDR.find((i) => i === ip);
          if (!ipItem) {
            self.external_inboundBlockedCIDR.push(ip);
          }
        }
      }
      self.external_inboundBlockedCIDRInput.reset();
    },
    external_setAllOutboundAllowed(value: boolean) {
      const ipItem = self.external_outboundAllowCIDR.find((i) => i === defaultValues.allowIP);
      if (value) {
        if (!ipItem) {
          self.external_outboundAllowCIDR.push(defaultValues.allowIP);
        }
      } else {
        if (ipItem) {
          self.external_outboundAllowCIDR.remove(ipItem);
        }
      }
    },
    external_removeOutboundAllowCIDR(ip: string) {
      const ipItem = self.external_outboundAllowCIDR.find((i) => i === ip);
      if (ipItem) {
        self.external_outboundAllowCIDR.remove(ipItem);
      }
    },
    external_addOutboundAllowCIDR() {
      if (!self.external_outboundAllowCIDRInput.isValid) return;
      const input = self.external_outboundAllowCIDRInput.value.trim();
      const inputArray = input.split(" ");
      for (let inputItem of inputArray) {
        let ipArr: string[] = [];
        if (inputItem.includes(",")) {
          ipArr = inputItem.split(",");
        } else if (inputItem.includes(" ")) {
          ipArr = inputItem.split(" ");
        } else {
          ipArr = [inputItem];
        }
        for (let ip of ipArr.map((ip) => ip.trim())) {
          if (!ip) {
            continue;
          }
          const ipItem = self.external_outboundAllowCIDR.find((i) => i === ip);
          if (!ipItem) {
            self.external_outboundAllowCIDR.push(ip);
          }
        }
      }
      self.external_outboundAllowCIDRInput.reset();
    },
    external_removeOutboundBlockedCIDR(ip: string) {
      const ipItem = self.external_outboundBlockedCIDR.find((i) => i === ip);
      if (ipItem) {
        self.external_outboundBlockedCIDR.remove(ipItem);
      }
    },
    external_addOutboundBlockedCIDR() {
      if (!self.external_outboundBlockedCIDRInput.isValid) return;
      const input = self.external_outboundBlockedCIDRInput.value.trim();
      const inputArray = input.split(" ");
      for (let inputItem of inputArray) {
        let ipArr: string[] = [];
        if (inputItem.includes(",")) {
          ipArr = inputItem.split(",");
        } else if (inputItem.includes(" ")) {
          ipArr = inputItem.split(" ");
        } else {
          ipArr = [inputItem];
        }
        for (let ip of ipArr.map((ip) => ip.trim())) {
          if (!ip) {
            continue;
          }
          const ipItem = self.external_outboundBlockedCIDR.find((i) => i === ip);
          if (!ipItem) {
            self.external_outboundBlockedCIDR.push(ip);
          }
        }
      }
      self.external_outboundBlockedCIDRInput.reset();
    },
    external_removeOutboundAllowHostname(hostname: string) {
      const hostnameItem = self.external_outboundAllowHostname.find((i) => i === hostname);
      if (hostnameItem) {
        self.external_outboundAllowHostname.remove(hostnameItem);
      }
    },
    external_addOutboundAllowHostname() {
      if (!self.external_outboundAllowHostnameInput.isValid) return;
      const hostnameToAdd = self.external_outboundAllowHostnameInput.value;
      const hostnameItem = self.external_outboundAllowHostname.find((i) => i === hostnameToAdd);
      if (!hostnameItem) {
        self.external_outboundAllowHostname.push(hostnameToAdd);
        self.external_outboundAllowHostnameInput.reset();
      }
    },
    external_removeOutboundAllowPort(id: string) {
      const portItem = self.external_outboundAllowPort.find((p) => p.id === id);
      if (portItem) {
        self.external_outboundAllowPort.remove(portItem);
      }
    },
    external_addOutboundAllowPort() {
      const portItem = OutboundAllowPortModel.create();
      self.external_outboundAllowPort.push(portItem);
    },
    reset() {
      self.internal_inboundAllowType.setInitialValue(self._internal_inboundAllowType);
      self.internal_inboundAllowItself = self._internal_inboundAllowItself;
      self.internal_inboundAllowWorkload.clear();
      for (const value of self._internal_inboundAllowWorkload) {
        self.internal_inboundAllowWorkload.push(value);
      }

      self.external_inboundAllowCIDRInput.reset();
      self.external_inboundBlockedCIDRInput.reset();
      self.external_outboundAllowCIDRInput.reset();
      self.external_outboundAllowHostnameInput.reset();

      self.external_inboundAllowCIDR.clear();
      for (let cidr of self._external_inboundAllowCIDR) {
        self.external_inboundAllowCIDR.push(cidr);
      }

      self.external_inboundBlockedCIDR.clear();
      for (let cidr of self._external_inboundBlockedCIDR) {
        self.external_inboundBlockedCIDR.push(cidr);
      }

      self.external_outboundAllowCIDR.clear();
      for (let cidr of self._external_outboundAllowCIDR) {
        self.external_outboundAllowCIDR.push(cidr);
      }

      self.external_outboundBlockedCIDR.clear();
      for (let cidr of self._external_outboundBlockedCIDR) {
        self.external_outboundBlockedCIDR.push(cidr);
      }

      self.external_outboundAllowHostname.clear();
      for (let cidr of self._external_outboundAllowHostname) {
        self.external_outboundAllowHostname.push(cidr);
      }

      self.external_outboundAllowPort.clear();
      for (let port of self._external_outboundAllowPort) {
        self.external_outboundAllowPort.push({ _protocol: port.protocol, _number: port.number });
      }
      self._resetTriggerKey += 1;
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.reset();
    },
    confirm() {
      self._internal_inboundAllowType = self.internal_inboundAllowType.value as any;
      self.internal_inboundAllowType.confirm();
      self._internal_inboundAllowWorkload.clear();
      if (["workload-list", "same-gvc-and-workload-list"].includes(self.internal_inboundAllowType.value)) {
        for (let link of self.internal_inboundAllowWorkload) {
          self._internal_inboundAllowWorkload.push(link);
        }
      }
      self._internal_inboundAllowItself = self.internal_inboundAllowItself;
      self._external_inboundAllowCIDR.clear();
      for (let cidr of self.external_inboundAllowCIDR) {
        self._external_inboundAllowCIDR.push(cidr);
      }
      self._external_inboundBlockedCIDR.clear();
      for (let cidr of self.external_inboundBlockedCIDR) {
        self._external_inboundBlockedCIDR.push(cidr);
      }
      self._external_outboundAllowCIDR.clear();
      for (let cidr of self.external_outboundAllowCIDR) {
        self._external_outboundAllowCIDR.push(cidr);
      }
      self._external_outboundBlockedCIDR.clear();
      for (let cidr of self.external_outboundBlockedCIDR) {
        self._external_outboundBlockedCIDR.push(cidr);
      }
      self._external_outboundAllowHostname.clear();
      for (let cidr of self.external_outboundAllowHostname) {
        self._external_outboundAllowHostname.push(cidr);
      }
      self._external_outboundAllowPort.clear();
      for (let port of self.external_outboundAllowPort) {
        self._external_outboundAllowPort.push(port.asObject);
        port.confirm();
      }
    },
  }))
  .views((self) => ({
    get isDirty() {
      let res = false;
      if (self.internal_inboundAllowType.isDirty) res = true;
      if (self._internal_inboundAllowItself !== self.internal_inboundAllowItself) res = true;
      if (["workload-list", "same-gvc-and-workload-list"].includes(self.internal_inboundAllowType.value)) {
        if (!arraysAreEqual([...self.internal_inboundAllowWorkload], [...self._internal_inboundAllowWorkload])) {
          res = true;
        }
      }
      if (!arraysAreEqual([...self.external_inboundAllowCIDR], [...self._external_inboundAllowCIDR])) res = true;
      if (!arraysAreEqual([...self.external_inboundBlockedCIDR], [...self._external_inboundBlockedCIDR])) res = true;
      if (!arraysAreEqual([...self.external_outboundAllowCIDR], [...self._external_outboundAllowCIDR])) res = true;
      if (!arraysAreEqual([...self.external_outboundBlockedCIDR], [...self._external_outboundBlockedCIDR])) res = true;
      if (!arraysAreEqual([...self.external_outboundAllowHostname], [...self._external_outboundAllowHostname])) {
        res = true;
      }

      if (self._external_outboundAllowPort.length !== self.external_outboundAllowPort.length) res = true;
      if (self.external_outboundAllowPort.some((p) => p.isDirty)) res = true;
      for (const port of self.external_outboundAllowPort.map((p) => p.asObject)) {
        if (!self._external_outboundAllowPort.some((p) => p.protocol === port.protocol && p.number === port.number)) {
          res = true;
        }
      }
      for (const p of self._external_outboundAllowPort) {
        if (
          !self.external_outboundAllowPort.some(
            (port) => p.protocol === port.asObject.protocol && p.number === port.asObject.number,
          )
        ) {
          res = true;
        }
      }

      return res;
    },
    get isValid() {
      let res = true;
      if (!self.external_allInboundAllowed && self.external_inboundAllowCIDRInput.value.length > 0) {
        res = false;
      }
      if (
        !self.external_allOutboundAllowed &&
        (self.external_outboundAllowCIDRInput.value.length > 0 ||
          self.external_outboundAllowHostnameInput.value.length > 0)
      ) {
        res = false;
      }
      if (self.external_outboundAllowPort.some((p) => !p.isValid)) res = false;
      return res;
    },
    get asObject() {
      let res: any = {
        internal: {
          inboundAllowType: self.internal_inboundAllowType.value,
          inboundAllowWorkload: [] as string[],
        },
        external: {
          inboundAllowCIDR: [...self.external_inboundAllowCIDR],
          inboundBlockedCIDR: [...self.external_inboundBlockedCIDR],
          outboundAllowCIDR: [...self.external_outboundAllowCIDR],
          outboundBlockedCIDR: [...self.external_outboundBlockedCIDR],
          outboundAllowHostname: [...self.external_outboundAllowHostname],
          outboundAllowPort: [...self.external_outboundAllowPort.map((p) => p.asObject)],
        },
      };

      if (FIREWALL_ALLALLOW_REMOVES_PREV_ITEMS) {
        if (self.external_allInboundAllowed) {
          res.external.inboundAllowCIDR = [defaultValues.allowIP];
        }
        if (self.external_allOutboundAllowed) {
          res.external.outboundAllowCIDR = [defaultValues.allowIP];
          res.external.outboundAllowHostname = [];
        }
      }

      if (["workload-list", "same-gvc-and-workload-list"].includes(self.internal_inboundAllowType.value)) {
        const workloadLinks = [...self.internal_inboundAllowWorkload];
        if (self.internal_inboundAllowItself) {
          workloadLinks.push(self.workloadLink);
        }
        if (self.internal_inboundAllowType.value === "same-gvc-and-workload-list") {
          res.internal.inboundAllowType = "same-gvc";
        }
        res.internal.inboundAllowWorkload = workloadLinks;
      }
      return res;
    },
  }))
  .views((self) => ({
    get isDirtyReason() {
      let reason = "";
      if (self.internal_inboundAllowType.isDirty) reason = self.internal_inboundAllowType.isDirtyReason;
      if (self._internal_inboundAllowItself !== self.internal_inboundAllowItself) reason = "inbound allow itself";
      if (["workload-list", "same-gvc-and-workload-list"].includes(self.internal_inboundAllowType.value)) {
        if (!arraysAreEqual([...self.internal_inboundAllowWorkload], [...self._internal_inboundAllowWorkload])) {
          reason = "inbound allow workload list";
        }
      }
      if (!arraysAreEqual([...self.external_inboundAllowCIDR], [...self._external_inboundAllowCIDR])) {
        reason = "inbound allow cidr";
      }
      if (!arraysAreEqual([...self.external_inboundBlockedCIDR], [...self._external_inboundBlockedCIDR])) {
        reason = "inbound blocked cidr";
      }
      if (!arraysAreEqual([...self.external_outboundAllowCIDR], [...self._external_outboundAllowCIDR])) {
        reason = "outbound allow cidr";
      }
      if (!arraysAreEqual([...self.external_outboundBlockedCIDR], [...self._external_outboundBlockedCIDR])) {
        reason = "outbound blocked cidr";
      }
      if (!arraysAreEqual([...self.external_outboundAllowHostname], [...self._external_outboundAllowHostname])) {
        reason = "outbound allow hostname";
      }
      if (self._external_outboundAllowPort.length !== self.external_outboundAllowPort.length) reason = "ports 1 length";
      if (self.external_outboundAllowPort.some((p) => p.isDirty))
        reason = "ports 2 isDirty: " + self.external_outboundAllowPort.find((p) => p.isDirty)!.isDirtyReason;
      for (const port of self.external_outboundAllowPort.map((p) => p.asObject)) {
        if (!self._external_outboundAllowPort.some((p) => p.protocol === port.protocol && p.number === port.number)) {
          reason = "ports 3 different port";
        }
      }
      for (const p of self._external_outboundAllowPort) {
        if (
          !self.external_outboundAllowPort.some(
            (port) => p.protocol === port.asObject.protocol && p.number === port.asObject.number,
          )
        ) {
          reason = "ports 4 different port";
        }
      }
      return reason;
    },
  }));
export interface WorkloadDraftFirewallMobx extends Instance<typeof WorkloadDraftFirewallModel> {}
