import { observer } from "mobx-react-lite";
import * as React from "react";
import { CodeEditor } from "../group/identityMatcher/codeEditor";
import { NGLabel } from "../../newcomponents/text/label";
import jsYaml from "js-yaml";
import { FormButtons } from "../../components/forms/formButtons";
import { guessTextLanguage } from "../../utils/guessTextFormat";
import { notification } from "antd";
import { request } from "../../services/cpln";

interface Props {
  link: string;
  extras?: any;
}
const ExtrasRaw: React.FC<Props> = ({ link, extras: _extras }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [initialExtras, setInitialExtras] = React.useState(_extras ? jsYaml.dump(_extras) : "");
  const [language, setLanguage] = React.useState<"json" | "yaml">(guessTextLanguage(initialExtras, "yaml") as any);
  const [extras, setExtras] = React.useState(initialExtras);

  React.useEffect(() => {
    setLanguage(guessTextLanguage(extras, "yaml") as any);
  }, [extras]);

  const isDirty = React.useMemo(() => {
    return initialExtras !== extras;
  }, [initialExtras, extras]);

  async function confirm() {
    try {
      setIsLoading(true);
      let value = "";
      if (language === "json") {
        value = JSON.parse(extras);
      } else {
        value = jsYaml.load(extras) as string;
      }
      let body: any = {
        spec: {
          "$replace/extras": value,
        },
      };
      if (!!initialExtras && !extras) {
        body = {
          spec: {
            extras: null,
          },
        };
      }
      await request({
        url: link,
        method: "patch",
        body: body,
      });
      setInitialExtras(extras);
      notification.success({ message: "Success", description: "Updated Extras" });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({ message: "Failed", description: errorMessage });
    }
  }

  async function reset() {
    setLanguage(guessTextLanguage(initialExtras) as any);
    setExtras(initialExtras);
  }

  return (
    <div>
      <NGLabel className="mb-4" size={2}>
        Extras
      </NGLabel>
      <CodeEditor value={extras} setValue={(value) => setExtras(value)} language={language} options={{}} lint />
      <FormButtons
        onSave={confirm}
        onReset={reset}
        resetDisabled={!isDirty || isLoading}
        saveDisabled={!isDirty || isLoading}
        loading={isLoading}
      />
    </div>
  );
};

export const Extras = observer(ExtrasRaw);
