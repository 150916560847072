import { initializeApp, getApps } from "firebase/app";
import {
  getAuth,
  setPersistence,
  inMemoryPersistence,
  linkWithCredential,
  SAMLAuthProvider,
  GoogleAuthProvider,
  GithubAuthProvider,
  OAuthProvider,
  fetchSignInMethodsForEmail,
  signInWithPopup,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { getAnalytics, setUserProperties, setUserId, logEvent } from "firebase/analytics";
import type { User as FirebaseUser } from "firebase/auth";
import { makeAutoObservable } from "mobx";
import { Discovery } from "../discovery/discovery";
import { getToken, request } from "../../services/cpln";
import {
  BASE_URL,
  HUBSPOT_SERVICE_URL,
  IS_CYPRESS,
  IS_DEPLOYMENT_ENV_PROD,
  IS_DEPLOYMENT_ENV_TEST,
  IS_HUBSPOT_INTEGRATION_ENABLED,
  IS_SENTRY_ENABLED,
  STORAGE_KEY_LOGGED_OUT_STATE,
  STORAGE_KEY_USER_ACTIVE_TIMESTAMP,
  USER_ACTIVE_THRESHOLD_MS,
} from "../../envVariables";
import { UserData } from "../userData/userData";
import { Modal, notification } from "antd";
import { checkCanCreateTrialAccount, getErrorMessage, getReferralCookies } from "../../services/utils";
import { ConsoleContext } from "../consoleContext/consoleContext";
import { UIData } from "../uiData/uiData";
import { BillingContext } from "../billingContext/billingContext";
import { jwtDecode } from "jwt-decode";
// Here for calling firebase
import axios from "axios";
import { NGButton } from "../../newcomponents/button/Button";
import { hotjarIdentify } from "../../tools/hotjar";
import { setupSentry } from "../../tools/sentry";

const onTrialIntentURL = IS_DEPLOYMENT_ENV_TEST
  ? "https://us-central1-controlplane-test-268922.cloudfunctions.net/onTrialIntent"
  : "https://us-central1-cpln-prod01.cloudfunctions.net/onTrialIntent";

class UserMobx {
  firebaseUser: any = {};
  firebaseInitialized: boolean = false;

  type: "none" | "user" | "impersonate" | "inspect" = "none";
  email: string = "";
  name: string = "";
  photoURL: string = "";
  uid: string = "";
  isSaml: boolean = false;
  samlDomain: string = "";

  // admin panel related
  expires: number = 0;
  key: string = "";
  org: string = "";

  // flags
  firstCheckDone: boolean = false;
  isProcessing: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  setFirstCheckDone(value: boolean) {
    this.firstCheckDone = value;
  }

  *setUser(user: FirebaseUser | null) {
    if (this.type === "impersonate" || this.type === "inspect") {
      const feedbackDiv = document.getElementById("feedback-button");
      if (feedbackDiv) {
        feedbackDiv.style.display = "none";
      }
      return;
    }
    if (!user) {
      this.type = "none";
      // Hiding Sentry's user feedback button
      const feedbackDiv = document.getElementById("feedback-button");
      if (feedbackDiv) {
        feedbackDiv.style.display = "none";
      }
      return;
    }

    this.isProcessing = true;
    this.firebaseUser = user;

    this.type = "user";
    this.name = user?.displayName || "";
    this.email = user?.email || "no email";
    this.photoURL = user?.photoURL || "";
    this.uid = user?.uid || "";

    // @ts-ignore yield
    const token = yield getToken();
    try {
      const decodedToken: any = jwtDecode(token);
      this.isSaml = ((decodedToken?.firebase?.sign_in_provider as string) || "").startsWith("saml.");
      this.samlDomain = "";
      if (this.isSaml) {
        this.samlDomain = ((decodedToken?.firebase?.sign_in_provider as string) || "").slice(5);
      }
    } catch (e) {
      this.isSaml = false;
      this.samlDomain = "";
    }

    // touch base
    try {
      yield fetch(`${BASE_URL}/user`, { method: "post", headers: { Authorization: token } });
    } catch (e) {
      notification.error({ message: "Failed to connect", description: getErrorMessage(e) });
      yield this.signOut(false);
      return;
    }

    // touch hubspot service for referral record, doesnt touch hubspot anymore
    this.touchHubspotForReferral();

    yield Promise.all([UserData.requestAccountSummaries(), UserData.requestOrgNames(), UserData.requestOrgInvites()]);

    try {
      if (IS_DEPLOYMENT_ENV_PROD) {
        // @ts-ignore
        window.dataLayer.push({
          event: "userSignedIn",
        });
      }
    } catch (e) {
      console.error("data layer userSignedIn event failed", e.message);
    }

    let preventHubspot = false;
    if (!user || !User.isLoggedIn || User.isCplnTeam || UserData.hasAccount || UserData.hasOrg) {
      preventHubspot = true;
    }

    // Touch hubspot
    if (IS_HUBSPOT_INTEGRATION_ENABLED && !preventHubspot) {
      // TODO make this a function
      let hubspotutk = "";
      try {
        let _hubspotutk = document.cookie
          .split(" ")
          .find((c) => c.startsWith("hubspotutk"))!
          .split("=")[1];
        if (_hubspotutk.endsWith(";")) {
          _hubspotutk = _hubspotutk.split(";")[0];
        }
        hubspotutk = _hubspotutk;
      } catch (e) {}

      request({
        service: "self",
        method: "post",
        url: `${HUBSPOT_SERVICE_URL}/firebasesignin`,
        body: {
          account: { fullName: User.name, email: User.email },
          hubspotutk,
          environment: IS_DEPLOYMENT_ENV_TEST ? "test" : "production",
        },
      }).catch(() => {
        // TODO keep this in sentry
        console.error("Failed to inform hubspot");
      });
    }

    yield ConsoleContext.setInitialContext();
    this.isProcessing = false;

    // Showing Sentry's user feedback button if it is active
    if (IS_SENTRY_ENABLED) {
      const feedbackDiv = document.getElementById("feedback-button");
      if (feedbackDiv) {
        feedbackDiv.style.display = "block";
      }
    }

    localStorage.setItem(STORAGE_KEY_LOGGED_OUT_STATE, "false");
    const userAnalyticsData = { email: this.email, username: this.name, id: this.uid };
    setUserProperties(getAnalytics(this.getFirebaseApp()), userAnalyticsData);
    setUserId(getAnalytics(this.getFirebaseApp()), userAnalyticsData.id);
    setupSentry();
  }

  *touchHubspotForReferral() {
    try {
      const HUBSPOT_USER_STORAGE_KEY = `HUBSPOT_USER_${this.email}`;
      const isAlreadyCalled = localStorage.getItem(HUBSPOT_USER_STORAGE_KEY) === "true";
      if (IS_HUBSPOT_INTEGRATION_ENABLED && !isAlreadyCalled && !this.isCplnTeam) {
        let externalId = "";
        if (UIData.initialUrl.includes("?id=")) {
          externalId = UIData.initialUrl.split("?id=")[1] || "";
        }

        const referralCookie = getReferralCookies().pVisitorId;
        // @ts-ignore yield
        const { data } = yield request({
          service: "self",
          method: "post",
          url: `${HUBSPOT_SERVICE_URL}/user`,
          body: { email: this.email, name: this.name, leadSource: "Console", externalId, referralCookie },
        });
        if (data.success === true) {
          localStorage.setItem(HUBSPOT_USER_STORAGE_KEY, "true");
        } else {
          console.error("Failed to touch hubspot user", data.error);
        }
      }
    } catch (e) {
      console.error("Failed to touch hubspot user", e.message);
    }
  }

  *inspect(data: any) {
    this.type = "inspect";
    this.name = data.name;
    this.expires = Number(data.expires);
    this.key = data.key;
    this.org = data.org;
    UserData.setOrgNames(this.org);
    yield ConsoleContext.setOrg(this.org);
    this.setFirstCheckDone(true);
  }

  *impersonate(data: any) {
    this.type = "impersonate";
    this.name = data.name;
    this.org = data.org;
    this.expires = Number(data.expires);
    this.key = data.key;
    this.email = data.impersonatedUserEmail;
    UserData.setOrgNames(this.org);
    yield ConsoleContext.setOrg(this.org);
    this.setFirstCheckDone(true);
  }

  getFirebaseApp() {
    return getApps().find((a) => a.name === "main");
  }

  initializeFirebase() {
    if (!this.getFirebaseApp()) {
      const firebaseConfig = {
        apiKey: Discovery.firebase.apiKey,
        authDomain: Discovery.firebase.authDomain,
        projectId: Discovery.firebase.projectId,
        measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
        appId: process.env.REACT_APP_FIREBASE_APP_ID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
      };
      initializeApp(firebaseConfig, "main");
    }
    this.firebaseInitialized = true;
    if (IS_CYPRESS) {
      setPersistence(getAuth(this.getFirebaseApp()), inMemoryPersistence);
    }
    getAuth(this.getFirebaseApp()).onAuthStateChanged(async (user) => {
      if (!this.firstCheckDone) {
        this.setFirstCheckDone(true);

        if (user) {
          const lastActiveTick = Number(localStorage.getItem(STORAGE_KEY_USER_ACTIVE_TIMESTAMP) || "0");
          const currentTick = new Date().getTime();
          const difference_MS = currentTick - lastActiveTick;
          if (difference_MS > USER_ACTIVE_THRESHOLD_MS) {
            await signOut(getAuth(this.getFirebaseApp()));
            return;
          }
        }
      }

      if (!!user && UserData.hereForTrial) {
        const emailPasswordLogin = user.providerData.length === 1 && user.providerData[0]?.providerId === "password";
        if (!emailPasswordLogin) {
          const res = await checkCanCreateTrialAccount(user.email || "");
          if (!res.isWorkEmail) {
            localStorage.setItem("trial-info", "not-work");
            await signOut(getAuth(this.getFirebaseApp()));
          } else {
            await axios.post(onTrialIntentURL, { email: user.email });
          }
        }
      }

      this.setUser(user);
      logEvent(getAnalytics(this.getFirebaseApp()), "login");
      hotjarIdentify();
    });
  }

  *signOut(keepUrl: boolean) {
    if (this.type !== "user") {
      return;
    }
    yield signOut(getAuth(this.getFirebaseApp()));

    ConsoleContext.clearOrg();
    BillingContext.clearAccount();
    if (keepUrl) {
      UIData.setInitialUrl(window.location.pathname);
    } else {
      window.history.replaceState(null, "", "/");
    }

    try {
      setUserProperties(getAnalytics(this.getFirebaseApp()), {});
      setUserId(getAnalytics(this.getFirebaseApp()), "");
    } catch (e) {}
  }

  *cancelLinking() {
    notification.info({
      message: "User cancelled linking the provider",
    });
  }

  *approveLinking(provider: any, pendingCredential: any) {
    try {
      yield signInWithPopup(getAuth(this.getFirebaseApp()), provider);
      const user = getAuth(this.getFirebaseApp()).currentUser;
      if (user) {
        yield linkWithCredential(user, pendingCredential);
      }
    } catch (e) {
      notification.error({
        message: "Failed to link provider",
        description: e.message,
      });
    }
  }

  *signInEmail(email: string, password: string) {
    try {
      yield signInWithEmailAndPassword(getAuth(this.getFirebaseApp()), email, password);
    } catch (e) {
      throw e;
    }
  }

  *signInSaml(email: string) {
    try {
      const { data } = yield request({ url: `/auth/details/${email}` });
      const samlProvider = new SAMLAuthProvider(data.provider);
      yield signInWithPopup(getAuth(this.getFirebaseApp()), samlProvider);
    } catch (e) {
      throw e;
    }
  }

  *signInByProvider(provider: "google" | "microsoft" | "github") {
    const githubProvider = new GithubAuthProvider();
    const microsoftProvider = new OAuthProvider("microsoft.com").setCustomParameters({
      prompt: "select_account",
    });
    const googleProvider = new GoogleAuthProvider().setCustomParameters({ prompt: "select_account" });
    try {
      let firebaseProvider: any = null as any;
      switch (provider) {
        case "google":
          firebaseProvider = googleProvider;
          break;
        case "github":
          firebaseProvider = githubProvider;
          break;
        case "microsoft":
          firebaseProvider = microsoftProvider;
          break;
      }

      yield signInWithPopup(getAuth(this.getFirebaseApp()), firebaseProvider);
    } catch (e) {
      if (e.code !== "auth/account-exists-with-different-credential") {
        throw e;
      }

      const { credential: pendingCredential, email } = e;
      // Get sign-in methods for this email.
      // @ts-ignore yield
      const methods = yield fetchSignInMethodsForEmail(getAuth(this.getFirebaseApp()), email);
      const method = methods[0];

      if (methods[0] === "password") {
        throw e;
      }

      const providerForMethod = method.includes("google")
        ? googleProvider
        : method.includes("github")
        ? githubProvider
        : method.includes("microsoft")
        ? microsoftProvider
        : null;
      if (!providerForMethod) {
        throw e;
      }

      const m = Modal.confirm({
        title: "Linking Account",
        content: `You already have an account with the provider ${method}, to link your new provider, please confirm by signing in with old provider.`,
        footer: (
          <div className="modal-actions">
            <NGButton
              variant="secondary"
              onClick={() => {
                this.cancelLinking();
                m.destroy();
              }}
            >
              Cancel
            </NGButton>
            <NGButton
              variant="primary"
              onClick={() => {
                this.approveLinking(providerForMethod, pendingCredential);
                m.destroy();
              }}
            >
              Confirm
            </NGButton>
          </div>
        ),
        onCancel: () => {
          this.cancelLinking();
        },
      });
    }
  }

  get isLoggedIn() {
    return this.isAdmin || this.type === "user";
  }

  get isAdmin() {
    return this.type === "impersonate" || this.type === "inspect";
  }

  get avatarInitials() {
    if (this.name.length > 0) {
      return this.name
        .split(" ")
        .filter((p) => p.length > 0)
        .map((p) => p.slice(0, 1))
        .join("")
        .toUpperCase();
    }
    if (this.email.length > 0) {
      return this.email.slice(0, 1).toUpperCase();
    }
    return "";
  }

  get isCplnTeam() {
    if (!this.isLoggedIn) {
      return false;
    }
    return this.email.includes("@controlplane.com");
  }
}

export const User = new UserMobx();
