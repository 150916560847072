import * as React from "react";
import { Modal, notification } from "antd";
import { observer } from "mobx-react-lite";
import { AlertCircle, ChevronDown, ChevronUp } from "react-feather";
import { useNavigate } from "react-router-dom";
import { GVCIcon, WorkloadIcon } from "../../../components/Icons";
import { ConsoleContext } from "../../../mobxStores/consoleContext/consoleContext";
import { commonHeaders, methods } from "../../../mst/kinds/domain";
import { allowedCiphers } from "../../../mst/kinds/domain/ciphers";
import { DomainDraftMobx } from "../../../mst/stores/domain.draft";
import { homeLink, request, resourceLink } from "../../../services/cpln";
import { parsedDomain } from "../../../services/utils";
import { CNAMECard } from "./create_new_cname_card";
import { CustomCard } from "./create_new_custom_card";
import { DNSValue } from "./create_new_dns_value";
import { CreateNewData } from "./create_new_mobx";
import { NSCard } from "./create_new_ns_card";
import { DomainCreateNewSectionLabel } from "./create_new_section_label";
import { DomainCreateNewSectionPortLabel } from "./create_new_section_port_label";
import { DomainNewSpecPortRoute } from "./create_new_spec_port_route";
import { v4 as uuidv4 } from "uuid";
import styles from "./style.module.scss";
import { Theme } from "../../../mobxStores/uiData/theme";
import { RoadblockDetails } from "./types";
import { NGButton } from "../../../newcomponents/button/Button";
import NGAlert from "../../../newcomponents/alert";
import { PromptContext } from "../../../mobxStores/prompt/prompt";
import { useCleanPrompt } from "../../../reactHooks/useCleanPrompt";
import { NGInputListMst } from "../../../newcomponents/inputList/inputListMst";
import { NGSelect } from "../../../newcomponents/select/ngselect";
import { NGInput } from "../../../newcomponents/input/input";
import { NGLabel } from "../../../newcomponents/text/label";
import { NGCheckbox } from "../../../newcomponents/checkbox";
import { NGRadioGroup } from "../../../newcomponents/radioGroup";
import { NGKindSelect } from "../../../newcomponents/select/ngkindselect";
import { NGFormLabel } from "../../../newcomponents/text/formLabel";
import { NGSwitch } from "../../../newcomponents/switch";
import { NGLabelText } from "../../../newcomponents/text/labelText";
import { InfoTooltip } from "../../../components/InfoTooltip";
import { Gvc } from "../../../schema/types/gvc";
import { DomainPortRouteEditHeadersModal } from "../routeEditHeadersModal";

interface NewNavItemBase {
  title: string;
  url?: string;
  onAdd?: (() => Promise<void>) | (() => void);
  onRemove?: (() => Promise<void>) | (() => void);
  info?: React.ReactNode;
  level?: 0 | 1 | 2;
  disabled?: boolean;
}

export interface NewNavItem extends NewNavItemBase {
  children?: NewNavItemBase[];
}

interface Props {
  draft: DomainDraftMobx;
}

const DomainCreateNewRaw: React.FC<Props> = ({ draft }) => {
  const [scrollTop, setScrollTop] = React.useState(0);
  const navigate = useNavigate();
  const dataRef = React.useRef<CreateNewData>(new CreateNewData());
  const data = dataRef.current;

  const [isEditingRouteHeaders, setIsEditingRouteHeaders] = React.useState(false);

  // domain name, txt check
  const [txtCheckPassed, setTXTCheckPassed] = React.useState(false);
  const [txtCheckIsLoading, setTXTCheckIsLoading] = React.useState(false);
  const [txtCheckError, setTXTCheckError] = React.useState("");
  const [txtCheckDetails, setTXTCheckDetails] = React.useState<RoadblockDetails | null>(null);

  const [apexIsLoading, setApexIsLoading] = React.useState<boolean>(false);
  const [needsApex, setNeedsApex] = React.useState<boolean>(false);
  const [showApexModal, setShowApexModal] = React.useState<boolean>(false);
  const [apexDetails, setApexDetails] = React.useState<RoadblockDetails | null>(null);
  const [apexDNSConfigured, setApexDNSConfigured] = React.useState(false);

  // dns, routing choice
  const [dnsRoutingPassed, setDNSRoutingPassed] = React.useState(false);

  // accept all hosts
  const [basicModeAcceptAllHostsPassed, setBasicModeAcceptAllHostsPassed] = React.useState(false);
  const [skipAcceptAllHosts, setSkipAcceptAllHosts] = React.useState(false);

  // routing details
  const [basicModeRoutingDetailsPassed, setBasicModeRoutingDetailsPassed] = React.useState(false);
  const [basicModeRoutingDetailsIsLoading, setBasicModeRoutingDetailsIsLoading] = React.useState(false);
  const [basicModeRoutingDetailsError, setBasicModeRoutingDetailsError] = React.useState("");
  const [basicModeRoutingDetailsDetails, setBasicModeRoutingDetailsDetails] = React.useState<RoadblockDetails | null>(
    null,
  );

  // advanced mode ports details
  const [advancedModePortsDetailsPassed, setAdvancedModePortsDetailsPassed] = React.useState(false);
  const [advancedModePortsDetailsIsLoading, setAdvancedModePortsDetailsIsLoading] = React.useState(false);
  const [advancedModePortsDetailsError, setAdvancedModePortsDetailsError] = React.useState("");
  const [advancedModePortsDetailsDetails, setAdvancedModePortsDetailsDetails] = React.useState<RoadblockDetails | null>(
    null,
  );

  const [_dnsIsConfigured, setDNSIsConfigured] = React.useState(false);
  let dnsIsRequired = false;
  if (
    data.isBasic &&
    basicModeRoutingDetailsDetails?.dnsConfig &&
    basicModeRoutingDetailsDetails?.dnsConfig.length > 0
  ) {
    dnsIsRequired = true;
  }
  if (
    data.isAdvanced &&
    advancedModePortsDetailsDetails?.dnsConfig &&
    advancedModePortsDetailsDetails?.dnsConfig.length > 0
  ) {
    dnsIsRequired = true;
  }

  const dnsIsConfigured = _dnsIsConfigured || !dnsIsRequired;

  React.useEffect(() => {
    const onScroll = () => {
      setScrollTop(document.documentElement.scrollTop);
    };

    document.addEventListener("scroll", onScroll);
    return () => {
      document.removeEventListener("scroll", onScroll);
    };
  }, []);

  React.useEffect(() => {
    PromptContext.setWhen(draft.isDirty);
  }, [draft.isDirty]);

  useCleanPrompt();

  React.useEffect(() => {
    if (draft.routingMode === "none") {
      return;
    }
    if (draft.routingMode === "subdomainBased") {
      data.fetchGVCAlias(ConsoleContext.org!, draft.gvcName);
    }
    if (draft.routingMode === "pathBased") {
      data.fetchGVCAlias(ConsoleContext.org!, draft.routeGvcName);
    }
  }, [draft.gvcName, draft.routeGvcName, draft.routingMode]);

  React.useEffect(() => {
    setTXTCheckPassed(false);
    setTXTCheckIsLoading(false);
    setTXTCheckError("");
    setTXTCheckDetails(null);
    setNeedsApex(false);
    setApexIsLoading(false);
    setShowApexModal(false);
    setApexDetails(null);
    setApexDNSConfigured(false);
    setBasicModeAcceptAllHostsPassed(false);
  }, [draft.name.value]);

  // Select the first port when port is added the first time
  React.useEffect(() => {
    if (!!data.selectedPortId) {
      return;
    }

    if (draft.ports.length < 1) {
      return;
    }

    data.setSelectedPortId(draft.ports[0].id);
  }, [draft.ports.length]);

  // Add an example route in basic mode for pathBased
  React.useEffect(() => {
    if (!dnsRoutingPassed) {
      return;
    }
    if (data.isAdvanced) {
      return;
    }
    if (draft.routingMode !== "pathBased") {
      return;
    }
    try {
      if (draft.ports[0].routes.length < 1) {
        draft.ports[0].addRoute();
      }
    } catch (e) {}
  }, [dnsRoutingPassed, data.isAdvanced, draft.routingMode]);

  // Basic mode limits the dns & routing choice
  React.useEffect(() => {
    if (data.isAdvanced) {
      return;
    }
    if (draft.dnsMode === "ns" && draft.routingMode === "subdomainBased") {
      return;
    }
    if (draft.dnsMode === "cname" && draft.routingMode === "pathBased") {
      return;
    }
    setDNSRoutingPassed(false);
    setBasicModeAcceptAllHostsPassed(false);
    setDNSIsConfigured(false);
  }, [data.isAdvanced]);

  // Basic mode limits the port count, remove all ports except the first one
  // TODO inform user about the limitations of the basic mode
  React.useEffect(() => {
    if (data.isAdvanced) {
      return;
    }
    if (draft.ports.length < 2) {
      return;
    }
    const firstPort = draft.ports[0];
    if (firstPort) {
      data.setSelectedPortId(firstPort.id);
    } else {
      data.setSelectedPortId("");
    }
    if (draft.ports.length > 1) {
      const portIdsToRemove = draft.ports.slice(1).map((p: any) => p.id);
      for (const portId of portIdsToRemove) {
        draft.removePort(portId);
      }
    }
  }, [data.isAdvanced]);

  // Sync passed values
  React.useEffect(() => {
    if (!txtCheckPassed) {
      // setTXTCheckApexPassed(false);
      setDNSRoutingPassed(false);
      setBasicModeRoutingDetailsPassed(false);
      setAdvancedModePortsDetailsPassed(false);
      setBasicModeAcceptAllHostsPassed(false);
      setDNSIsConfigured(false);
    }
  }, [txtCheckPassed]);

  React.useEffect(() => {
    if (!dnsRoutingPassed) {
      setBasicModeRoutingDetailsPassed(false);
      setAdvancedModePortsDetailsPassed(false);
      setBasicModeAcceptAllHostsPassed(false);
      setDNSIsConfigured(false);
    }
  }, [dnsRoutingPassed]);

  React.useEffect(() => {
    if (!basicModeRoutingDetailsPassed) {
      setAdvancedModePortsDetailsPassed(false);
      setBasicModeAcceptAllHostsPassed(false);
      setDNSIsConfigured(false);
    }
  }, [basicModeRoutingDetailsPassed]);

  React.useEffect(() => {
    if (!advancedModePortsDetailsPassed) {
      setBasicModeRoutingDetailsPassed(false);
      setBasicModeAcceptAllHostsPassed(false);
      setDNSIsConfigured(false);
    }
  }, [advancedModePortsDetailsPassed]);
  // Sync Done

  function dnsRouting_nsSubdomain() {
    draft.setDNSMode("ns");
    draft.setRoutingMode("subdomainBased");
    setDNSRoutingPassed(true);
  }

  function dnsRouting_cnamePathBased() {
    draft.setDNSMode("cname");
    draft.setRoutingMode("pathBased");
    setDNSRoutingPassed(true);
  }

  const [collapsedHelpers, setCollapsedHelpers] = React.useState<number[]>([]);
  function toggleCollapse(index: number) {
    return () => {
      if (collapsedHelpers.includes(index)) {
        setCollapsedHelpers(collapsedHelpers.filter((c) => c !== index));
      } else {
        setCollapsedHelpers([...collapsedHelpers, index]);
      }
    };
  }

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  async function onConfirm() {
    try {
      setIsLoading(true);
      const body = draft.asObject;
      await request({ method: "post", url: homeLink("domain"), body });
      const itemLink = resourceLink("domain", body.name);
      draft.confirm();
      notification.success({
        message: "Success",
        description: "Domain is created",
      });
      setTimeout(() => {
        navigate(`/console${itemLink}`);
      }, 100);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      // TODO update these messages
      if (errorMessage.includes("need to prove ownership of the domain")) {
        errorMessage +=
          ". If you have already made the change to DNS, please wait a few minutes. It takes time for DNS propagation. Try again soon.";
      }
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
      setIsLoading(false);
    }
  }

  async function processTXTStep() {
    // Reset state for txtCheckError effect to trigger
    setTXTCheckIsLoading(true);
    setTXTCheckPassed(false);
    setTXTCheckError("");
    setTXTCheckDetails(null);

    try {
      await request({
        method: "post",
        url: homeLink("domain") + "?dryRun",
        body: { name: draft.name.value },
      });
      setTXTCheckPassed(true);
      setTXTCheckError("");
      setTXTCheckIsLoading(false);
      if (draft.isApexDomain) {
        draft.setDNSMode("cname");
        draft.setRoutingMode("pathBased");
        setDNSRoutingPassed(true);
      }
    } catch (e) {
      if (e?.response?.data.code === "apex_must_exist") {
        setNeedsApex(true);
        setShowApexModal(true);
        dryRunApex();
      }
      setTXTCheckPassed(false);
      setTXTCheckError(e?.response?.data.message);
      setTXTCheckDetails(e?.response?.data.details);
      setTXTCheckIsLoading(false);
    }
  }

  async function dryRunApex() {
    try {
      setApexIsLoading(true);
      await request({
        method: "post",
        url: homeLink("domain") + "?dryRun",
        body: { name: parsedDomain(draft.name.value).domain },
      });
      setApexIsLoading(false);
    } catch (e) {
      setApexDetails(e?.response?.data.details);
      setApexIsLoading(false);
    }
  }

  async function createApex() {
    try {
      setApexIsLoading(true);
      await request({
        method: "post",
        url: homeLink("domain"),
        body: { name: parsedDomain(draft.name.value).domain },
      });
      setNeedsApex(false);
      setShowApexModal(false);
      setApexIsLoading(false);
      notification.success({ message: "Created Apex Domain" });
      processTXTStep();
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({ message: "Failed to Create Apex Domain", description: errorMessage });
      setApexIsLoading(false);
    }
  }

  async function processRouteGvcHasDedicatedLoadBalancer() {
    const requestId = uuidv4();
    draft.setRouteGVCHasDedicatedLoadBalancerRequestId(requestId);
    try {
      draft.setRouteGVCHasDedicatedLoadBalancer(false);
      const res = await request({ url: draft.routeGvcLink });
      const gvc: Gvc = res.data;
      if (draft.routeGvcHasDedicatedLoadBalancerRequestId === requestId) {
        if (gvc.spec?.loadBalancer?.dedicated === true) {
          draft.setRouteGVCHasDedicatedLoadBalancer(true);
        }
      }
    } catch (e) {
      if (draft.routeGvcHasDedicatedLoadBalancerRequestId === requestId) {
        draft.setRouteGVCHasDedicatedLoadBalancer(true);
      }
    }
  }

  async function processSubdomainGvcHasDedicatedLoadBalancer() {
    const requestId = uuidv4();
    draft.setSubdomainGVCHasDedicatedLoadBalancerRequestId(requestId);
    try {
      draft.setSubdomainGVCHasDedicatedLoadBalancer(false);
      const res = await request({ url: draft.gvcLink });
      const gvc: Gvc = res.data;
      if (draft.subdomainGvcHasDedicatedLoadBalancerRequestId === requestId) {
        if (gvc.spec?.loadBalancer?.dedicated === true) {
          draft.setSubdomainGVCHasDedicatedLoadBalancer(true);
        }
      }
    } catch (e) {
      if (draft.subdomainGvcHasDedicatedLoadBalancerRequestId === requestId) {
        draft.setSubdomainGVCHasDedicatedLoadBalancer(true);
      }
    }
  }

  React.useEffect(() => {
    processRouteGvcHasDedicatedLoadBalancer();
  }, [draft.routeGvcName]);

  React.useEffect(() => {
    processSubdomainGvcHasDedicatedLoadBalancer();
  }, [draft.gvcName]);

  React.useEffect(() => {
    const value =
      (draft.routingMode === "pathBased" && !draft.routeGvcHasDedicatedLoadBalancer) ||
      (draft.routingMode === "subdomainBased" && !draft.subdomainGvcHasDedicatedLoadBalancer);
    setSkipAcceptAllHosts(value);
  }, [draft.routingMode, draft.routeGvcHasDedicatedLoadBalancer, draft.subdomainGvcHasDedicatedLoadBalancer]);

  React.useEffect(() => {
    if (skipAcceptAllHosts) {
      draft.setAcceptAllHosts(false);
    }
  }, [skipAcceptAllHosts]);

  React.useEffect(() => {
    if (draft.routingMode === "pathBased") {
      setAdvancedModePortsDetailsPassed(false);
    }
  }, [draft.routeGvcName]);

  let loadBalancerPortMismatchReason: "none" | "number" | "protocol" | "both" = "none";
  if (
    (draft.routingMode === "pathBased" &&
      !draft.routeGvcHasDedicatedLoadBalancer &&
      !draft.routeGvcHasDedicatedLoadBalancer) ||
    (draft.routingMode === "subdomainBased" &&
      !draft.subdomainGvcHasDedicatedLoadBalancer &&
      !draft.subdomainGvcHasDedicatedLoadBalancer)
  ) {
    const allowedPorts = ["443", "80"];
    const disallowedProtocol = ["tcp"];
    let mismatchNumber = false;
    let mismatchProtocol = false;
    for (const port of draft.ports) {
      if (!allowedPorts.includes(port.number.value)) {
        mismatchNumber = true;
      }
      if (disallowedProtocol.includes(port.protocol.value)) {
        mismatchProtocol = true;
      }
    }

    loadBalancerPortMismatchReason =
      mismatchNumber && mismatchProtocol ? "both" : mismatchNumber ? "number" : mismatchProtocol ? "protocol" : "none";
  }
  let loadBalancerPortMismatchMessage = "";
  switch (loadBalancerPortMismatchReason) {
    case "both":
      loadBalancerPortMismatchMessage =
        "Custom port number and protocol is only configurable with a GVC which has a Dedicated Load Balancer.";
      break;
    case "number":
      loadBalancerPortMismatchMessage =
        "Custom port number is only configurable with a GVC which has a Dedicated Load Balancer.";
      break;
    case "protocol":
      loadBalancerPortMismatchMessage =
        "Custom port protocol is only configurable with a GVC which has a Dedicated Load Balancer.";
      break;
    default:
      break;
  }

  return (
    <>
      <div className={styles.page}>
        <div className={styles.container}>
          <div className={styles.content}>
            <div className="border px-4 pb-4">
              <DomainCreateNewSectionLabel onEdit={txtCheckPassed ? () => setTXTCheckPassed(false) : null}>
                Create a Domain
              </DomainCreateNewSectionLabel>
              {txtCheckPassed ? (
                <div>{draft.name.value}</div>
              ) : (
                <div className={"mb-4"}>
                  <NGInput
                    onChange={(e) => draft.name.setValue(e.target.value)}
                    value={draft.name.value}
                    placeholder={"my.domain.xyz"}
                  />
                </div>
              )}
              {txtCheckError ? (
                <div className="mb-4">
                  <NGAlert className="mb-2" type={"error"} message={txtCheckError} />
                  {txtCheckDetails &&
                    txtCheckDetails.ownershipConfig &&
                    txtCheckDetails.ownershipConfig.map((r) => (
                      <div className="flex items-center gap-2 border px-2 py-1">
                        <DNSValue column={`w-5/12`} value={r.host === "@" ? draft.name.value : r.host} />
                        <DNSValue column={`w-1/12`} value={r.ttl.toString()} />
                        <DNSValue column={`w-1/12`} value={r.type} />
                        <DNSValue column={`w-5/12`} value={r.value} />
                      </div>
                    ))}
                  {txtCheckDetails &&
                    txtCheckDetails.dnsConfig &&
                    txtCheckDetails.dnsConfig.map((r) => (
                      <div className="flex items-center gap-2 border px-2 py-1">
                        <DNSValue column={`w-5/12`} value={r.host === "@" ? draft.name.value : r.host} />
                        <DNSValue column={`w-1/12`} value={r.ttl.toString()} />
                        <DNSValue column={`w-1/12`} value={r.type} />
                        <DNSValue column={`w-5/12`} value={r.value} />
                      </div>
                    ))}
                </div>
              ) : null}
              {txtCheckPassed ? null : needsApex ? (
                <NGButton
                  variant={"primary"}
                  disabled={txtCheckIsLoading}
                  loading={txtCheckIsLoading}
                  onClick={async () => setShowApexModal(true)}
                >
                  Create Apex Domain
                </NGButton>
              ) : (
                <NGButton
                  variant={"primary"}
                  disabled={!draft.name.isValid || txtCheckIsLoading}
                  loading={txtCheckIsLoading}
                  onClick={processTXTStep}
                >
                  {txtCheckError
                    ? txtCheckError.includes("already")
                      ? "Continue"
                      : "I Configured DNS, Continue"
                    : "Continue"}
                </NGButton>
              )}
              {showApexModal ? (
                <Modal
                  open={showApexModal}
                  title={"Create Apex Domain"}
                  onCancel={() => setShowApexModal(false)}
                  footer={
                    <div className="modal-actions">
                      <NGButton variant="secondary" disabled={apexIsLoading} onClick={() => setShowApexModal(false)}>
                        Cancel
                      </NGButton>
                      <NGButton
                        variant="primary"
                        loading={apexIsLoading}
                        disabled={apexIsLoading || (!!apexDetails && !apexDNSConfigured)}
                        onClick={() => createApex()}
                      >
                        Create Apex
                      </NGButton>
                    </div>
                  }
                  width={1000}
                >
                  <div>
                    <div className="flex flex-col gap-1 mb-4">
                      <NGLabel>Apex Domain</NGLabel>
                      <div>{parsedDomain(draft.name.value).domain}</div>
                    </div>

                    {apexDetails ? (
                      <div className="mb-4">
                        {(apexDetails.ownershipConfig && apexDetails.ownershipConfig.length > 0) ||
                        (apexDetails.dnsConfig && apexDetails.dnsConfig.length > 0) ? (
                          <>
                            <div className={`mb-2`}>Please create the DNS records below.</div>
                            <div className="flex items-center gap-2 border px-2 py-1 bg-table-label-drop text-table-label-text">
                              <div className="w-5/12">Host</div>
                              <div className="w-1/12">TTL</div>
                              <div className="w-1/12">Type</div>
                              <div className="w-5/12">Value</div>
                            </div>
                          </>
                        ) : null}
                        {apexDetails.ownershipConfig &&
                          apexDetails.ownershipConfig.map((r) => (
                            <div className="flex items-center gap-2 border px-2 py-1">
                              <DNSValue column={`w-5/12`} value={r.host === "@" ? draft.name.value : r.host} />
                              <DNSValue column={`w-1/12`} value={r.ttl.toString()} />
                              <DNSValue column={`w-1/12`} value={r.type} />
                              <DNSValue column={`w-5/12`} value={r.value.replace("<gvcAlias>", data.gvcAlias)} />
                            </div>
                          ))}
                        {apexDetails.dnsConfig &&
                          apexDetails.dnsConfig.map((r) => (
                            <div className="flex items-center gap-2 border px-2 py-1">
                              <DNSValue column={`w-5/12`} value={r.host === "@" ? draft.name.value : r.host} />
                              <DNSValue column={`w-1/12`} value={r.ttl.toString()} />
                              <DNSValue column={`w-1/12`} value={r.type} />
                              <DNSValue column={`w-5/12`} value={r.value.replace("<gvcAlias>", data.gvcAlias)} />
                            </div>
                          ))}
                        {(apexDetails.ownershipConfig && apexDetails.ownershipConfig.length > 0) ||
                        (apexDetails.dnsConfig && apexDetails.dnsConfig.length > 0) ? (
                          <NGCheckbox
                            className="mt-2"
                            checked={apexDNSConfigured}
                            onChange={(value) => setApexDNSConfigured(value)}
                          >
                            <NGLabelText>I have created the DNS records</NGLabelText>
                          </NGCheckbox>
                        ) : null}
                      </div>
                    ) : (
                      <NGAlert
                        type={"success"}
                        message={`Click "Create Apex" to create the prerequisite apex domain. No DNS configuration is needed.`}
                      />
                    )}
                  </div>
                </Modal>
              ) : null}
            </div>
            {txtCheckPassed && dnsRoutingPassed ? (
              <div className="border px-4 pb-4">
                <DomainCreateNewSectionLabel onEdit={dnsRoutingPassed ? () => setDNSRoutingPassed(false) : null}>
                  DNS & Routing Modes
                </DomainCreateNewSectionLabel>
                {draft.dnsMode === "cname" && draft.routingMode === "pathBased" ? (
                  <CNAMECard draft={draft} />
                ) : draft.dnsMode === "ns" && draft.routingMode === "subdomainBased" ? (
                  <NSCard draft={draft} />
                ) : (
                  <CustomCard dnsMode={draft.dnsMode as any} routingMode={draft.routingMode as any} />
                )}
              </div>
            ) : null}
            {txtCheckPassed && !dnsRoutingPassed ? (
              <div className="border px-4 pb-4">
                <DomainCreateNewSectionLabel>DNS & Routing Modes</DomainCreateNewSectionLabel>
                {data.showMoreDNSRoutingOptions ? (
                  <div>
                    <NGLabel>DNS Mode</NGLabel>
                    {draft.isApexDomain ? (
                      <div>CNAME</div>
                    ) : (
                      <div>
                        <NGRadioGroup
                          options={[
                            { label: "NS", value: "ns" },
                            { label: "CNAME", value: "cname" },
                          ]}
                          value={draft.dnsMode}
                          onChange={(value) => draft.setDNSMode(value as any)}
                        />
                      </div>
                    )}
                    <div>
                      <NGLabel>Routing Mode</NGLabel>
                      <NGRadioGroup
                        options={[
                          { label: "None", value: "none" },
                          { label: "Subdomain Routing", value: "subdomainBased" },
                          { label: "Path Routing", value: "pathBased" },
                        ]}
                        value={draft.routingMode}
                        onChange={(value) => draft.setRoutingMode(value as any)}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="flex justify-between gap-4">
                    <CNAMECard draft={draft} onClick={dnsRouting_cnamePathBased} />
                    {draft.isApexDomain ? null : <NSCard draft={draft} onClick={dnsRouting_nsSubdomain} />}
                  </div>
                )}
                <div className="flex gap-2 justify-between mt-2">
                  {data.showMoreDNSRoutingOptions ? (
                    <NGButton
                      variant={"primary"}
                      disabled={!draft.name.isValid || txtCheckIsLoading}
                      loading={txtCheckIsLoading}
                      onClick={() => {
                        setDNSRoutingPassed(true);
                      }}
                    >
                      Continue
                    </NGButton>
                  ) : null}
                  <div className="flex-grow" />
                  {/* {showMoreOptions ? null : (
                    <Button disabled onClick={dnsRouting_helpMeDecide}>
                      Help Me Decide
                    </Button>
                  )} */}
                  {data.isAdvanced ? (
                    <NGButton variant={"secondary"} onClick={() => data.toggleShowMoreDNSRoutingOptions()}>
                      {data.showMoreDNSRoutingOptions ? "Show Basic Options" : "Show More Options"}
                    </NGButton>
                  ) : null}
                </div>
              </div>
            ) : null}
            {data.isAdvanced && dnsRoutingPassed && draft.routingMode === "subdomainBased" ? (
              <div className="border border-r-0 px-4 pb-4 -mr-4">
                <DomainCreateNewSectionLabel sub>Subdomain Based Routing</DomainCreateNewSectionLabel>
                <div className="my-4" style={{ width: 462 }}>
                  <NGFormLabel required invalid={!draft.gvcName}>
                    GVC for Subdomain Routing
                  </NGFormLabel>
                  <NGKindSelect value={draft.gvcName} onChange={draft.setGvcName} kind="gvc" />
                </div>
                {/* TODO show examples */}
              </div>
            ) : null}
            {txtCheckPassed && dnsRoutingPassed ? (
              <>
                {draft.ports.map((port) => {
                  if (port.id !== data.selectedPortId) {
                    return null;
                  }
                  if (data.isBasic) {
                    return (
                      <>
                        <div className="border px-4 pb-4">
                          {draft.routingMode === "subdomainBased" ? (
                            basicModeRoutingDetailsPassed ? (
                              <div>
                                <DomainCreateNewSectionLabel
                                  onEdit={
                                    basicModeRoutingDetailsPassed
                                      ? () => {
                                          setBasicModeRoutingDetailsPassed(false);
                                          setBasicModeRoutingDetailsError("");
                                        }
                                      : null
                                  }
                                >
                                  Subdomain Based Routing
                                </DomainCreateNewSectionLabel>
                                <div className="flex items-center gap-1">
                                  <div style={{ transform: "translateY(-2px)" }}>
                                    <GVCIcon width={16} height={16} fill={Theme.theme === "dark" ? "white" : ""} />
                                  </div>
                                  <NGLabel>GVC</NGLabel>
                                </div>
                                <span>{draft.gvcLink}</span>
                              </div>
                            ) : (
                              <>
                                <DomainCreateNewSectionLabel
                                  onEdit={
                                    basicModeRoutingDetailsPassed
                                      ? () => {
                                          setBasicModeRoutingDetailsPassed(false);
                                          setBasicModeRoutingDetailsError("");
                                        }
                                      : null
                                  }
                                >
                                  Subdomain Based Routing
                                </DomainCreateNewSectionLabel>
                                <div className="my-4" style={{ width: 462 }}>
                                  <NGFormLabel required invalid={!draft.gvcName}>
                                    GVC for Subdomain Routing
                                  </NGFormLabel>
                                  <NGKindSelect value={draft.gvcName} onChange={draft.setGvcName} kind="gvc" />
                                </div>
                              </>
                            )
                          ) : null}
                          {draft.routingMode === "pathBased" ? (
                            basicModeRoutingDetailsPassed ? (
                              <div>
                                <DomainCreateNewSectionLabel
                                  onEdit={
                                    basicModeRoutingDetailsPassed
                                      ? () => {
                                          setBasicModeRoutingDetailsPassed(false);
                                          setBasicModeRoutingDetailsError("");
                                        }
                                      : null
                                  }
                                >
                                  Path Based Routing
                                </DomainCreateNewSectionLabel>
                                <div className="flex items-center gap-1">
                                  <div style={{ transform: "translateY(-2px)" }}>
                                    <GVCIcon width={16} height={16} fill={Theme.theme === "dark" ? "white" : ""} />
                                  </div>
                                  <NGLabel>GVC</NGLabel>
                                </div>
                                <span>{draft.routeGvcLink}</span>
                                {port.routes.length > 0 ? (
                                  <>
                                    <div className="flex items-center gap-1 mt-4">
                                      <div style={{ transform: "translateY(-2px)" }}>
                                        <WorkloadIcon
                                          width={16}
                                          height={16}
                                          fill={Theme.theme === "dark" ? "white" : ""}
                                        />
                                      </div>
                                      <NGLabel>Workload</NGLabel>
                                    </div>
                                    <span>{port.routes[0].workloadLink}</span>
                                  </>
                                ) : null}
                              </div>
                            ) : (
                              <>
                                <DomainCreateNewSectionLabel
                                  onEdit={
                                    basicModeRoutingDetailsPassed
                                      ? () => {
                                          setBasicModeRoutingDetailsPassed(false);
                                          setBasicModeRoutingDetailsError("");
                                        }
                                      : null
                                  }
                                >
                                  Path Based Routing
                                </DomainCreateNewSectionLabel>
                                <div>
                                  <div className="mb-4">
                                    {/* Parent gvc for basic mode */}
                                    <NGFormLabel required invalid={!draft.routeGvcName}>
                                      GVC
                                    </NGFormLabel>
                                    <NGKindSelect
                                      kind={"gvc"}
                                      value={draft.routeGvcName}
                                      onChange={draft.setRouteGvcName}
                                    />
                                  </div>
                                  {/* TODO handle here if there are multiple routes already but we only show one and the rest are invalid */}
                                  {draft.routeGvcName && port.routes.length > 0 ? (
                                    <>
                                      <div className="mb-4" key={draft.routeGvcName}>
                                        <NGFormLabel required invalid={!port.routes[0].workloadLink}>
                                          Workload
                                        </NGFormLabel>
                                        <NGKindSelect
                                          kind={"workload"}
                                          fetchAll
                                          value={port.routes[0].workloadName}
                                          onChange={port.routes[0].setWorkloadName}
                                          queries={[{ rel: "gvc", value: draft.routeGvcName }]}
                                        />
                                      </div>
                                    </>
                                  ) : null}
                                  <div className="mb-4">
                                    {/* TODO Warning for multiple routes already existing? */}
                                    <NGAlert
                                      type={"info"}
                                      message={
                                        "To configure for dedicated load balancer, or having multiple routes, switch to advanced mode."
                                      }
                                    />
                                  </div>
                                </div>
                              </>
                            )
                          ) : null}
                          {/* Keep it separate to above two (path based and subdomain details) because the button is for both */}
                          {basicModeRoutingDetailsPassed ? null : (
                            <NGButton
                              variant={"primary"}
                              disabled={!draft.isSpecValid || basicModeRoutingDetailsIsLoading}
                              loading={basicModeRoutingDetailsIsLoading}
                              onClick={async () => {
                                try {
                                  setBasicModeRoutingDetailsIsLoading(true);
                                  // dryRun for current domain
                                  const { data } = await request({
                                    method: "post",
                                    url: homeLink("domain") + "?dryRun",
                                    body: draft.asObject,
                                  });
                                  setBasicModeRoutingDetailsPassed(true);
                                  const hasDetails =
                                    data.details && (data.details.dnsConfig || data.details.ownershipConfig);
                                  setBasicModeRoutingDetailsError(hasDetails);
                                  if (hasDetails) {
                                    setBasicModeRoutingDetailsDetails(data.details);
                                  }
                                  setBasicModeRoutingDetailsIsLoading(false);
                                } catch (e) {
                                  setBasicModeRoutingDetailsPassed(false);
                                  setBasicModeRoutingDetailsError(e?.response?.data.message);
                                  setBasicModeRoutingDetailsDetails(e?.response?.data.details);
                                  setBasicModeRoutingDetailsIsLoading(false);
                                }
                              }}
                            >
                              {/* Test when it would show Retry  */}
                              {basicModeRoutingDetailsError ? "Retry" : "Continue"}
                            </NGButton>
                          )}
                        </div>
                        {skipAcceptAllHosts ? null : basicModeRoutingDetailsPassed ? (
                          basicModeAcceptAllHostsPassed ? (
                            <div className="border px-4 pb-4">
                              <DomainCreateNewSectionLabel onEdit={() => setBasicModeAcceptAllHostsPassed(false)}>
                                Accept All Hosts
                              </DomainCreateNewSectionLabel>
                              <NGLabel className={"mb-4"}>{draft.acceptAllHosts ? "Enabled" : "Disabled"}</NGLabel>
                            </div>
                          ) : (
                            <div className="border px-4 pb-4">
                              <DomainCreateNewSectionLabel>Accept All Hosts</DomainCreateNewSectionLabel>
                              <div className={`mb-4 flex items-center`}>
                                <NGSwitch
                                  data-testid="switch-configure-tls"
                                  value={draft.acceptAllHosts}
                                  onChange={draft.setAcceptAllHosts}
                                >
                                  <NGLabelText>Enable</NGLabelText>
                                </NGSwitch>
                              </div>
                              <div className="flex flex-col gap-2 text-sm mb-2">
                                <div>
                                  Accept All Hosts configures the domain to allow all traffic to the configured
                                  workloads, regardless of what the Host header or SNI is for the request.
                                </div>
                                <div>
                                  Host Prefix is also available to match host headers by prefix to different workloads
                                  and will only function when acceptAllHosts is also enabled for the domain.
                                </div>
                              </div>
                              <NGButton
                                variant={"primary"}
                                onClick={async () => setBasicModeAcceptAllHostsPassed(true)}
                              >
                                Continue
                              </NGButton>
                            </div>
                          )
                        ) : null}
                        {/* TODO can we use this also for the advanced mode? if not, separate it out to a component and use also in the modal and the first step */}
                        {basicModeRoutingDetailsPassed &&
                        basicModeRoutingDetailsError &&
                        (skipAcceptAllHosts || basicModeAcceptAllHostsPassed) ? (
                          <div className="border px-4 pb-4">
                            <DomainCreateNewSectionLabel>DNS Config</DomainCreateNewSectionLabel>
                            <div className="mb-4">
                              {basicModeRoutingDetailsDetails &&
                              ((basicModeRoutingDetailsDetails.ownershipConfig &&
                                basicModeRoutingDetailsDetails.ownershipConfig.length > 0) ||
                                (basicModeRoutingDetailsDetails.dnsConfig &&
                                  basicModeRoutingDetailsDetails.dnsConfig.length > 0)) ? (
                                <>
                                  <div className={`mb-2`}>Please create the DNS records below.</div>
                                  <div
                                    className="flex items-center gap-2 border px-2 py-1 table-labels"
                                    style={{ borderTopLeftRadius: 6, borderTopRightRadius: 6 }}
                                  >
                                    <div className="w-5/12">Host</div>
                                    <div className="w-1/12">TTL</div>
                                    <div className="w-1/12">Type</div>
                                    <div className="w-5/12">Value</div>
                                  </div>
                                </>
                              ) : null}
                              {basicModeRoutingDetailsDetails &&
                                basicModeRoutingDetailsDetails.ownershipConfig &&
                                basicModeRoutingDetailsDetails.ownershipConfig.map((r) => (
                                  <div className="flex items-center gap-2 border px-2 py-1">
                                    <DNSValue column={`w-5/12`} value={r.host === "@" ? draft.name.value : r.host} />
                                    <DNSValue column={`w-1/12`} value={r.ttl.toString()} />
                                    <DNSValue column={`w-1/12`} value={r.type} />
                                    <DNSValue column={`w-5/12`} value={r.value.replace("<gvcAlias>", data.gvcAlias)} />
                                  </div>
                                ))}
                              {basicModeRoutingDetailsDetails &&
                                basicModeRoutingDetailsDetails.dnsConfig &&
                                basicModeRoutingDetailsDetails.dnsConfig.map((r) => (
                                  <div className="flex items-center gap-2 border px-2 py-1">
                                    <DNSValue column={`w-5/12`} value={r.host === "@" ? draft.name.value : r.host} />
                                    <DNSValue column={`w-1/12`} value={r.ttl.toString()} />
                                    <DNSValue column={`w-1/12`} value={r.type} />
                                    <DNSValue column={`w-5/12`} value={r.value.replace("<gvcAlias>", data.gvcAlias)} />
                                  </div>
                                ))}
                              {basicModeRoutingDetailsDetails &&
                              basicModeRoutingDetailsDetails.dnsConfig &&
                              basicModeRoutingDetailsDetails.dnsConfig.length > 0 ? (
                                <NGCheckbox
                                  className="mt-2"
                                  checked={dnsIsConfigured}
                                  onChange={(value) => setDNSIsConfigured(value)}
                                >
                                  <NGLabelText>I have created the DNS records</NGLabelText>
                                </NGCheckbox>
                              ) : null}
                            </div>
                          </div>
                        ) : null}
                      </>
                    );
                  }

                  // advanced mode port
                  return (
                    <div className="border px-4 pb-4">
                      <DomainCreateNewSectionPortLabel draft={draft} data={data} />
                      <div className="border border-r-0 px-4 pb-4 -mr-4 -mt-4">
                        <DomainCreateNewSectionLabel sub>Definition</DomainCreateNewSectionLabel>
                        <div className="flex items-center mb-4">
                          <div>
                            <NGLabel>{port.number.label}</NGLabel>
                            <NGSelect
                              style={{ width: 225, marginRight: 5 }}
                              options={port.number.options}
                              value={port.number.value}
                              onChange={(value) => draft.setPortNumber(port.id, value)}
                            />
                          </div>
                          <div>
                            <NGLabel>{port.protocol.label}</NGLabel>
                            <NGSelect
                              style={{ width: 225, marginLeft: 5 }}
                              options={port.protocol.options}
                              value={port.protocol.value}
                              onChange={port.protocol.setValue}
                            />
                          </div>
                        </div>
                        {loadBalancerPortMismatchReason !== "none" ? (
                          <div className="mb-4">
                            <NGAlert type="warning" message={loadBalancerPortMismatchMessage} />
                          </div>
                        ) : null}
                      </div>
                      {draft.routingMode === "pathBased" ? (
                        <div className="border border-r-0 px-4 pb-4 -mr-4 -mt-4">
                          <DomainCreateNewSectionLabel sub>Routes</DomainCreateNewSectionLabel>
                          <div>
                            <div className="mb-4">
                              <div className="mb-1 flex items-center">
                                <NGFormLabel required invalid={!draft.routeGvcName}>
                                  Parent GVC
                                </NGFormLabel>
                                {draft.ports.length > 1 ? (
                                  <div className="ml-2 flex items-center gap-1 text-sm">
                                    <AlertCircle className={`feather-icon color-primary`} />
                                    <span>
                                      This value is common for all ports. Any changes will be applied to all ports.
                                    </span>
                                  </div>
                                ) : null}
                              </div>
                              <NGKindSelect value={draft.routeGvcName} onChange={draft.setRouteGvcName} kind={"gvc"} />
                            </div>
                            {!draft.routeGvcName ? null : (
                              <>
                                {skipAcceptAllHosts ? null : (
                                  <div className={`mb-4 flex items-center`}>
                                    <NGSwitch
                                      data-testid="switch-configure-tls"
                                      value={draft.acceptAllHosts}
                                      onChange={draft.setAcceptAllHosts}
                                    >
                                      <NGLabelText>Enable Accept All Hosts</NGLabelText>
                                    </NGSwitch>
                                    <InfoTooltip
                                      title={[
                                        "Accept All Hosts configures the domain to allow all traffic to the configured workloads, regardless of what the Host header or SNI is for the request.",
                                        "Host Prefix is also available to match host headers by prefix to different workloads and will only function when acceptAllHosts is also enabled for the domain.",
                                      ]}
                                    />
                                    {draft.ports.length > 1 ? (
                                      <div className="ml-2 flex items-center gap-1 text-sm">
                                        <AlertCircle className={`feather-icon color-primary`} />
                                        <span>
                                          This value is common for all ports. Any changes will be applied to all ports.
                                        </span>
                                      </div>
                                    ) : null}
                                  </div>
                                )}
                                <div className="flex items-center mb-4">
                                  <NGLabel>Routes</NGLabel>
                                  <NGButton
                                    className="ml-2"
                                    disabled={port.routes.some((r) => !r.isValid)}
                                    size={"small"}
                                    variant="action"
                                    onClick={() => port.addRoute()}
                                  >
                                    Add
                                  </NGButton>
                                  <InfoTooltip
                                    title={[
                                      `Routes will be ordered automatically by their "prefix" value, in the order of specificity, if all of them use Prefix method.`,
                                      port.routes.some((r) => !r.isValid)
                                        ? "Please fill current routes with valid data (prefix and workload) before adding a new route."
                                        : "",
                                    ].filter(Boolean)}
                                  />
                                  {port.routes.length < 1 ? (
                                    <div className="ml-2 flex items-center gap-1 text-sm">
                                      <AlertCircle className={`feather-icon color-link`} />
                                      <span>At least one route is required.</span>
                                    </div>
                                  ) : null}
                                  <NGButton
                                    className="ml-2"
                                    disabled={port.routes.some((r) => !r.isValid)}
                                    size={"small"}
                                    variant="secondary"
                                    onClick={() => setIsEditingRouteHeaders(true)}
                                  >
                                    Edit Headers
                                  </NGButton>
                                </div>
                                {port.routes.length > 0 ? (
                                  <div className="flex items-center gap-2 mb-2">
                                    <div style={{ width: 100 }}>Method</div>
                                    <div style={{ width: 145 }}>
                                      Prefix/Regex<span className="color-danger">*</span>
                                    </div>
                                    <div style={{ width: 145 }} className="flex items-center">
                                      <div>Replace Prefix</div>
                                      <InfoTooltip title="When a 'Replace Prefix' is provided, the URI path prefix will be replaced with the given string before the request is forwarded to the Workload." />
                                    </div>
                                    {draft.routeGvcHasDedicatedLoadBalancer ? (
                                      <div style={{ width: 145 }} className="flex items-center">
                                        <div>Host Prefix</div>
                                        <InfoTooltip
                                          title={[
                                            "This option allows forwarding traffic for different host headers to different workloads.",
                                            "This will only be used when the target GVC has dedicated load balancing enabled and the Domain is configured for wildcard support (acceptAllHosts=true).",
                                            "Contact your account manager for details.",
                                          ]}
                                        />
                                      </div>
                                    ) : null}
                                    <div className="flex-grow">
                                      Workload<span className="color-danger">*</span>
                                    </div>
                                    <div style={{ width: 105 }}>Port</div>
                                    <div style={{ width: 80 + 32 }} className="flex items-center">
                                      <div>Replica</div>
                                      <InfoTooltip
                                        title={[
                                          "The replica number of a stateful workload to route to. If not provided, traffic will be routed to all replicas.",
                                        ]}
                                      />
                                    </div>
                                  </div>
                                ) : null}
                                {port.routes.map((route) => (
                                  <DomainNewSpecPortRoute
                                    key={route.id + draft.routeGvcName}
                                    draft={draft}
                                    port={port}
                                    route={route}
                                  />
                                ))}
                              </>
                            )}
                          </div>
                        </div>
                      ) : null}
                      <div className="border border-r-0 px-4 pb-4 -mr-4 -mt-4">
                        <DomainCreateNewSectionLabel sub>TLS</DomainCreateNewSectionLabel>
                        <div>
                          <div className={`${port.configureTLSGet ? "mb-2" : "mb-4"} flex items-center`}>
                            <NGSwitch
                              data-testid="switch-configure-tls"
                              value={port.configureTLSGet}
                              onChange={port.setConfigureTLS}
                              isDisabled={port.configureTLSRequired}
                            >
                              <NGLabelText>Customize TLS</NGLabelText>
                            </NGSwitch>
                            {port.configureTLSRequired ? <InfoTooltip title="TLS must be enabled." /> : null}
                          </div>
                          {port.configureTLSGet && port.tls ? (
                            <>
                              <NGLabel>{port.tls.minProtocolVersion.label}</NGLabel>
                              <NGSelect
                                style={{ width: 450 }}
                                className="mb-4"
                                options={port.tls.minProtocolVersion.options}
                                onChange={port.tls.minProtocolVersion.setValue}
                                value={port.tls.minProtocolVersion.value}
                              />
                              <div
                                className={`${port.tls!.forwardClientCertificate ? "mb-2" : "mb-4"} flex items-center`}
                              >
                                <NGSwitch
                                  data-testid="switch-forward-client-certificate"
                                  value={port.tls!.forwardClientCertificate}
                                  onChange={port.tls!.setForwardClientCertificate}
                                >
                                  <NGLabelText>Forward Client Certificate</NGLabelText>
                                </NGSwitch>
                                <InfoTooltip title="When clientCertificate is defined, requests may optionally supply a client certificate. The certificate details will be included in the x-forwarded-client-cert header." />
                              </div>
                              {port.tls!.forwardClientCertificate ? (
                                <div className={"mb-4"} style={{ width: 450 }}>
                                  <div className="mb-1 flex items-center">
                                    <NGLabel>Client Certificate Authority PEM</NGLabel>
                                    <InfoTooltip
                                      title={[
                                        "The secret will include a client certificate authority cert in PEM format used to verify requests which include client certificates.",
                                        "The key subject must match the domain and the key usage properties must be configured for client certificate authorization.",
                                        "The secret type must be keypair.",
                                      ]}
                                    />
                                  </div>
                                  <NGKindSelect
                                    value={port.tls.clientCertificateSecretName}
                                    onChange={port.tls.setClientCertificateSecretName}
                                    queries={[{ property: "type", value: "tls" }]}
                                    placeholder="Select a TLS Secret"
                                    kind="secret"
                                    // extraInfoFns={[(item) => item.type.toUpperCase()]}
                                  />
                                </div>
                              ) : null}
                              <div
                                className={`${
                                  port.tls!.useCustomServerCertificate ? "mb-2" : "mb-4"
                                } flex items-center`}
                              >
                                <NGSwitch
                                  data-testid="switch-use-custom-server-certificate"
                                  value={port.useCustomServerCertificateRequired || port.tls.useCustomServerCertificate}
                                  onChange={port.tls!.setUseCustomServerCertificate}
                                  isDisabled={port.useCustomServerCertificateRequired}
                                >
                                  <NGLabelText>Use Custom Server Certificate</NGLabelText>
                                </NGSwitch>
                                <InfoTooltip title="Configure an optional custom server certificate for the domain. When the port number is 443 and this is not supplied, a certificate will be provisioned automatically." />
                              </div>
                              {port.useCustomServerCertificateRequired || port.tls.useCustomServerCertificate ? (
                                <div className={"mb-4"} style={{ width: 450 }}>
                                  <div className="mb-1 flex items-center">
                                    <NGLabel>Server Certificate Authority PEM</NGLabel>
                                    {port.useCustomServerCertificateRequired ? (
                                      <span className="color-danger">*</span>
                                    ) : null}
                                    <InfoTooltip
                                      title={[
                                        "When provided, this will be used as the server certificate authority.",
                                        "The secret type must be keypair and the content must be PEM encoded.",
                                      ]}
                                    />
                                  </div>
                                  <NGKindSelect
                                    value={port.tls.serverCertificateSecretName}
                                    onChange={port.tls.setServerCertificateSecretName}
                                    queries={[{ property: "type", value: "tls" }]}
                                    placeholder="Select a TLS Secret"
                                    // extraInfoFns={[(item) => item.type.toUpperCase()]}
                                    kind={"secret"}
                                  />
                                </div>
                              ) : null}
                              <NGInputListMst
                                label={"Cipher Suites"}
                                styles={{ container: { width: 450 } }}
                                data={port.tls.cipherSuites}
                                canAdd={() => allowedCiphers.length !== port.tls!.cipherSuites.items.length}
                                firstInput={(item) => (
                                  <NGSelect
                                    value={item.firstValue}
                                    onChange={item.setFirstValue}
                                    invalid={!item.firstValue}
                                    options={allowedCiphers
                                      .filter((c) => !port.tls!.cipherSuites.items.some((i) => i.firstValue === c))
                                      .map((v) => ({ label: v, value: v }))}
                                  />
                                )}
                              />
                            </>
                          ) : null}
                        </div>
                      </div>
                      <div className="border border-r-0 px-4 pb-4 -mr-4 -mt-4">
                        <DomainCreateNewSectionLabel sub>Cors</DomainCreateNewSectionLabel>
                        <div>
                          <NGSwitch
                            className="mb-2"
                            data-testid="switch-configure-cors"
                            value={port.configureCors}
                            onChange={port.setConfigureCors}
                          >
                            <NGLabelText>Configure CORS</NGLabelText>
                          </NGSwitch>
                          {port.configureCors && port.cors ? (
                            <>
                              <NGSwitch
                                data-testid="switch-allow-credentials"
                                className="mb-4"
                                value={port.cors.allowCredentials}
                                onChange={port.cors.setAllowCredentials}
                              >
                                <NGLabelText>Allow Credentials</NGLabelText>
                              </NGSwitch>
                              <NGInput
                                className="mb-4"
                                style={{ width: 450 }}
                                value={port.cors.maxAgeInput.value}
                                onChange={(e) => port.cors!.maxAgeInput.setValue(e.target.value)}
                              />
                              <NGInputListMst
                                styles={{ container: { width: 450 } }}
                                label="Allow Origins"
                                data={port.cors.allowOrigins}
                                firstInput={(item) => (
                                  <NGInput
                                    value={item.firstValue}
                                    onChange={(e) => item.setFirstValue(e.target.value)}
                                    invalid={!item.firstValue}
                                  />
                                )}
                              />
                              <NGInputListMst
                                styles={{ container: { width: 450 } }}
                                label="Allow Methods"
                                data={port.cors.allowMethods}
                                firstInput={(item) => (
                                  <NGSelect
                                    value={item.firstValue}
                                    onChange={item.setFirstValue}
                                    options={methods.map((v) => ({ label: v, value: v })) as any}
                                    invalid={!item.firstValue}
                                  />
                                )}
                              />
                              <NGInputListMst
                                styles={{ container: { width: 450 } }}
                                label="Allow Headers"
                                data={port.cors.allowHeaders}
                                firstInput={(item) => (
                                  <NGSelect
                                    value={item.firstValue}
                                    onChange={item.setFirstValue}
                                    options={commonHeaders.map((v) => ({ label: v, value: v })) as any}
                                    invalid={!item.firstValue}
                                  />
                                )}
                              />
                              <NGInputListMst
                                styles={{ container: { width: 450 } }}
                                label="Expose Headers"
                                data={port.cors.exposeHeaders}
                                firstInput={(item) => (
                                  <NGSelect
                                    value={item.firstValue}
                                    onChange={item.setFirstValue}
                                    options={commonHeaders.map((v) => ({ label: v, value: v })) as any}
                                    invalid={!item.firstValue}
                                  />
                                )}
                              />
                            </>
                          ) : null}
                        </div>
                      </div>
                      {/* PORTS continue button for the advanced mode */}
                      {data.isBasic || draft.routingMode === "none" || advancedModePortsDetailsPassed ? null : (
                        <NGButton
                          className="mt-4"
                          variant={"primary"}
                          disabled={!draft.isSpecValid || advancedModePortsDetailsIsLoading}
                          loading={advancedModePortsDetailsIsLoading}
                          onClick={async () => {
                            try {
                              setAdvancedModePortsDetailsIsLoading(true);

                              // dryRun for current domain
                              const { data } = await request({
                                method: "post",
                                url: homeLink("domain") + "?dryRun",
                                body: draft.asObject,
                              });
                              setAdvancedModePortsDetailsPassed(true);
                              const hasDetails =
                                data.details && (data.details.dnsConfig || data.details.ownershipConfig);
                              setAdvancedModePortsDetailsError(hasDetails);
                              if (hasDetails) {
                                setAdvancedModePortsDetailsDetails(data.details);
                              }
                              setAdvancedModePortsDetailsIsLoading(false);
                            } catch (e) {
                              setAdvancedModePortsDetailsPassed(false);
                              setAdvancedModePortsDetailsError(e?.response?.data.message);
                              setAdvancedModePortsDetailsDetails(e?.response?.data.details);
                              setAdvancedModePortsDetailsIsLoading(false);
                            }
                          }}
                        >
                          Continue
                        </NGButton>
                      )}
                    </div>
                  );
                })}
                {data.isAdvanced &&
                advancedModePortsDetailsPassed &&
                advancedModePortsDetailsError &&
                advancedModePortsDetailsError.length > 0 ? (
                  <div className="border px-4 pb-4">
                    <DomainCreateNewSectionLabel>DNS Config</DomainCreateNewSectionLabel>
                    <div className="mb-4">
                      {advancedModePortsDetailsDetails &&
                      ((advancedModePortsDetailsDetails.ownershipConfig &&
                        advancedModePortsDetailsDetails.ownershipConfig.length > 0) ||
                        (advancedModePortsDetailsDetails.dnsConfig &&
                          advancedModePortsDetailsDetails.dnsConfig.length > 0)) ? (
                        <>
                          <div className={`mb-2`}>Please create the DNS records below.</div>
                          <div
                            className="flex items-center gap-2 border px-2 py-1 table-labels"
                            style={{ borderTopLeftRadius: 6, borderTopRightRadius: 6 }}
                          >
                            <div className="w-5/12">Host</div>
                            <div className="w-1/12">TTL</div>
                            <div className="w-1/12">Type</div>
                            <div className="w-5/12">Value</div>
                          </div>
                        </>
                      ) : null}
                      {advancedModePortsDetailsDetails &&
                        advancedModePortsDetailsDetails.ownershipConfig &&
                        advancedModePortsDetailsDetails.ownershipConfig.map((r) => (
                          <div className="flex items-center gap-2 border px-2 py-1">
                            <DNSValue column={`w-5/12`} value={r.host === "@" ? draft.name.value : r.host} />
                            <DNSValue column={`w-1/12`} value={r.ttl.toString()} />
                            <DNSValue column={`w-1/12`} value={r.type} />
                            <DNSValue column={`w-5/12`} value={r.value.replace("<gvcAlias>", data.gvcAlias)} />
                          </div>
                        ))}
                      {advancedModePortsDetailsDetails &&
                        advancedModePortsDetailsDetails.dnsConfig &&
                        advancedModePortsDetailsDetails.dnsConfig.map((r) => (
                          <div className="flex items-center gap-2 border px-2 py-1">
                            <DNSValue column={`w-5/12`} value={r.host === "@" ? draft.name.value : r.host} />
                            <DNSValue column={`w-1/12`} value={r.ttl.toString()} />
                            <DNSValue column={`w-1/12`} value={r.type} />
                            <DNSValue column={`w-5/12`} value={r.value.replace("<gvcAlias>", data.gvcAlias)} />
                          </div>
                        ))}
                      {advancedModePortsDetailsDetails &&
                      advancedModePortsDetailsDetails.dnsConfig &&
                      advancedModePortsDetailsDetails.dnsConfig.length > 0 ? (
                        <NGCheckbox
                          className="mt-2"
                          checked={dnsIsConfigured}
                          onChange={(value) => setDNSIsConfigured(value)}
                        >
                          <NGLabelText>I have created the DNS records</NGLabelText>
                        </NGCheckbox>
                      ) : null}
                    </div>
                  </div>
                ) : null}
              </>
            ) : null}
            <div className="flex justify-end gap-4 mt-6">
              <NGButton
                style={{ width: 170 }}
                variant={"danger"}
                disabled={isLoading}
                onClick={() => {
                  navigate(`/console/org/${ConsoleContext.org}/domain`);
                }}
              >
                Cancel
              </NGButton>
              {txtCheckPassed ? (
                <>
                  <NGButton
                    style={{ width: 170 }}
                    variant={"secondary"}
                    disabled={isLoading}
                    onClick={() => {
                      if (data.isAdvanced && draft.ports.length > 1) {
                        const m = Modal.confirm({
                          title: "Switch to Basic Mode",
                          content: (
                            <div>
                              <div>
                                Domain currently has multiple ports. If you switch to Basic Mode, only one port will
                                remain and you will lose other ports' configuration.
                              </div>
                            </div>
                          ),
                          footer: (
                            <div className="modal-actions">
                              <NGButton variant="danger" outlined onClick={() => m.destroy()}>
                                Cancel
                              </NGButton>
                              <NGButton
                                variant="primary"
                                onClick={() => {
                                  data.toggleMode();
                                  m.destroy();
                                }}
                              >
                                Switch
                              </NGButton>
                            </div>
                          ),
                        });
                      } else {
                        data.toggleMode();
                      }
                    }}
                  >
                    {data.isAdvanced ? "Basic" : "Advanced"} Mode
                  </NGButton>
                  <NGButton
                    style={{ width: 170 }}
                    variant={"action"}
                    disabled={
                      !draft.isValid ||
                      isLoading ||
                      !txtCheckPassed ||
                      !dnsRoutingPassed ||
                      (!(basicModeRoutingDetailsPassed || advancedModePortsDetailsPassed) &&
                        draft.routingMode !== "none") ||
                      !dnsIsConfigured
                    }
                    loading={isLoading}
                    onClick={onConfirm}
                  >
                    Create
                  </NGButton>
                </>
              ) : null}
            </div>
          </div>
          <div className={styles.help} style={{ top: scrollTop }}>
            {dnsRoutingPassed ? null : txtCheckPassed ? (
              <>
                <div className="my-2">
                  <div className="mt-2 flex justify-between">
                    <NGLabel>What is DNS Mode?</NGLabel>
                    <button onClick={toggleCollapse(1)} className={"focus text-sm color-link"}>
                      {collapsedHelpers.includes(1) ? "Show" : "Hide"}
                      {collapsedHelpers.includes(1) ? (
                        <ChevronDown className="inline-block feather-icon" style={{ transform: "translateY(3px)" }} />
                      ) : (
                        <ChevronUp className="inline-block feather-icon" style={{ transform: "translateY(3px)" }} />
                      )}
                    </button>
                  </div>
                  {collapsedHelpers.includes(1) ? null : (
                    <>
                      <div className="text-sm">
                        DNS mode decides which records are used to route workloads to your custom domain.
                      </div>
                      <NGLabel className="mt-2">NS Option</NGLabel>
                      <div className="text-sm">
                        With the NS records, you need to configure 4 NS records so we can route your domain to workload
                        traffic.
                      </div>
                      <div className="text-sm">
                        This option is recommended if you want to use Subdomain Based routing mode.
                      </div>
                      <NGLabel className="mt-2">CNAME Option</NGLabel>
                      <div className="text-sm">
                        CNAME record uses redirection and once it is configured, you can set any gvc and workloads from
                        that gvc without touching DNS again.
                      </div>
                      <div className="text-sm">
                        This option is recommended if you want to use Path Based Routing mode. Read next section to
                        understand routing modes.
                      </div>
                    </>
                  )}
                </div>
                <div className="my-2 mt-4">
                  <div className="mt-2 flex justify-between">
                    <NGLabel>What is Routing Mode?</NGLabel>
                    <button onClick={toggleCollapse(2)} className={"focus text-sm color-link"}>
                      {collapsedHelpers.includes(2) ? "Show" : "Hide"}
                      {collapsedHelpers.includes(2) ? (
                        <ChevronDown className="inline-block feather-icon" style={{ transform: "translateY(3px)" }} />
                      ) : (
                        <ChevronUp className="inline-block feather-icon" style={{ transform: "translateY(3px)" }} />
                      )}
                    </button>
                  </div>
                  {collapsedHelpers.includes(2) ? null : (
                    <>
                      <div className="text-sm">
                        We offer two types of routing to your workloads. Subdomain based or path based depending on your
                        use case. Both methods can only target workloads from a single GVC.
                      </div>
                      <NGLabel className="mt-2">Subdomain Based</NGLabel>
                      <div className="text-sm">
                        Creates subdomain of this domain for each workload in a selected GVC
                      </div>
                      <div className="text-sm">
                        Example; if a GVC has workloads named wl1, wl2, wl3, then the generated endpoints would be{" "}
                        {`wl1.${draft.name.value}, wl2.${draft.name.value}, wl3.${draft.name.value}`}.
                      </div>
                      <NGLabel className="mt-2">Path Based</NGLabel>
                      <div className="text-sm">Explanation about path based</div>
                      <NGLabel className="mt-2">None</NGLabel>
                      <div className="text-sm">Explanation about none option</div>
                    </>
                  )}
                </div>
              </>
            ) : null}
            {draft.invalidReason.code !== 0 && (
              <div className={`${draft.invalidReason.type === "error" ? "color-danger" : ""}`}>
                {draft.invalidReason.message}
              </div>
            )}
          </div>
        </div>
      </div>
      {isEditingRouteHeaders ? (
        <DomainPortRouteEditHeadersModal
          port={draft.ports.find((p) => p.id === data.selectedPortId)}
          onClose={() => setIsEditingRouteHeaders(false)}
        />
      ) : null}
    </>
  );
};

export const DomainCreateNew = observer(DomainCreateNewRaw);
