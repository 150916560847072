import * as React from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { IncorrectItem } from "../../components/generic/IncorrectItem/incorrectItem";
import { fetchCommandsOf, linksOf, request, resourceLink } from "../../services/cpln";
import { Loader } from "../../components/layout/loader";
import { DetailContext } from "../../components/detail/detailContext";
import { Helmet } from "react-helmet";
import { IS_DEPLOYMENT_ENV_TEST, VOLUMESET_COMMANDS_INTERVAL_MS } from "../../envVariables";
import { VolumeSetMobx, VolumeSetModel } from "../../mst/kinds/volumeset";
import { VolumeSetDetail } from "./detail";
import { getDraftVolumeSet, VolumeSetDraftMobx } from "../../mst/stores/volumeset.draft";
import { Command } from "../../mst/kinds/command";
import { NGFormData } from "../../mobxStores/ngFormData";
import { NGFormContext } from "../../reactContexts/ngFormContext";
import { captureExc } from "../../errorBoundary";
import { CommandsContext } from "../../components/detail/commandsContext";

type RouteParams = "volumeset";
const VolumeSetDetailRouteRaw: React.FC = () => {
  const formDataRef = React.useRef(new NGFormData());
  const { volumeset: volumeSetName } = useParams<RouteParams>();
  const [volumeSet, setVolumeSet] = React.useState<VolumeSetMobx>(null as any);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isIncorrect, setIsIncorrect] = React.useState(false);
  const draftRef = React.useRef<VolumeSetDraftMobx>(null as any);

  const [commands, setCommands] = React.useState<Command[]>([]);
  async function fetchCommands() {
    const selfLink = linksOf(volumeSet).self;
    const _commands = await fetchCommandsOf(selfLink);
    setCommands(_commands);
  }
  React.useEffect(() => {
    if (volumeSet) {
      fetchCommands();
    }
    const intervalId = setInterval(() => {
      fetchCommands();
    }, VOLUMESET_COMMANDS_INTERVAL_MS);

    return () => {
      clearInterval(intervalId);
    };
  }, [volumeSet]);

  React.useEffect(() => {
    fetchItem();
  }, [volumeSetName]);

  async function fetchItem() {
    try {
      setIsLoading(true);
      const { data: volumeSetRes } = await request({ url: resourceLink("volumeset", volumeSetName) });
      const volumeSetInstance: VolumeSetMobx = VolumeSetModel.create(volumeSetRes);
      setVolumeSet(volumeSetInstance);
      draftRef.current = getDraftVolumeSet(volumeSetInstance);
      setIsLoading(false);
    } catch (e) {
      captureExc(e);
      setIsIncorrect(true);
      setIsLoading(false);
    }
  }

  if (isLoading) {
    return <Loader fullScreen reason={"fetching volumeset to show detail"} />;
  }

  if (isIncorrect) {
    return <IncorrectItem kind={"volumeset"} canCreate={true} />;
  }

  return (
    <>
      <Helmet>
        <title>
          {IS_DEPLOYMENT_ENV_TEST ? `TEST | ` : ""}
          {volumeSet.name} - Volume Set - From many clouds, one™
        </title>
      </Helmet>
      <NGFormContext.Provider value={formDataRef.current}>
        <DetailContext.Provider value={{ item: volumeSet, fetchItem }}>
          <CommandsContext.Provider
            value={{
              fetchCommands: fetchCommands,
              commands: commands,
            }}
          >
            <VolumeSetDetail volumeSet={volumeSet} volumeSetDraft={draftRef.current} />
          </CommandsContext.Provider>
        </DetailContext.Provider>
      </NGFormContext.Provider>
    </>
  );
};

export const VolumeSetDetailRoute = observer(VolumeSetDetailRouteRaw);
