import * as React from "react";
import { observer } from "mobx-react-lite";
import { AWSIdentityInfo } from "./create/aws/info";
import { AzureIdentityInfo } from "./create/azure/info";
import { GCPIdentityInfo } from "./create/gcp/info";
import { IdentityDraftMobx } from "../../mst/stores/identity.draft";
import { notification } from "antd";
import { AWSSetupModal } from "./create/aws/setupModal";
import { AzureSetupModal } from "./create/azure/setupModal";
import { GCPSetupModal } from "./create/gcp/setupModal";
import { FormButtons } from "../../components/forms/formButtons";
import { NGSIdentityInfo } from "./create/ngs/info";
import { NGSSetupModal } from "./create/ngs/setupModal";
import { useDetailContext } from "../../components/detail/detailContext";
import { IdentityMobx } from "../../mst/kinds/identity";
import { PromptContext } from "../../mobxStores/prompt/prompt";
import { useCleanPrompt } from "../../reactHooks/useCleanPrompt";

interface Props {
  identity: IdentityMobx;
  identityDraft: IdentityDraftMobx;
}
const CloudaccessRaw: React.FC<Props> = ({ identity, identityDraft }) => {
  const { fetchItem } = useDetailContext();
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    identityDraft.reset();
    return () => {
      identityDraft.reset();
    };
  }, []);

  React.useEffect(() => {
    PromptContext.setWhen(identityDraft.isDirty || isLoading);
  }, [identityDraft.isDirty, isLoading]);

  useCleanPrompt();

  function reset() {
    identityDraft.reset();
  }

  async function save() {
    try {
      setIsLoading(true);
      await identity.patch(identityDraft.asPatch);
      identityDraft.confirm();
      notification.success({
        message: "Success",
        description: "Updated identity",
      });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
      if (e.response.status === 409) {
        if (fetchItem) {
          await fetchItem();
          notification.info({
            message: "Updated Item",
            description: "Fetched the latest version of the item and discarded changes.",
          });
        }
      }
    }
  }

  return (
    <>
      <AWSIdentityInfo aws={identityDraft.aws} providerStatus={identity.status.aws} infoOnly={false} />
      {identityDraft.aws.isSettingUp ? <AWSSetupModal aws={identityDraft.aws} /> : null}
      <AzureIdentityInfo azure={identityDraft.azure} providerStatus={identity.status.azure} infoOnly={false} />
      {identityDraft.azure.isSettingUp ? <AzureSetupModal azure={identityDraft.azure} /> : null}
      <GCPIdentityInfo gcp={identityDraft.gcp} providerStatus={identity.status.gcp} infoOnly={false} />
      {identityDraft.gcp.isSettingUp ? <GCPSetupModal gcp={identityDraft.gcp} /> : null}
      <NGSIdentityInfo ngs={identityDraft.ngs} infoOnly={false} />
      {identityDraft.ngs.isSettingUp ? <NGSSetupModal ngs={identityDraft.ngs} /> : null}
      <FormButtons
        onReset={reset}
        onSave={save}
        resetDisabled={isLoading || !identityDraft.isDirty}
        saveDisabled={isLoading || !identityDraft.isDirty}
        loading={isLoading}
      />
    </>
  );
};

export const Cloudaccess = observer(CloudaccessRaw);
