import * as React from "react";
import { observer } from "mobx-react-lite";
import { IdentityDraftMobx } from "../../../mst/stores/identity.draft";
import { RemovableValue } from "../../../components/tag/removableValue";
import {
  NetworkResourcesTable,
  NetworkResourcesTableHeaderItem,
  NetworkResourceModalType,
} from "../../../components/networkResources/networkResourcesTable";
import {
  IdentityDraftNativeNetworkResourceMobx,
  IdentityDraftNativeNetworkResourceModel,
} from "../../../mst/stores/identity.draft.nativenetworkresource";
import { NativeResCloudaccountModal } from "./nativeResCloudaccountModal";
import { BrowserServiceModal } from "../create/browserServiceModal";
import { NGButton } from "../../../newcomponents/button/Button";
import { NGRadioGroup } from "../../../newcomponents/radioGroup";
import NGAlert from "../../../newcomponents/alert";
import { NGInput } from "../../../newcomponents/input/input";
import { NGLabel } from "../../../newcomponents/text/label";
import { MoreHorizontal } from "react-feather";
import { IPorPort } from "../../../components/networkResources/ipOrPort";
import { NGFormLabel } from "../../../newcomponents/text/formLabel";
import { Tooltip } from "../../../components/Tooltip";

interface Props {
  identity: IdentityDraftMobx;
}

const NativeNetworkResourcesBaseRaw: React.FC<Props> = ({ identity }) => {
  const awsPrivateLinkLogoPathRef = React.useRef("/resources/logos/awsPrivateLink.png");
  const gcpServiceConnectLogoPathRef = React.useRef("/resources/logos/gcpServiceConnect.png");
  const azurePrivateLinkLogoPathRef = React.useRef("/resources/logos/azurePrivateLink.svg");
  const awsPrivateLinkLogoPath = awsPrivateLinkLogoPathRef.current;
  const gcpServiceConnectLogoPath = gcpServiceConnectLogoPathRef.current;
  const azurePrivateLinkLogoPath = azurePrivateLinkLogoPathRef.current;

  const browse = identity.nativeNetworkResources.browse;
  const genericResourcesTableHeader: NetworkResourcesTableHeaderItem[] = [
    {
      widthProportion: "5/12",
      content: <span>FQDN</span>,
    },
    {
      widthProportion: "4/12",
      content: <span>Network</span>,
    },
    {
      widthProportion: "2/12",
      content: <span>Ports</span>,
    },
  ];

  function isNameConflicting(resource: IdentityDraftNativeNetworkResourceMobx) {
    if (identity.nativeNetworkResourceNames.filter((n) => n === resource.resourceName).length > 1) {
      return true;
    }
    return false;
  }

  function isFqdnConflicting(resource: IdentityDraftNativeNetworkResourceMobx) {
    if (identity.nativeNetworkResourceNames.filter((f) => f === resource.fqdnInput.value).length > 1) {
      return true;
    }
    return false;
  }

  function isResourceValid(resource: IdentityDraftNativeNetworkResourceMobx) {
    let res = resource.isValid;
    if (isNameConflicting(resource)) res = false;
    if (isFqdnConflicting(resource)) res = false;
    return res;
  }

  // Functions //
  function onOpenModalForAddition(setItemToCreate: (resource: IdentityDraftNativeNetworkResourceMobx) => void): void {
    const resource = IdentityDraftNativeNetworkResourceModel.create();
    setItemToCreate(resource);
  }

  function onOpenModalForEdit(
    setItemToCreate: (resource: IdentityDraftNativeNetworkResourceMobx) => void,
    resourceToEdit: IdentityDraftNativeNetworkResourceMobx,
  ): void {
    const newResource = IdentityDraftNativeNetworkResourceModel.create();
    copyResource(resourceToEdit, newResource);
    setItemToCreate(newResource);
  }

  function copyResource(
    sourceResource: IdentityDraftNativeNetworkResourceMobx,
    targetResource: IdentityDraftNativeNetworkResourceMobx,
  ): void {
    targetResource.clear();
    targetResource.setNameInp(sourceResource.resourceName);
    targetResource.fqdnInput.setValue(sourceResource.fqdnInput.value);
    targetResource.setProvider(sourceResource.provider as "aws" | "gcp");
    targetResource.awsPrivateLinkInput.setValue(sourceResource.awsPrivateLinkInput.value);
    targetResource.gcpServiceConnectInput.setValue(sourceResource.gcpServiceConnectInput.value);

    for (const port of sourceResource.ports) {
      targetResource.portInput.setValue(port);
      targetResource.addPort();
    }
  }

  function onConfirmModal(
    modalState: NetworkResourceModalType,
    resource: IdentityDraftNativeNetworkResourceMobx,
    resourceToEdit: IdentityDraftNativeNetworkResourceMobx | undefined,
  ) {
    switch (modalState) {
      case "edit":
        copyResource(resource, resourceToEdit!);
        break;
      default:
        identity.nativeNetworkResources.addPreDefinedResource(resource);
        break;
    }
  }

  function isSearchedItemFound(resource: IdentityDraftNativeNetworkResourceMobx, searchContent: string): boolean {
    return resource.fqdnInput.value.includes(searchContent);
  }

  function buildModal(resource: IdentityDraftNativeNetworkResourceMobx): React.ReactNode {
    const fqdnConflicts = isFqdnConflicting(resource);
    const nameConflicts = isNameConflicting(resource);
    browse.setProvider(resource.provider as any);

    return (
      <div>
        <>
          <NGFormLabel required invalid={fqdnConflicts || !resource.fqdnInput.isValid}>
            {resource.fqdnInput.label}
          </NGFormLabel>
          <div className="mb-2 w-full">
            <NGInput value={resource.fqdnInput.value} onChange={(e) => resource.fqdnInput.setValue(e.target.value)} />
            {fqdnConflicts ? (
              <NGAlert
                className="mt-2"
                message="FQDN must be unique across all types of network resources"
                size="small"
                type={"error"}
              />
            ) : null}
          </div>
        </>
        <>
          <NGFormLabel required invalid={!resource.isResourceNameValid}>
            Name
          </NGFormLabel>
          <div className="w-full mb-2">
            <NGInput
              value={resource.nameInp}
              placeholder={resource.resourceName}
              onChange={(e) => resource.setNameInp(e.target.value)}
            />
            {nameConflicts ? (
              <NGAlert
                className="mt-2"
                message="Name must be unique across all types of network resources"
                type={"error"}
                size={"small"}
              />
            ) : null}
          </div>
        </>
        <NGFormLabel required invalid={resource.ports.length < 1}>
          Ports
        </NGFormLabel>
        {resource.ports.length === 0 ? (
          <NGAlert className="mt-1 mb-2" size="small" message={"At least 1 port is required"} type={"warning"} />
        ) : null}
        <div className={`flex items-center mt-1 ${resource.ports.length > 4 ? "mb-2" : ""}`}>
          {resource.ports.map((port: string) => (
            <RemovableValue key={port} value={port} onRemove={() => resource.removePort(port)} />
          ))}
        </div>
        {resource.ports.length < 5 ? (
          <div className={`flex mb-2`}>
            <NGInput
              className="flex-grow mr-2"
              value={resource.portInput.value}
              onChange={(e) => resource.portInput.setValue(e.target.value)}
            />
            <NGButton
              variant={"action"}
              style={{ width: 150 }}
              onClick={resource.addPort}
              disabled={!resource.canAddPort}
            >
              Add Port
            </NGButton>
          </div>
        ) : null}
        <NGLabel>Cloud Provider</NGLabel>
        <NGRadioGroup
          className="my-2"
          options={[
            {
              value: "aws",
              label: (
                <div className="flex items-center gap-1">
                  <img style={{ width: 20, height: 20 }} src={awsPrivateLinkLogoPath} />
                  <span>AWS Private Link</span>
                </div>
              ),
              labelString: "AWS Private Link",
            },
            {
              value: "gcp",
              label: (
                <div className="flex items-center gap-1">
                  <img style={{ width: 22, height: 22 }} src={gcpServiceConnectLogoPath} />
                  <span>GCP Service Connect</span>
                </div>
              ),
              labelString: "GCP Service Connect",
            },
            {
              value: "azure",
              label: (
                <div className="flex items-center gap-1">
                  <img style={{ width: 20, height: 20 }} src={azurePrivateLinkLogoPath} />
                  <span>Azure Private Link</span>
                </div>
              ),
              labelString: "Azure Private Link",
              isDisabled: true,
            },
          ]}
          value={browse.provider}
          onChange={(date) => {
            browse.setProvider(date as "gcp" | "aws");
            resource.setProvider(date as "gcp" | "aws");
          }}
        />

        {resource.provider === "aws" ? (
          <>
            <NGInput
              className="w-full"
              value={resource.awsPrivateLinkInput.value}
              onChange={(e) => resource.awsPrivateLinkInput.setValue(e.target.value)}
              placeholder="AWS Private Link"
              buttons={[
                {
                  title: "Browse AWS Endpoint Services",
                  onClick: () => {
                    browse.setProvider(resource.provider as "aws");
                    browse.setPageAndIndex("cloudaccount");
                  },
                  render: () => <MoreHorizontal className="h-4" />,
                },
              ]}
            />
          </>
        ) : resource.provider === "gcp" ? (
          <>
            <NGInput
              className="w-full"
              value={resource.gcpServiceConnectInput.value}
              onChange={(e) => resource.gcpServiceConnectInput.setValue(e.target.value)}
              placeholder="GCP Service Connect"
              buttons={[
                {
                  title: "Browse GCP Service Connect",
                  onClick: () => {
                    browse.setProvider(resource.provider as "gcp");
                    browse.setPageAndIndex("cloudaccount");
                  },
                  render: () => <MoreHorizontal className="h-4" />,
                },
              ]}
            />
          </>
        ) : null}
        {browse.page === "none" ? null : browse.page === "cloudaccount" ? (
          <NativeResCloudaccountModal browse={browse} />
        ) : browse.page === "item" ? (
          <BrowserServiceModal
            title={`Select ${
              browse.provider === "aws" ? "Endpoint Service" : browse.provider === "gcp" ? "Target Service" : ""
            }`}
            restrictToVPCEndpointService={true}
            dataTypeRestrictions={
              browse.provider === "aws" ? ["/vpces"] : browse.provider === "gcp" ? ["/serviceAttachment"] : []
            }
            onClose={(uri) => {
              const value = uri.length > 0 ? uri[0]._cpln.ref : "";
              if (value && browse.provider === "aws") {
                resource.awsPrivateLinkInput.setValue(value);
              }
              if (value && browse.provider === "gcp") {
                resource.gcpServiceConnectInput.setValue(value);
              }
              browse.done();
            }}
            selectionType={"single"}
            browserService={browse.browserService}
          />
        ) : null}
      </div>
    );
  }

  function buildItem(resource: IdentityDraftNativeNetworkResourceMobx, ـ: number): React.ReactNode {
    return (
      <>
        <div className="w-5/12 pr-2">
          <Tooltip title={resource.fqdnInput.value} open={resource.fqdnInput.value.length > 16}>
            <div className="truncate pr-2">{resource.fqdnInput.value}</div>
          </Tooltip>
        </div>
        {resource.provider === "aws" ? (
          <div className="w-4/12 break-all pr-4">{resource.awsPrivateLinkInput.value}</div>
        ) : null}
        {resource.provider === "gcp" ? (
          <div className="w-4/12 break-all pr-4">{resource.gcpServiceConnectInput.value}</div>
        ) : null}
        <div className={`w-2/12 flex flex-wrap items-center pr-2 gap-2`}>
          {resource.ports.map((port) => (
            <IPorPort item={port} />
          ))}
        </div>
      </>
    );
  }

  function onItemRemoval(resource: IdentityDraftNativeNetworkResourceMobx): void {
    identity.nativeNetworkResources.removeByResource(resource);
  }

  return (
    <NetworkResourcesTable
      typeName="Native Networking"
      filterPlaceholder="Search by FQDN"
      header={genericResourcesTableHeader}
      items={identity.nativeNetworkResources.resources}
      onOpenModalForAddition={onOpenModalForAddition}
      onOpenModalForEdit={onOpenModalForEdit}
      onConfirmModal={onConfirmModal}
      // isOkButtonDisabled={(resource: IdentityDraftNativeNetworkResourceMobx) => !isResourceValid(resource)}
      isOkButtonDisabled={(resource: IdentityDraftNativeNetworkResourceMobx) => false}
      isSearchedItemFound={isSearchedItemFound}
      buildModal={buildModal}
      buildItem={buildItem}
      onItemRemoval={onItemRemoval}
    />
  );
};

export const NativeNetworkResourcesBase = observer(NativeNetworkResourcesBaseRaw);
