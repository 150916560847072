import { types, Instance } from "mobx-state-tree";
import { IdentityNativeNetworkResource } from "../kinds/identity";
import { StringModel } from "../../mobxDataModels/stringModel";
import { PortModel } from "../../mobxDataModels/portModel";
import { defaultValues } from "../base";
import { inputValidations } from "../../mobxDataModels/validations";

export const IdentityDraftNativeNetworkResourceModel = types
  .model({
    _nameInp: types.optional(types.string, ""),
    nameInp: types.optional(types.string, ""),
    provider: types.optional(types.enumeration(["aws", "gcp"]), defaultValues.nativeNetworkResource.provider as any),
    fqdnInput: types.optional(StringModel, () =>
      StringModel.create({
        label: "FQDN",
        isRequired: true,
        validationKey: "domainWithoutPattern",
        transformKey: "lowerCase",
      }),
    ),
    ports: types.array(types.string),
    portInput: types.optional(StringModel, () => PortModel.create({ label: "Port", min: 0, max: 65535 })),
    awsPrivateLinkInput: types.optional(StringModel, () =>
      StringModel.create({
        label: "Endpoint Service Name",
        isRequired: true,
        // validationKey: "awsPrivateLinkEndpointServiceName",
      }),
    ),
    gcpServiceConnectInput: types.optional(StringModel, () =>
      StringModel.create({
        label: "Target Service",
        isRequired: true,
        // validationKey: "gcpServiceConnectTargetService",
      }),
    ),
  })
  .actions((self) => ({
    setNameInp(value: string) {
      self.nameInp = value;
    },
  }))
  .views((self) => ({
    get canAddPort() {
      if (self.ports.length > 4) return false;
      if (self.portInput.value.length < 1) return false;
      if (!self.portInput.isValid) return false;
      const alreadyAddedPort = self.ports.find((port) => port === self.portInput.value);
      if (alreadyAddedPort) return false;
      return true;
    },
    get fqdnSlug() {
      try {
        return self.fqdnInput.value.replaceAll(".", "-");
      } catch (e) {
        return "";
      }
    },
  }))
  .views((self) => ({
    get resourceName() {
      return self.nameInp || self.fqdnSlug;
    },
  }))
  .views((self) => ({
    get isResourceNameValid() {
      if (!self.resourceName) {
        return false;
      }
      for (const validation of inputValidations.identityNetworkResourceServiceName) {
        const validationResult = validation("value", self.resourceName);
        if (validationResult !== true) {
          return false;
        }
      }
      return true;
    },
  }))
  .actions((self) => ({
    clear() {
      self.provider = defaultValues.nativeNetworkResource.provider as any;
      self.nameInp = self._nameInp;
      self.fqdnInput.setInitialValue("");
      self.fqdnInput.reset();
      self.ports.clear();
      self.portInput.setInitialValue("");
      self.portInput.reset();
      self.awsPrivateLinkInput.setInitialValue("");
      self.awsPrivateLinkInput.reset();
    },
    setProvider(value: "aws" | "gcp") {
      self.provider = value;
    },
    addPort() {
      if (!self.canAddPort) return;
      self.ports.push(self.portInput.value);
      self.portInput.reset();
    },
    removePort(value: string) {
      const port = self.ports.find((port) => port === value);
      if (port) {
        self.ports.remove(port);
      }
    },
  }))
  .actions((self) => ({
    apply(resource: IdentityNativeNetworkResource) {
      self.clear();
      self._nameInp = resource.name;
      self.nameInp = resource.name;
      self.fqdnInput.setInitialValue(resource.FQDN);
      for (let port of resource.ports) {
        self.ports.push(String(port));
      }
      if (resource.awsPrivateLink && resource.awsPrivateLink.endpointServiceName) {
        self.setProvider("aws");
      } else if (resource.gcpServiceConnect && resource.gcpServiceConnect.targetService) {
        self.setProvider("gcp");
      }
      if (self.provider === "aws") {
        self.awsPrivateLinkInput.setInitialValue(resource.awsPrivateLink!.endpointServiceName!);
      }
      if (self.provider === "gcp") {
        self.gcpServiceConnectInput.setInitialValue(resource.gcpServiceConnect!.targetService!);
      }
    },
  }))
  .views((self) => ({
    get isValid() {
      let res = true;
      if (!self.fqdnInput.isValid) res = false;
      if (!self.isResourceNameValid) res = false;
      if (self.ports.length < 1) res = false;
      if (self.ports.length > 5) res = false;
      if (self.portInput.value.length > 0) res = false;
      if (self.provider === "aws") {
        if (!self.awsPrivateLinkInput.isValid) res = false;
      }
      if (self.provider === "gcp") {
        if (!self.gcpServiceConnectInput.isValid) res = false;
      }
      return res;
    },
    get asObject(): IdentityNativeNetworkResource {
      return {
        name: self.resourceName,
        FQDN: self.fqdnInput.value,
        ports: [...self.ports]
          .slice()
          .sort()
          .map((p) => Number(p)),
        awsPrivateLink: self.provider === "aws" ? { endpointServiceName: self.awsPrivateLinkInput.value } : undefined,
        gcpServiceConnect: self.provider === "gcp" ? { targetService: self.gcpServiceConnectInput.value } : undefined,
      };
    },
  }));

export interface IdentityDraftNativeNetworkResourceMobx
  extends Instance<typeof IdentityDraftNativeNetworkResourceModel> {}
