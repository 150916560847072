import * as React from "react";
import { observer } from "mobx-react-lite";
import { Tooltip } from "../Tooltip";
import { TooltipProps as AntTooltipProps } from "antd";
import { ExternalLink, Info } from "react-feather";

interface Props {
  style?: any;
  className?: string;
}

const InfoTooltipRaw: React.FC<Props & AntTooltipProps> = ({ title: titleRaw, ...props }) => {
  const title: React.ReactNode = React.useMemo(() => {
    if (!Array.isArray(titleRaw)) {
      return titleRaw as any;
    }

    const contents: React.ReactNode[] = [];

    for (const contentIndexStr in titleRaw) {
      const contentIndex = Number(contentIndexStr);
      const content = titleRaw[contentIndex];
      if (content === "Custom_Memory_1") {
        contents.push(
          <div key={contentIndex}>
            For all available memory units, refer to{" "}
            <a
              href={`${process.env.REACT_APP_DOCS}/reference/workload#resources`}
              target={"_blank"}
              className="color-link"
            >
              this page
              <ExternalLink className="feather-icon inline-block ml-1" style={{ transform: "translateY(2px)" }} />
            </a>
            .
          </div>,
        );
      } else {
        contents.push(content);
      }
    }

    return contents;
  }, [titleRaw]);

  return (
    <Tooltip title={title} {...props}>
      <Info className={`feather-icon ${props.className}`} style={{ marginLeft: 10, ...props.style }} />
    </Tooltip>
  );
};

export const InfoTooltip = observer(InfoTooltipRaw);
