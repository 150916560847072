import { types, Instance, getParent } from "mobx-state-tree";
import { SelectModel } from "../../mobxDataModels/selectModel";
import { arraysAreEqual, arraysAreEqualInOrder, arraysAreEqualLodash } from "../../services/cpln";
import { DomainPortRoute, DomainPortRouteModel } from "./domain.draft.port.route";
import { DomainPortTLS, DomainPortTLSModel } from "./domain.draft.port.tls";
import { DomainPortCors, DomainPortCorsModel } from "./domain.draft.port.cors";
import { DomainDraftMobx, InvalidReason } from "./domain.draft";
import { defaultValues } from "../base";
import { convertCiphers } from "../kinds/domain/ciphers";
import { v4 as uuidv4 } from "uuid";
import { ListOfItemsModel } from "../../mobxDataModels/listOfItemsModel";
import { ngParseLink } from "../../utils/linkParser/linkParser";

export const DomainDraftPortModel = types
  .model({
    id: types.optional(types.string, () => uuidv4()),
    _isCollapsed: types.optional(types.boolean, false),
    _tab: types.optional(types.enumeration(["info", "cors", "tls", "routes"]), "info"),
    _number: types.optional(types.number, 443),
    _protocol: types.optional(types.string, "http2"),
    _routes: types.array(types.frozen()), // max 10
    _cors: types.maybe(types.frozen()),
    _configureCors: types.optional(types.boolean, false),
    _configureTLS: types.optional(types.boolean, false),
    _tls: types.maybe(types.frozen()),
    number: types.optional(SelectModel, () =>
      SelectModel.create({
        label: "Number",
        initialValue: defaultValues.domain.port.number.options[0].toString(),
        options: defaultValues.domain.port.number.options.map((n) => ({
          label: String(n),
          value: String(n),
        })),
      }),
    ),
    protocol: types.optional(SelectModel, () =>
      SelectModel.create({
        label: "Protocol",
        initialValue: defaultValues.domain.port.protocol.options[0].toString(),
        options: defaultValues.domain.port.protocol.options.map((p) => ({ label: p.toUpperCase(), value: p })),
      }),
    ),
    routes: types.array(DomainPortRouteModel),
    cors: types.maybe(DomainPortCorsModel),
    configureCors: types.optional(types.boolean, false),
    configureTLS: types.optional(types.boolean, false),
    tls: types.optional(DomainPortTLSModel, () => DomainPortTLSModel.create()),
    resetCounter: types.optional(types.number, 0),
  })
  .actions((self) => ({
    toggleCollapse() {
      self._isCollapsed = !self._isCollapsed;
    },
    setTab(value: "info" | "cors" | "tls" | "routes") {
      self._tab = value;
    },
    reset() {
      self.number.setInitialValue(String(self._number));
      self.protocol.setInitialValue(String(self._protocol));
      self.routes.clear();
      for (let _route of self._routes) {
        const routeInstance = DomainPortRouteModel.create();
        routeInstance.method.setInitialValue(_route.regex ? "regex" : "prefix");
        routeInstance.regex.setInitialValue(_route.regex || "");
        routeInstance.prefix.setInitialValue(_route.prefix || "");
        const { name: workloadName } = ngParseLink(_route.workloadLink, { kind: "workload" });
        routeInstance.setWorkloadName(workloadName);
        routeInstance.replacePrefix.setInitialValue(_route.replacePrefix || "");
        routeInstance.hostPrefix.setInitialValue(_route.hostPrefix || "");
        routeInstance.portInput.setInitialValue(_route.port ? String(_route.port) : "");
        routeInstance.replicaInput.setInitialValue(_route.replica !== undefined ? String(_route.replica) : "");
        routeInstance.headersRequestSet.setInitialItems(
          Object.entries(_route.headers?.request?.set || {}).map(([key, value]) => ({
            firstValue: key,
            secondValue: value,
          })),
        );
        routeInstance.headersRequestSet.reset();
        self.routes.push(routeInstance);
      }

      // CORS

      self.cors = undefined;
      if (self._cors) {
        self._configureCors = true;
        self.cors = DomainPortCorsModel.create({
          allowOrigins: ListOfItemsModel.create({
            _items: (self._cors.allowOrigins || []).map((i: any) => ({ firstValue: i.exact })),
          }),
          allowHeaders: ListOfItemsModel.create({
            _items: (self._cors.allowHeaders || []).map((i: any) => ({ firstValue: i })),
          }),
          allowMethods: ListOfItemsModel.create({
            _items: (self._cors.allowMethods || []).map((i: any) => ({ firstValue: i })),
          }),
          exposeHeaders: ListOfItemsModel.create({
            _items: (self._cors.exposeHeaders || []).map((i: any) => ({ firstValue: i })),
          }),
          allowCredentials: !!self._cors.allowCredentials,
        });
        self.cors.setMaxAge(self._cors.maxAge);
      } else {
        self._configureCors = false;
      }
      self.configureCors = self._configureCors;

      // TLS
      if (self._tls) {
        self._configureTLS = true;
        self.tls = DomainPortTLSModel.create({
          _forwardClientCertificate: !!self._tls.clientCertificate,
          forwardClientCertificate: !!self._tls.clientCertificate,
          _useCustomServerCertificate: !!self._tls.serverCertificate,
          useCustomServerCertificate: !!self._tls.serverCertificate,
          cipherSuites: ListOfItemsModel.create({
            _items: convertCiphers(self._tls.cipherSuites || []).map((v) => ({ firstValue: v })),
          }),
        });
        self.tls.minProtocolVersion.setInitialValue(self._tls.minProtocolVersion);
      } else {
        self._configureTLS = false;
        self.tls = DomainPortTLSModel.create();
      }
      if (self._configureTLS) {
        if (self._tls.clientCertificate && self._tls.clientCertificate.secretLink) {
          const { name: clientCertificateSecretName } = ngParseLink(self._tls.clientCertificate.secretLink, {
            kind: "secret",
          });
          self.tls.clientCertificateSecretName = clientCertificateSecretName;
        } else {
          self.tls.clientCertificateSecretName = "";
        }
        if (self._tls.serverCertificate && self._tls.serverCertificate.secretLink) {
          const { name: serverCertificateSecretName } = ngParseLink(self._tls.serverCertificate.secretLink, {
            kind: "secret",
          });
          self.tls.serverCertificateSecretName = serverCertificateSecretName;
        } else {
          self.tls.serverCertificateSecretName = "";
        }
      }
      self.configureTLS = self._configureTLS;

      self.resetCounter += 1;
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.reset();
    },
    confirm() {
      self._number = Number(self.number.value);
      self.number.confirm();
      self._protocol = self.protocol.value;
      self.protocol.confirm();
      self._routes.clear();
      for (let newRoute of self.routes.map((r) => r.asObject)) {
        self._routes.push(newRoute);
      }
      self._configureCors = self.configureCors;
      if (self.configureCors) {
        self._cors = self.cors?.asObject;
      } else {
        self._cors = undefined;
      }

      // @ts-ignore
      self._configureTLS = self.configureTLSGet;
      // @ts-ignore
      if (self.configureTLSGet && self.tls) {
        const tls = JSON.parse(JSON.stringify(self.tls.asObject));
        // @ts-ignore
        if (!self.useCustomServerCertificateRequired && !self.tls.useCustomServerCertificate) {
          delete tls.serverCertificate;
        }
        self._tls = tls;
      } else {
        self._tls = undefined;
      }

      self.resetCounter += 1;
    },
    setConfigureCors(value: boolean) {
      self.configureCors = value;
      if (value && !self.cors) {
        self.cors = DomainPortCorsModel.create();
      }
    },
    setConfigureTLS(value: boolean) {
      self.configureTLS = value;
      if (value && !self.tls) {
        self.tls = DomainPortTLSModel.create();
      }
    },
    addRoute() {
      self.routes.push(DomainPortRouteModel.create());
      return;
    },
    removeRoute(index: string) {
      const route = self.routes.find((r) => r.id === index);
      if (route) {
        self.routes.remove(route);
        return;
      }
    },
  }))
  .actions((self) => ({
    setNumber(value: string) {
      self.number.setValue(value);
    },
  }))
  .views((self) => ({
    get useCustomServerCertificateRequired(): boolean {
      const parent: DomainDraftMobx = getParent(self, 2);
      if (parent.dnsMode === "cname" && parent.routingMode === "subdomainBased") {
        return true;
      }
      return false;
    },
    get hasPrefixConflict() {
      const prefixes: string[] = [];
      for (let route of self.routes) {
        if (route.method.value === "prefix" && prefixes.includes(route.prefix.value)) {
          return true;
        }
        prefixes.push(route.prefix.value);
      }
      return false;
    },
  }))
  .views((self) => ({
    get configureTLSRequired(): boolean {
      const isRequired = Number(self.number.value) === 443 && self.protocol.value !== "tcp";
      return isRequired || self.useCustomServerCertificateRequired;
    },
  }))
  .views((self) => ({
    get configureTLSGet() {
      return self.configureTLS || self.configureTLSRequired;
    },
    get isInfoValid() {
      return true;
    },
    get isCORSValid() {
      let res = true;
      if (self.configureCors && self.cors && !self.cors.isValid) {
        res = false;
      }
      return res;
    },
    get corsInvalidReason(): InvalidReason {
      if (self.configureCors && self.cors && !self.cors.isValid) {
        return self.cors.invalidReason;
      }
      return { code: 1, type: "info", message: "" };
    },
    get isTLSValid() {
      let res = true;
      if (self.tls.useCustomServerCertificate || self.useCustomServerCertificateRequired) {
        if (!self.tls.serverCertificateSecretName) {
          res = false;
        }
      }
      return res;
    },
    get tlsInvalidReason(): InvalidReason {
      if (self.tls.useCustomServerCertificate || self.useCustomServerCertificateRequired) {
        if (!self.tls.serverCertificateSecretName) {
          return { code: 1, type: "error", message: "A Secret Link for the TLS Server Certificate is required." };
        }
      }
      return { code: 0, type: "info", message: "" };
    },
    get isRoutesValid() {
      let res = true;
      if (self.routes.length < 1) {
        res = false;
      }
      for (let route of self.routes) {
        if (!route.isValid) {
          res = false;
        }
      }
      return res;
    },
    get routesInvalidReason(): InvalidReason {
      if (self.routes.length < 1) {
        return { code: 5, type: "error", message: "At least 1 route needs to be set." };
      }
      for (let route of self.routes) {
        if (!route.isValid) {
          return route.invalidReason;
        }
      }
      return { code: 0, type: "info", message: "" };
    },
  }))
  .views((self) => ({
    get isDirty() {
      let res = false;
      if (String(self._number) !== self.number.value) {
        res = true;
      }
      if (self._protocol !== self.protocol.value) {
        res = true;
      }
      const parent: DomainDraftMobx = getParent(self, 2);
      if (parent.routingMode === "pathBased") {
        if (self._routes.length !== self.routes.length) {
          res = true;
        }
        if (
          !arraysAreEqualInOrder(
            JSON.parse(JSON.stringify(self._routes)),
            self.routes.map((r) => r.asObject),
          )
        ) {
          res = true;
        }
      }

      if (self._configureCors !== self.configureCors) {
        res = true;
      }
      if (self.configureCors) {
        if (isCORSDifferent(self._cors, self.cors?.asObject)) {
          res = true;
        }
      }

      if (self._configureTLS !== self.configureTLSGet) {
        res = true;
      }
      if (self.configureTLSGet) {
        const tls = JSON.parse(JSON.stringify(self.tls.asObject));
        if (!self.useCustomServerCertificateRequired && !self.tls.useCustomServerCertificate) {
          delete tls.serverCertificate;
        }
        if (isTLSDifferent(self._tls, tls)) {
          res = true;
        }
      }

      return res;
    },
    get dirtyReason() {
      let res = false;
      if (String(self._number) !== self.number.value) {
        return "number";
      }
      if (self._protocol !== self.protocol.value) {
        return "procotol";
      }
      const parent: DomainDraftMobx = getParent(self, 2);
      if (parent.routingMode === "pathBased") {
        if (self._routes.length !== self.routes.length) {
          return "routes length";
        }
        if (
          !arraysAreEqualLodash(
            JSON.parse(JSON.stringify(self._routes)),
            self.routes.map((r) => r.asObject),
          )
        ) {
          return `route array content
Old:
${JSON.stringify(self._routes, null, 2)}
New:
${JSON.stringify(
  self.routes.map((r) => r.asObject),
  null,
  2,
)}
`;
        }
      }

      if (self._configureCors !== self.configureCors) {
        return "configure cors";
      }
      if (self.configureCors) {
        if (isCORSDifferent(self._cors, self.cors?.asObject)) {
          return "cors obj different";
        }
      }

      if (self._configureTLS !== self.configureTLSGet) {
        return "configure tls";
      }
      if (self.configureTLSGet) {
        const tls = JSON.parse(JSON.stringify(self.tls.asObject));
        if (!self.useCustomServerCertificateRequired && !self.tls.useCustomServerCertificate) {
          delete tls.serverCertificate;
        }
        if (isTLSDifferent(self._tls, tls)) {
          return "tls - " + isTLSDifferentReason(self._tls, tls);
        }
      }

      return res;
    },
    get isValid() {
      let res = true;
      const domainDraft: DomainDraftMobx = getParent(self, 2);
      if (domainDraft.routingMode === "pathBased" && !self.isRoutesValid) {
        res = false;
      }
      if (!self.isCORSValid) {
        res = false;
      }
      if (!self.isTLSValid) {
        res = false;
      }
      if (self.hasPrefixConflict) {
        res = false;
      }
      return res;
    },
    get invalidReason(): InvalidReason {
      const domainDraft: DomainDraftMobx = getParent(self, 2);
      if (domainDraft.routingMode === "pathBased" && !self.isRoutesValid) {
        return self.routesInvalidReason;
      }
      if (!self.isCORSValid) {
        return self.corsInvalidReason;
      }
      if (!self.isTLSValid) {
        return self.tlsInvalidReason;
      }
      if (self.hasPrefixConflict) {
        return { code: 1, type: "error", message: "Prefix cannot be the same" };
      }
      return { code: 0, type: "info", message: "" };
    },
    get asObject() {
      const res: any = {
        number: Number(self.number.value),
        protocol: self.protocol.value,
      };
      if (self.routes.length > 0) {
        res.routes = self.routes.map((r) => r.asObject);
      }
      if (self.configureCors && self.cors) {
        res.cors = self.cors.asObject;
      }
      if (self.configureTLSGet && self.tls) {
        const tls = JSON.parse(JSON.stringify(self.tls.asObject));
        if (!self.useCustomServerCertificateRequired && !self.tls.useCustomServerCertificate) {
          delete tls.serverCertificate;
        }
        res.tls = tls;
      }
      return res;
    },
  }));
export interface DomainDraftPortMobx extends Instance<typeof DomainDraftPortModel> {}
export interface DomainDraftPort {
  number: number;
  protocol: "http2" | "http";
  routes?: DomainPortRoute[];
  cors?: DomainPortCors;
  tls?: DomainPortTLS;
}

function isCORSDifferent(a?: DomainPortCors, b?: DomainPortCors) {
  let res = false;
  if (!!a !== !!b) {
    res = true;
  }
  if (a && b) {
    if (
      !arraysAreEqual(
        a.allowOrigins.map((a) => a.exact),
        b.allowOrigins.map((a) => a.exact),
      )
    ) {
      res = true;
    }
    if (!arraysAreEqual(a.allowMethods, b.allowMethods)) {
      res = true;
    }
    if (!arraysAreEqual(a.allowHeaders, b.allowHeaders)) {
      res = true;
    }
    if (!arraysAreEqual(a.exposeHeaders, b.exposeHeaders)) {
      res = true;
    }
    if (!!a.allowCredentials !== !!b.allowCredentials) {
      res = true;
    }
    if (a.maxAge !== b.maxAge) {
      res = true;
    }
  }
  return res;
}

function isTLSDifferent(a?: DomainPortTLS, b?: DomainPortTLS) {
  let res = false;
  if (!!a !== !!b) {
    res = true;
  }
  if (a && b) {
    if (a.minProtocolVersion !== b.minProtocolVersion) {
      res = true;
    }
    if (!arraysAreEqual(a.cipherSuites, b.cipherSuites)) {
      res = true;
    }
    if (!!a.clientCertificate !== !!b.clientCertificate) {
      res = true;
    }
    if (a.clientCertificate && b.clientCertificate) {
      if (
        (!!a.clientCertificate.secretLink || !!b.clientCertificate.secretLink) &&
        a.clientCertificate.secretLink !== b.clientCertificate.secretLink
      ) {
        res = true;
      }
    }
    if (!!a.serverCertificate !== !!b.serverCertificate) {
      res = true;
    }
    if (a.serverCertificate && b.serverCertificate) {
      if (
        (!!a.serverCertificate.secretLink || !!b.serverCertificate.secretLink) &&
        a.serverCertificate.secretLink !== b.serverCertificate.secretLink
      ) {
        res = true;
      }
    }
  }
  return res;
}

function isTLSDifferentReason(a?: DomainPortTLS, b?: DomainPortTLS) {
  let res = "";
  if (!!a !== !!b) {
    return "one tls doesnt exist";
  }
  if (a && b) {
    if (a.minProtocolVersion !== b.minProtocolVersion) {
      return "min protocol";
    }
    if (!arraysAreEqual(a.cipherSuites, b.cipherSuites)) {
      return "cipher suites array not equal";
    }
    if (!!a.clientCertificate !== !!b.clientCertificate) {
      return "client certificate";
    }
    if (a.clientCertificate && b.clientCertificate) {
      if (
        (!!a.clientCertificate.secretLink || !!b.clientCertificate.secretLink) &&
        a.clientCertificate.secretLink !== b.clientCertificate.secretLink
      ) {
        return "client certificate secret link";
      }
    }
    if (!!a.serverCertificate !== !!b.serverCertificate) {
      return "server cert";
    }
    if (a.serverCertificate && b.serverCertificate) {
      if (
        (!!a.serverCertificate.secretLink || !!b.serverCertificate.secretLink) &&
        a.serverCertificate.secretLink !== b.serverCertificate.secretLink
      ) {
        return "server cert secret link - " + a.serverCertificate.secretLink + " - " + b.serverCertificate.secretLink;
      }
    }
  }
  return res;
}
