import * as React from "react";
import { Route, Navigate, useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { DetailLayout } from "../../components/detail/layout";
import { Info } from "./info";
import { Firewall } from "./firewall";
import { DeploymentTable } from "../../components/table/deployment-table";
import { WorkloadMobx } from "../../mst/kinds/workload";
import { WorkloadDraftMobx } from "../../mst/stores/workload.draft";
import { ContainerDetail } from "./container/detail";
import { Identity } from "./identity";
import { WorkloadLogs } from "./logs";
import { AccessReport } from "../../components/detail/accessReport";
import { Options } from "./options";
import { DetailNavLink } from "../../components/detail/nav";
import { WorkloadConnect } from "./connect";
import { Job } from "./job";
import { JobExecutions } from "./jobExecutions";
import { RolloutOptions } from "./rolloutOptions";
import { AuditTrail } from "../../components/detail/auditTrail";
import { useDetailContext } from "../../components/detail/detailContext";
import { StatefulDeployments } from "./statefulDeployments/deployments";
import { StatefulReplica } from "./statefulDeployments/replica";
import { SecurityOptions } from "./securityOptions";
import { BasePathContext, useBasePath } from "../../reactContexts/basePathContext";
import { Auth } from "../../components/detail/auth";
import { OrgMobx } from "../../mst/kinds/org";
import { Commands } from "../../components/detail/commands";
import { IdentityMobx } from "../../mst/kinds/identity";
import { useEventlogs } from "../../reactHooks/useEventlog";
import { Events } from "../../components/detail/events";
import { Deployment } from "../../schema/types/workload/deployment";
import { workloadHelpers } from "../../mst/kinds/workload.helpers";
import { Tags } from "../../components/detail/tags";
import { LoadBalancer } from "./loadBalancer";
import { User } from "../../mobxStores/user/user";
import { Extras } from "./extras";
import { RequestRetryPolicy } from "./requestRetryPolicy";

interface Props {
  org?: OrgMobx;
  identityMobx: IdentityMobx | null;
  workloadMobx: WorkloadMobx;
  workloadDraft: WorkloadDraftMobx;
  deployments: Deployment[];
  gvcLocationNames: string[];
  gvcEnv: { name: string; value: string }[];
  hasDedicatedLB: boolean;
  startDraft: (w: WorkloadMobx) => Promise<void>;
}
const WorkloadDetailRaw: React.FC<Props> = ({
  org,
  identityMobx,
  workloadMobx,
  deployments,
  workloadDraft,
  gvcLocationNames,
  gvcEnv,
  hasDedicatedLB,
  startDraft,
}) => {
  const basePath = useBasePath("/gvc/:gvc/workload/:workload/*");
  const { pathname } = useLocation();
  const { fetchItem } = useDetailContext();
  const health = workloadHelpers.getHealth(deployments, workloadMobx as any);

  // Events start
  const eventlogHook = useEventlogs(workloadMobx.selfLink);

  React.useEffect(() => {
    eventlogHook.setItemLastModified(workloadMobx.lastModified);
  }, [workloadMobx.lastModified]);

  React.useEffect(() => {
    if (pathname.includes("/-events")) {
      return;
    }

    const id = setInterval(() => eventlogHook.fetchEventlogs(), 30 * 1000);

    return () => {
      if (id) {
        clearTimeout(id);
      }
    };
  }, [pathname]);
  // Events end

  const showIdentityAlertIcon = workloadDraft.hasAnyCloudVolume && !workloadDraft.identityLink;
  //  ||
  // identityMobx?.status?.aws?.usable === false ||
  // identityMobx?.status?.azure?.usable === false ||
  // identityMobx?.status?.gcp?.usable === false;

  const links: DetailNavLink[] = [
    { label: "Info", url: "-info" },
    { label: "Options", url: "-options" },
    workloadDraft.isStateful || workloadDraft.isStandard ? { label: "Rollout Options", url: "-rollout" } : undefined,
    { label: "Security Options", url: "-security" },
    { label: "Firewall", url: "-firewall" },
    { label: "Auth", url: "-auth" },
    { label: "Load Balancer", url: "-loadbalancer" },
    workloadDraft.isCron ? { label: "Job", url: "-job" } : undefined,
    workloadDraft.isCron
      ? {
          label: "Job Executions",
          url: "-jobexecutions",
        }
      : undefined,
    {
      label: "Identity",
      url: "-identity",
      icon: {
        isActive: showIdentityAlertIcon,
        class: "alertCircle",
        color: "color-danger",
      },
    },
    { label: "Containers", url: `container` },
  ].filter(Boolean) as DetailNavLink[];

  if (workloadDraft.container1.name.initialValue) {
    links.push({
      label: workloadDraft.container1.name.initialValue,
      url: `container/${workloadDraft.container1.name.initialValue}`,
      sub: true,
    });
  }

  if (workloadDraft.container2 && workloadDraft.container2.name.initialValue) {
    links.push({
      label: workloadDraft.container2.name.initialValue,
      url: `container/${workloadDraft.container2.name.initialValue}`,
      sub: true,
    });
  }
  if (workloadDraft.container3 && workloadDraft.container3.name.initialValue) {
    links.push({
      label: workloadDraft.container3.name.initialValue,
      url: `container/${workloadDraft.container3.name.initialValue}`,
      sub: true,
    });
  }
  if (workloadDraft.container4 && workloadDraft.container4.name.initialValue) {
    links.push({
      label: workloadDraft.container4.name.initialValue,
      url: `container/${workloadDraft.container4.name.initialValue}`,
      sub: true,
    });
  }
  if (workloadDraft.container5 && workloadDraft.container5.name.initialValue) {
    links.push({
      label: workloadDraft.container5.name.initialValue,
      url: `container/${workloadDraft.container5.name.initialValue}`,
      sub: true,
    });
  }
  if (workloadDraft.container6 && workloadDraft.container6.name.initialValue) {
    links.push({
      label: workloadDraft.container6.name.initialValue,
      url: `container/${workloadDraft.container6.name.initialValue}`,
      sub: true,
    });
  }
  if (workloadDraft.container7 && workloadDraft.container7.name.initialValue) {
    links.push({
      label: workloadDraft.container7.name.initialValue,
      url: `container/${workloadDraft.container7.name.initialValue}`,
      sub: true,
    });
  }
  if (workloadDraft.container8 && workloadDraft.container8.name.initialValue) {
    links.push({
      label: workloadDraft.container8.name.initialValue,
      url: `container/${workloadDraft.container8.name.initialValue}`,
      sub: true,
    });
  } else {
    links.push({
      label: "Add",
      url: "container/-new",
      sub: true,
      icon: { color: "color-action", class: "plusCircle", isActive: true },
    });
  }

  if (!workloadDraft.isCron) {
    links.push({
      label: "Deployments",
      url: "deployment",
      icon: {
        isActive: !health.isReady || (!health.isLatestReady && workloadMobx.spec.type !== "stateful"),
        class: "alertCircle",
        color: !health.isReady ? "color-danger" : "color-warning",
      },
    });
  }

  links.push({ label: "Tags", url: "-tags" });
  links.push({ label: "Extras", url: `-extras` });
  links.push({ label: "Request Retries", url: `-request-retries` });
  links.push({ label: "Metrics", url: "-metrics-workload" });
  if (org?.spec?.tracing?.provider?.controlplane) {
    links.push({ label: "Traces", url: "-traces-workload" });
  }
  links.push({
    label: "Events",
    url: "-events",
    icon: {
      isActive: eventlogHook.hasWarning && !health.isReady,
      class: "alertCircle",
      color: "color-warning",
    },
  });
  links.push({ label: "Audit Trail", url: `-audittrail` });
  links.push({ label: "Logs", url: "-logs" });
  links.push({ label: "Connect", url: "-connect" });
  links.push({ label: "Access Report", url: "-accessreport" });
  if (localStorage.getItem("CPLN_DEBUG") === "true") {
    links.push({ label: "Commands", url: "-commands" });
  }

  return (
    <BasePathContext.Provider value={basePath}>
      <DetailLayout links={links} deployments={deployments}>
        <Route
          path={`-info`}
          element={
            <Info
              workload={workloadMobx}
              workloadDraft={workloadDraft}
              hasDedicatedLB={hasDedicatedLB}
              deployments={deployments}
            />
          }
        />
        <Route
          path={`-options/*`}
          element={<Options gvcLocationNames={gvcLocationNames} draft={workloadDraft} patch={workloadMobx.patch} />}
        />
        <Route
          path={`-firewall`}
          element={
            <Firewall
              workloadDraft={workloadDraft}
              workloadLink={workloadMobx.selfLink}
              firewall={workloadDraft.firewall}
              patch={workloadMobx.patch}
            />
          }
        />
        <Route
          path={`-loadbalancer`}
          element={
            <LoadBalancer
              workloadDraft={workloadDraft}
              workloadLink={workloadMobx.selfLink}
              loadBalancer={workloadDraft.loadBalancer}
              patch={workloadMobx.patch}
            />
          }
        />
        <Route
          path={`-auth`}
          element={<Auth kind="workload" envoy={workloadMobx.spec.sidecar?.envoy} patch={workloadMobx.patch} />}
        />
        {workloadDraft.isStateful || workloadDraft.isStandard ? (
          <Route
            path={`-rollout`}
            element={
              <RolloutOptions
                draft={workloadDraft}
                rolloutOptions={workloadDraft.rolloutOptions}
                patch={workloadMobx.patch}
              />
            }
          />
        ) : null}
        <Route
          path={`-security`}
          element={
            <SecurityOptions
              draft={workloadDraft}
              securityOptions={workloadDraft.securityOptions}
              patch={workloadMobx.patch}
            />
          }
        />
        {workloadDraft.isCron ? (
          <Route
            path={`-job`}
            element={<Job draft={workloadDraft} job={workloadDraft.job} patch={workloadMobx.patch} />}
          />
        ) : null}
        {workloadDraft.isCron ? (
          <Route
            path={`-jobexecutions`}
            element={<JobExecutions workloadMobx={workloadMobx} deployments={deployments} />}
          />
        ) : null}
        <Route
          path={`-identity`}
          element={<Identity draft={workloadDraft} identityMobx={identityMobx} patch={workloadMobx.patch} />}
        />
        <Route path={`container`} element={<Navigate to={`${workloadDraft.container1.name.initialValue}`} replace />} />
        <Route
          path={`container/${workloadDraft.container1.name.initialValue}/*`}
          element={
            <ContainerDetail
              key={workloadDraft.container1.name.initialValue}
              fetchItem={fetchItem}
              deleteContainer={workloadMobx.deleteContainer}
              containerDraft={workloadDraft.container1}
              isNew={false}
              workloadMobx={workloadMobx}
              workloadDraft={workloadDraft}
              startDraft={startDraft}
              gvcEnv={gvcEnv}
            />
          }
        />
        {workloadDraft.container2 ? (
          <Route
            path={`container/${workloadDraft.container2.name.initialValue}/*`}
            element={
              <ContainerDetail
                key={workloadDraft.container2.name.initialValue}
                fetchItem={fetchItem}
                deleteContainer={workloadMobx.deleteContainer}
                containerDraft={workloadDraft.container2}
                isNew={false}
                workloadMobx={workloadMobx}
                workloadDraft={workloadDraft}
                startDraft={startDraft}
                gvcEnv={gvcEnv}
              />
            }
          />
        ) : null}
        {workloadDraft.container3 ? (
          <Route
            path={`container/${workloadDraft.container3.name.initialValue}/*`}
            element={
              <ContainerDetail
                key={workloadDraft.container3.name.initialValue}
                fetchItem={fetchItem}
                deleteContainer={workloadMobx.deleteContainer}
                containerDraft={workloadDraft.container3}
                isNew={false}
                workloadMobx={workloadMobx}
                workloadDraft={workloadDraft}
                startDraft={startDraft}
                gvcEnv={gvcEnv}
              />
            }
          />
        ) : null}
        {workloadDraft.container4 ? (
          <Route
            path={`container/${workloadDraft.container4.name.initialValue}/*`}
            element={
              <ContainerDetail
                key={workloadDraft.container4.name.initialValue}
                fetchItem={fetchItem}
                deleteContainer={workloadMobx.deleteContainer}
                containerDraft={workloadDraft.container4}
                isNew={false}
                workloadMobx={workloadMobx}
                workloadDraft={workloadDraft}
                startDraft={startDraft}
                gvcEnv={gvcEnv}
              />
            }
          />
        ) : null}
        {workloadDraft.container5 ? (
          <Route
            path={`container/${workloadDraft.container5.name.initialValue}/*`}
            element={
              <ContainerDetail
                key={workloadDraft.container5.name.initialValue}
                fetchItem={fetchItem}
                deleteContainer={workloadMobx.deleteContainer}
                containerDraft={workloadDraft.container5}
                isNew={false}
                workloadMobx={workloadMobx}
                workloadDraft={workloadDraft}
                startDraft={startDraft}
                gvcEnv={gvcEnv}
              />
            }
          />
        ) : null}
        {workloadDraft.container6 ? (
          <Route
            path={`container/${workloadDraft.container6.name.initialValue}/*`}
            element={
              <ContainerDetail
                key={workloadDraft.container6.name.initialValue}
                fetchItem={fetchItem}
                deleteContainer={workloadMobx.deleteContainer}
                containerDraft={workloadDraft.container6}
                isNew={false}
                workloadMobx={workloadMobx}
                workloadDraft={workloadDraft}
                startDraft={startDraft}
                gvcEnv={gvcEnv}
              />
            }
          />
        ) : null}
        {workloadDraft.container7 ? (
          <Route
            path={`container/${workloadDraft.container7.name.initialValue}/*`}
            element={
              <ContainerDetail
                key={workloadDraft.container7.name.initialValue}
                fetchItem={fetchItem}
                deleteContainer={workloadMobx.deleteContainer}
                containerDraft={workloadDraft.container7}
                isNew={false}
                workloadMobx={workloadMobx}
                workloadDraft={workloadDraft}
                startDraft={startDraft}
                gvcEnv={gvcEnv}
              />
            }
          />
        ) : null}
        {workloadDraft.container8 ? (
          <Route
            path={`container/${workloadDraft.container8.name.initialValue}/*`}
            element={
              <ContainerDetail
                key={workloadDraft.container8.name.initialValue}
                fetchItem={fetchItem}
                deleteContainer={workloadMobx.deleteContainer}
                containerDraft={workloadDraft.container8}
                isNew={false}
                workloadMobx={workloadMobx}
                workloadDraft={workloadDraft}
                startDraft={startDraft}
                gvcEnv={gvcEnv}
              />
            }
          />
        ) : (
          <Route
            path={`container/-new/*`}
            element={
              <ContainerDetail
                key={"-newContainer"}
                fetchItem={fetchItem}
                containerDraft={workloadDraft.newContainer}
                isNew={true}
                workloadMobx={workloadMobx}
                workloadDraft={workloadDraft}
                startDraft={startDraft}
                gvcEnv={gvcEnv}
              />
            }
          />
        )}
        <Route path={`-tags`} element={<Tags link={workloadMobx.selfLink} tags={workloadMobx.tags} />} />
        <Route path={`-extras`} element={<Extras link={workloadMobx.selfLink} extras={workloadMobx.spec.extras} />} />
        <Route
          path={`-request-retries`}
          element={<RequestRetryPolicy link={workloadMobx.selfLink} policy={workloadMobx.spec.requestRetryPolicy} />}
        />
        <Route
          path={`-events`}
          element={
            <Events
              kind={"workload"}
              eventlogs={eventlogHook.eventlogs}
              isLoading={eventlogHook.isLoading}
              fetchEventlogs={eventlogHook.fetchEventlogs}
            />
          }
        />
        <Route path={`-audittrail`} element={<AuditTrail kind={"workload"} resourceId={workloadMobx.id} />} />
        <Route path={`-logs`} element={<WorkloadLogs workload={workloadMobx} deployments={deployments} />} />
        <Route path={`-connect`} element={<WorkloadConnect workload={workloadMobx} />} />
        <Route path={`-accessreport`} element={<AccessReport itemLink={workloadMobx.selfLink} />} />
        {localStorage.getItem("CPLN_DEBUG") === "true" ? (
          <Route path={`-commands`} element={<Commands selfLink={workloadMobx.selfLink} />} />
        ) : null}
        {workloadDraft.isCron ? null : workloadDraft.isStateful ? (
          <>
            <Route
              path={`deployment`}
              element={<StatefulDeployments workload={workloadMobx} deployments={deployments} />}
            />
            <Route
              path={`deployment/:deployment/replica/:replica/replicaindex/:replicaIndex`}
              element={<StatefulReplica workload={workloadMobx} deployments={deployments} />}
            />
          </>
        ) : (
          <Route path={`deployment`} element={<DeploymentTable workload={workloadMobx} deployments={deployments} />} />
        )}
      </DetailLayout>
    </BasePathContext.Provider>
  );
};

export const WorkloadDetail = observer(WorkloadDetailRaw);
