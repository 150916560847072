import * as React from "react";
import { Modal } from "antd";
import { observer } from "mobx-react-lite";
import { LoaderSmall } from "../../../../components/layout/loader/small";
import { RadioWithTooltip } from "../../../../components/generic/radioWithTooltip";
import { BrowserServiceModal } from "../browserServiceModal";
import { SelectRolesModal } from "../selectRolesModal";
import { IdentityDraftGcpHelper } from "../../../../mst/stores/identity.gcp.helper";
import { RemovableValue } from "../../../../components/tag/removableValue";
import { getRoot } from "mobx-state-tree";
import { NGButton } from "../../../../newcomponents/button/Button";
import { NGRadioGroup } from "../../../../newcomponents/radioGroup";
import { NGInput } from "../../../../newcomponents/input/input";
import { NGKindSelect } from "../../../../newcomponents/select/ngkindselect";
import NGAlert from "../../../../newcomponents/alert";
import { NGFormLabel } from "../../../../newcomponents/text/formLabel";

interface Props {
  gcp: IdentityDraftGcpHelper;
}
const GCPSetupModalRaw: React.FC<Props> = ({ gcp }) => {
  const draft = gcp.draft;

  async function selectRoles(bindingIndex: number) {
    const binding = draft.bindings[bindingIndex];
    if (!binding) return;
    if (!binding.forEdit.resourceIsManual) {
      binding.forEdit.selectRoles();
      return;
    }
    if (!draft.browserService!.canReach) {
      const m = Modal.info({
        title: "Select Roles",
        content: (
          <div>
            <p>Failed to browse the cloud with this cloud account.</p>
          </div>
        ),
        footer: (
          <div className="modal-actions">
            <NGButton variant="secondary" onClick={() => m.destroy()}>
              Close
            </NGButton>
          </div>
        ),
      });
    }
    if (gcp.saved.isActive) {
      await binding.forEdit.reverseLookup(draft.cloudaccountLink);
    }

    if (binding.forEdit.resourceDataItem) {
      binding.forEdit.selectRoles();
    } else {
      const m = Modal.info({
        title: "Select Roles",
        content: (
          <div>
            <p>You can't browse roles for a manually typed resource.</p>
          </div>
        ),
        footer: (
          <div className="modal-actions">
            <NGButton variant="primary" onClick={() => m.destroy()}>
              OK
            </NGButton>
          </div>
        ),
      });
    }
  }

  return (
    <Modal
      title={gcp.title}
      open={gcp.isSettingUp}
      width={gcp.modalWidth}
      closable={false}
      footer={
        <div className="modal-actions">
          <NGButton variant="danger" outlined onClick={() => gcp.cancel()} disabled={gcp.isLoading}>
            Cancel
          </NGButton>
          <div style={{ flexGrow: 1 }} />
          <NGButton variant="secondary" onClick={() => gcp.back()} disabled={gcp.step === "general" || gcp.isLoading}>
            Back
          </NGButton>
          {gcp.step === "general" ? (
            <NGButton
              variant="primary"
              onClick={() => gcp.next()}
              disabled={!draft.isValidPage_General || gcp.isLoading}
            >
              Next
            </NGButton>
          ) : (
            <NGButton variant="primary" onClick={() => gcp.done()} disabled={!draft.isValid || gcp.isLoading}>
              Done
            </NGButton>
          )}
        </div>
      }
    >
      {gcp.isLoading ? (
        <div className="flex items-center justify-center flex-col" style={{ height: 300 }}>
          <LoaderSmall />
          <div className="mt-2">
            Please wait. Loading {gcp.draft.method === "existing" ? "GCP Service Accounts" : "GCP Resources"}.
          </div>
        </div>
      ) : null}
      {!gcp.isLoading && gcp.step === "general" ? (
        <div style={{ height: 300 }}>
          <NGFormLabel required invalid={!draft.cloudaccountName}>
            Cloud Account
          </NGFormLabel>
          <NGKindSelect
            className="mb-4"
            kind={"cloudaccount"}
            invalid={!draft.cloudaccountName}
            value={draft.cloudaccountName}
            onChange={draft.setCloudaccountName}
            queries={[{ property: "provider", value: "gcp" }]}
          />
          <NGRadioGroup
            isVertical
            label="Method"
            value={draft.method}
            onChange={(value) => draft.setMethod(value as "existing" | "new")}
            isDisabled={gcp.isRadioDisabled}
            options={[
              { value: "existing", label: "Use an Existing GCP Service Account" },
              { value: "new", label: "Configure Service Account Bindings" },
            ]}
          />
        </div>
      ) : null}
      {!gcp.isLoading && gcp.step === "config" && draft.method === "existing" && draft.browserService!.canReach ? (
        <div style={{ height: 400 }} className="flex flex-col">
          <div className="flex mb-2">
            <NGInput
              className="flex-grow mr-2"
              value={draft.serviceaccountInput.value}
              onChange={(e) => draft.serviceaccountInput.setValue(e.target.value)}
              placeholder={draft.serviceaccountInput.label}
              disabled={!draft.useManualInput}
            />
            {draft.useManualInput ? (
              <NGButton
                style={{ width: 180 }}
                variant={"primary"}
                onClick={() => {
                  draft.setUseManualInput(false);
                }}
              >
                Confirm
              </NGButton>
            ) : (
              <NGButton
                size={"small"}
                variant={"primary"}
                style={{ width: 180 }}
                onClick={() => {
                  draft.setUseManualInput(true);
                }}
              >
                Edit Manually
              </NGButton>
            )}
          </div>
          <NGInput
            className="mb-4"
            value={draft.filterInput.value}
            placeholder={draft.filterInput.label}
            onChange={(e) => draft.filterInput.setValue(e.target.value)}
            disabled={draft.useManualInput}
          />
          <div className="overflow-auto flex-grow">
            {draft.filteredServiceaccounts.map((serviceaccount) => (
              <RadioWithTooltip
                key={serviceaccount._cpln.ref}
                onChange={() => {
                  draft.selectServiceAccount(serviceaccount._cpln.ref);
                }}
                checked={serviceaccount._cpln.ref.split("/serviceAccounts/")[1] === draft.serviceaccountInput.value}
                label={serviceaccount._cpln.label!}
                disabled={draft.useManualInput}
              />
            ))}
          </div>
        </div>
      ) : null}
      {!gcp.isLoading && gcp.step === "config" && draft.method === "existing" && !draft.browserService!.canReach ? (
        <div style={{ height: 300 }}>
          <div className={`mb-2`}>
            <div>Failed to browse the cloud with this cloud account.</div>
            <div>
              You need to type the <span className=" ">GCP Service Account</span> manually.
            </div>
          </div>
          <NGInput
            className="mb-2"
            value={draft.serviceaccountInput.value}
            placeholder={draft.serviceaccountInput.label}
            onChange={(e) => draft.serviceaccountInput.setValue(e.target.value)}
          />
        </div>
      ) : null}
      {!gcp.isLoading && gcp.step === "config" && draft.method === "new" ? (
        <div style={{ height: 500, paddingRight: 15 }} className="overflow-auto">
          <div className={`mb-4 flex items-center border p-4`}>
            <div className="flex-grow">You can add a binding when current bindings are valid.</div>
            <NGButton
              disabled={!draft.canAddBinding}
              style={{ width: 140, marginLeft: 10 }}
              variant={"action"}
              onClick={() => {
                draft.addBinding();
              }}
            >
              Add Binding
            </NGButton>
          </div>
          {draft.bindings.map((binding, index) => (
            <div key={index}>
              <div className="mt-4 mb-8 p-4 border   ">
                {binding.forEdit.resourceInput.value ? (
                  binding.forEdit.roles.length === 0 ? (
                    <NGAlert className="mb-2" message="At least 1 role is required." type="error" />
                  ) : null
                ) : (
                  <NGAlert className="mb-2" message="Resource selection is required" type="error" />
                )}
                <div className="flex">
                  <NGInput
                    className="flex-grow"
                    value={binding.forEdit.resourceInput.value}
                    placeholder={binding.forEdit.resourceInput.label}
                    onChange={(e) => binding.forEdit.resourceInput.setValue(e.target.value)}
                  />
                  {draft.browserService!.canReach ? (
                    <NGButton
                      variant={"primary"}
                      style={{ width: 200, marginLeft: 10 }}
                      onClick={() => {
                        binding.forEdit.selectResource();
                      }}
                    >
                      Select Resource
                    </NGButton>
                  ) : null}
                </div>
                <div>
                  <div className="flex flex-col mt-4">
                    {binding.roles.length === 0 ? null : <div className="mr-2">Selected Roles</div>}
                    {binding.forEdit.roles.map((role) => (
                      <RemovableValue
                        key={role}
                        value={role}
                        onRemove={() => {
                          binding.forEdit.removeRole(role);
                        }}
                      />
                    ))}
                  </div>
                  <div className="flex">
                    <NGInput
                      disabled={!binding.forEdit.resourceInput.value}
                      className="flex-grow mr-2"
                      value={binding.forEdit.roleInput.value}
                      placeholder={binding.forEdit.roleInput.label}
                      onChange={(e) => binding.forEdit.roleInput.setValue(e.target.value)}
                    />
                    <NGButton
                      disabled={
                        !binding.forEdit.resourceInput.value ||
                        !binding.forEdit.roleInput.isValid ||
                        binding.forEdit.roleInput.value.length < 1
                      }
                      style={{ width: draft.browserService!.canReach ? 77 : undefined }}
                      variant={"action"}
                      onClick={() => {
                        binding.forEdit.addRole();
                      }}
                    >
                      Add
                    </NGButton>
                    {draft.browserService!.canReach ? (
                      <NGButton
                        disabled={!binding.forEdit.resourceInput.value}
                        style={{ width: 200, marginLeft: 10 }}
                        variant={"primary"}
                        onClick={() => {
                          selectRoles(index);
                        }}
                      >
                        Select Roles
                      </NGButton>
                    ) : null}
                  </div>
                </div>
                {draft.bindings.length > 1 ? (
                  <div className="mt-4 flex justify-end">
                    <NGButton
                      style={{ width: 140 }}
                      variant={"danger"}
                      onClick={() => {
                        draft.removeBinding(index);
                      }}
                    >
                      Remove
                    </NGButton>
                  </div>
                ) : null}
              </div>
              {binding.forEdit.isSelectingResource ? (
                <BrowserServiceModal
                  title={"Select Resource"}
                  forGcpResource={{ isActive: true, cloudaccountLink: draft.cloudaccountLink }}
                  onClose={(selections) => {
                    binding.forEdit.doneSelectResource(selections);
                  }}
                  selectionType={"single"}
                  browserService={(getRoot(draft) as any).browserService}
                />
              ) : null}
              {binding.forEdit.isSelectingRoles ? (
                binding.forEdit.resourceDataItem ? (
                  <SelectRolesModal
                    onClose={(selections) => {
                      binding.forEdit.doneSelectRoles(selections);
                    }}
                    itemHref={binding.forEdit.resourceDataItem.href}
                    currentSelections={[...binding.forEdit.roles]}
                  />
                ) : null
              ) : null}
            </div>
          ))}
        </div>
      ) : null}
    </Modal>
  );
};

export const GCPSetupModal = observer(GCPSetupModalRaw);
