import { types, Instance } from "mobx-state-tree";
import { IdentityNetworkResource } from "../kinds/identity";
import { StringModel } from "../../mobxDataModels/stringModel";
import { PortModel } from "../../mobxDataModels/portModel";
import { defaultValues } from "../base";
import { ngParseLink } from "../../utils/linkParser/linkParser";
import { inputValidations } from "../../mobxDataModels/validations";

export const IdentityDraftNetworkResourceModel = types
  .model({
    _nameInp: types.optional(types.string, ""),
    nameInp: types.optional(types.string, ""),
    discover: types.optional(types.enumeration(["ip", "fqdn"]), defaultValues.networkResource.discover as any),
    IPs: types.array(types.string),
    ipInput: types.optional(StringModel, () => StringModel.create({ label: "IP (v4)", validationKey: "ipv4" })),
    fqdnInput: types.optional(StringModel, () =>
      StringModel.create({
        label: "FQDN",
        isRequired: true,
        validationKey: "domainWithoutPattern",
        transformKey: "lowerCase",
      }),
    ),
    ports: types.array(types.string),
    portInput: types.optional(StringModel, () => PortModel.create({ label: "Port", min: 0, max: 65535 })),
    resolverIpInput: types.optional(StringModel, () =>
      StringModel.create({ label: "Resolver IP", validationKey: "ipv4" }),
    ),
    agentName: types.optional(types.string, ""),
  })
  .actions((self) => ({
    setNameInp(value: string) {
      self.nameInp = value;
    },
  }))
  .views((self) => ({
    get canAddIP() {
      if (self.IPs.length > 4) return false;
      if (self.ipInput.value.length < 1) return false;
      if (!self.ipInput.isValid) return false;
      const alreadyAddedIP = self.IPs.find((ip) => ip === self.ipInput.value);
      if (alreadyAddedIP) return false;
      return true;
    },
    get canAddPort() {
      if (self.ports.length > 4) return false;
      if (self.portInput.value.length < 1) return false;
      if (!self.portInput.isValid) return false;
      const alreadyAddedPort = self.ports.find((port) => port === self.portInput.value);
      if (alreadyAddedPort) return false;
      return true;
    },
    get fqdnSlug() {
      try {
        return self.fqdnInput.value.replaceAll(".", "-");
      } catch (e) {
        return "";
      }
    },
  }))
  .views((self) => ({
    get resourceName() {
      return self.nameInp || self.fqdnSlug;
    },
  }))
  .views((self) => ({
    get isResourceNameValid() {
      if (!self.resourceName) {
        return false;
      }
      for (const validation of inputValidations.identityNetworkResourceServiceName) {
        const validationResult = validation("value", self.resourceName);
        if (validationResult !== true) {
          return false;
        }
      }
      return true;
    },
  }))
  .actions((self) => ({
    clear() {
      self.agentName = "";
      self.nameInp = self._nameInp;
      self.discover = defaultValues.networkResource.discover as any;
      self.IPs.clear();
      self.ipInput.setInitialValue("");
      self.ipInput.reset();
      self.fqdnInput.setInitialValue("");
      self.fqdnInput.reset();
      self.ports.clear();
      self.portInput.setInitialValue("");
      self.portInput.reset();
      self.resolverIpInput.setInitialValue("");
      self.resolverIpInput.reset();
    },
    setDiscover(value: "fqdn" | "ip") {
      self.discover = value;
    },
    addIP() {
      if (!self.canAddIP) return;
      self.IPs.push(self.ipInput.value);
      self.ipInput.reset();
    },
    removeIP(value: string) {
      const ip = self.IPs.find((ip) => ip === value);
      if (ip) {
        self.IPs.remove(ip);
      }
    },
    addPort() {
      if (!self.canAddPort) return;
      self.ports.push(self.portInput.value);
      self.portInput.reset();
    },
    removePort(value: string) {
      const port = self.ports.find((port) => port === value);
      if (port) {
        self.ports.remove(port);
      }
    },
  }))
  .actions((self) => ({
    setAgentName(name: string) {
      self.agentName = name;
    },
  }))
  .actions((self) => ({
    apply(resource: IdentityNetworkResource) {
      self.clear();
      const { name: agentName } = ngParseLink(resource.agentLink, { kind: "agent" });
      self.agentName = agentName;
      self._nameInp = resource.name;
      self.nameInp = resource.name;
      if (resource.FQDN) {
        self.discover = "fqdn";
        self.fqdnInput.setInitialValue(resource.FQDN);
      } else {
        self.discover = "ip";
        for (let ip of resource.IPs!) {
          self.IPs.push(ip);
        }
      }
      for (let port of resource.ports) {
        self.ports.push(String(port));
      }
      if (resource.resolverIP) {
        self.resolverIpInput.setInitialValue(resource.resolverIP);
      }
    },
  }))
  .views((self) => ({
    get isValid() {
      let res = true;
      if (!self.agentName) res = false;
      if (!self.isResourceNameValid) res = false;
      if (self.discover === "fqdn") {
        if (!self.fqdnInput.isValid) res = false;
      } else {
        if (self.IPs.length < 1) res = false;
        if (self.IPs.length > 5) res = false;
        if (self.ipInput.value.length > 0) res = false;
      }
      if (self.ports.length < 1) res = false;
      if (self.ports.length > 5) res = false;
      if (self.portInput.value.length > 0) res = false;
      if (self.resolverIpInput.value.length > 0 && !self.resolverIpInput.isValid) res = false;
      return res;
    },
    get asObject(): IdentityNetworkResource {
      const { absolute: agentLink } = ngParseLink(self.agentName, { kind: "agent" });
      return {
        agentLink: agentLink,
        name: self.resourceName,
        FQDN: self.discover === "fqdn" ? self.fqdnInput.value : undefined,
        IPs: self.discover === "fqdn" ? undefined : [...self.IPs].slice().sort(),
        ports: [...self.ports]
          .slice()
          .sort()
          .map((p) => Number(p)),
        resolverIP:
          self.discover === "fqdn"
            ? self.resolverIpInput.value.length > 0
              ? self.resolverIpInput.value
              : undefined
            : undefined,
      };
    },
  }));

export interface IdentityDraftNetworkResourceMobx extends Instance<typeof IdentityDraftNetworkResourceModel> {}
