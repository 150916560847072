import * as React from "react";
import { notification } from "antd";
import { observer } from "mobx-react-lite";
import { ChevronDown, ChevronUp, Copy, ExternalLink } from "react-feather";
import { NGButton } from "../../../../newcomponents/button/Button";
import { CodeEditor } from "../../../group/identityMatcher/codeEditor";
import { NGFormElement } from "../../../../newcomponents/ngformelement/ngformelement";

interface Props {
  name: string;
  accountId: string;
  setAccountId: (value: string) => void;
  instructions: any;
}
const CloudaccountCreateAwsConfigurationRaw: React.FC<Props> = ({ name, accountId, setAccountId, instructions }) => {
  const [continueWith, setContinueWith] = React.useState<"ui" | "cli" | "none">("none");
  const [showAwsAccountIdGuide, setShowAwsAccountIdGuide] = React.useState(false);

  async function copyToClipboard(value: string) {
    navigator.clipboard.writeText(value);
    notification.success({
      message: "Copied to clipboard",
    });
  }

  function onConsole() {
    if (continueWith === "ui") {
      setContinueWith("none");
    } else {
      setContinueWith("ui");
    }
  }

  function onCPLN() {
    if (continueWith === "cli") {
      setContinueWith("none");
    } else {
      setContinueWith("cli");
    }
  }

  if (!instructions.data) return null;

  return (
    <>
      <div className="my-4">
        <button className="ngfocus color-link font-medium" onClick={onConsole}>
          Continue with AWS UI
          {continueWith === "ui" ? (
            <ChevronUp className="ml-1 feather-icon inline-block" style={{ transform: "translateY(2px)" }} />
          ) : (
            <ChevronDown className="ml-1 feather-icon inline-block" style={{ transform: "translateY(2px)" }} />
          )}
        </button>
      </div>
      {continueWith === "ui" ? (
        <>
          <ul className="pl-8">
            <li className="list-decimal my-2 ml-4">
              Sign into the{" "}
              <a
                className="underline hover:underline ngfocus color-link"
                href={"https://console.aws.amazon.com"}
                target={"_blank"}
              >
                AWS Console
                <ExternalLink className="ml-1 feather-icon inline-block" style={{ transform: "translateY(2px)" }} />
              </a>
            </li>
            <li className="my-2 list-decimal ml-4">
              Click the dropdown next to your <span className="font-semibold">username</span>.
            </li>
            <li className="my-2 list-decimal ml-4">
              Copy the <span className="font-semibold">Account ID</span> next to "My Account" and type it below.
            </li>
            <div className="flex items-center justify-center my-2 border" style={{ width: 421, height: 358 }}>
              <img
                className="object-container"
                style={{ width: 421, height: 358 }}
                src={"/resources/howto/aws_accountIdSteps.jpg"}
                alt={"how do I get my aws account id?"}
              />
            </div>
            <NGFormElement
              required
              name="aws_accountId"
              label={"AWS Account Id"}
              value={accountId}
              onChange={setAccountId}
            />
            <li className="list-decimal my-2 ml-4">
              Navigate to the IAM Dashboard by typing <span className="font-semibold">IAM</span> in the search bar.
            </li>
            <li className="list-decimal my-2 ml-4">
              In the navigation pane, choose <span className="font-semibold">Roles</span>.
            </li>
            <li className="list-decimal my-2 ml-4">
              Click <span className="font-semibold">Create Role</span>.
            </li>
            <li className="list-decimal my-2 ml-4">
              Select <span className="font-semibold">AWS Account</span> option.
            </li>
            <div className="flex items-center justify-center mb-2 border" style={{ width: 800 }}>
              <img
                className="object-container"
                style={{ width: 800 }}
                src={"/resources/howto/aws-new-aws-account.png"}
                alt={"select the option aws account"}
              />
            </div>
            <li className="list-decimal my-2 ml-4">
              Keep <span className="font-semibold">This account</span> option selected.
            </li>
            <div className="flex items-center justify-center mb-2 border" style={{ width: 650 }}>
              <img
                className="object-container"
                style={{ width: 650 }}
                src={"/resources/howto/aws-new-this-account.png"}
                alt={"no changes at this step, just informing"}
              />
            </div>
            <li className="list-decimal my-2 ml-4">
              Click <span className="font-semibold">Next</span> twice to go to{" "}
              <span className="font-semibold">Name, review, and create</span> step.
            </li>
            <li className="list-decimal my-2 ml-4">
              Input role name as <span className="font-semibold">{instructions.data.bridgeRole}</span>.
              <button
                className={`ml-1 ngfocus color-link-hover`}
                onClick={() => copyToClipboard(instructions.data.bridgeRole)}
              >
                <Copy className="ml-1 feather-icon" style={{ transform: "translateY(2px)" }} />
              </button>
            </li>
            <li className="list-decimal my-2 ml-4">
              Click <span className="font-semibold">Create role</span>.
            </li>
            {/* Create policy */}
            <li className="list-decimal my-2 ml-4">
              In the navigation pane, choose <span className="font-semibold">Policies</span>.
            </li>
            <li className="list-decimal my-2 ml-4">
              Click <span className="font-semibold">Create Policy</span>.
            </li>
            <li className="list-decimal my-2 ml-4">
              Switch to the <span className="font-semibold">JSON</span> tab, copy and paste the following policy:.
            </li>
            <NGButton
              variant={"secondary"}
              className="my-2"
              onClick={() => copyToClipboard(JSON.stringify(instructions.data.connectorPolicy, null, 2))}
            >
              Copy Policy
            </NGButton>
            <CodeEditor
              value={JSON.stringify(instructions.data.connectorPolicy, null, 2)}
              setValue={() => {}}
              language={"json"}
              options={{
                style: {
                  fontSize: 14,
                },
                basicSetup: {
                  highlightActiveLine: false,
                  highlightActiveLineGutter: false,
                  lineNumbers: true,
                  autocompletion: false,
                  foldGutter: false,
                },
                readOnly: true,
              }}
            />
            <li className="list-decimal my-2 ml-4">
              Click <span className="font-semibold">Next: Review</span>.
            </li>
            <li className="list-decimal my-2 ml-4">
              Input policy name as <span className="font-semibold">cpln-connector</span>.
              <button className={`ml-1 ngfocus color-link-hover`} onClick={() => copyToClipboard("cpln-connector")}>
                <Copy className="ml-1 feather-icon" style={{ transform: "translateY(2px)" }} />
              </button>
            </li>
            <li className="list-decimal my-2 ml-4">
              Click <span className="font-semibold">Create policy</span>.
            </li>
            {/* Attach policies */}
            <li className="list-decimal my-2 ml-4">
              In the navigation pane, choose <span className="font-semibold">Roles</span>.
            </li>
            <li className="list-decimal my-2 ml-4">
              Search for the role <span className="font-semibold">{instructions.data.bridgeRole}</span> and click on the
              name.
            </li>
            <li className="list-decimal my-2 ml-4">
              Click <span>Add permissions</span> and select <span>Attach policy</span> within the Permissions tab.
            </li>
            <div className="flex items-center justify-center mb-2 border" style={{ width: 700 }}>
              <img
                className="object-container"
                style={{ width: 700 }}
                src={"/resources/howto/aws-new-attach-policies.png"}
                alt={"click attach policies under permission tab"}
              />
            </div>
            {(instructions.data.accessPolicies as string[]).map((policy) => (
              <li key={policy} className="list-decimal my-2 ml-4">
                Search for the policy <span className="font-semibold">{policy}</span> and click the checkbox.
              </li>
            ))}
            <div className="flex items-center justify-center mb-2 border" style={{ width: 350 }}>
              <img
                className="object-container"
                style={{ width: 350 }}
                src={"/resources/howto/aws-new-selected-policies.png"}
                alt={"search for the policy iamfullaccess and select it"}
              />
            </div>
            <li className="list-decimal my-2 ml-4">
              Click <span className="font-semibold">Add permissions</span> at the end of the list.
            </li>

            {/* Attach trust policy */}
            <li className="list-decimal my-2 ml-4">
              Click the <span className="font-semibold">Trust Relationships</span> tab.
            </li>
            <li className="list-decimal my-2 ml-4">
              Click <span className="font-semibold">Edit trust policy</span>.
            </li>
            <div className="flex items-center justify-center mb-2 border" style={{ width: 720 }}>
              <img
                className="object-container"
                style={{ width: 720 }}
                src={"/resources/howto/aws-new-trust-relationship-accent.png"}
                alt={"edit trust relationship of the role"}
              />
            </div>
            <li className="list-decimal my-2 ml-4">Copy and paste the following trust policy</li>
            <NGButton
              variant={"secondary"}
              className="my-2"
              onClick={() => copyToClipboard(JSON.stringify(instructions.data.trustPolicy, null, 2))}
            >
              Copy Trust Policy
            </NGButton>
            <CodeEditor
              value={JSON.stringify(instructions.data.trustPolicy, null, 2)}
              setValue={() => {}}
              language={"json"}
              options={{
                style: {
                  fontSize: 14,
                },
                basicSetup: {
                  highlightActiveLine: false,
                  highlightActiveLineGutter: false,
                  lineNumbers: true,
                  autocompletion: false,
                  foldGutter: false,
                },
                readOnly: true,
              }}
            />
            <li className="list-decimal my-2 ml-4">
              Click <span className="font-semibold">Update Policy</span>.
            </li>
          </ul>
        </>
      ) : null}
      <div className="my-4">
        <button className="ngfocus font-medium color-link" onClick={onCPLN}>
          Optionally you can use the Control Plane CLI (cpln)
          {continueWith === "cli" ? (
            <ChevronUp className="ml-1 feather-icon inline-block" style={{ transform: "translateY(2px)" }} />
          ) : (
            <ChevronDown className="ml-1 feather-icon inline-block" style={{ transform: "translateY(2px)" }} />
          )}
        </button>
      </div>
      {continueWith === "cli" ? (
        instructions.data.cli ? (
          <>
            <ul className="pl-8">
              <li className="list-decimal my-2 ml-4">
                Install the <span className="font-semibold">Control Plane CLI</span>.
              </li>
              <div className="flex items-center my-2" style={{ width: 450 }}>
                <input
                  className="flex-grow px-4 py-2 border rounded"
                  style={{
                    width: 450,
                  }}
                  disabled={true}
                  value={"npm i -g @controlplane/cli"}
                />
                <button
                  className={`ml-2 ngfocus color-link-hover`}
                  onClick={() => copyToClipboard("npm i -g @controlplane/cli")}
                >
                  <Copy className="ml-1 feather-icon" style={{ transform: "translateY(2px)" }} />
                </button>
              </div>
              <li className="list-decimal my-2 ml-4">
                Install the{" "}
                <a
                  className="underline hover:underline focus:underline ngfocus color-link"
                  href={"https://aws.amazon.com/cli"}
                  target={"_blank"}
                >
                  AWS CLI
                </a>{" "}
                and{" "}
                <a
                  className="underline hover:underline focus:underline ngfocus color-link"
                  href={"https://docs.aws.amazon.com/cli/latest/userguide/cli-chap-configure.html"}
                  target={"_blank"}
                >
                  configure authentication
                </a>
                .
              </li>

              <li className="list-decimal my-2 ml-4">Type your AWS Account ID below.</li>
              <button
                className="ngfocus color-link font-medium"
                onClick={() => {
                  setShowAwsAccountIdGuide((x) => !x);
                }}
              >
                How do I find my AWS Account ID?
                {showAwsAccountIdGuide ? (
                  <ChevronUp className="ml-1 feather-icon inline-block" style={{ transform: "translateY(2px)" }} />
                ) : (
                  <ChevronDown className="ml-1 feather-icon inline-block" style={{ transform: "translateY(2px)" }} />
                )}
              </button>
              {showAwsAccountIdGuide ? (
                <>
                  <ul className="ml-8">
                    <li className="list-decimal my-2 ml-4">
                      Sign into the{" "}
                      <a
                        className="underline hover:underline ngfocus color-link"
                        href={"https://console.aws.amazon.com"}
                        target={"_blank"}
                      >
                        AWS Console
                        <ExternalLink
                          className="ml-1 feather-icon inline-block"
                          style={{ transform: "translateY(2px)" }}
                        />
                      </a>
                    </li>
                    <li className="my-2 list-decimal ml-4">
                      Click the dropdown next to your <span className="font-semibold">username</span>.
                    </li>
                    <li className="my-2 list-decimal ml-4">
                      Copy the <span className="font-semibold">Account ID</span> next to "My Account" and type it below.
                    </li>
                  </ul>
                  <div
                    className="ml-8 flex items-center justify-center my-2 border"
                    style={{ width: 421, height: 358 }}
                  >
                    <img
                      className="object-container"
                      style={{ width: 421, height: 358 }}
                      src={"/resources/howto/aws_accountIdSteps.jpg"}
                      alt={"how do I get my aws account id?"}
                    />
                  </div>
                </>
              ) : null}
              <NGFormElement
                name="aws_accountIdSecond"
                label={"AWS Account Id"}
                required
                value={accountId}
                onChange={setAccountId}
              />

              {instructions.data.cli.map((step: string, index: number) => {
                step = step.replace("$ACCOUNT_NAME", name);
                if (accountId) {
                  step = step.replace("$ACCOUNT_ID", accountId);
                }

                return (
                  <div key={index}>
                    {index === 0 ? (
                      <li className="list-decimal my-2 ml-4">
                        Create a <span className="font-semibold">Cloud Account</span> using the Control Plane CLI.
                      </li>
                    ) : null}
                    {index === 1 ? (
                      <li className="list-decimal my-2 ml-4">
                        Create a <span className="font-semibold">role</span> and{" "}
                        <span className="font-semibold">trust policy</span> with Control Plane using the AWS CLI.
                      </li>
                    ) : null}
                    {index === 2 ? (
                      <li className="list-decimal my-2 ml-4">
                        Create the <span className="font-semibold">cpln-connector</span> policy using the AWS CLI.
                      </li>
                    ) : null}
                    {index === 3 ? (
                      <li className="list-decimal my-2 ml-4">
                        Assign the <span className="font-semibold">{"ReadOnlyAccess"}</span> policy to the created role
                        using the AWS CLI.
                      </li>
                    ) : null}
                    {index === 4 ? (
                      <li className="list-decimal my-2 ml-4">
                        Assign the <span className="font-semibold">{"cpln-connector"}</span> policy to the created role
                        using the AWS CLI.
                      </li>
                    ) : null}
                    <div className="flex items-center my-2" key={step}>
                      <input className="flex-grow px-4 py-2 border rounded" disabled={true} value={step} />
                      <button className={`ml-2 focus`} onClick={() => copyToClipboard(step)}>
                        <Copy className="ml-1 feather-icon color-link-hover" style={{ transform: "translateY(2px)" }} />
                      </button>
                    </div>
                  </div>
                );
              })}
              <li className="list-decimal my-2 ml-4">You're done. You can cancel this wizard.</li>
            </ul>
          </>
        ) : null
      ) : null}
    </>
  );
};

export const CloudaccountCreateAwsConfiguration = observer(CloudaccountCreateAwsConfigurationRaw);
