import clsx from "clsx";
import * as React from "react";
import { MinusCircle, Trash2 } from "react-feather";
import { NGButton } from "../button/Button";
import { NGCheckbox } from "../checkbox";
import { ReactSortable } from "react-sortablejs";
import "./inputList.css";
import { observer } from "mobx-react-lite";
import { DragHandle } from "./inputListMobx";
import { NGFormLabel } from "../text/formLabel";
import { NGError } from "../text/error";
import { InfoTooltip } from "../../components/InfoTooltip";

// items type {id: string, chosen: boolean, firstValue: string, secondValue: string, thirdValue: string}
export interface NGInputListProps<T extends { id: string; chosen: boolean }> {
  label: string;
  items: T[];
  isAllSelected?: boolean;
  selections?: string[];
  toggleAllSelect?: () => void;
  toggleSelect?: (id: string) => void;
  removeSelections?: () => void;
  add: () => void;
  remove?: (id: string) => void;
  setItems?: (items: any[]) => void;
  firstInput: (item: T) => React.ReactElement;
  secondInput?: (item: T) => React.ReactElement;
  thirdInput?: (item: T) => React.ReactElement;
  itemExtra?: (item: T) => React.ReactElement | null;
  allowListSelection?: boolean;
  draggable?: boolean;
  noItemLabel?: string;
  required?: boolean;
  invalid?: boolean;
  error?: string;
  info?: string[];
  canAdd?: () => boolean;
  className?: string;
  styles?: {
    container?: React.CSSProperties;
    header?: React.CSSProperties;
    firstInputContainer?: React.CSSProperties;
    secondInputContainer?: React.CSSProperties;
    thirdInputContainer?: React.CSSProperties;
  };
}

function NGInputListRaw<T extends { id: string; chosen: boolean }>({
  label,
  isAllSelected,
  items,
  selections,
  toggleAllSelect,
  toggleSelect,
  removeSelections,
  add,
  remove,
  setItems,
  firstInput,
  secondInput,
  thirdInput,
  itemExtra,
  allowListSelection = false,
  draggable = false,
  noItemLabel,
  required,
  invalid,
  error,
  info = [],
  canAdd = () => true,
  className = "",
  styles = {
    container: {},
    header: {},
    firstInputContainer: {},
    secondInputContainer: {},
    thirdInputContainer: {},
  },
}: NGInputListProps<T>) {
  isAllSelected;
  selections;
  toggleAllSelect;
  toggleSelect;
  removeSelections;
  if (
    allowListSelection &&
    (isAllSelected === undefined ||
      selections === undefined ||
      toggleAllSelect === undefined ||
      toggleSelect === undefined ||
      removeSelections === undefined)
  ) {
    console.error(
      'NgInputList with allowListSelection=true must set properties: "isAllSelected", "selections", "toggleAllSelect", "toggleSelect", "removeSelections"',
    );
    return null;
  } else if (!allowListSelection && remove === undefined) {
    console.error('NgInputList with allowListSelection=false must set properties: "remove"');
    return null;
  } else if (draggable && setItems === undefined) {
    console.error('NgInputList with draggable=true must set properties: "setItems"');
    return null;
  }

  return (
    <div className={clsx("flex flex-col mb-4", className)} style={styles.container}>
      <div className={clsx("flex items-center gap-1 mb-2")} style={{ width: 450, ...styles.header }}>
        {allowListSelection ? (
          <NGCheckbox
            aria-label={`input-list-selectall-${label}`}
            checked={isAllSelected}
            isDisabled={items.length < 1}
            indeterminate={!isAllSelected && selections!.length > 0}
            onChange={toggleAllSelect}
          />
        ) : null}
        <div className="flex items-center">
          <NGFormLabel required={required} invalid={invalid}>
            {label}
          </NGFormLabel>
          {info.length > 0 ? <InfoTooltip title={info} /> : null}
        </div>
        <div className="flex-grow" />
        {allowListSelection ? (
          <NGButton
            size={"small"}
            variant={"danger"}
            outlined
            renderIcon={() => <Trash2 className="h-4" />}
            disabled={selections!.length < 1}
            onClick={() => removeSelections!()}
          >
            Remove
          </NGButton>
        ) : null}
        <NGButton disabled={canAdd() === false} size={"small"} variant={"action"} onClick={() => add()}>
          Add
        </NGButton>
      </div>
      <div>
        {error ? null : items.length === 0 ? <div>{noItemLabel || `No ${label} Items`}</div> : null}
        <ReactSortable
          handle=".drag-handle"
          className="flex flex-col gap-2"
          list={JSON.parse(JSON.stringify(items))}
          setList={setItems || (() => {})}
        >
          {items.map((item: T) => {
            return (
              <div>
                <div className={clsx("flex items-center relative", { "ml-4": draggable })}>
                  {draggable ? <DragHandle /> : null}
                  {allowListSelection ? (
                    <NGCheckbox
                      aria-label={`input-list-select-${item.id}`}
                      checked={selections!.includes(item.id)}
                      onChange={(value) => toggleSelect!(item.id)}
                    />
                  ) : null}
                  <div className={`basis-0 flex-grow ml-1`} style={styles.firstInputContainer}>
                    {firstInput(item)}
                  </div>
                  {secondInput ? (
                    <div className={`basis-0 flex-grow ml-2`} style={styles.secondInputContainer}>
                      {secondInput(item)}
                    </div>
                  ) : null}
                  {thirdInput ? (
                    <div className="basis-0 flex-grow ml-2" style={styles.thirdInputContainer}>
                      {thirdInput(item)}
                    </div>
                  ) : null}
                  {allowListSelection ? null : (
                    <NGButton
                      onClick={() => remove!(item.id)}
                      variant="danger"
                      className="ml-1"
                      text
                      renderIcon={(_, props) => <MinusCircle {...props} />}
                    />
                  )}
                </div>
                {itemExtra ? (
                  <div className="mt-1" style={{ marginLeft: 27 }}>
                    {itemExtra(item)}
                  </div>
                ) : null}
              </div>
            );
          })}
        </ReactSortable>
        {error ? <NGError>{error}</NGError> : null}
      </div>
    </div>
  );
}

export const NGInputList = observer(NGInputListRaw);
