import { types } from "mobx-state-tree";
import { StringModel } from "../../mobxDataModels/stringModel";

export const Mk8sDraftAddonLogsModel = types
  .model({
    _isEnabled: types.optional(types.boolean, false),
    isEnabled: types.optional(types.boolean, false),
    _auditEnabled: types.optional(types.boolean, false),
    auditEnabled: types.optional(types.boolean, false),
    _includeNamespaces: types.optional(types.string, ""), // regexp
    includeNamespaces: types.optional(StringModel, () => StringModel.create({ label: "Include Namespaces" })),
    _excludeNamespaces: types.optional(types.string, ""), // regexp
    excludeNamespaces: types.optional(StringModel, () => StringModel.create({ label: "Exclude Namespaces" })),
    _docker: types.optional(types.boolean, false),
    docker: types.optional(types.boolean, false),
    _kubelet: types.optional(types.boolean, false),
    kubelet: types.optional(types.boolean, false),
    _kernel: types.optional(types.boolean, false),
    kernel: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    setIsEnabled(value: boolean) {
      self.isEnabled = value;
    },
    setAuditEnabled(value: boolean) {
      self.auditEnabled = value;
    },
    setDocker(value: boolean) {
      self.docker = value;
    },
    setKubelet(value: boolean) {
      self.kubelet = value;
    },
    setKernel(value: boolean) {
      self.kernel = value;
    },
  }))
  .actions((self) => ({
    reset() {
      self.isEnabled = self._isEnabled;
      self.auditEnabled = self._auditEnabled;
      self.docker = self._docker;
      self.kubelet = self._kubelet;
      self.kernel = self._kernel;
      self.includeNamespaces.setInitialValue(self._includeNamespaces);
      self.excludeNamespaces.setInitialValue(self._excludeNamespaces);
    },
    confirm() {
      self._isEnabled = self.isEnabled;
      self._auditEnabled = self.auditEnabled;
      self._docker = self.docker;
      self._kubelet = self.kubelet;
      self._kernel = self.kernel;
      self.includeNamespaces.confirm();
      self._includeNamespaces = self.includeNamespaces.value;
      self.excludeNamespaces.confirm();
      self._excludeNamespaces = self.excludeNamespaces.value;
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.reset();
    },
  }))
  .views((self) => ({
    get isValid() {
      let res = true;
      if (self.isEnabled) {
        if (!self.includeNamespaces.isValid) res = false;
        if (!self.excludeNamespaces.isValid) res = false;
      }
      return res;
    },
    get invalidReason() {
      let reason = "";
      if (self.isEnabled) {
        if (!self.includeNamespaces.isValid) reason = "include namespaces";
        if (!self.excludeNamespaces.isValid) reason = "exclude namespaces";
      }
      return reason;
    },
    get isDirty() {
      let res = false;
      if (self.isEnabled !== self._isEnabled) res = true;
      if (self.isEnabled) {
        if (self.auditEnabled !== self._auditEnabled) res = true;
        if (self.docker !== self._docker) res = true;
        if (self.kubelet !== self._kubelet) res = true;
        if (self.kernel !== self._kernel) res = true;
        if (self.includeNamespaces.isDirty) res = true;
        if (self.excludeNamespaces.isDirty) res = true;
      }
      return res;
    },
    get dirtyReason() {
      let reason = "";
      if (self.isEnabled !== self._isEnabled) reason = "enabled changed";
      if (self.isEnabled) {
        if (self.auditEnabled !== self._auditEnabled) reason = "audit enabled changed";
        if (self.docker !== self._docker) reason = "docker changed";
        if (self.kubelet !== self._kubelet) reason = "kubelet changed";
        if (self.kernel !== self._kernel) reason = "kernel changed";
        if (self.includeNamespaces.isDirty) reason = self.includeNamespaces.isDirtyReason;
        if (self.excludeNamespaces.isDirty) reason = self.excludeNamespaces.isDirtyReason;
      }
      return reason;
    },
    get asObject() {
      let res: any = {
        auditEnabled: self.auditEnabled,
        docker: self.docker,
        kubelet: self.kubelet,
        kernel: self.kernel,
      };
      if (self.includeNamespaces.value) {
        res.includeNamespaces = self.includeNamespaces.value;
      }
      if (self.excludeNamespaces.value) {
        res.excludeNamespaces = self.excludeNamespaces.value;
      }
      return res;
    },
  }));
