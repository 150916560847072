import * as React from "react";
import { observer } from "mobx-react-lite";
import { useNavigate, useLocation, Routes, Route, Navigate } from "react-router-dom";
import { notification } from "antd";
import { CreateLayout } from "../../../components/create/layout";
import { IdentityCreateGeneral } from "./general";
import { IdentityDraftMobx } from "../../../mst/stores/identity.draft";
import { IdentityCreateCloudaccess } from "./cloudaccess";
import { parentLink, request, resourceLink } from "../../../services/cpln";
import { CreateFormLink } from "../../org/create/create";
import { TagsNew } from "../../../components/create/tags";
import { NativeNetworkResourcesBase } from "../nativeNetworkResources/nativeNetworkResourcesBase";
import { IdentityCreateNetworkResources } from "../networkResources/networkResourcesCreate";
import { BasePathContext, useBasePath } from "../../../reactContexts/basePathContext";
import { PromptContext } from "../../../mobxStores/prompt/prompt";
import { NGFormData } from "../../../mobxStores/ngFormData";
import { NGFormContext } from "../../../reactContexts/ngFormContext";

interface Props {
  identity: IdentityDraftMobx;
}

const IdentityCreateRaw: React.FC<Props> = ({ identity }) => {
  const formDataRef = React.useRef(new NGFormData());
  const basePath = useBasePath("/gvc/:gvc/identity/-create/*");

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    const startingPage = `${basePath}/-general`;
    navigate(startingPage, { replace: true });
  }, []);

  async function onConfirm() {
    try {
      setIsLoading(true);
      const body = identity.asObject;
      await request({ method: "post", url: parentLink("identity"), body });
      const itemLink = resourceLink("identity", body.name);
      notification.success({
        message: "Success",
        description: "Identity is created",
      });
      PromptContext.setIsDisabled(true);
      navigate(`/console${itemLink}`);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
      setIsLoading(false);
    }
  }

  function shouldBlockNavigation(nextLocation: any) {
    const { pathname } = nextLocation;
    return !pathname.includes("/identity/-create");
  }

  const links: CreateFormLink[] = [
    { name: "General", value: `-general`, isActive: true },
    { name: "Cloud Access", value: `-cloudaccess`, isActive: identity.name.isValid },
    { name: "Network Resources", value: `-networkresources`, isActive: identity.name.isValid },
    { name: "Native Networking", value: `-nativenetworkresources`, isActive: identity.name.isValid },
    { name: "Tags", value: `-tags`, isActive: identity.isValidForTags },
  ];

  const next = {
    isActive: false,
    label: "Next",
    url: "/",
  };
  if (pathname.includes("-general")) {
    next.isActive = identity.name.isValid;
    next.label = "Next (Cloud Access)";
    next.url = `-cloudaccess`;
  } else if (pathname.includes("-cloudaccess")) {
    next.isActive = identity.name.isValid;
    next.label = "Next (Network Resources)";
    next.url = `-networkresources`;
  } else if (pathname.includes("-networkresources")) {
    next.isActive = identity.name.isValid;
    next.label = "Next (Native Networking)";
    next.url = `-nativenetworkresources`;
  } else if (pathname.includes("-nativenetworkresources")) {
    next.isActive = identity.isValidForTags;
    next.label = "Next (Tags)";
    next.url = `-tags`;
  }

  return (
    <BasePathContext.Provider value={basePath}>
      <NGFormContext.Provider value={formDataRef.current}>
        <CreateLayout
          getPreview={() => ({ kind: "identity", ...identity.asObject })}
          next={next}
          shouldBlockNavigation={shouldBlockNavigation}
          title={"Create Identity"}
          name={identity.name.value}
          canCreate={identity.isValid}
          onConfirm={onConfirm}
          isLoading={isLoading}
          links={links}
        >
          <Routes>
            <Route index element={<Navigate to={`-general`} replace />} />
            <Route path={`-general`} element={<IdentityCreateGeneral identity={identity} />} />
            <Route path={`-cloudaccess`} element={<IdentityCreateCloudaccess identity={identity} />} />
            <Route path={`-networkresources/*`} element={<IdentityCreateNetworkResources identity={identity} />} />
            <Route path={`-nativenetworkresources`} element={<NativeNetworkResourcesBase identity={identity} />} />
            <Route path={`-tags`} element={<TagsNew tags={identity.tags} />} />
          </Routes>
        </CreateLayout>
      </NGFormContext.Provider>
    </BasePathContext.Provider>
  );
};

export const IdentityCreate = observer(IdentityCreateRaw);
