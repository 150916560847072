import * as React from "react";
import jsYaml from "js-yaml";
import { ViewModal } from "../../components/modals/viewModal";
import { WorkloadMobx } from "../../mst/kinds/workload";
import { request } from "../../services/cpln";
import { Button as AntButton, Space, Checkbox, Dropdown, notification } from "antd";
import { k8sKeySort, toSortedJSON } from "../../services/utils";
import clsx from "clsx";
import "./jobExecutions.scss";
import { ChevronDown, ChevronUp } from "react-feather";
import { observer } from "mobx-react-lite";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ConsoleContext } from "../../mobxStores/consoleContext/consoleContext";
import { CommandModal_StartCronJob } from "./commandModal_startCronJob";
import { NGButton } from "../../newcomponents/button/Button";
import { useBasePath } from "../../reactContexts/basePathContext";
import { CommandModal_ConfirmReplicaTermination } from "./commands/commandModal_comfirmReplicaTermination";
import { DownOutlined } from "@ant-design/icons";
import { DateString } from "../../components/dateString";
import { Timezone } from "../../mobxStores/userData/timezone";
import { formatStrings } from "../../utils/time";
import moment from "moment";
import { Deployment } from "../../schema/types/workload/deployment";
import { Message } from "./statefulDeployments/message";
import { useCommandsContext } from "../../components/detail/commandsContext";

const STORAGE_KEY_VIEW_CONTAINERS = "workload-job-view-containers";

interface ConfirmReplicaTerminationProps {
  location: string;
  replica: string;
}
interface Props {
  workloadMobx: WorkloadMobx;
  deployments: Deployment[];
}

// TODO update icons
const JobExecutionsRaw: React.FC<Props> = ({ workloadMobx, deployments: deploymentItems }) => {
  const { org } = ConsoleContext;
  const basePath = useBasePath("/gvc/:gvc/workload/:workload/*");
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { commands } = useCommandsContext();

  const deployments = React.useMemo<Deployment[]>(() => {
    const result = [...deploymentItems];
    const deploymentLocations: string[] = deploymentItems.map((d) => d.name).filter(Boolean);

    for (const c of commands.toReversed()) {
      if (
        //
        !!c.spec?.location &&
        !deploymentLocations.includes(c.spec.location) &&
        c.type === "runCronWorkload" && //
        !!c.lifecycleStage &&
        ["running", "pending", "failed"].includes(c.lifecycleStage)
      ) {
        result.push({
          name: c.spec.location,
          kind: "deployment",
          links: [{ href: "self", rel: `${workloadMobx.selfLink}/deployment/${c.spec.location}` }],
        });
        deploymentLocations.push(c.spec.location);
      }
    }
    return result;
  }, [deploymentItems, deploymentItems, commands, commands]);

  const updatedTimeoutIdRef = React.useRef<any>(null);
  const [collapsedDeployments, setCollapsedDeployments] = React.useState<string[]>([]);
  const [isViewing, setIsViewing] = React.useState(false);
  function getDefaultViewContainers() {
    let value = false;
    const localValue = window.localStorage.getItem(STORAGE_KEY_VIEW_CONTAINERS);
    if (localValue && localValue === "true") {
      value = true;
    }
    return value;
  }
  const [viewContainers, setViewContainers] = React.useState(getDefaultViewContainers());
  const [startCronJobLocation, setStartCronJobLocation] = React.useState("");
  const [terminateReplicaModal, setTerminateReplicaModal] = React.useState<ConfirmReplicaTerminationProps | undefined>(
    undefined,
  );

  React.useEffect(() => {
    window.localStorage.setItem(STORAGE_KEY_VIEW_CONTAINERS, String(viewContainers));
  }, [viewContainers]);

  React.useEffect(() => {
    return () => {
      if (updatedTimeoutIdRef.current) {
        clearTimeout(updatedTimeoutIdRef.current);
      }
    };
  }, []);

  function onToggleCollapseDeployment(deployment: string) {
    let _collapsedDeployments = [...collapsedDeployments];
    if (_collapsedDeployments.includes(deployment)) {
      _collapsedDeployments = _collapsedDeployments.filter((x) => x !== deployment);
    } else {
      _collapsedDeployments.push(deployment);
    }
    setCollapsedDeployments(_collapsedDeployments);
  }

  async function handleExportMenuClick(e: any) {
    if (e.key === "json") {
      await exportJSON();
    } else if (e.key === "yaml") {
      await exportYaml();
    }
  }

  async function exportJSON() {
    try {
      const a = document.createElement("a");
      a.style.display = "none";
      a.classList.add("cpln-temp-a");
      a.download = workloadMobx.name + "-deployments.json";
      let blob = "";
      const { data } = await request({ url: workloadMobx.selfLink + "/deployment" });
      blob = JSON.stringify(toSortedJSON(data), null, 2);
      const file = new Blob([blob], { type: "text/json" });
      const href = URL.createObjectURL(file);
      a.href = href;
      a.click();
    } catch (e) {
      notification.warning({ message: "Failed", description: e.message });
    }
  }

  async function exportYaml() {
    try {
      const a = document.createElement("a");
      a.style.display = "none";
      a.classList.add("cpln-temp-a");
      a.download = workloadMobx.name + "-deployments.yaml";
      let blob = "";
      const { data } = await request({ url: workloadMobx.selfLink + "/deployment" });
      blob = jsYaml.dump(data, { indent: 2, noRefs: true, sortKeys: k8sKeySort });
      const file = new Blob([blob], { type: "text/yaml" });
      const href = URL.createObjectURL(file);
      a.href = href;
      a.click();
    } catch (e) {
      notification.warning({ message: "Failed", description: e.message });
    }
  }

  const isCollapsedAll = deployments.length === collapsedDeployments.length;

  function onToggleCollapseAll() {
    if (isCollapsedAll) {
      setCollapsedDeployments([]);
    } else {
      setCollapsedDeployments(deployments.map((d) => d.name));
    }
  }

  return (
    <>
      {isViewing ? (
        <ViewModal
          kind={"deployment"}
          link={workloadMobx.selfLink + "/deployment"}
          onClose={() => setIsViewing(false)}
          title={workloadMobx.name + " Deployments"}
          visible={isViewing}
          filename={workloadMobx.name + "-deployments"}
        />
      ) : null}
      {!!terminateReplicaModal ? (
        <CommandModal_ConfirmReplicaTermination
          location={terminateReplicaModal.location}
          replica={terminateReplicaModal.replica}
          onClose={() => setTerminateReplicaModal(undefined)}
          onDone={() => setTerminateReplicaModal(undefined)}
          visible={!!terminateReplicaModal}
        />
      ) : null}
      <div className="absolute top-0 right-0 flex items-start gap-6">
        <button onClick={onToggleCollapseAll} className={"flex items-center ngfocus color-link"}>
          {isCollapsedAll ? "Expand All" : "Collapse All"}
          {isCollapsedAll ? <ChevronDown className={`feather-icon`} /> : <ChevronUp className={`feather-icon`} />}
        </button>
        <div className="flex flex-col items-start">
          <button className="m-0 mr-2 flex items-center ngfocus color-link" onClick={() => setIsViewing(true)}>
            <span>View Executions</span>
          </button>
          <Dropdown
            menu={{
              onClick: handleExportMenuClick,
              items: [
                { key: "json", label: "JSON" },
                { key: "yaml", label: "Yaml" },
              ],
            }}
            trigger={["click"]}
          >
            <button className="m-0 mr-2 flex items-center ngfocus color-link">
              <span>Export Executions</span>
              <ChevronDown className={`feather-icon`} />
            </button>
          </Dropdown>
        </div>
      </div>
      <div className="flex items-center mb-4">
        <Checkbox
          id={"view-containers"}
          className="mr-2"
          checked={viewContainers}
          onChange={(e) => setViewContainers(e.target.checked)}
        />
        <label className="cursor-pointer" htmlFor={"view-containers"}>
          View Containers
        </label>
      </div>
      <div>
        {deployments
          .slice()
          .sort((a, b) => {
            if (a.name > b.name) return 1;
            if (a.name < b.name) return -1;
            return 0;
          })
          .map((deployment, _deploymentIndex) => {
            let isCollapsed = collapsedDeployments.includes(deployment.name);

            const deploymentExecutions = (deployment.status?.jobExecutions as any) || [];
            const executions: any[] = [
              ...deploymentExecutions,
              ...commands
                .filter(
                  (c) =>
                    c.type === "runCronWorkload" &&
                    c.spec?.location === deployment.name &&
                    !!c.lifecycleStage &&
                    ["running", "pending", "failed"].includes(c.lifecycleStage) &&
                    !deploymentExecutions.some((e) => e.name === `command-${c.id}`),
                )
                .slice(0, 30)
                .map((c) => ({
                  name: `command-${c.id}`,
                  conditions: [],
                  containers: {},
                  replica: "",
                  startTime: c.created,
                  workloadVersion: 0,
                  status: c.lifecycleStage === "running" ? "active" : c.lifecycleStage,
                })),
            ];
            return (
              <div className={"mb-10"} key={deployment.name}>
                <div className={`flex items-center text-xl font-medium mr-4 mb-4 gap-2`}>
                  {deployment.name}
                  <button onClick={() => onToggleCollapseDeployment(deployment.name)} className="text-base">
                    {isCollapsed ? (
                      <ChevronDown className={`feather-icon`} />
                    ) : (
                      <ChevronUp className={`feather-icon`} />
                    )}
                  </button>
                  <NGButton
                    size={"small"}
                    variant={"secondary"}
                    onClick={() => {
                      setStartCronJobLocation(deployment.name);
                    }}
                  >
                    Start Cron Job
                  </NGButton>
                </div>
                <Message
                  className="mb-2"
                  messages={[
                    deployment.status?.message || "",
                    ...(deployment.status?.versions || []).map((v) => v.message || ""),
                  ]}
                  logsLink={pathname.replace("-jobexecutions", "-logs?trigger=true")}
                  eventsLink={pathname.replace("-jobexecutions", "-events")}
                />
                {isCollapsed ? null : (
                  <>
                    {executions.length < 1 ? (
                      <div>No job executions on this location</div>
                    ) : (
                      <div className="flex items-center px-4 py-2 table-labels rounded text-sm">
                        <div className="w-3/12">Name</div>
                        <div className="w-2/12">Status</div>
                        <div className="w-3/12">Start Time ({Timezone.label})</div>
                        <div className="w-3/12">Completion Time ({Timezone.label})</div>
                        <div className="w-1/12">Actions</div>
                      </div>
                    )}
                    {executions
                      .slice()
                      .sort((a, b) => {
                        const aDate = new Date(a.startTime).getTime();
                        const bDate = new Date(b.startTime).getTime();
                        if (aDate < bDate) return 1;
                        if (aDate > bDate) return -1;
                        return 0;
                      })
                      .map((exec, index) => {
                        const lastItem = index === executions.length - 1;

                        const liveLogs = exec.status === "active" && !exec.completionTime;

                        const startDate = moment(exec.startTime).subtract(1, "second");
                        const completionDate = moment(exec.completionTime).add(1, "second");

                        const logLink = `${basePath}/-logs?query=${encodeURIComponent(
                          `{gvc="${workloadMobx.gvc}", location="${deployment.name}", workload="${workloadMobx.name}", replica=~"${exec.name}.+"}`,
                        )}&from=${startDate.toISOString()}${
                          exec.completionTime ? `&to=${completionDate.toISOString()}` : ""
                        }&execute=true&live=${liveLogs ? "true" : "false"}`;

                        const isStopReplicaDisabled =
                          !exec.replica ||
                          exec.status === "failed" ||
                          exec.status === "invalid" ||
                          exec.status === "removed";

                        return (
                          <>
                            <div className={clsx("jobExecution px-4 py-2 flex flex-col", { "border-b": !lastItem })}>
                              <div className="flex items-center">
                                <div className={clsx("w-3/12 pr-2 font-semibold")}>{exec.name}</div>
                                <div className={clsx("w-2/12 font-semibold")}>
                                  <span
                                    className={clsx({
                                      statusActive: exec.status === "active",
                                      statusSuccessful: exec.status === "successful",
                                      statusFailed: exec.status === "failed",
                                      statusInvalid: exec.status === "invalid",
                                      statusRemoved: exec.status === "removed",
                                    })}
                                  >
                                    {exec.status.slice(0, 1).toUpperCase()}
                                    {exec.status.slice(1)}
                                  </span>
                                </div>
                                <div className="w-3/12">
                                  <DateString iso={exec.startTime} format={formatStrings.sec} />
                                </div>
                                <div className="w-3/12">
                                  <DateString iso={exec.completionTime} format={formatStrings.sec} />
                                </div>
                                <div className={clsx("w-1/12")}>
                                  <Dropdown
                                    trigger={["click"]}
                                    menu={{
                                      onClick: async function ({ key }: any) {
                                        switch (key) {
                                          case "view-logs":
                                            navigate(logLink);
                                            break;
                                          case "stop-replica":
                                            setTerminateReplicaModal({
                                              location: deployment.name,
                                              replica: exec.replica,
                                            });
                                            break;
                                          default:
                                            break;
                                        }
                                      },
                                      items: [
                                        {
                                          key: "view-logs",
                                          label: "View Logs",
                                        },
                                        {
                                          key: "stop-replica",
                                          label: "Stop Replica",
                                          danger: !isStopReplicaDisabled,
                                          disabled: isStopReplicaDisabled,
                                        },
                                      ],
                                    }}
                                  >
                                    <AntButton size="small" type={"primary"}>
                                      <Space>
                                        Actions
                                        <DownOutlined />
                                      </Space>
                                    </AntButton>
                                  </Dropdown>
                                </div>
                              </div>
                              {viewContainers && Object.values(exec.containers).length > 0 ? (
                                <div className="px-4 my-2">
                                  <div className="pl-2 font-semibold">Containers</div>
                                  <div
                                    className="flex items-center px-2 py-1 table-labels border border-b-0"
                                    style={{ borderTopLeftRadius: 6, borderTopRightRadius: 6 }}
                                  >
                                    <div className="w-3/12">Name</div>
                                    <div className="w-6/12">Image</div>
                                    <div className="w-1/12">CPU</div>
                                    <div className="w-1/12">Memory</div>
                                    <div className="w-1/12">Replicas</div>
                                  </div>
                                  {((Object.values(exec.containers) as unknown) as any[]).map((container, index) => (
                                    <div className={clsx("border", { "border-t-0": index !== 0 })}>
                                      <div className={`flex items-center px-2 py-1`}>
                                        <div className="w-3/12">{container.name}</div>
                                        <div className="w-6/12 truncate">
                                          {container.image.startsWith(`/org/${org}/image/`) ? (
                                            <Link
                                              className={`ngfocus color-link`}
                                              to={`/console/org/${org}/image/${container.image
                                                .replace(`/org/${org}/image/`, "")
                                                .replaceAll(":", "/")}`}
                                            >
                                              {container.image}
                                            </Link>
                                          ) : (
                                            container.image
                                          )}
                                        </div>
                                        <div className="w-1/12">{container.resources.cpu}m</div>
                                        <div className="w-1/12">{container.resources.memory}Mi</div>
                                        <div className="w-1/12">
                                          {container.resources.replicasReady}/{container.resources.replicas}
                                        </div>
                                      </div>
                                      {container.message ? (
                                        <div className="px-2 py-1 color-danger">{container.message}</div>
                                      ) : null}
                                    </div>
                                  ))}
                                </div>
                              ) : null}
                            </div>
                          </>
                        );
                      })}
                  </>
                )}
              </div>
            );
          })}
      </div>
      {startCronJobLocation ? (
        <CommandModal_StartCronJob
          workloadMobx={workloadMobx}
          initialLocation={startCronJobLocation}
          onDone={() => setStartCronJobLocation("")}
          onClose={() => setStartCronJobLocation("")}
          visible={!!startCronJobLocation}
        />
      ) : null}
    </>
  );
};

export const JobExecutions = observer(JobExecutionsRaw);
