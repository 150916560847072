import * as React from "react";
import { observer } from "mobx-react-lite";
import { IdentityDraftAzureHelper } from "../../../../mst/stores/identity.azure.helper";
import { Link } from "react-router-dom";
import { CloudaccountMobx, CloudaccountModel } from "../../../../mst/kinds/cloudaccount";
import { request } from "../../../../services/cpln";
import { LoadingOutlined } from "@ant-design/icons";
import { FormLabel } from "../../../../components/forms/formLabel";
import { NGButton } from "../../../../newcomponents/button/Button";
import { Tooltip } from "../../../../components/Tooltip";
import { IdentityStatusProviderMobx } from "../../../../mst/kinds/identity";
import { NGChip } from "../../../../newcomponents/chip";

interface Props {
  azure: IdentityDraftAzureHelper;
  providerStatus?: IdentityStatusProviderMobx;
  infoOnly: boolean;
}
const AzureIdentityInfoRaw: React.FC<Props> = ({ azure, providerStatus, infoOnly }) => {
  const azureLogoPathRef = React.useRef("/resources/logos/azureLogo.png");
  const azureLogoPath = azureLogoPathRef.current;
  const [isPageReady, setIsPageReady] = React.useState(false);
  const [cloudaccount, setCloudaccount] = React.useState<CloudaccountMobx>(null as any);
  const cloudaccountLink = azure.edited.cloudaccountLink;
  // const hasStatusError = providerStatus?.usable === false;

  React.useEffect(() => {
    if (!azure.edited.isActive) return;
    request({ url: azure.edited.cloudaccountLink })
      .then(({ data }) => {
        setCloudaccount(CloudaccountModel.create(data));
        setIsPageReady(true);
      })
      .catch(() => {
        setIsPageReady(true);
      });
  }, [azure.edited.isActive, azure.edited.cloudaccountLink]);

  if (!azure.edited.isActive) {
    if (infoOnly) return null;
    return (
      <button
        data-testid="azure-none"
        onClick={() => {
          azure.setup();
        }}
        className={`mb-8 p-4 w-full hover:shadow-lg border flex flex-col items-center focus-big`}
        style={{ backgroundColor: "#090e17" }}
      >
        <div className="py-2" style={{ height: 88 }}>
          <img className="h-full" src={azureLogoPath} />
        </div>
      </button>
    );
  }

  if (!isPageReady) {
    return (
      <div
        data-testid="azure-loading"
        className="mb-8 p-4 border cursor-wait flex flex-col items-center relative"
        style={{ height: 122, backgroundColor: "#090e17" }}
      >
        <span
          className="absolute flex items-center justify-center text-white"
          style={{ width: 20, height: 20, left: "calc(50% - 10px)", top: "calc(75% - 10px)" }}
        >
          <LoadingOutlined />
        </span>
        <div className="py-2" style={{ height: 44 }}>
          <img className={`h-full`} src={azureLogoPath} />
        </div>
      </div>
    );
  }

  const hasCloudaccount = cloudaccount !== null;
  const cloudaccountName = cloudaccount?.name || cloudaccountLink;

  return (
    <div data-testid="azure-new" className="mb-8 pb-4 border">
      <div
        className="flex gap-4 items-center p-4 pt-5 mb-4 bg-identity-aws-logo-drop border-b-4 border-identity-aws-logo-accent"
        style={{ backgroundColor: "#090e17" }}
      >
        <img style={{ height: 30 }} src={azureLogoPath} />
        {/* {hasStatusError ? <NGChip size="small" variant={"error"} label={"Unusable"} /> : null} */}
      </div>
      <div className="mb-2 px-4 flex">
        <div className="w-4/12 pr-4">
          <FormLabel>Cloud Account</FormLabel>
          <Tooltip title={azure.edited.cloudaccountLink} open={cloudaccountName.length >= 25}>
            {hasCloudaccount ? (
              <Link
                className={`w-full inline-block truncate ngfocus font-medium color-link`}
                data-testid="azure-ca"
                to={`/console${azure.edited.cloudaccountLink}`}
              >
                {cloudaccountName}
              </Link>
            ) : (
              <span className="w-full inline-block truncate">{cloudaccountName}</span>
            )}
          </Tooltip>
        </div>
        {hasCloudaccount && cloudaccount.data?.subscriptionId ? (
          <div className="w-8/12">
            <FormLabel>Subscription Id</FormLabel>
            <div data-testid="azure-subscriptionid" className="font-mono truncate">
              {cloudaccount.data?.subscriptionId}
            </div>
          </div>
        ) : null}
      </div>
      <div className="mb-2 px-4">
        <FormLabel>Role Assignments</FormLabel>
        {azure.edited.roleAssignments.map((roleAssignment, index) => (
          <div key={index} className={`mb-4 mt-2 p-4 border border-t-4 border-identity-azure-logo-accent rounded-b`}>
            <FormLabel>Scope</FormLabel>
            <Tooltip
              title={roleAssignment.forEdit.scopeInput.value || "Subscription Itself (Legacy)"}
              open={roleAssignment.forEdit.scopeInput.value.length >= 86}
            >
              <div
                data-testid={`azure-scope-${roleAssignment.forEdit.scopeInput.value || "subscription-itself"}`}
                className="font-mono truncate mb-2"
              >
                {roleAssignment.forEdit.scopeInput.value || "Subscription Itself (Legacy)"}
              </div>
            </Tooltip>
            <FormLabel>Roles</FormLabel>
            <ul className="pl-8">
              {roleAssignment.forEdit.roles.map((role) => (
                <li key={role} className="list-disc">
                  <div
                    data-testid={`azure-role-${role}-scope-${
                      roleAssignment.forEdit.scopeInput.value || "subscription-itself"
                    }`}
                    className="font-mono truncate"
                  >
                    {role}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      {infoOnly ? null : (
        <div className="px-4 flex items-center justify-end">
          <NGButton
            style={{ width: 77, marginRight: 10 }}
            className="mt-2"
            variant={"primary"}
            onClick={() => {
              azure.setup();
            }}
          >
            Edit
          </NGButton>
          <NGButton
            variant={"danger"}
            outlined
            style={{ width: 77, marginLeft: 10 }}
            className="mt-2"
            onClick={() => {
              azure.remove();
            }}
          >
            Remove
          </NGButton>
        </div>
      )}
    </div>
  );
};

export const AzureIdentityInfo = observer(AzureIdentityInfoRaw);
