import { types, Instance } from "mobx-state-tree";
import { StringModel } from "./stringModel";
import { NumberModel } from "./numberModel";
import { SelectModel } from "./selectModel";

function parseMemory(memory: string): { isOther: boolean; amount: number; unit: string } {
  const validUnits = ["Mi", "Gi"];
  if (memory.includes("e")) {
    return { isOther: true, amount: 0, unit: "" };
  }
  for (let unit of validUnits) {
    if (memory.endsWith(unit)) {
      try {
        let amountStr = memory.split(unit)[0];
        let amount = Number(amountStr);
        if (amountStr.includes(".") && unit !== "Gi") {
          return { isOther: true, amount: 0, unit: "" };
        }
        if (amountStr.includes(".") && unit === "Gi") {
          const whole = Number(amountStr.split(".")[0]);
          const fraction = Number(amountStr.split(".")[1]);
          return { isOther: false, amount: whole * 1_000 + fraction * 100, unit: "Mi" };
        }
        return { isOther: false, amount, unit };
      } catch (e) {
        return { isOther: true, amount: 0, unit: "" };
      }
    }
  }
  return { isOther: true, amount: 0, unit: "" };
}

export const memoryUnitOptions = [
  { label: "Mi (Mebibytes)", value: "Mi" },
  { label: "Gi (Gibibytes)", value: "Gi" },
  { label: "Other", value: "Other" },
];
export const MemoryModel = types
  .model({
    _label: types.enumeration(["Min", "Max", "Reserved"]),
    _defaultValue: types.string,
    _memory: types.optional(types.string, ""),
    _requiredOnNonMin: types.optional(types.boolean, true),
    memory: types.optional(StringModel, () => StringModel.create({ label: "Memory", validationKey: "memoryResource" })),
    amount: types.optional(StringModel, () => NumberModel.create({ label: "Memory" })),
    unit: types.optional(SelectModel, () =>
      SelectModel.create({
        label: "Unit",
        initialValue: memoryUnitOptions[2].value,
        options: memoryUnitOptions,
      }),
    ),
  })
  .views((self) => ({
    get isOther() {
      return self.unit.value === "Other";
    },
  }))
  .actions((self) => ({
    reset() {
      self.memory.setLabel(`${self._label} Memory`);
      self.amount.setLabel(`${self._label} Memory`);
      self.memory.setIsRequired(false);
      self.amount.setIsRequired(false);
      if (self._requiredOnNonMin) {
        self.memory.setIsRequired(self._label !== "Min");
        self.amount.setIsRequired(self._label !== "Min");
      }
      self._memory = self._defaultValue;

      self.memory.setInitialValue(self._memory);

      const { isOther, unit, amount } = parseMemory(self._memory);
      if (isOther) {
        self.unit.setInitialValue("Other");
      } else {
        self.amount.setInitialValue(amount > 0 ? String(amount) : "");
        self.unit.setInitialValue(unit);
      }

      if (self.unit.value === "Mi") {
        self.amount.setMin(32);
      } else {
        self.amount.setMin(1);
      }
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.reset();
    },
  }))
  .actions((self) => ({
    setUnit(newUnit: string) {
      self.unit.setValue(newUnit);
      if (self.unit.value === "Mi") {
        self.amount.setMin(32);
      } else if (newUnit === "Gi") {
        self.amount.setMin(1);
      }
    },
    setInitialUnit(value: string) {
      self.unit.setInitialValue(value);
      if (self.unit.value === "Mi") {
        self.amount.setMin(32);
      } else if (value === "Gi") {
        self.amount.setMin(1);
      }
    },
    setLabel(value: "Min" | "Max" | "Reserved") {
      self._label = value;
      self.memory.setLabel(`${self._label} Memory`);
      self.amount.setLabel(`${self._label} Memory`);
      self.memory.setIsRequired(false);
      self.amount.setIsRequired(false);
      if (self._requiredOnNonMin) {
        self.memory.setIsRequired(self._label !== "Min");
        self.amount.setIsRequired(self._label !== "Min");
      }
    },
    setInitialValue(initial: string) {
      self._memory = initial;
      self.reset();
    },
    confirm() {
      if (self.isOther) {
        self._memory = self.memory.value;
        self._defaultValue = self._memory;
        self.memory.confirm();
      } else {
        self._memory = String(self.amount.value) + String(self.unit.value);
        self._defaultValue = self._memory;
        self.amount.confirm();
        self.unit.confirm();
      }
    },
  }))
  .views((self) => ({
    get isDirty() {
      let res = false;
      if (self.isOther) {
        if (self.memory.isDirty) res = true;
      } else {
        if (self.amount.isDirty) res = true;
        if (self.unit.isDirty) res = true;
      }
      return res;
    },
    get isValid() {
      return self.isOther ? self.memory.isValid : self.amount.isValid;
    },
    get value() {
      return self.isOther
        ? self.memory.value
        : self.amount.value
        ? String(self.amount.value) + String(self.unit.value)
        : "";
    },
  }));

export interface MemoryMobx extends Instance<typeof MemoryModel> {}
