import { types, Instance } from "mobx-state-tree";
import { defaultValues } from "../base";
import { MemoryModel } from "../../mobxDataModels/memoryModel";
import { CpuModel } from "../../mobxDataModels/cpuModel";

export const VolumeSetDraftMountOptionsModel = types
  .model({
    cpu: types.optional(CpuModel, () =>
      CpuModel.create({ _label: "Max", _defaultValue: defaultValues.cpu, _requiredOnNonMin: false }),
    ),
    memory: types.optional(MemoryModel, () =>
      MemoryModel.create({ _label: "Max", _defaultValue: defaultValues.memory, _requiredOnNonMin: false }),
    ),
    minCpu: types.optional(CpuModel, () =>
      CpuModel.create({ _label: "Min", _defaultValue: "0m", _requiredOnNonMin: false }),
    ),
    minMemory: types.optional(MemoryModel, () =>
      MemoryModel.create({ _label: "Min", _defaultValue: "0Mi", _requiredOnNonMin: false }),
    ),
  })
  .actions((self) => ({
    reset() {
      self.cpu.reset();
      self.memory.reset();
      self.minCpu.reset();
      self.minMemory.reset();
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.reset();
    },
    confirm() {
      self.cpu.confirm();
      self.memory.confirm();
      self.minCpu.confirm();
      self.minMemory.confirm();
    },
  }))
  .views((self) => ({
    get isDirty() {
      let res = false;
      if (self.cpu.isDirty) res = true;
      if (self.memory.isDirty) res = true;
      if (self.minCpu.isDirty) res = true;
      if (self.minMemory.isDirty) res = true;
      return res;
    },
    get isValid() {
      let res = true;
      if (!self.cpu.isValid) res = false;
      if (!self.memory.isValid) res = false;
      if (!self.minCpu.isValid) res = false;
      if (!self.minMemory.isValid) res = false;
      return res;
    },
    get asObject() {
      let res: any = {};
      let resources: any = {};
      let anyIsSet = false;
      if (self.cpu.amount.value) {
        resources.maxCpu = self.cpu.value;
        anyIsSet = true;
      }
      if (self.memory.value) {
        resources.maxMemory = self.memory.value;
        anyIsSet = true;
      }
      if (self.minCpu.amount.value) {
        resources.minCpu = self.minCpu.value;
        anyIsSet = true;
      }
      if (self.minMemory.value) {
        resources.minMemory = self.minMemory.value;
        anyIsSet = true;
      }
      if (anyIsSet) {
        res.resources = resources;
      }
      return res;
    },
  }));
export interface VolumeSetDraftMountOptionsMobx extends Instance<typeof VolumeSetDraftMountOptionsModel> {}
