import * as React from "react";
import { observer } from "mobx-react-lite";
import { RequestRetryPolicyMobx } from "../../mst/kinds/workload";
import { defaultValues } from "../../mst/base";
import { FormButtons } from "../../components/forms/formButtons";
import { Select as AntSelect, notification } from "antd";
import { arraysAreEqual, request } from "../../services/cpln";
import { NGInput } from "../../newcomponents/input/input";
import { NGLabel } from "../../newcomponents/text/label";
import { NGLabelText } from "../../newcomponents/text/labelText";
import { NGSwitch } from "../../newcomponents/switch";

interface Props {
  link: string;
  policy?: RequestRetryPolicyMobx;
}
const RequestRetryPolicyRaw: React.FC<Props> = ({ link, policy }) => {
  const [initialPolicy, setInitialPolicy] = React.useState({
    enabled: !!policy,
    attempts: policy?.attempts || defaultValues.workload.requestRetryPolicy.attempts.default,
    retryOn: policy?.retryOn || defaultValues.workload.requestRetryPolicy.retryOn.default,
  });
  const [enableRequestPolicy, setEnableRequestPolicy] = React.useState(initialPolicy.enabled);
  const [attempts, setAttempts] = React.useState(String(initialPolicy.attempts));
  const [retryOn, setRetryOn] = React.useState(initialPolicy.retryOn);

  const [isLoading, setIsLoading] = React.useState(false);
  const [isDirty, setIsDirty] = React.useState(false);

  React.useEffect(() => {
    let isDirty = false;
    if (initialPolicy.enabled !== enableRequestPolicy) isDirty = true;
    if (enableRequestPolicy) {
      if (String(initialPolicy.attempts) !== attempts) isDirty = true;
      if (!arraysAreEqual(initialPolicy.retryOn, retryOn)) isDirty = true;
    }
    setIsDirty(isDirty);
  }, [
    initialPolicy.enabled,
    initialPolicy.attempts,
    initialPolicy.retryOn,
    attempts,
    retryOn.length,
    retryOn,
    enableRequestPolicy,
  ]);

  function reset() {
    setAttempts(String(initialPolicy.attempts));
    setRetryOn(initialPolicy.retryOn);
    setEnableRequestPolicy(initialPolicy.enabled);
  }

  async function save() {
    try {
      setIsLoading(true);
      const newPolicy = enableRequestPolicy
        ? {
            attempts: Number(attempts),
            retryOn: retryOn,
          }
        : null;
      await request({ url: link, method: "patch", body: { spec: { requestRetryPolicy: newPolicy } } });
      const newInitialPolicy = {
        enabled: enableRequestPolicy,
        attempts: Number(attempts),
        retryOn: retryOn,
      };
      if (!enableRequestPolicy) {
        newInitialPolicy.attempts = defaultValues.workload.requestRetryPolicy.attempts.default;
        newInitialPolicy.retryOn = defaultValues.workload.requestRetryPolicy.retryOn.default;
        setAttempts(String(newInitialPolicy.attempts));
        setRetryOn(newInitialPolicy.retryOn);
      }
      setInitialPolicy(newInitialPolicy);
      notification.success({ message: "Success", description: "Updated request retry policy" });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({ message: "Failed", description: errorMessage });
    }
  }

  return (
    <div>
      <NGSwitch
        data-testid="switch-request-retry-policy"
        className="mb-4"
        onChange={setEnableRequestPolicy}
        value={enableRequestPolicy}
      >
        <NGLabelText>Enable Request Retry Policy</NGLabelText>
      </NGSwitch>
      {enableRequestPolicy ? (
        <>
          <NGLabel size={3}>Attempts</NGLabel>
          <NGInput
            className="mb-4"
            style={{ width: 450 }}
            value={attempts}
            onChange={(e) => setAttempts(e.target.value)}
          />
          <NGLabel size={3}>Retry On</NGLabel>
          <AntSelect
            style={{ width: 450 }}
            mode={"tags"}
            options={defaultValues.workload.requestRetryPolicy.retryOn.options.map((v) => ({
              label: v,
              value: v,
            }))}
            tokenSeparators={[",", " "]}
            value={retryOn}
            onChange={(values) => {
              console.log(values);
              setRetryOn(values);
            }}
          />
        </>
      ) : null}

      <FormButtons
        loading={isLoading}
        onReset={reset}
        onSave={save}
        resetDisabled={!isDirty || isLoading}
        saveDisabled={!isDirty || isLoading}
      />
    </div>
  );
};

export const RequestRetryPolicy = observer(RequestRetryPolicyRaw);
