import * as React from "react";
import { Radio, notification } from "antd";
import { observer } from "mobx-react-lite";
import { StringMobx } from "../../../../mobxDataModels/stringModel";
import { SelectMobx } from "../../../../mobxDataModels/selectModel";
import { Copy, ExternalLink } from "react-feather";
import { NGFormElement } from "../../../../newcomponents/ngformelement/ngformelement";
import { NGButton } from "../../../../newcomponents/button/Button";
import { useTableKindId } from "../../../../newcomponents/table/data/useTableKindId";
import { useTableItemQueryData } from "../../../../newcomponents/table/data/useTableItemQueryData";
import { Secret } from "../../../../schema/types/secret";
import { Table } from "../../../../newcomponents/table/table";
import { TableHeaderRefreshButton } from "../../../../newcomponents/table/components/RefreshButton";
import { NameDescriptionColumn } from "../../../../newcomponents/table/columns/wellKnown/nameDescriptionColumn";
import { TagsColumn } from "../../../../newcomponents/table/columns/wellKnown/tagsColumn";
import { CreatedColumn } from "../../../../newcomponents/table/columns/wellKnown/createdColumn";
import { LastModifiedColumn } from "../../../../newcomponents/table/columns/wellKnown/lastModifiedColumn";
import { linksOf } from "../../../../services/cpln";

interface SecretTableItem extends Secret {
  selfLink: string;
}

interface Props {
  name: string;
  azureMethod: "new-azure-connector" | "old-secret";
  setAzureMethod: React.Dispatch<React.SetStateAction<"new-azure-connector" | "old-secret">>;
  subscriptionId: string;
  locationRef: SelectMobx;
  resourceGroupRef: StringMobx;
  storageAccountRef: StringMobx;
  functionAppNameRef: StringMobx;
  urlRef: StringMobx;
  codeRef: StringMobx;
  selections: string[];
  onSelectionsChange: (values: string[]) => void;
}
const CloudaccountCreateAzureConfigurationRaw: React.FC<Props> = ({
  azureMethod,
  setAzureMethod,
  subscriptionId,
  locationRef,
  resourceGroupRef,
  storageAccountRef,
  functionAppNameRef,
  urlRef,
  codeRef,
  selections,
  onSelectionsChange,
}) => {
  const [activeTab, setActiveTab] = React.useState<"linux" | "win">("linux");

  const { kind, id } = useTableKindId("secret", "cloudaccount-create-azure-secret-list");
  // TODO if there is predefined query, do not allow custom query because of match: 'all'
  const query = React.useMemo(
    () => ({
      kind: "secret" as any,
      spec: { match: "all" as any, terms: [{ property: "type", op: "=" as any, value: "azure-connector" }] },
    }),
    [],
  );
  const qData = useTableItemQueryData<Secret>(kind, {
    fetchAllPages: true,
    query: query,
  });

  async function copyToClipboard(value: string) {
    navigator.clipboard.writeText(value);
    notification.success({
      message: "Copied to clipboard",
    });
  }

  const step1Linux = `
  az functionapp create \\
  --subscription ${subscriptionId || "<subscriptionId>"} \\
  --resource-group ${resourceGroupRef.value || "<resourceGroup>"} \\
  --name ${functionAppNameRef.value || "<functionAppName>"} \\
  --storage-account ${storageAccountRef.value || "<storageAccount>"} \\
  --assign-identity \\
  --role Owner \\
  --scope /subscriptions/${subscriptionId || "<subscriptionId>"} \\
  --os-type linux \\
  --runtime node \\
  --functions-version 4 \\
  --runtime-version 20 \\
  --disable-app-insights \\
  --consumption-plan-location ${locationRef.value || "<location>"}
  `;

  const step1Windows = `
  az functionapp create --subscription ${subscriptionId || "<subscriptionId>"} --resource-group ${
    resourceGroupRef.value || "<resourceGroup>"
  } --name ${functionAppNameRef.value || "<functionAppName>"} --storage-account ${
    storageAccountRef.value || "<storageAccount>"
  } --assign-identity --role Owner --scope /subscriptions/${
    subscriptionId || "<subscriptionId>"
  } --os-type linux --runtime node --functions-version 4 --runtime-version 20 --disable-app-insights --consumption-plan-location ${
    locationRef.value || "<location>"
  }
  `;

  const step2Linux = `
  wget https://storage.googleapis.com/artifacts.cpln-build.appspot.com/binaries/azure-cpln-connector/latest/azure-cpln-connector.zip
  `;

  const step2Windows = `
  wget https://storage.googleapis.com/artifacts.cpln-build.appspot.com/binaries/azure-cpln-connector/latest/azure-cpln-connector.zip
  `;

  // const step2Windows = `
  // curl https://storage.googleapis.com/artifacts.cpln-build.appspot.com/binaries/azure-cpln-connector/latest/azure-cpln-connector.zip --output azure-cpln-connector.zip
  // `;

  const step3Linux = `
  az functionapp deployment source config-zip \\
  --subscription ${subscriptionId || "<subscriptionId>"} \\
  --resource-group ${resourceGroupRef.value || "<resourceGroup>"} \\
  --name ${functionAppNameRef.value || "<functionAppName>"} \\
  --src azure-cpln-connector.zip
  `;

  const step3Windows = `
  az functionapp deployment source config-zip  --subscription ${
    subscriptionId || "<subscriptionId>"
  }  --resource-group ${resourceGroupRef.value || "<resourceGroup>"}  --name ${
    functionAppNameRef.value || "<functionAppName>"
  }  --src azure-cpln-connector.zip
  `;

  const step4Linux = `
  az functionapp function show \\
  --subscription ${subscriptionId || "<subscriptionId>"} \\
  --resource-group ${resourceGroupRef.value || "<resourceGroup>"} \\
  --name ${functionAppNameRef.value || "<functionAppName>"} \\
  --function iam-broker \\
  --query invokeUrlTemplate \\
  -o tsv
  `;

  const step4Windows = `
  az functionapp function show --subscription ${subscriptionId || "<subscriptionId>"} --resource-group ${
    resourceGroupRef.value || "<resourceGroup>"
  } --name ${functionAppNameRef.value || "<functionAppName>"} --function iam-broker --query invokeUrlTemplate -o tsv
  `;

  const step5Linux = `
  az functionapp function keys list \\
  --subscription ${subscriptionId || "<subscriptionId>"} \\
  --resource-group ${resourceGroupRef.value || "<resourceGroup>"} \\
  --name ${functionAppNameRef.value || "<functionAppName>"} \\
  --function-name iam-broker \\
  --query default \\
  -o tsv
  `;

  const step5Windows = `
  az functionapp function keys list --subscription ${subscriptionId || "<subscriptionId>"} --resource-group ${
    resourceGroupRef.value || "<resourceGroup>"
  } --name ${functionAppNameRef.value || "<functionAppName>"} --function-name iam-broker --query default -o tsv
  `;

  const secretTableItems: SecretTableItem[] = qData.visibleItems.map((item) => ({
    ...item,
    selfLink: linksOf(item).self!,
  }));

  return (
    <>
      <div className="mb-2">Select the method of creating this cloud account.</div>
      <Radio.Group
        className="mb-4"
        options={[
          { label: "Azure Connector", value: "new-azure-connector" },
          { label: "Select Existing Secret", value: "old-secret" },
        ]}
        onChange={(e) => {
          setAzureMethod(e.target.value);
        }}
        value={azureMethod}
        optionType="button"
        buttonStyle="solid"
      />
      {azureMethod === "old-secret" ? (
        <Table<SecretTableItem>
          title={"Secrets"}
          data={secretTableItems}
          tableId={id}
          selectMode="single"
          selectKey={"selfLink"}
          selections={selections}
          onSelectionsChange={onSelectionsChange}
          headerRenderer={() => {
            return (
              <>
                <TableHeaderRefreshButton onClick={qData.fetchItems} />
              </>
            );
          }}
          enableFilter
          isLoading={qData.isLoading}
          columns={[
            NameDescriptionColumn(),
            {
              id: "type",
              label: "Type",
              groupLabelMap: {
                aws: "AWS",
                "azure-connector": "Azure Connector",
                "azure-sdk": "Azure SDK",
                docker: "Docker",
                dictionary: "Dictionary",
                ecr: "ECR",
                gcp: "GCP",
                keypair: "Keypair",
                "nats-account": "Nats Account",
                opaque: "Opaque",
                tls: "TLS",
                userpass: "Username & Password",
              },
              size: 200,
            },
            TagsColumn(),
            CreatedColumn(),
            LastModifiedColumn(),
          ]}
        />
      ) : null}
      {azureMethod === "new-azure-connector" ? (
        <>
          <ul style={{ paddingLeft: 24 }}>
            <AzureCLISteps />
            <li className="list-decimal my-2">
              Enter the name of an existing Azure resource group and storage account then select a deployment location.
              These values will be used to generate the commands in the next step.
            </li>
            <div className="flex flex-col" style={{ width: 450 }}>
              <NGFormElement
                name={"resourceGroup"}
                label={resourceGroupRef.label}
                required
                error={resourceGroupRef.error}
                value={resourceGroupRef.value}
                onChange={resourceGroupRef.setValue}
              />
              <NGFormElement
                name={"storageAccount"}
                label={storageAccountRef.label}
                required
                error={storageAccountRef.error}
                value={storageAccountRef.value}
                onChange={storageAccountRef.setValue}
              />
              <NGFormElement
                name={"location"}
                as={"select"}
                label={locationRef.label}
                required
                onChange={locationRef.setValue}
                options={locationRef.options}
                value={locationRef.value}
              />
              <NGFormElement
                name={"functionAppName"}
                label={functionAppNameRef.label}
                required
                value={functionAppNameRef.value}
                onChange={functionAppNameRef.setValue}
              />
            </div>
            <li className="list-decimal my-2">Execute the commands below:</li>
            <div className="flex items-center">
              <NGButton
                style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                size={"small"}
                variant={activeTab === "linux" ? "primary" : "secondary"}
                onClick={() => setActiveTab("linux")}
              >
                Linux/MacOS
              </NGButton>
              <NGButton
                style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                size={"small"}
                variant={activeTab === "win" ? "primary" : "secondary"}
                onClick={() => setActiveTab("win")}
              >
                Windows
              </NGButton>
            </div>
            {activeTab === "linux" ? (
              <>
                <div className="flex my-2">
                  <textarea
                    className="border px-4 font-mono"
                    style={{
                      height: 405,
                      resize: "none",
                      width: 700,
                    }}
                    disabled={true}
                    value={step1Linux}
                  />
                  <button className={`ml-2 ngfocus color-link-hover`} onClick={() => copyToClipboard(step1Linux)}>
                    <Copy className="ml-1 feather-icon" style={{ transform: "translateY(2px)" }} />
                  </button>
                </div>
                <div className="flex my-2">
                  <textarea
                    className="border px-4 font-mono"
                    style={{
                      height: 105,
                      resize: "none",
                      width: 700,
                    }}
                    disabled={true}
                    value={step2Linux}
                  />
                  <button className={`ml-2 ngfocus color-link-hover`} onClick={() => copyToClipboard(step2Linux)}>
                    <Copy className="ml-1 feather-icon" style={{ transform: "translateY(2px)" }} />
                  </button>
                </div>
                <div className="flex my-2">
                  <textarea
                    className="border px-4 font-mono"
                    style={{
                      height: 177,
                      resize: "none",

                      width: 700,
                    }}
                    disabled={true}
                    value={step3Linux}
                  />
                  <button className={`ml-2 ngfocus color-link-hover`} onClick={() => copyToClipboard(step3Linux)}>
                    <Copy className="ml-1 feather-icon" style={{ transform: "translateY(2px)" }} />
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="flex my-2">
                  <textarea
                    className="border px-4 font-mono"
                    style={{
                      height: 177,
                      resize: "none",

                      width: 700,
                    }}
                    disabled={true}
                    value={step1Windows}
                  />
                  <button className={`ml-2 ngfocus color-link-hover`} onClick={() => copyToClipboard(step1Windows)}>
                    <Copy className="ml-1 feather-icon" style={{ transform: "translateY(2px)" }} />
                  </button>
                </div>
                <div className="flex my-2">
                  <textarea
                    className="border px-4 font-mono"
                    style={{
                      height: 105,
                      resize: "none",

                      width: 700,
                    }}
                    disabled={true}
                    value={step2Windows}
                  />
                  <button className={`ml-2 ngfocus color-link-hover`} onClick={() => copyToClipboard(step2Windows)}>
                    <Copy className="ml-1 feather-icon" style={{ transform: "translateY(2px)" }} />
                  </button>
                </div>
                <div className="flex my-2">
                  <textarea
                    className="border px-4 font-mono"
                    style={{
                      height: 127,
                      resize: "none",

                      width: 700,
                    }}
                    disabled={true}
                    value={step3Windows}
                  />
                  <button className={`ml-2 ngfocus color-link-hover`} onClick={() => copyToClipboard(step3Windows)}>
                    <Copy className="ml-1 feather-icon" style={{ transform: "translateY(2px)" }} />
                  </button>
                </div>
              </>
            )}
            <li className="list-decimal my-2">
              Execute the command below to get the URL of the deployed function and enter it into the text box below:
            </li>
            <div className="flex items-center">
              <NGButton
                style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                size={"small"}
                variant={activeTab === "linux" ? "primary" : "secondary"}
                onClick={() => setActiveTab("linux")}
              >
                Linux/MacOS
              </NGButton>
              <NGButton
                style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                size={"small"}
                variant={activeTab === "win" ? "primary" : "secondary"}
                onClick={() => setActiveTab("win")}
              >
                Windows
              </NGButton>
            </div>
            {activeTab === "linux" ? (
              <>
                <div className="flex my-2">
                  <textarea
                    className="border px-4 font-mono"
                    style={{
                      height: 227,
                      resize: "none",

                      width: 700,
                    }}
                    disabled={true}
                    value={step4Linux}
                  />
                  <button className={`ml-2 ngfocus color-link-hover`} onClick={() => copyToClipboard(step4Linux)}>
                    <Copy className="ml-1 feather-icon" style={{ transform: "translateY(2px)" }} />
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="flex my-2">
                  <textarea
                    className="border px-4 font-mono"
                    style={{
                      height: 127,
                      resize: "none",

                      width: 700,
                    }}
                    disabled={true}
                    value={step4Windows}
                  />
                  <button className={`ml-2 ngfocus color-link-hover`} onClick={() => copyToClipboard(step4Windows)}>
                    <Copy className="ml-1 feather-icon" style={{ transform: "translateY(2px)" }} />
                  </button>
                </div>
              </>
            )}
            <NGFormElement
              name={"url"}
              label={urlRef.label}
              value={urlRef.value}
              required
              error={urlRef.error}
              onChange={urlRef.setValue}
            />
            <li className="list-decimal my-2">
              Execute the command below to get the code of the deployed function and enter it into the text box below:
            </li>
            <div className="flex items-center">
              <NGButton
                style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                size={"small"}
                variant={activeTab === "linux" ? "primary" : "secondary"}
                onClick={() => setActiveTab("linux")}
              >
                Linux/MacOS
              </NGButton>
              <NGButton
                style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                size={"small"}
                variant={activeTab === "win" ? "primary" : "secondary"}
                onClick={() => setActiveTab("win")}
              >
                Windows
              </NGButton>
            </div>
            {activeTab === "linux" ? (
              <>
                <div className="flex my-2">
                  <textarea
                    className="border px-4 font-mono"
                    style={{
                      height: 227,
                      resize: "none",

                      width: 700,
                    }}
                    disabled={true}
                    value={step5Linux}
                  />
                  <button className={`ml-2 ngfocus color-link-hover`} onClick={() => copyToClipboard(step5Linux)}>
                    <Copy className="ml-1 feather-icon" style={{ transform: "translateY(2px)" }} />
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="flex my-2">
                  <textarea
                    className="border px-4 font-mono"
                    style={{
                      height: 127,
                      resize: "none",

                      width: 700,
                    }}
                    disabled={true}
                    value={step5Windows}
                  />
                  <button className={`ml-2 ngfocus color-link-hover`} onClick={() => copyToClipboard(step5Windows)}>
                    <Copy className="ml-1 feather-icon" style={{ transform: "translateY(2px)" }} />
                  </button>
                </div>
              </>
            )}
            <NGFormElement
              name={"code"}
              label={codeRef.label}
              value={codeRef.value}
              required
              error={codeRef.error}
              onChange={codeRef.setValue}
            />
          </ul>
        </>
      ) : null}
    </>
  );
};

export const CloudaccountCreateAzureConfiguration = observer(CloudaccountCreateAzureConfigurationRaw);

const AzureCLISteps: React.FC = () => {
  return (
    <>
      <li className="list-decimal my-2">
        <a
          target={"_blank"}
          href="https://docs.microsoft.com/en-us/cli/azure/install-azure-cli"
          className="underline ngfocus color-link"
        >
          Install the Azure CLI
          <ExternalLink className="ml-1 feather-icon inline-block" style={{ transform: "translateY(2px)" }} />
        </a>
      </li>
      <li className="list-decimal my-2">
        Sign in with your Azure account using the{" "}
        <a
          className="underline ngfocus color-link"
          href={"https://docs.microsoft.com/en-us/cli/azure/authenticate-azure-cli"}
          target={"_blank"}
        >
          Azure CLI
          <ExternalLink className="ml-1 feather-icon inline-block" style={{ transform: "translateY(2px)" }} />
        </a>
        .
      </li>
    </>
  );
};
