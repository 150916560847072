import * as React from "react";
import { observer } from "mobx-react-lite";
// calls ihazip
import * as axios from "axios";
import { Mk8sDraftMobx } from "../../../mst/stores/mk8s.draft";
import { User } from "../../../mobxStores/user/user";
import { NGButton } from "../../../newcomponents/button/Button";
import { FirewallItem } from "../firewallItem";
import { DryRunAlert } from "../dryRunAlert";
import { mk8sDryRun } from "../../../mobxStores/dryRun/mk8s";

interface Props {
  mk8sDraft: Mk8sDraftMobx;
}
const Mk8sCreateFirewallRaw: React.FC<Props> = ({ mk8sDraft }) => {
  const [myIP, setMyIP] = React.useState<string>(null as any);

  async function toggleMyIp(): Promise<void> {
    if (!myIP) {
      return;
    }

    if (f.allowsAll) {
      return;
    }

    if (f.allowsCidr(myIP)) {
      f.removeItemWith(myIP);
    } else {
      f.addItem();
      const item = f.items[f.items.length - 1];
      item.sourceCIDR.setValue(myIP);
      item.description.setValue(`${User.name}'s IP`);
    }
  }

  React.useEffect(() => {
    populateIp();
  }, []);

  async function populateIp() {
    let ip = "";
    try {
      const { data: ipRes } = await axios.default.get("https://ipv4.icanhazip.com/");
      ip = ipRes.trim();
      setMyIP(ip + "/32");
    } catch (e) {
      // notification.warning({ message: "Failed", description: "Failed to get your IP address." });
      return;
    }
  }

  const f = mk8sDraft.firewall;

  const paths: string[] = [];
  f.items.forEach((_, index) => {
    paths.push(`spec.firewall[${index}]`, `spec.firewall[${index}].sourceCIDR`, `spec.firewall[${index}].description`);
  });

  return (
    <>
      <div className="flex items-center mb-4">
        <div className="flex items-center gap-4">
          <div className="text-2xl">Firewall Rules</div>
          <NGButton size={"small"} disabled={!f.isValid} onClick={() => f.addItem()} variant={"action"}>
            Add
          </NGButton>
          {/* TODO check dirtiness by deep equality check */}
          {f.isDefaults ? null : (
            <NGButton size={"small"} onClick={() => f.setDefaults()} variant={"secondary"}>
              Set Defaults
            </NGButton>
          )}
          {!myIP || f.allowsAll ? null : (
            <NGButton size={"small"} onClick={toggleMyIp} variant={"secondary"}>
              {f.allowsCidr(myIP) ? "Remove " : "Add "}My IP
            </NGButton>
          )}
        </div>
      </div>
      <div className="mb-2">
        {f.items.length > 0 ? null : <div className="text-lg mt-4">No firewall rules</div>}
        <div className="flex flex-col gap-1">
          {f.items.map((i) => (
            <>
              <FirewallItem key={i.index} item={i} onRemove={() => f.removeItemAt(i.index)} />
            </>
          ))}
        </div>
      </div>
      <DryRunAlert canShow={true} dryRunResponse={mk8sDryRun.response} path={"spec.firewall"} paths={paths} />
    </>
  );
};

export const Mk8sCreateFirewall = observer(Mk8sCreateFirewallRaw);
