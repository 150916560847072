import React from "react";
import { notification } from "antd";
import { observer } from "mobx-react-lite";
import { UIData } from "./mobxStores/uiData/uiData";
import { ConsoleContext } from "./mobxStores/consoleContext/consoleContext";

const GenericAlertContentRaw = () => {
  React.useEffect(() => {
    if (UIData.isAlertClosed || UIData.alertContent === null) {
      return;
    }
    const key = ConsoleContext.org || "none";
    notification.open({
      key: key,
      type: "warning",
      message: UIData.alertContent.title,
      description: (
        <>
          {UIData.alertContent.link ? (
            <span
              className="cursor-pointer color-link"
              onClick={() => {
                UIData.closeAlert();
                notification.destroy(key);
                ConsoleContext.setRedirect(UIData.alertContent!.link);
              }}
            >
              Click here
            </span>
          ) : null}
          <span>{UIData.alertContent.message}</span>
          <span
            className="block cursor-pointer color-link mt-2"
            onClick={() => {
              localStorage.setItem(`${UIData.alertContent?.id}-${ConsoleContext.org}`, "true");
              UIData.closeAlert();
              notification.destroy(key);
            }}
          >
            Do not show again
          </span>
        </>
      ),
      closable: true,
      duration: 10000,
      onClose: () => UIData.closeAlert(),
      placement: "bottom",
    });

    return () => {
      notification.destroy(key);
    };
  }, [UIData.alertContent]);

  return null;
};

export const GenericAlertContent = observer(GenericAlertContentRaw);
