import { types, flow, applySnapshot } from "mobx-state-tree";
import type { Instance } from "mobx-state-tree";
import {
  WithLinksModel,
  WithIdentifierModel,
  WithVersionModel,
  WithCreatedAndLastModifiedModel,
  WithDescriptionModel,
  WithTagsModel,
  WithKindModel,
  WithJSONModel,
  defaultValues,
} from "../base";
import { request } from "../../services/cpln";
import { defaultCiphers, convertCiphers } from "./domain/ciphers";
import { domainHelpers } from "./domain.helpers";

export const methods: string[] = ["*", "GET", "POST", "PUT", "PATCH", "DELETE", "OPTIONS", "HEAD", "CONNECT", "TRACE"];

export const commonHeaders = [
  "*",
  "Accept-Ranges",
  "Age",
  "Allow",
  "Alternate-Protocol",
  "Cache-Control",
  "Client-Date",
  "Client-Peer",
  "Client-Response-Num",
  "Connection",
  "Content-Disposition",
  "Content-Encoding",
  "Content-Language",
  "Content-Length",
  "Content-Location",
  "Content-MD5",
  "Content-Range",
  "Content-Security-Policy",
  "X-Content-Security-Policy",
  "X-WebKit-CSP",
  "Content-Security-Policy-Report-Only",
  "Content-Type",
  "Date",
  "ETag",
  "Expires",
  "HTTP",
  "Keep-Alive",
  "Last-Modified",
  "Link",
  "Location",
  "P3P",
  "Pragma",
  "Proxy-Authenticate",
  "Proxy-Connection",
  "Refresh",
  "Retry-After",
  "Server",
  "Set-Cookie",
  "Status",
  "Strict-Transport-Security",
  "Timing-Allow-Origin",
  "Trailer",
  "Transfer-Encoding",
  "Upgrade",
  "Vary",
  "Via",
  "Warning",
  "WWW-Authenticate",
  "X-Aspnet-Version",
  "X-Content-Type-Options",
  "X-Frame-Options",
  "X-Permitted-Cross-Domain-Policies",
  "X-Pingback",
  "X-Powered-By",
  "X-Robots-Tag",
  "X-UA-Compatible",
  "X-XSS-Protection",
];

export const RouteHeadersModel = types.model({
  request: types.maybe(
    types.model({
      // Modify the headers for all http requests for this route. | Manipulates HTTP headers
      set: types.maybe(types.frozen()), // Sets or overrides headers to all http requests for this route. {[_: string]: string}
    }),
  ),
});

export const RouteModel = types.model({
  prefix: types.maybe(types.string),
  regex: types.maybe(types.string),
  replacePrefix: types.maybe(types.string),
  hostPrefix: types.maybe(types.string),
  port: types.maybe(types.number),
  replica: types.maybe(types.number),
  workloadLink: types.optional(types.string, ""),
  headers: types.maybe(RouteHeadersModel),
});
export interface RouteMobx extends Instance<typeof RouteModel> {}
export interface Route {
  prefix?: string;
  regex?: string;
  replacePrefix?: string;
  hostPrefix?: string;
  workloadLink: string;
  port?: number;
  replica?: number;
  headers?: {
    request?: {
      set?: any;
    };
  };
}

export const ExternalPortTLSClientCertificateModel = types.model({
  secretLink: types.maybe(types.string), // of kind tls only
});
export interface ExternalPortTLSClientCertificateMobx extends Instance<typeof ExternalPortTLSClientCertificateModel> {}
export interface ExternalPortTLSClientCertificate {
  secretLink?: string;
}

export const ExternalPortTLSServerCertificateModel = types.model({
  secretLink: types.maybe(types.string), // of kind tls only
});
export interface ExternalPortTLSServerCertificateMobx extends Instance<typeof ExternalPortTLSServerCertificateModel> {}
export interface ExternalPortTLSServerCertificate {
  secretLink?: string;
}

export const ExternalPortTLSModel = types
  .model({
    cipherSuites: types.optional(types.array(types.string), defaultCiphers),
    clientCertificate: types.maybe(ExternalPortTLSClientCertificateModel),
    serverCertificate: types.maybe(ExternalPortTLSServerCertificateModel),
    minProtocolVersion: types.optional(types.string, "TLSV1_2"),
  })
  .actions((self) => ({
    afterCreate() {
      const newCipherSuites = convertCiphers(self.cipherSuites);
      self.cipherSuites.clear();
      for (let cipher of newCipherSuites) {
        self.cipherSuites.push(cipher);
      }
    },
  }));
export interface ExternalPortTLSMobx extends Instance<typeof ExternalPortTLSModel> {}
export interface ExternalPortTLS {
  cipherSuites: string[];
  clientCertificate?: ExternalPortTLSClientCertificate;
  serverCertificate?: ExternalPortTLSServerCertificate;
  minProtocolVersion: string;
}

export const CorsModel = types.model({
  allowOrigins: types.array(
    types.model({
      exact: types.string,
    }),
  ),
  allowMethods: types.array(types.string),
  allowHeaders: types.array(types.string),
  exposeHeaders: types.array(types.string),
  maxAge: types.optional(types.string, defaultValues.domain.cors.maxAge), //  /^[\d\.]+[wdhm]+$/
  allowCredentials: types.optional(types.boolean, defaultValues.domain.cors.allowCredentials),
});

export const ExternalPortModel = types.model({
  number: types.optional(types.number, 443),
  protocol: types.optional(types.string, "http2"), // http2 http
  tls: types.maybe(ExternalPortTLSModel),
  routes: types.array(RouteModel), // limit to 1, will be 0-10 later
  cors: types.maybe(CorsModel),
});
export interface ExternalPortMobx extends Instance<typeof ExternalPortModel> {}
export interface ExternalPort {
  protocol: string;
  tls?: ExternalPortTLS;
  routes: Route[];
}

export const DomainSpecModel = types
  .model({
    dnsMode: types.optional(types.string, "ns"), // cname, ns
    // when cname, can only use ports
    gvcLink: types.maybe(types.string), // mutually exclusive with routes
    ports: types.optional(types.array(ExternalPortModel), () => [ExternalPortModel.create()]), // between 0 and 1, will be 1 for now,
    acceptAllHosts: types.optional(types.boolean, false),
  })
  .views((self) => ({
    get routingMode() {
      if (!!self.gvcLink) {
        return "subdomain";
      }
      if (self.ports.some((p) => p.routes.length > 0)) {
        return "pathBased";
      }
      return "none";
    },
  }));

export interface DomainSpecMobx extends Instance<typeof DomainSpecModel> {}
export interface DomainSpec {
  dnsMode: "cname" | "ns";
  gvcLink?: string;
  routingMode: "pathBased" | "subdomain" | "none";
  ports: ExternalPort[];
  acceptAllHosts: boolean;
}

export const DomainStatusModel = types.model({
  endpoints: types.array(
    types.model({
      url: types.string,
      workloadLink: types.string,
    }),
  ),
  status: types.optional(types.string, "initializing"), // 'initializing', 'ready', 'pendingDnsConfig', 'pendingCertificate', 'usedByGvc', 'warning'
  warning: types.optional(types.string, ""),
  locations: types.array(
    types.model({
      name: types.string,
      certificateStatus: types.optional(types.string, "initializing"),
    }),
  ),
  fingerprint: types.optional(types.string, ""),
});

export interface DomainStatusEndpoint {
  url: string;
  workloadLink: string;
}
export interface DomainStatusLocation {
  name: string;
  certificateStatus: DomainStatusEnum;
}

export interface DomainStatusMobx extends Instance<typeof DomainStatusModel> {}

type DomainStatusEnum = "initializing" | "ready" | "pendingDnsConfig" | "pendingCertificate" | "usedByGvc" | "warning";
export interface DomainStatus {
  endpoints: { url: string; workloadLink: string }[];
  status: DomainStatusEnum;
  warning: string;
  locations: { name: string; certificateStatus: DomainStatusEnum }[];
  fingerprint: string;
}

export const DomainModel = types
  .compose(
    "Domain",
    WithIdentifierModel,
    WithDescriptionModel,
    WithCreatedAndLastModifiedModel,
    WithKindModel,
    WithLinksModel,
    WithVersionModel,
    WithTagsModel,
    WithJSONModel,
    types.model({
      name: types.string, // at least 3 sections
      spec: types.maybe(DomainSpecModel),
      status: types.maybe(DomainStatusModel),
    }),
  )
  .views((self) => ({
    get isApex() {
      return domainHelpers.getIsApex(self as any);
    },
    get domainStatusText() {
      return domainHelpers.getDomainStatusText(self as any);
    },
    get type() {
      return domainHelpers.getType(self as any);
    },
    get mappedGVC() {
      return domainHelpers.getMappedGVC(self as any);
    },
    get endpointCount() {
      return domainHelpers.getEndpointCount(self as any);
    },
  }))
  .actions((self) => {
    const fetch: () => Promise<void> = flow(function* () {
      const { data } = yield request({ url: self.selfLink });
      applySnapshot(self, data);
      self.json = data;
    });
    return { fetch };
  })
  .actions((self) => {
    const patch: (body: Object) => Promise<void> = flow(function* (body: Object) {
      body = Object.assign({}, body, { id: self.id, version: self.version });
      yield request({ method: "patch", url: self.selfLink, body });
      yield self.fetch();
    });
    return { patch };
  })
  .actions((self) => ({
    setJson(val: any) {
      self.json = val;
    },
  }));

export interface DomainMobx extends Instance<typeof DomainModel> {}
