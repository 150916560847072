import * as React from "react";
import { Route } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { DetailLayout } from "../../components/detail/layout";
import { Events } from "../../components/detail/events";
import { IdentityMobx } from "../../mst/kinds/identity";
import { Info } from "./info";
import { IdentityDraftMobx } from "../../mst/stores/identity.draft";
import { Cloudaccess } from "./cloudaccess";
import { AccessReport } from "../../components/detail/accessReport";
import { DetailNavLink } from "../../components/detail/nav";
import { NativeNetworkResourcesView } from "./nativeNetworkResources/nativeNetworkResourcesView";
import { AuditTrail } from "../../components/detail/auditTrail";
import { NetworkResourcesView } from "./networkResources/networkResourcesView";
import { BasePathContext, useBasePath } from "../../reactContexts/basePathContext";
import { useEventlogs } from "../../reactHooks/useEventlog";
import { Tags } from "../../components/detail/tags";

interface Props {
  identity: IdentityMobx;
  identityDraft: IdentityDraftMobx;
}

const IdentityDetailRaw: React.FC<Props> = ({ identity, identityDraft }) => {
  const basePath = useBasePath("/gvc/:gvc/identity/:identity/*");

  const eventlogHook = useEventlogs(identity.selfLink);

  const links: DetailNavLink[] = [
    { label: "Info", url: "-info" },
    {
      label: "Cloud Access",
      url: "-cloudaccess",
      // icon: {
      //   isActive:
      //     identity.status.aws?.usable === false ||
      //     identity.status.azure?.usable === false ||
      //     identity.status.gcp?.usable === false,
      //   class: "alertCircle",
      //   color: "color-danger",
      // },
    },
    { label: "Network Resources", url: "-networkresources" },
    { label: "Native Networking", url: "-nativenetworkresources" },
    { label: "Tags", url: "-tags" },
    { label: "Events", url: "-events" },
    { label: "Audit Trail", url: `-audittrail` },
    { label: "Access Report", url: "-accessreport" },
  ].filter(Boolean) as any;

  return (
    <BasePathContext.Provider value={basePath}>
      <DetailLayout links={links}>
        <Route path={`-info`} element={<Info identity={identity} identityDraft={identityDraft} />} />
        <Route path={`-cloudaccess`} element={<Cloudaccess identityDraft={identityDraft} identity={identity} />} />
        <Route
          path={`-networkresources/*`}
          element={<NetworkResourcesView identityDraft={identityDraft} patch={identity.patch} />}
        />
        <Route
          path={`-nativenetworkresources`}
          element={<NativeNetworkResourcesView identityDraft={identityDraft} patch={identity.patch} />}
        />
        <Route path={`-tags`} element={<Tags link={identity.selfLink} tags={identity.tags} />} />
        <Route
          path={`-events`}
          element={
            <Events
              kind={"identity"}
              eventlogs={eventlogHook.eventlogs}
              isLoading={eventlogHook.isLoading}
              fetchEventlogs={eventlogHook.fetchEventlogs}
            />
          }
        />
        <Route path={`-audittrail`} element={<AuditTrail kind="identity" resourceId={identity.id} />} />
        <Route path={`-accessreport`} element={<AccessReport itemLink={identity.selfLink} />} />
      </DetailLayout>
    </BasePathContext.Provider>
  );
};

export const IdentityDetail = observer(IdentityDetailRaw);
