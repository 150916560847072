import jsYaml from "js-yaml";
import { Buffer } from "buffer";

export const getTemplate = (
  org: string,
  clusterName: string,
  clusterAlias: string,
  serviceAccountName: string,
  serviceAccountKey: string,
  cacerts: string,
  serverUrl: string,
) => {
  const template = {
    apiVersion: "v1",
    kind: "Config",
    clusters: [
      {
        cluster: {
          "certificate-authority-data": Buffer.from(cacerts).toString("base64"),
          server: serverUrl,
        },
        name: `${org}/${clusterName}/${clusterAlias}`,
      },
    ],
    contexts: [
      {
        context: {
          cluster: `${org}/${clusterName}/${clusterAlias}`,
          user: `${org}/${clusterName}/${clusterAlias}/sa:${serviceAccountName}`,
        },
        name: `${org}/${clusterName}/${clusterAlias}`,
      },
    ],
    "current-context": `${org}/${clusterName}/${clusterAlias}`,
    users: [
      {
        name: `${org}/${clusterName}/${clusterAlias}/sa:${serviceAccountName}`,
        user: {
          token: serviceAccountKey,
        },
      },
    ],
  };

  return jsYaml.dump(template, {
    indent: 2,
    noRefs: true,
    skipInvalid: true,
    lineWidth: 2048,
  });
};
