import * as React from "react";
import { GVCMobx } from "../../mst/kinds/gvc";
import { StringModel } from "../../mobxDataModels/stringModel";
import { observer } from "mobx-react-lite";
import { notification } from "antd";
import { FormButtons } from "../../components/forms/formButtons";
import { useDetailContext } from "../../components/detail/detailContext";
import { TagsNewModel } from "../../mobxDataModels/tagsNewModel";
import { kindMobxToTagsModel } from "../../mst/mobxUtilts";
import { tagLinkUrlPrefixes } from "../../services/utils";
import { PromptContext } from "../../mobxStores/prompt/prompt";
import { useCleanPrompt } from "../../reactHooks/useCleanPrompt";
import { NGLabel } from "../../newcomponents/text/label";
import { CopyButton } from "../../components/copy";
import { NGFormElement } from "../../newcomponents/ngformelement/ngformelement";
import { TagLinksTable } from "../../components/detail/tagLinksTable";
import { InfoTooltip } from "../../components/InfoTooltip";
import { DOCS_URL } from "../../envVariables";
import { CodeSnippet } from "../../components/generic/codeSnippet/codeSnippet";
import { UserData } from "../../mobxStores/userData/userData";
import { ConsoleContext } from "../../mobxStores/consoleContext/consoleContext";

interface Props {
  gvc: GVCMobx;
}
const InfoRaw: React.FC<Props> = ({ gvc }) => {
  const { fetchItem } = useDetailContext();

  const descriptionRef = React.useRef(StringModel.create({ label: "Description", initialValue: gvc.description }));
  const tagsRef = React.useRef(TagsNewModel.create({ tags: kindMobxToTagsModel(gvc) }));

  const [isDirty, setIsDirty] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    PromptContext.setWhen(isDirty || isLoading);
  }, [isDirty, isLoading]);

  useCleanPrompt();

  React.useEffect(() => {
    let res = false;
    if (descriptionRef.current.isDirty) res = true;
    setIsDirty(res);
  }, [descriptionRef.current.isDirty]);

  function reset() {
    descriptionRef.current.reset();
  }

  async function save() {
    try {
      setIsLoading(true);
      const description = descriptionRef.current.value || null;
      const body: any = { description };
      await gvc.patch(body);
      descriptionRef.current.confirm();
      if (!descriptionRef.current.value) {
        descriptionRef.current.setInitialValue(gvc.name);
      }
      notification.success({
        message: "Success",
        description: "Updated GVC",
      });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
      if (e.response.status === 409) {
        if (fetchItem) {
          await fetchItem();
          notification.info({
            message: "Updated Item",
            description: "Fetched the latest version of the item and discarded changes.",
          });
        }
      }
    }
  }

  const prefix = UserData.orgItems.find((o) => o.name === ConsoleContext.org)?.status?.endpointPrefix;

  return (
    <>
      <NGLabel>Alias</NGLabel>
      <div className="flex items-center mb-4">
        <div>{gvc.alias}</div>
        <CopyButton content={gvc.alias} />
      </div>
      <NGFormElement
        name="description"
        label={descriptionRef.current.label}
        value={descriptionRef.current.value}
        onChange={descriptionRef.current.setValue}
      />
      <div className="flex items-center">
        <NGLabel>Endpoint Naming Format</NGLabel>
        <InfoTooltip
          title={[
            <div style={{ display: "inline", whiteSpace: "nowrap" }}>
              Refer to{" "}
              <a
                target="_blank"
                style={{ textDecoration: "underline" }}
                className="color-link"
                href={`${DOCS_URL}/reference/gvc`}
              >
                documentation
              </a>{" "}
              for more information examples.
            </div>,
          ]}
        />
      </div>
      <div className="mb-4">
        <span>{(gvc.spec.endpointNamingFormat || "default") === "default" ? "Default" : "Org"}</span>
        <span> - </span>
        <span>
          {(gvc.spec.endpointNamingFormat || "default") === "default"
            ? "{workloadName}-{gvcName}.cpln.app"
            : `{workloadName}-{gvcName}.${prefix || "{orgEndpointPrefix}"}.cpln.app`}
        </span>
      </div>
      {/* <CodeSnippet
        style={{ paddingInline: "0.5rem", paddingBlock: "0.125rem", width: "fit-content" }}
        className="font-mono mt-1 mb-4"
        hideCopy
        code={
          (gvc.spec.endpointNamingFormat || "default") === "default"
            ? "{workloadName}-{gvcName}.cpln.app"
            : "{workloadName}-{gvcName}.{org}.cpln.app"
        }
      /> */}
      <TagLinksTable
        tableId="gvc-info-tag-links"
        tags={tagsRef.current.editTags.filter((t) => tagLinkUrlPrefixes.some((p) => t.value.startsWith(p)))}
        styles={{ header: { marginBottom: 0 } }}
      />

      <FormButtons
        onReset={reset}
        onSave={save}
        resetDisabled={isLoading || !isDirty}
        saveDisabled={isLoading || !isDirty}
        loading={isLoading}
      />
    </>
  );
};

export const Info = observer(InfoRaw);
