import * as React from "react";
import { observer } from "mobx-react-lite";
import { notification } from "antd";
import { FormElement } from "../../components/forms/formElement";
import { ChevronDown, ChevronUp, Copy } from "react-feather";
import { CodeEditor } from "../group/identityMatcher/codeEditor";
import { Image } from "./../../schema/types/image";
import { TagLinksTable } from "../../components/detail/tagLinksTable";
import { tagLinkUrlPrefixes } from "../../services/utils";
import { NGLabel } from "../../newcomponents/text/label";
import { linksOf } from "../../services/cpln";
import { CopyButton } from "../../components/copy";

interface Props {
  image: Image;
}
const InfoRaw: React.FC<Props> = ({ image }) => {
  const [isManifestOpen, setIsManifestOpen] = React.useState(false);

  function toggleManifest() {
    setIsManifestOpen((val) => !val);
  }

  return (
    <div>
      <NGLabel>Image Link</NGLabel>
      <div className="flex items-center mb-4">
        <div>{linksOf(image).self!}</div>
        <CopyButton content={linksOf(image).self!} />
      </div>
      <div>
        <div className="flex mb-2">
          <button className="ngfocus color-link" onClick={toggleManifest}>
            Manifest
            {isManifestOpen ? (
              <ChevronUp
                data-testid="toggle-manifest"
                className="feather-icon inline-block"
                style={{ transform: "translateY(2px)" }}
              />
            ) : (
              <ChevronDown
                data-testid="toggle-manifest"
                className="feather-icon inline-block"
                style={{ transform: "translateY(2px)" }}
              />
            )}
          </button>
          {isManifestOpen ? (
            <button
              onClick={() => {
                navigator.clipboard.writeText(JSON.stringify(image.manifest, null, 2));
                notification.success({ message: "Copied to clipboard" });
              }}
              className="ngfocus color-link-hover"
            >
              <Copy className="feather-icon ml-2" />
            </button>
          ) : null}
        </div>
        {isManifestOpen ? (
          <CodeEditor
            value={JSON.stringify(image.manifest, null, 2)}
            setValue={() => {}}
            language={"json"}
            options={{
              style: {
                fontSize: 14,
              },
              basicSetup: {
                highlightActiveLine: false,
                highlightActiveLineGutter: false,
                lineNumbers: true,
                autocompletion: false,
                foldGutter: false,
              },
              readOnly: true,
            }}
          />
        ) : null}
      </div>
      <TagLinksTable
        classNames={{ header: "mt-4" }}
        tableId="image-info-tag-links"
        tags={Object.entries(image.tags || {})
          .map(([tagKey, tagValue]) => ({ key: tagKey, value: String(tagValue || "") }))
          .filter((t) => tagLinkUrlPrefixes.some((p) => t.value.startsWith(p)))}
        styles={{ header: { marginBottom: 0 } }}
      />
    </div>
  );
};

export const Info = observer(InfoRaw);
