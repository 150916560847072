import * as React from "react";
import { ViewModal } from "../../../components/modals/viewModal";
import { WorkloadMobx } from "../../../mst/kinds/workload";
import { Dropdown, notification } from "antd";
import { request, resourceLink } from "../../../services/cpln";
import { k8sKeySort, toSortedJSON } from "../../../services/utils";
import { Activity, AlertCircle, ChevronDown, ChevronUp, Copy, ExternalLink, MoreVertical } from "react-feather";
import { observer } from "mobx-react-lite";
import { VolumeSetMobx, VolumeSetModel, VolumeSetStatusVolumeMobx } from "../../../mst/kinds/volumeset";
import { Link, useLocation, useNavigate } from "react-router-dom";
import jsYaml from "js-yaml";
import clsx from "clsx";
import { Message } from "./message";
import { InfoTooltip } from "../../../components/InfoTooltip";
import { useCommandsContext } from "../../../components/detail/commandsContext";
import { Deployment, DeploymentVersion } from "../../../schema/types/workload/deployment";
import { sortBy } from "lodash";
import { ViewInfo } from "../../../mst/kinds/workload.helpers";
import { ConsoleContext } from "../../../mobxStores/consoleContext/consoleContext";
import { BasePathContext } from "../../../reactContexts/basePathContext";
import { CommandModal_ReplicaTermination } from "../commands/commandModal_replicaTermination";

interface Props {
  workload: WorkloadMobx;
  deployments: Deployment[];
}

const StatefulDeploymentsRaw: React.FC<Props> = ({ workload, deployments }) => {
  const basePath = React.useContext(BasePathContext);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const updatedTimeoutIdRef = React.useRef<any>(null);
  const [collapsedDeployments, setCollapsedDeployments] = React.useState<string[]>([]);
  const [isVolumeSetsReady, setIsVolumeSetsReady] = React.useState<boolean>(false);
  const [volumesets, setVolumesets] = React.useState<VolumeSetMobx[]>([]);
  const { commands } = useCommandsContext();
  const [viewInfo, setViewInfo] = React.useState<ViewInfo | null>(null);

  // can move all of these additionals modals to a global context and it can be enabled from anywhere, similar to apply
  const [replicaTerminationInfo, setReplicaTerminationInfo] = React.useState<{
    location: string;
    replica: string;
  } | null>(null);
  const [action, setAction] = React.useState<"" | "replicaTermination">("");

  React.useEffect(() => {
    return () => {
      if (updatedTimeoutIdRef.current) {
        clearTimeout(updatedTimeoutIdRef.current);
      }
    };
  }, []);

  React.useEffect(() => {
    // TODO do we update the page when a patch is applied?
    fetchVolumesets();
  }, []);

  async function fetchVolumesets() {
    const links: string[] = [];
    for (const container of workload.spec.containers) {
      for (const volume of container.volumes.filter((v) => v.uri.startsWith("cpln://volumeset/"))) {
        const name = volume.uri.split("cpln://volumeset/")[1];
        const link = resourceLink("volumeset", name);
        if (!links.includes(link)) {
          links.push(link);
        }
      }
    }
    const _volumesets: VolumeSetMobx[] = [];
    for (const link of links) {
      try {
        const { data: volumeset } = await request({ url: link });
        _volumesets.push(VolumeSetModel.create(volumeset));
      } catch (e) {
        console.error("failed to fetch and create mobx instance for volumeset " + link);
      }
    }
    setVolumesets(_volumesets);
    setIsVolumeSetsReady(true);
  }

  function onToggleCollapseDeployment(deployment: string) {
    let _collapsedDeployments = [...collapsedDeployments];
    if (_collapsedDeployments.includes(deployment)) {
      _collapsedDeployments = _collapsedDeployments.filter((x) => x !== deployment);
    } else {
      _collapsedDeployments.push(deployment);
    }
    setCollapsedDeployments(_collapsedDeployments);
  }

  async function handleExportMenuClick(e: any) {
    if (e.key === "json") {
      await exportJSON();
    } else if (e.key === "yaml") {
      await exportYaml();
    }
  }

  async function exportJSON() {
    try {
      const a = document.createElement("a");
      a.style.display = "none";
      a.classList.add("cpln-temp-a");
      a.download = workload.name + "-deployments.json";
      let blob = "";
      const { data } = await request({ url: workload.selfLink + "/deployment" });
      blob = JSON.stringify(toSortedJSON(data), null, 2);
      const file = new Blob([blob], { type: "text/json" });
      const href = URL.createObjectURL(file);
      a.href = href;
      a.click();
    } catch (e) {
      notification.warning({ message: "Failed", description: e.message });
    }
  }

  async function exportYaml() {
    try {
      const a = document.createElement("a");
      a.style.display = "none";
      a.classList.add("cpln-temp-a");
      a.download = workload.name + "-deployments.yaml";
      let blob = "";
      const { data } = await request({ url: workload.selfLink + "/deployment" });
      blob = jsYaml.dump(data, { indent: 2, noRefs: true, sortKeys: k8sKeySort });
      const file = new Blob([blob], { type: "text/yaml" });
      const href = URL.createObjectURL(file);
      a.href = href;
      a.click();
    } catch (e) {
      notification.warning({ message: "Failed", description: e.message });
    }
  }

  const isCollapsedAll = deployments.length === collapsedDeployments.length;

  function onToggleCollapseAll() {
    if (isCollapsedAll) {
      setCollapsedDeployments([]);
    } else {
      setCollapsedDeployments(deployments.map((d) => d.name));
    }
  }

  function getViewModalTitle(): string {
    let value = `Deployments (${workload.name})`;
    if (viewInfo) {
      if (viewInfo.deployment) {
        value = `Deployment ${viewInfo.deployment}`;
      }
      if (viewInfo.version !== undefined) {
        value = `${viewInfo.deployment} ver. ${viewInfo.version}`;
      }
    }
    return value;
  }

  function getViewModalFilename(): string {
    let value = `org-${ConsoleContext.org}-gvc-${workload.gvc}-workload-${workload.name}-deployments`;
    if (viewInfo) {
      if (viewInfo.deployment) {
        value += `-${viewInfo.deployment}`;
      }
      if (viewInfo.version !== undefined) {
        value += `-version-${viewInfo.version}`;
      }
      if (viewInfo.container) {
        value += `-container-${viewInfo.container}`;
      }
    }
    return value;
  }

  function getViewModalObject(): any {
    let value: any = deployments;
    if (viewInfo) {
      if (viewInfo.deployment) {
        value = (value as Deployment[]).find((deployment) => deployment.name === viewInfo.deployment)!;
      }
      if (viewInfo.version !== undefined) {
        if ((value as Deployment).status?.versions) {
          value = (value as Deployment).status!.versions!.find((version) => version.workload === viewInfo.version)!;
        }
      }
      if (viewInfo.container) {
        if ((value as DeploymentVersion).containers) {
          value = (value as DeploymentVersion).containers![viewInfo.container];
        }
      }
    }
    return value;
  }

  return (
    <>
      {viewInfo ? (
        <ViewModal
          kind={"deployment"}
          object={getViewModalObject()}
          onClose={() => setViewInfo(null)}
          title={getViewModalTitle()}
          visible={viewInfo !== null}
          filename={getViewModalFilename()}
          noSlim
        />
      ) : null}
      <div className="absolute top-0 right-0 flex items-start gap-6">
        <button onClick={onToggleCollapseAll} className={"flex items-center ngfocus color-link"}>
          {isCollapsedAll ? "Expand All" : "Collapse All"}
          {isCollapsedAll ? <ChevronDown className={`feather-icon`} /> : <ChevronUp className={`feather-icon`} />}
        </button>
        <div className="flex flex-col items-start">
          <button className="m-0 mr-2 flex items-center ngfocus color-link" onClick={() => setViewInfo({})}>
            <span>View Deployments</span>
          </button>
          <Dropdown
            menu={{
              onClick: handleExportMenuClick,
              items: [
                { key: "json", label: "JSON" },
                { key: "yaml", label: "Yaml" },
              ],
            }}
            trigger={["click"]}
          >
            <button className="m-0 mr-2 flex items-center ngfocus color-link">
              <span>Export Deployments</span>
              <ChevronDown className={`feather-icon`} />
            </button>
          </Dropdown>
        </div>
      </div>
      <div>
        {deployments.length < 1 ? (
          <div className="flex flex-col items-center">
            <AlertCircle className="my-4" />
            <span>No deployments running yet.</span>
          </div>
        ) : null}
        {sortBy([...deployments], "name").map((deployment, deploymentIndex) => {
          let isCollapsed = collapsedDeployments.includes(deployment.name);
          const activeStopCommands = commands.filter(
            (command) =>
              command.type === "stopReplica" &&
              command.spec?.location === deployment.name &&
              command.lifecycleStage === "running",
          );

          return (
            <div key={deployment.name} className={isCollapsed ? "mb-4" : "mb-10"}>
              <div className="mb-4">
                <div className={`text-xl font-medium mr-4 flex items-center`}>
                  <span>{deployment.name}</span>
                  <button className={`ngfocus`} onClick={() => setViewInfo({ deployment: deployment.name })}>
                    <Activity
                      className={`feather-icon ${deployment.status?.ready ? "color-action" : "color-danger"}`}
                    />
                  </button>
                  <button
                    onClick={() => onToggleCollapseDeployment(deployment.name)}
                    className={"ml-4 text-base flex items-center ngfocus color-link"}
                  >
                    {isCollapsed ? "Expand" : "Collapse"}
                    {isCollapsed ? (
                      <ChevronDown className={`feather-icon`} />
                    ) : (
                      <ChevronUp className={`feather-icon`} />
                    )}
                  </button>
                </div>
                {deployment.status?.endpoint ? (
                  <div className="flex items-center mb-4 leading-none text-sm">
                    <div>{deployment.status.endpoint}</div>
                    <button
                      onClick={() => {
                        navigator.clipboard.writeText(deployment.status?.endpoint || "");
                        notification.success({
                          message: "Copied to Clipboard",
                        });
                      }}
                      className={`ml-3 ngfocus color-link`}
                    >
                      <Copy className="feather-icon" />
                    </button>
                    <button
                      className={`ml-3 ngfocus color-link`}
                      onClick={() => window.open(deployment.status?.endpoint, "_blank")}
                    >
                      Open
                      <ExternalLink
                        className="feather-icon ml-1 inline-block"
                        style={{ transform: "translateY(2px)" }}
                      />
                    </button>
                  </div>
                ) : null}
                <Message
                  messages={[
                    deployment.status?.message || "",
                    ...(activeStopCommands.length > 1
                      ? [`Replicas "${activeStopCommands.map((c) => c.spec.replica).join(", ")}" are being restarted`]
                      : activeStopCommands.length > 0
                      ? [`Replica "${activeStopCommands[0].spec.replica}" is being restarted`]
                      : []),
                  ]}
                  // TODO remove logsLink and eventsLink requirement
                  logsLink={pathname.replace("deployment", "-logs?trigger=true")}
                  eventsLink={pathname.replace("deployment", "-events")}
                />
              </div>
              {isCollapsed ? null : (
                <>
                  <div
                    className={`flex items-center px-4 py-2 ${
                      deploymentIndex === 0 ? "mt-6" : ""
                    } font-semibold table-labels text-sm rounded`}
                  >
                    <div className="w-3/12">Replica</div>
                    <div className="w-1/12">Version</div>
                    <div className="w-8/12 flex items-center">
                      <div className="flex-1 flex-grow">Status</div>
                      <div className="flex-1 flex-grow flex items-center">
                        <span>Reserved CPU</span>
                        <InfoTooltip title={"Sum of all containers of the replica"} />
                      </div>
                      <div className="flex-1 flex-grow flex items-center">
                        <span>Reserved Memory</span>
                        <InfoTooltip title={"Sum of all containers of the replica"} />
                      </div>
                      <div className="flex-1 flex-grow flex items-center">
                        <span>Volume Size</span>
                        <InfoTooltip title={"Sum of all containers of the replica"} />
                      </div>
                    </div>
                  </div>
                  {deployment.status?.versions?.map((version, versionIndex) => {
                    const lastItem = versionIndex === (deployment.status?.versions || []).length - 1;

                    const singleReplicaByDefault = (workload.spec.defaultOptions?.autoscaling?.minScale || 0) < 2;
                    const localOptions = workload.spec.localOptions.find((o) => o.location === deployment.name);
                    let singleReplicaByLocal = false;
                    singleReplicaByLocal = !!localOptions && (localOptions?.autoscaling?.minScale || 0) < 2;
                    const singleReplica = singleReplicaByDefault || singleReplicaByLocal;

                    let totalReservedCPU = 0;
                    let totalReservedMemory = 0;
                    const processedVolumesets: string[] = [];
                    let totalStorageSize = 0;

                    for (let container of Object.values(version.containers || {})) {
                      totalReservedCPU += container.resources?.cpu || 0;
                      totalReservedMemory += container.resources?.memory || 0;

                      // storage size calculation
                      const containerVolumeSets = volumesets.filter((vs) => {
                        for (const c of workload.spec.containers) {
                          if (c.name !== container.name) {
                            continue;
                          }
                          if (c.volumes.some((v) => v.uri.includes(`cpln://volumeset/${vs.name}`))) {
                            return true;
                          }
                        }
                        return false;
                      });

                      const containerVolumes: VolumeSetStatusVolumeMobx[] = [];

                      for (const volumeSet of containerVolumeSets) {
                        for (const location of volumeSet.status.locations) {
                          if (location.name !== deployment.name) {
                            continue;
                          }
                          if (volumeSet.spec.fileSystemType !== "shared") {
                            for (const volume of location.volumes) {
                              if (volume.index !== versionIndex) {
                                continue;
                              }
                              if (!processedVolumesets.includes(volumeSet.name)) {
                                processedVolumesets.push(volumeSet.name);
                                containerVolumes.push(volume);
                              }
                            }
                          } else {
                            if (!processedVolumesets.includes(volumeSet.name)) {
                              processedVolumesets.push(volumeSet.name);
                              containerVolumes.push(...location.volumes);
                            }
                          }
                        }
                      }

                      let containerStorageSize = 0;
                      for (const volume of containerVolumes) {
                        containerStorageSize += volume.currentSize;
                      }
                      totalStorageSize += containerStorageSize;
                    }

                    return (
                      <div
                        key={version.name || versionIndex}
                        className={clsx("px-4 py-2 flex flex-col", { "border-b": !lastItem })}
                      >
                        <div className="flex items-center">
                          <div className="w-3/12 flex items-center gap-1">
                            {version.name ? (
                              <Link
                                className="color-link ngfocus"
                                to={`${deployment.name}/replica/${encodeURI(
                                  version.name || "",
                                )}/replicaindex/${versionIndex}`}
                              >
                                {version.name}
                              </Link>
                            ) : (
                              <span>{`Replica ${versionIndex}`}</span>
                            )}
                            <button
                              className={`ngfocus`}
                              onClick={() =>
                                setViewInfo({
                                  deployment: deployment.name,
                                  version: version.workload,
                                })
                              }
                            >
                              <Activity className={`feather-icon ${version.ready ? "color-action" : "color-danger"}`} />
                            </button>
                          </div>
                          <div className="w-1/12">{version.workload}</div>
                          <div className="w-8/12 flex items-center">
                            <div className={`flex-1 flex-grow ${version.ready ? "color-action" : "color-danger"}`}>
                              {version.ready ? "Ready" : "Not Ready"}
                            </div>
                            <div className="flex-1 flex-grow">{totalReservedCPU}m</div>
                            <div className="flex-1 flex-grow">{totalReservedMemory}Mi</div>
                            <div className="flex-1 flex-grow flex items-center">
                              <span>{isVolumeSetsReady ? `${totalStorageSize}GB` : "Loading..."}</span>
                              <div className="flex-grow" />
                              <Dropdown
                                trigger={["click"]}
                                placement={"bottomRight"}
                                menu={{
                                  items: [
                                    { key: "logs", label: "Logs" },
                                    { key: "tail", label: "Tail" },
                                    { key: "connect", label: "Connect" },
                                    { key: "restart", label: "Restart" },
                                  ],
                                  className: "menu",
                                  onClick: ({ key }) => {
                                    switch (key) {
                                      case "logs":
                                        const logLink = `${basePath}/-logs?query=${encodeURIComponent(
                                          `{gvc="${workload.gvc}", location="${deployment.name}", workload="${
                                            workload.name
                                          }", replica="${`${workload.name}-${versionIndex}`}"}`,
                                        )}&execute=true&live=false`;
                                        navigate(logLink);
                                        break;
                                      case "tail":
                                        const tailLink = `${basePath}/-logs?query=${encodeURIComponent(
                                          `{gvc="${workload.gvc}", location="${deployment.name}", workload="${
                                            workload.name
                                          }", replica="${`${workload.name}-${versionIndex}`}"}`,
                                        )}&execute=true&live=true`;
                                        navigate(tailLink);
                                        break;
                                      case "connect":
                                        const connectLink = `${basePath}/-connect?location=${
                                          deployment.name
                                        }&replica=${`${workload.name}-${versionIndex}`}&autoConnect=true`;
                                        navigate(connectLink);
                                        break;
                                      case "restart":
                                        setReplicaTerminationInfo({
                                          location: deployment.name,
                                          replica: `${workload.name}-${versionIndex}`,
                                        });
                                        setAction("replicaTermination");
                                        break;
                                      default:
                                        break;
                                    }
                                  },
                                }}
                              >
                                <button className={`ml-2 color-link ngfocus`} style={{ width: 24 }}>
                                  <MoreVertical style={{ minWidth: 18, minHeight: 18, width: 18, height: 18 }} />
                                </button>
                              </Dropdown>
                            </div>
                          </div>
                        </div>
                        <Message
                          messages={[
                            version.message || "",
                            ...Object.values(version.containers || { message: "" }).map((c) => c.message || ""),
                            singleReplica && String(workload.tags["console/marketplace"]) !== "true"
                              ? "To increase availability, consider using more than 1 replica."
                              : "",
                            ...(version.ready ? [] : workload.status.resolvedImages?.errorMessages || []),
                          ]}
                          isHealthy={version.ready}
                          logsLink={pathname.replace("deployment", "-logs?trigger=true")}
                          eventsLink={pathname.replace("deployment", "-events")}
                        />
                      </div>
                    );
                  })}
                </>
              )}
            </div>
          );
        })}
      </div>
      {action === "replicaTermination" ? (
        <CommandModal_ReplicaTermination
          visible={true}
          onClose={() => {
            setReplicaTerminationInfo(null);
            setAction("");
          }}
          onDone={() => {
            setReplicaTerminationInfo(null);
            setAction("");
          }}
          initialLocation={replicaTerminationInfo?.location}
          initialReplica={replicaTerminationInfo?.replica}
        />
      ) : null}
    </>
  );
};

export const StatefulDeployments = observer(StatefulDeploymentsRaw);
