import * as React from "react";
import { observer } from "mobx-react-lite";
import { SelectMobx } from "../../../mobxDataModels/selectModel";
import { NGLabel } from "../../../newcomponents/text/label";
import { NGSelect } from "../../../newcomponents/select/ngselect";
import { UserData } from "../../../mobxStores/userData/userData";
import { ConsoleContext } from "../../../mobxStores/consoleContext/consoleContext";
import NGAlert from "../../../newcomponents/alert";

interface Props {
  endpoint: SelectMobx;
}
const EndpointRaw: React.FC<Props> = ({ endpoint }) => {
  const prefix = UserData.orgItems.find((o) => o.name === ConsoleContext.org)?.status?.endpointPrefix;

  return (
    <>
      <div>
        <NGLabel name="endpointNamingFormat">{endpoint.label}</NGLabel>
        <NGSelect
          name="endpointNamingFormat"
          style={{ width: 450 }}
          className="mb-4"
          value={endpoint.value}
          options={endpoint.options}
          onChange={(value) => endpoint.setValue(value)}
        />
        <div>Customizes the subdomain format for the canonical workload endpoint.</div>
        <ul>
          <li
            className="ml-6 text-sm"
            style={{ listStyleType: "disc" }}
          >{`Default - leaves it as {workloadName}-{gvcName}.cpln.app`}</li>
          <li
            className="ml-6 text-sm"
            style={{ listStyleType: "disc" }}
          >{`Org - follows the scheme {workloadName}-{gvcName}.{orgEndpointPrefix}.cpln.app`}</li>
        </ul>
        {prefix ? (
          <NGAlert
            className="mt-4"
            size={"small"}
            title={`Endpoint Prefix for Org ${ConsoleContext.org}`}
            message={prefix}
          />
        ) : null}
      </div>
    </>
  );
};

export const Endpoint = observer(EndpointRaw);
